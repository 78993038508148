import { useEffect, useState } from "react";
import { CustomButton, CustomSelectField, Loader, NavigateBack } from "../../components"
import { CompanyService, ServiceCodeService } from "../../_service";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Tooltip } from "@mui/material";
import { Notify, Utils } from "../../utils";
import { Heading } from "../appSettings/AppSettingsStyles";
import { Constant } from "../../config";
import { useAppSelector } from "../../_app";
import { useLocation, useNavigate } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import moment from "moment";
import SimorEffectInvoiceTable from "./SimorEffectInvoiceTable";
import { LoaderContainer } from "../../CommonStyles";
interface serviceCodesInterface {
  id: number;
  name: string;
  fixed_amount: number;
  variable_amount: number;
  description: string;
}
interface Company {
  id: number;
  name: string;
}

const AddRepeatedInvoice = () => {

  // redux
  const navigate = useNavigate();
  const companyId = useAppSelector((state) => state.user.defaultCompnayId);
  const location = useLocation();
  const isEdit = location?.state?.isEdit;
  const repeatInvoiceID = location?.state?.id;
  //state
  const [companyData, setCompanyData] = useState([]);
  const [repeatInvoiceData, setRepeatInvoiceData] = useState<any>({})
  const [selectedCompany, setSelectedCompany] = useState(isEdit ? location?.state?.company : companyId);
  const [repeatInterval, setRepeatInterval] = useState(1);
  const [repeatUnit, setRepeatUnit] = useState("monthly");
  const [invoiceDate, setInvoiceDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState("");
  const [reference, setReference] = useState("");
  const [BillingSign, setBillingSign] = useState("");
  interface CheckSpc {
    company_data: {
      spc_details: {
        company_name: string;
      };
    };
  }
  
  const [checkSpc, setCheckSpc] = useState<CheckSpc | null>(null);
  const [mapToData, setMapToData] = useState<any[]>([])
  //service Code
  const [serviceCodes, setServiceCodes] = useState<serviceCodesInterface[]>([])
  const [serviceCodesList, setServiceCodesList] = useState<serviceCodesInterface[]>([])
  const [selectedServiceCode, setSelectedServiceCode] = useState<serviceCodesInterface | null>(null)
  const [noOfEmployee, setnoOfEmployee] = useState(0)
  let [totalAmount, settotalAmount] = useState(0.00)
  const [gstPercentageDefault, setGstPercentageDefault] = useState("")
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hideContent, setHideContent] = useState({
    hide: false,
    message: ""
  })
  const [Error, setError] = useState({
    hide: false,
    message: ""
  })
  const [dateError, setDateError] = useState({
    hide: false,
    message: ""
  })
  const filteredCompanies: Company[] = companyData.filter((x: any) => x.id === parseInt(selectedCompany));
  const selectedCompanyName: string = filteredCompanies.length > 0 ? filteredCompanies[0].name : "Company not found";

  const grandTotalUpdated = mapToData.reduce((total: number, item: any) => {
    return total + (item.total_amount || 0);
  }, 0);
  const gstPercentage = isEdit ? repeatInvoiceData?.company_data?.billing_details?.gst_percentage : gstPercentageDefault;
  const gstTaotalAmount = (grandTotalUpdated * gstPercentage) / 100;
  const afterGstTaotalAmount = grandTotalUpdated + gstTaotalAmount;
  console.log("isLoading", isLoading);

  const checkSpcAndBillingDetails = async () => {
    setIsLoading(true);
    try {
      setHideContent({
        hide: false,
        message: "",
      });
      const { data, status } = await ServiceCodeService._checkSpcAndBillingDetails(selectedCompany);
      if (status === 200) {
        setGstPercentageDefault(data?.data?.company_data?.billing_details?.gst_percentage);
        setBillingSign(data?.data?.company_data?.billing_details?.currency_details?.symbol);
        setCheckSpc(data?.data)
        const dataLog = data?.data;
        if (dataLog?.is_service_provider_selected === false) {
          setHideContent({
            hide: true,
            message: `SPC not saved for ${selectedCompanyName}. Please switch to the ${selectedCompanyName} and save the SPC in Settings -> Manage Set-up Settings -> Employer Details.`,
          });
        }
        if (dataLog?.is_billing_details === false) {
          setHideContent({
            hide: true,
            message: `Billing Details not saved for ${selectedCompanyName}. Please switch to the ${selectedCompanyName} and save the billing details in Settings -> Manage Set-up Settings -> Billing`,
          });
        }
      }
    } catch (error) {
      Notify("Something went wrong spc and billing", 0);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllCompany = async () => {
    try {
      setIsLoading(true);
      const { data, status } = await CompanyService._getAllComanies(1);
      if (status === 200) {
        setCompanyData(data);
      }
    } catch (error) {
      Notify("Something went wrong, can't get company", 0);
    } finally {
      setIsLoading(false); // Ensuring it runs even in case of error
    }
  };

  const getRepeatInvoiceData = async () => {
    setIsLoading(true);

    try {
      const { data } = await ServiceCodeService._getRepeatInvoiceData(repeatInvoiceID);
      setRepeatInvoiceData(data?.data)
      setMapToData(data?.data?.json_data?.items)
      setSelectedCompany(data?.data?.company)
      setRepeatInterval(data?.data?.interval)
      setRepeatUnit(data?.data?.period_type)
      setEndDate(data?.data?.next_invoice_date)
      setInvoiceDate(data?.data?.start_date)
      setReference(data?.data?.reference)
    } catch (error) {
      Notify("Something went wrong", 0);
    }
    setIsLoading(false);

  };
  const getServiceCodesList = async () => {
    try {
      setIsLoading(() => true);
      const { data, status } = await ServiceCodeService._getServiceCodeList();
      if (status === 200) {
        setServiceCodesList(() =>
          data.data.map((dataObj: any) => ({
            id: dataObj.id,
            name: dataObj.name,
            fixed_amount: dataObj.fixed_amount,
            variable_amount: dataObj.variable_amount,
            description: dataObj.description,
          }))
        );
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsLoading(() => false);


  };

  const handleAddService = (servicecode: any) => {
    setnoOfEmployee(0);
    settotalAmount(0);
    if (totalAmount === 0) {
      totalAmount = totalAmount + servicecode.fixed_amount;
    }

    const updatedFormData = {
      service_id: servicecode.id,
      name: servicecode.name,
      description: servicecode.description,
      fixed_amount: servicecode.fixed_amount,
      variable_amount: servicecode.variable_amount,
      no_of_employees: noOfEmployee,
      total_amount: totalAmount,
    };

    const isDuplicateServiceCode = mapToData?.some((sc: any) => sc.service_id === servicecode.id);

    if (isDuplicateServiceCode) {
      Notify("This service is already added!", 0);
    } else {
      setMapToData((prev: any) => [...prev, updatedFormData]);
      setError({
        hide: false,
        message: ""
      })
      Notify("Service added Successfully", 1);
    }
  };

  const handleChange = (e: any, id: any, field: any) => {

    const newData = mapToData.map((row: any) => {
      if (row.service_id === id) {
        const updatedValue = field === "description" ? e?.target?.value : parseFloat(e?.target?.value) || 0;

        const updatedRow = { ...row, [field]: updatedValue };

        if (field === "fixed_amount" || field === "variable_amount" || field === "no_of_employees") {
          updatedRow.total_amount =
            (parseFloat(updatedRow.fixed_amount) || 0) +
            (parseFloat(updatedRow.variable_amount) || 0) *
            (parseFloat(updatedRow.no_of_employees) || 0);
        }

        return updatedRow;
      }
      return row;
    });

    setMapToData(newData);
  };

  const onDeleteButton = (sid: any) => {
    const updatedData = mapToData.filter((item: any) => item.service_id !== sid);
    setMapToData(updatedData);
  };

  const handleSave = async (action: any) => {

    if (mapToData.length <= 0) {
      setError({
        hide: true,
        message: "* Atleast Select One Service Code"
      }
      )
      return
    }
    if (invoiceDate > endDate) {
      setDateError({
        hide: true,
        message: "* End date cannot less than start date"
      }
      )
      return
    }

    if (!isEdit) {
      let payload = {
        period_type: repeatUnit,
        start_date: invoiceDate,
        end_date: endDate || null,
        company: selectedCompany,
        interval: repeatInterval,
        reference: reference,
        json_data: {
          items: mapToData,
          sub_total: grandTotalUpdated,
          gst: gstPercentage,
          gst_amount: gstTaotalAmount,
          grand_total: afterGstTaotalAmount,
        },
      };

      console.log({ gstPercentage, gstTaotalAmount, afterGstTaotalAmount, grandTotalUpdated });


      try {
        const { status } = await ServiceCodeService._createRepeatInvoice(payload);
        if (status === 201) {
          Notify("Repeat Invoice Created Successfully", 1);
          navigate(-1);
        }
      } catch (e: any) {
        Notify(Constant.common.something_went_wrong, 0);
      }
    } else {
      let payload = {
        id: repeatInvoiceID,
        period_type: repeatUnit,
        start_date: invoiceDate,
        end_date: endDate == "" ? null : endDate,
        company: location?.state?.company,
        reference: reference,
        interval: repeatInterval,
        json_data: {
          items: mapToData,
          sub_total: grandTotalUpdated,
          gst: gstPercentage,
          gst_amount: gstTaotalAmount,
          grand_total: afterGstTaotalAmount,
        },
      };

      try {
        const { status } = await ServiceCodeService._updateRepeatInvoice(repeatInvoiceID, payload);
        if (status === 200) {
          Notify("Repeated Invoice updated Successfully", 1);
          navigate(-1);
        }
      } catch (e: any) {
        if (e?.response?.data?.message || e?.response?.data?.number[0]) {
          Notify(e?.response?.data?.message || e?.response?.data?.number[0], 0);
        } else {
          Notify(Constant.common.something_went_wrong, 0);
        }
      }
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await getAllCompany();
      await getServiceCodesList();
      if (isEdit) {
        await getRepeatInvoiceData();
      }
      setIsLoading(false);
    };
    fetchData();
  }, [isEdit]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await checkSpcAndBillingDetails();
      setIsLoading(false);
    };
    fetchData();
  }, [selectedCompany]);


  return (

    <div>
      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) :

        (<div style={{
          padding: "0px 6px"
        }}>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <NavigateBack />
            <Heading>{isEdit ? `${"Edit Repeating Invoice"}` : `${("New Repeating Invoice")}`}</Heading>
          </div>

          {!isEdit ? <div>
            <CustomSelectField
              require
              title="Select Customer"
              placeholder="Select Customer"
              value={selectedCompany}
              onChangeOption={setSelectedCompany}
              option={companyData.map((value: any) => ({
                key: value.name,
                value: value.id,
              }))}
            />
          </div>
            :
            <div style={{ marginTop: "10px", display: "flex", alignItems: "center", width: "100%", }}>
              <label style={{ fontWeight: "600", width: "50%", maxWidth: "252px" }}>Customer Name</label>

              <div style={{
                padding: "6px",
                marginTop: "4px",
              }}> {repeatInvoiceData?.company_data?.company_details?.name}</div>


            </div>
          }

          <div style={{ marginTop: "10px", display: "flex", alignItems: "center", width: "100%", }}>
            <label style={{ fontWeight: "600", width: "50%", maxWidth: "255px" }}>SPC</label>
            <div
              style={{
                marginTop: "4px",
              }}
            > {checkSpc?.company_data?.spc_details?.company_name || "-"}</div>
          </div>


          {hideContent.hide && <div style={{
            padding: "10px",
            margin: "10px 0",
            borderRadius: "5px",
            fontWeight: "bold",
            backgroundColor: "#f8d7da",
            color: "#856404",
            border: "1px solid #f5c6cb",
          }}>{hideContent.message}</div>
          }

          {/* Repeat Interval */}
          <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}>
            {
              !hideContent.hide &&
              <div style={{ display: "flex", alignItems: "center", marginTop: "10px", gap: "1.7rem" }}>
                <label style={{ fontWeight: "600", width: "50%", maxWidth: "253px" }}>Repeat every{!isEdit && <span style={{ color: "red", fontSize: ".8rem" }}>*</span>}</label>
                <div style={{
                  display: "flex",
                  alignItems: "center",
                }}>
                  <input
                    min="1"
                    type="number"
                    value={repeatInterval}
                    onChange={(e: any) => setRepeatInterval(e.target.value.replace(/[^0-9]/g, ""))}
                    style={{
                      padding: "6px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      marginTop: "4px",
                    }}
                  />
                  <select
                    value={repeatUnit}
                    onChange={(e) => setRepeatUnit(e.target.value)}
                    style={{
                      padding: "5px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      marginTop: "4px",
                    }}
                  >
                    <option value="Month(s)">Month(s)</option>
                  </select>
                </div>
              </div>
            }

            {/* Invoice Date */}
            {
              !hideContent.hide &&
              <div style={{ marginTop: "10px", display: "flex", alignItems: "center", gap: "2rem" }}>
                <label style={{ fontWeight: "600", maxWidth: "252px" }}>Invoice Date:{!isEdit && <span style={{ color: "red", fontSize: ".8rem" }}>*</span>}</label>
                <input
                  type="date"
                  value={invoiceDate}
                  onChange={(e) => setInvoiceDate(e.target.value)}
                  style={{
                    padding: "6px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    marginTop: "4px",
                  }}
                />
              </div>
            }

            {/* End Date */}
            {<div>
              {!hideContent.hide &&
                <div style={{ marginTop: "10px", display: "flex", alignItems: "center", gap: "2rem", paddingRight: "1rem" }}>
                  <label style={{ fontWeight: "600", maxWidth: "252px" }}>End Date (Optional):</label>
                  <input
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    style={{
                      padding: "6px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      marginTop: "4px",
                    }}
                  />
                </div>
              }
              {dateError?.hide && (
                <div style={{
                  padding: "10px",
                  color: "#d8000c",
                }}>
                  {dateError?.message}
                </div>
              )}            </div>
            }

          </div>
          {
            !hideContent.hide &&
            <div style={{ marginTop: "10px", display: "flex", alignItems: "center", gap: "2.8rem", paddingRight: "1rem" }}>
              <label style={{ fontWeight: "600", maxWidth: "252px" }}>Reference:</label>
              <input
                type="text"
                value={reference}
                onChange={(e) => setReference(e.target.value)}
                style={{
                  padding: "6px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  marginTop: "4px",
                }}
                disabled={isEdit}
              />
            </div>
          }

          {
            !hideContent.hide &&
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <div style={{ display: "flex", gap: "1.8rem" }}>
                <label style={{ marginBottom: "", fontSize: "16px", whiteSpace: "nowrap", color: "#495057", fontWeight: "600" }}>
                  Service Code {!isEdit && <span style={{ color: "red", fontSize: ".8rem" }}>*</span>}
                </label>
                <select
                  value={selectedServiceCode?.id || ""}
                  onChange={(e) => {
                    const selectedId = Number(e.target.value);
                    const selectedService =
                      serviceCodesList.find((service: any) => service.id === selectedId) || null;
                    setSelectedServiceCode(selectedService);
                  }}
                  style={{
                    padding: "5px",
                    borderRadius: "0.2rem",
                    border: "1px solid #ccc",
                    minWidth: "180px"

                  }}
                >
                  <option value="" disabled>
                    Select service code
                  </option>
                  {serviceCodesList.map((option: any) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>


              <div style={{ display: "flex", gap: "0.2rem" }}>
                <label style={{ marginBottom: "3px", fontSize: "12px", color: "#F9F9F9" }}>
                  Action
                </label>
                <CustomButton
                  title="Add"
                  onClick={() => handleAddService(selectedServiceCode)}
                  size="small"
                  width="80px"
                  disable={selectedServiceCode === null ? true : false}

                />
              </div>
              {Error?.hide && (
                <div style={{
                  padding: "10px",
                  color: "#d8000c",
                }}>
                  {Error?.message}
                </div>
              )}
            </div>
          }

          {
            !hideContent.hide &&
            <div style={{
              marginTop: "1rem"
            }}>

              <TableContainer component={Paper} sx={{ overflow: 'auto' }}>
                <Table sx={{ minWidth: 650 }} aria-label="invoice table">
                  <TableHead>
                    <TableRow sx={{ background: "#b8daff", color: "#000000de" }}>
                      {["Code", "Description", "Fixed Amount", "Per Employees", "Number", "Amount", "Delete"].map((text, index) => (
                        <TableCell key={index} sx={{ fontWeight: 700, color: "#000000de", padding: "8px" }} align={index > 1 ? "center" : "left"}>{text}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mapToData.length ? mapToData?.map((item: any) => (
                      <TableRow key={item.service_id} sx={{ "&:hover": { backgroundColor: "#e3f2fd" } }}>
                        <TableCell align="left" sx={{ padding: "10px" }}>{item.name}</TableCell>
                        <TableCell align="left" sx={{ padding: "10px" }}>
                          <input value={item.description} onChange={(e) => handleChange(e, item.service_id, "description")} style={{ textAlign: "left", padding: "5px", borderRadius: "4px", width: "300px", border: "1px solid #90caf9" }} />
                        </TableCell>
                        {["fixed_amount", "variable_amount", "no_of_employees"].map((field, idx) => (
                          <TableCell key={idx} align="right" sx={{ padding: "10px" }}>
                            <input value={item[field]} onChange={(e) => handleChange(e, item.service_id, field)} style={{ textAlign: "right", padding: "5px", borderRadius: "4px", border: "1px solid #90caf9", width: "80px" }} />
                          </TableCell>
                        ))}
                        <TableCell align="right" sx={{ fontWeight: 600, padding: "10px" }}>{repeatInvoiceData?.company_data?.billing_details?.currency_details?.symbol || BillingSign}{Utils.getCommanSepartedNumericString(item?.total_amount || "0")}</TableCell>
                        <TableCell align="center" sx={{ display: 'flex', justifyContent: 'center', padding: "10px", }}>
                          <Tooltip title="Remove">
                            <CustomButton type="secondary" size="small" del width="35px" title="" onClick={() => onDeleteButton(item.service_id)} icon={<AiFillDelete />} />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    )) : <SimorEffectInvoiceTable />}


                    {grandTotalUpdated > 0 && <TableRow sx={{ background: "#b8daff", color: "#000000de" }}>
                      <TableCell sx={{ fontWeight: 700, fontSize: "1rem", padding: "8px" }} colSpan={6} align="right">Total:</TableCell>
                      <TableCell sx={{ fontWeight: 700, fontSize: "1rem", padding: "8px" }} colSpan={1} align="right">
                        {repeatInvoiceData?.company_data?.billing_details?.currency_details?.symbol || BillingSign}{Utils.getCommanSepartedNumericString(grandTotalUpdated || "")}
                      </TableCell>
                    </TableRow>
                    }

                  </TableBody>
                </Table>
              </TableContainer>

            </div>
          }

          {
            !hideContent.hide &&
            <div style={{
              display: "flex",
              justifyContent: 'space-between',
              marginTop: "1rem",
            }}>
              <div></div>
              <CustomButton title="Save" onClick={() => handleSave("save")} loading={isLoading} />

            </div>
          }





        </div>)
      }
    </div>
  )
}

export default AddRepeatedInvoice