import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../_app";
import { PayrollService } from "../../_service";
import { Notify, Utils } from "../../utils";
import { RiDeleteBinLine } from "react-icons/ri";
import {
  CustomDateField,
  CustomNumberField,
  CustomSelectField,
  CustomTextField,
  CustomeCkeckboxField,
} from "../customFormField";
import { CustomButton } from "../customButton";
import styled from "styled-components";
import { CommonModal } from "../modals";

const RegularInputItemsAddField = () => {
  let location = useLocation();
  const navigate = useNavigate();

  const employee = useAppSelector((state) => state.employee);
  const employee_id = useAppSelector(
    (state) => state.employee.employee_info.id
  );
  const company_id = useAppSelector((state) => state.user.defaultCompnayId);

  const [customFieldData, setCustomFieldData] = useState<any>();

  const [amount, setAmount] = useState("");
  const [checkbox, setCheckbox] = useState(false);

  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");

  const [select1, setSelect1] = useState("");

  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);

  const [date, setDate] = useState();

  const [hourly_paid_bool, setHourly_paid_bool] = useState<boolean>(false);
  const [rate_overide_hourly_amount, setRate_overide_hourly_amount] =
    useState<string>("0.00");
  const [reateOverride, setReateOverride] = useState<boolean>(false);
  const [fixed_amount, setFixed_amount] = useState<string>("0.00");
  const [hourly_amount, setHourly_amount] = useState<string>("0.00");
  const [monthlyRate, setMonthlyRate] = useState<string>("0.00");
  const handleFixedAmountBlur = () => {
    if (fixed_amount) {
      setFixed_amount((prev) => parseFloat(prev).toFixed(2));
    }
  };
  const handleHourlyRateBlur = () => {
    if (hourly_amount) {
      setHourly_amount((prev) => parseFloat(prev).toFixed(2));
    }
  };
  const [isUpdate, setIsUpdate] = useState(false);

  const handleNavigateBack = () => {
    navigate("/dashboard/payroll/emp", { state: { reload: true } });
  };
  const goBack = () => {
    navigate(-1);
  };
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDel, setLoadingDel] = useState(false);
  const handleDeleteInput = (remove: Boolean) => {
    if (remove) {
      setOpenModal(true);
    } else {
      handleDataUpdate(false);
    }
  };

  const handleDataUpdate = async (remove: Boolean) => {
    try {
      setLoading(true);
      let _amount: any;
      let _value: any;
      let _value1: any;
      let _checkbox: any;
      let _data: any = [];
      let _selected: any;
      let data =
        Object.keys(location.state).length > 0 &&
        !Object.keys(location.state).includes("0")
          ? location.state?.[
              Object.keys(location.state)?.filter((_) => _ !== "item_id")[0]
            ]
          : location.state;
      if (location.pathname.slice(37) === "nsmen_pay") {
        _amount = data.filter((item: any) => item.field_id === "nsmen_pay");
        _checkbox = data.filter(
          (item: any) => item.field_id === "employee_reimbursed"
        );
        if (_amount[0]?.id) {
          _data = [
            ..._data,
            {
              regular_input_item_id: _amount[0].id,
              value: amount,
            },
          ];
        }
        if (_checkbox[0]?.id) {
          _data = [
            ..._data,
            {
              regular_input_item_id: _checkbox[0].id,
              value: checkbox,
            },
          ];
        }
      } else if (location.pathname.slice(37) === "fwl") {
        _amount = data.filter((item: any) => item.field_id === "amount");
        _data = [
          {
            regular_input_item_id: _amount[0].id,
            value: amount,
          },
        ];
      } else if (location.pathname.slice(37) === "savings") {
        _amount = data.filter(
          (item: any) => item.field_id === "regular_deduction"
        );
        _data = [
          {
            regular_input_item_id: _amount[0].id,
            value: amount,
          },
        ];
      } else if (location.pathname.slice(37) === "employee_loan") {
        _amount = data.filter((item: any) => item.field_id === "interest_rate");
        _value = data.filter(
          (item: any) => item.field_id === "regular_repayment"
        );
        _data = [
          {
            regular_input_item_id: _amount[0].id,
            value: value1,
          },
          {
            regular_input_item_id: _value[0].id,
            value: value2,
          },
        ];
      } else if (location.pathname.slice(37) === "self-help_gruop_funds") {
        _checkbox = data.filter(
          (item: any) => item.field_id === "override_cdac_ecf_sinda"
        );
        _selected = data.filter(
          (item: any) => item.field_id === "override_mbmf"
        );
        _amount = data.filter(
          (item: any) => item.field_id === "race_based_override"
        );
        _value = data.filter(
          (item: any) => item.field_id === "mbmf_mbf_override"
        );
        _value1 = data.filter(
          (item: any) => item.field_id === "mbmf_mendaki_override"
        );
        _data = [
          {
            regular_input_item_id: _checkbox[0].id,
            value: checkbox1,
          },
          {
            regular_input_item_id: _selected[0].id,
            value: checkbox2,
          },
          {
            regular_input_item_id: _amount[0].id,
            value: checkbox2 ? 0 : amount,
          },
          {
            regular_input_item_id: _value[0].id,
            value: checkbox1 ? 0 : value1,
          },
          {
            regular_input_item_id: _value1[0].id,
            value: checkbox1 ? 0 : value2,
          },
        ];
      } else if (location.pathname.slice(37) === "pension_provident_fund") {
        _selected = data.filter((item: any) => item.field_id === "calculation");
        _value = data.filter(
          (item: any) => item.field_id === "fixed_contribution_by_employee"
        );
        _value1 = data.filter(
          (item: any) => item.field_id === "fixed_contribution_by_employer"
        );
        _data = [
          {
            regular_input_item_id: _selected[0].id,
            value: select1,
          },
          {
            regular_input_item_id: _value[0].id,
            value: value1,
          },
          {
            regular_input_item_id: _value1[0].id,
            value: value2,
          },
        ];
      } else if (location.pathname.slice(37) === "employer_loan") {
        _amount = data?.filter(
          (item: any) => item.field_id === "interest_rate"
        )[0];
        _value = data?.filter(
          (item: any) => item.field_id === "regular_repayment"
        )[0];
        _data = [
          {
            regular_input_item_id: _amount?.id,
            value: value1,
          },
          {
            regular_input_item_id: _value?.id,
            value: value2,
          },
        ];
      } else if (location.pathname.slice(37) === "share") {
        _amount = data.filter(
          (item: any) => item.field_id === "employee_amount"
        );
        _value = data.filter(
          (item: any) => item.field_id === "employer_amount"
        );
        _data = [
          {
            regular_input_item_id: _amount[0].id,
            value: value1 || 0,
          },
          {
            regular_input_item_id: _value[0].id,
            value: value2 || 0,
          },
        ];
      } else if (location.pathname.slice(37) === "cpf_at_higher_rates") {
        _selected = data.filter(
          (item: any) => item.field_id === "contribution"
        );
        _amount = data.filter(
          (item: any) => item.field_id === "date_effective"
        );
        _data = [
          {
            regular_input_item_id: _selected[0].id,
            value: select1,
          },
          {
            regular_input_item_id: _amount[0].id,
            value: date,
          },
        ];
      } else if (location.pathname.slice(37) === "monthly_comission") {
        _amount = data.filter(
          (item: any) => item.item_id === "monthly_comission"
        )[0];
        _data = [
          {
            regular_input_item_id: _amount?.id,
            value: "",
          },
        ];
      } else if (location.pathname.slice(37) === "basic_salary") {
        _checkbox = data.filter(
          (item: any) => item.field_id === "hourly_paid"
        )[0];

        _value = data.filter((item: any) => item.field_id === "hourly_rate")[0];
        _value1 = data.filter(
          (item: any) => item.field_id === "rate_override"
        )[0];
        _amount = data.filter(
          (item: any) => item.field_id === "fixed_amount"
        )[0];

        _selected = data.filter(
          (item: any) => item.field_id === "override_caluclated_hourly_rate"
        )[0];
        _data = [
          {
            regular_input_item_id: _checkbox?.id,
            value: hourly_paid_bool,
          },
          {
            regular_input_item_id: _selected?.id,
            value: reateOverride,
          },
          {
            regular_input_item_id: _value?.id,
            value: hourly_paid_bool ? hourly_amount : 0,
          },
          {
            regular_input_item_id: _value1?.id,
            value: hourly_paid_bool
              ? "0"
              : reateOverride
              ? rate_overide_hourly_amount
              : "0",
          },
          {
            regular_input_item_id: _amount?.id || 1,
            value: hourly_paid_bool ? "0" : fixed_amount,
          },
        ];
      } else {
        if (customFieldData?.field_name) {
          _data = [
            {
              regular_input_item_id: customFieldData?.id,
              value: amount,
            },
          ];
        } else {
          _data = [
            {
              regular_input_item_id: customFieldData?.id,
              value: "",
            },
          ];
        }
      }
      const month = employee.last_date_of_current_month;

      if (remove) {
        setLoadingDel(true);
        const res = await PayrollService._deleteRegularInputItem(
          employee_id,
          company_id,
          month,
          _data
        );
        if (res.status === 200) {
          setLoadingDel(false);
          if (remove) {
            Notify(
              location.state[0]?.item_name ||
                Object.keys(location.state)?.filter((_) => _ !== "item_id")[0] +
                  " updated successfully",
              1
            );
          }
        }
      } else {
        setLoading(true);
        const res = await PayrollService._updateRegularInputItem(
          employee_id,
          company_id,
          month,
          _data
        );
        if (res.status === 200) {
          setLoading(false);
          if (!remove) {
            Notify(
              location.state[0]?.item_name ||
                Object.keys(location.state)?.filter((_) => _ !== "item_id")[0] +
                  " updated successfully",
              1
            );
          }
        }
      }
      setLoading(false);

      handleNavigateBack();
    } catch (e: any) {
      setLoading(false);
      setLoadingDel(false);
      console.error(e);
      if (e?.response?.status === 401) {
        // disaptch(resetUser());
      } else {
        Notify("Something went wrong!", 0);
      }
    }
  };

  const setDataFromProps = () => {
    let _amount: any;
    let _value: any;
    let _value1: any;
    let _checkbox: any;
    let _data: any;
    let _selected: any;

    const data: any[] =
      location.state?.[
        Object.keys(location.state)?.filter((_) => _ !== "item_id")[0]
      ];
    if (location.pathname.slice(37) === "employer_loan") {
      _amount = data?.filter(
        (item: any) => item.field_id === "interest_rate"
      )[0];
      _value = data?.filter(
        (item: any) => item.field_id === "regular_repayment"
      )[0];
      setValue1(Utils.getPriceFromString(_amount?.value || ""));
      setValue2(Utils.getPriceFromString(_value?.value || ""));
    } else if (location.pathname.slice(37) === "nsmen_pay") {
      _amount = data?.filter((item: any) => item.field_id === "nsmen_pay")[0];
      _checkbox = data?.filter(
        (item: any) => item.field_id === "employee_reimbursed"
      )[0];
      setAmount(Utils.getPriceFromString(_amount?.value));
      setCheckbox(_checkbox?.value === "True" ? true : false);
    } else if (location.pathname.slice(37) === "fwl") {
      _amount = data?.filter((item: any) => item.field_id === "amount")[0];
      setAmount(Utils.getPriceFromString(_amount?.value));
    } else if (location.pathname.slice(37) === "savings") {
      _amount = data?.filter(
        (item: any) => item.field_id === "regular_deduction"
      )[0];
      setAmount(Utils.getPriceFromString(_amount?.value));
    } else if (location.pathname.slice(37) === "employee_loan") {
      _amount = data?.filter(
        (item: any) => item.field_id === "interest_rate"
      )[0];
      _value = data?.filter(
        (item: any) => item.field_id === "regular_repayment"
      )[0];
      setValue1(Utils.getPriceFromString(_amount?.value || ""));
      setValue2(Utils.getPriceFromString(_value?.value || ""));
    } else if (location.pathname.slice(37) === "self-help_gruop_funds") {
      _checkbox = data?.filter(
        (item: any) => item.field_id === "override_cdac_ecf_sinda"
      )[0];
      _selected = data?.filter(
        (item: any) => item.field_id === "override_mbmf"
      )[0];
      _amount = data?.filter(
        (item: any) => item.field_id === "race_based_override"
      )[0];
      _value = data?.filter(
        (item: any) => item.field_id === "mbmf_mbf_override"
      )[0];
      _value1 = data?.filter(
        (item: any) => item.field_id === "mbmf_mendaki_override"
      )[0];
      setCheckbox1(_checkbox?.value === "True" ? true : false);
      setCheckbox2(_selected?.value === "True" ? true : false);
      setAmount(Utils.getPriceFromString(_amount?.value));
      setValue1(Utils.getPriceFromString(_value?.value));
      setValue2(Utils.getPriceFromString(_value1?.value));
    } else if (location.pathname.slice(37) === "pension_provident_fund") {
      _selected = data?.filter(
        (item: any) => item.field_id === "calculation"
      )[0];
      _value = data?.filter(
        (item: any) => item.field_id === "fixed_contribution_by_employee"
      )[0];
      _value1 = data?.filter(
        (item: any) => item.field_id === "fixed_contribution_by_employer"
      )[0];
      setSelect1(_selected?.value);
      setValue1(Utils.getPriceFromString(_value?.value));
      setValue2(Utils.getPriceFromString(_value1?.value));
    } else if (location.pathname.slice(37) === "share") {
      _amount = data?.filter(
        (item: any) => item.field_id === "employee_amount"
      )[0];
      _value = data?.filter(
        (item: any) => item.field_id === "employer_amount"
      )[0];
      setValue1(Utils.getPriceFromString(_amount?.value));
      setValue2(Utils.getPriceFromString(_value?.value));
    } else if (location.pathname.slice(37) === "cpf_at_higher_rates") {
      _selected = data?.filter(
        (item: any) => item.field_id === "contribution"
      )[0];
      _amount = data?.filter(
        (item: any) => item.field_id === "date_effective"
      )[0];
      setSelect1(_selected?.value);
      setValue1(Utils.getPriceFromString(_value?.value));
      setDate(_amount?.value ? _amount?.value : null);
    } else if (location.pathname.slice(37) === "monthly_comission") {
      _data = [];
    } else if (location.pathname.slice(37) === "basic_salary") {
      _checkbox = data?.filter(
        (item: any) => item?.field_id === "hourly_paid"
      )[0];
      _value = data?.filter((item: any) => item?.field_id === "hourly_rate")[0];
      _value1 = data?.filter(
        (item: any) => item?.field_id === "rate_override"
      )[0];
      _amount = data?.filter(
        (item: any) => item?.field_id === "fixed_amount"
      )[0];
      _selected = data?.filter(
        (item: any) => item?.field_id === "override_caluclated_hourly_rate"
      )[0];
      setHourly_paid_bool(_checkbox?.value === "True" ? true : false);
      setReateOverride(_selected?.value === "True" ? true : false);
      setHourly_amount(Utils.getPriceFromString(_value?.value) || "");
      setRate_overide_hourly_amount(
        Utils.getPriceFromString(_value1?.value) || ""
      );
      setFixed_amount(Utils.getPriceFromString(_amount?.value) || "");
    } else {
      setCustomFieldData(data[0]);
      setAmount(data[0]?.value || "");
    }
    setIsUpdate(true);
  };
  useEffect(() => {
    if (!Object.keys(location.state).includes("0")) {
      setDataFromProps();
    } else if (
      location.pathname.slice(37) !== "monthly_comission" ||
      location.pathname.slice(37) !== "nsmen_pay" ||
      location.pathname.slice(37) !== "savings" ||
      location.pathname.slice(37) !== "fwl" ||
      location.pathname.slice(37) !== "employee_loan" ||
      location.pathname.slice(37) !== "share" ||
      location.pathname.slice(37) !== "self-help_gruop_funds" ||
      location.pathname.slice(37) !== "pension_provident_fund" ||
      location.pathname.slice(37) !== "employer_loan" ||
      location.pathname.slice(37) !== "cpf_at_higher_rates" ||
      location.pathname.slice(37) !== "basic_salary"
    ) {
      setCustomFieldData(location.state[0]);
    }
  }, []);

  const getMonthlyRate = async (id:any, fixedAmount:any) => {
    const res = await PayrollService._getMonthlyRate(id, fixedAmount);
    
    if(res.status >= 200 && res.status < 300) {
      setMonthlyRate(res?.data?.data?.monthly_rated || "");
    }
  }

  useEffect(() => {
    getMonthlyRate(employee_id, fixed_amount);
  }, [employee_id, fixed_amount]);

  return (
    <div>
      <h2 style={{ color: "#476575" }}>
        ADD{" "}
        {location.state[0]?.item_name ||
          Object.keys(location.state)?.filter((_) => _ !== "item_id")[0]}
        {` - `}
        {employee?.currentEmployee?.name || ""}
      </h2>
      <CommonModal
        handleClose={() => setOpenModal(false)}
        open={openModal}
        updateData={() => handleDataUpdate(true)}
        body={`Are you sure you want to delete the Regular Input
        ${
          location.state[0]?.item_name ||
          Object.keys(location.state)?.filter((_) => _ !== "item_id")[0]
        }?`}
        loading={loading}
      />
      {/* custom input handle */}
      {location.pathname.slice(37) !== "monthly_comission" &&
        location.pathname.slice(37) !== "nsmen_pay" &&
        location.pathname.slice(37) !== "savings" &&
        location.pathname.slice(37) !== "fwl" &&
        location.pathname.slice(37) !== "employee_loan" &&
        location.pathname.slice(37) !== "share" &&
        location.pathname.slice(37) !== "self-help_gruop_funds" &&
        location.pathname.slice(37) !== "pension_provident_fund" &&
        location.pathname.slice(37) !== "employer_loan" &&
        location.pathname.slice(37) !== "cpf_at_higher_rates" &&
        location.pathname.slice(37) !== "basic_salary" && (
          <div>
            <div
              style={{
                display: "flex",
                marginTop: customFieldData?.field_name ? "3rem" : "1rem",
              }}
            >
              {customFieldData?.field_type !== "float" &&
                !customFieldData?.field_name &&
                customFieldData?.field_type !== "string" && (
                  <p>You will be prompted on every payslip for the Quantity</p>
                )}
              {customFieldData?.field_type === "float" &&
                customFieldData?.field_name && (
                  <CustomNumberField
                    title={customFieldData?.field_name}
                    value={amount}
                    onChangeText={setAmount}
                    onBlur={() =>
                      setAmount(() => Utils.addDecimalAndZeroes(amount))
                    }
                  />
                )}
              {customFieldData?.field_type === "string" &&
                customFieldData?.field_name && (
                  <CustomTextField
                    title={customFieldData?.field_name}
                    value={amount}
                    onChangeText={setAmount}
                  />
                )}
            </div>
          </div>
        )}
      {location.pathname.slice(37) === "monthly_comission" && (
        <div>
          <div style={{ display: "flex", marginTop: "3rem" }}>
            <label htmlFor="amount">
              You will be prompted on every payslip for the Amount
            </label>
          </div>
        </div>
      )}
      {(location.pathname.slice(37) === "nsmen_pay" ||
        location.pathname.slice(37) === "fwl" ||
        location.pathname.slice(37) === "savings") && (
        <div>
          <CustomNumberField
            title={
              location.pathname.slice(37) === "savings"
                ? "Regular deduction"
                : "Amount"
            }
            value={amount}
            onChangeText={setAmount}
          />
        </div>
      )}
      {location.pathname.slice(37) === "nsmen_pay" && (
        <CustomeCkeckboxField
          title="Employee is Reimbursed"
          value={checkbox}
          onChange={() => setCheckbox((_) => !_)}
        />
      )}
      {(location.pathname.slice(37) === "employee_loan" ||
        location.pathname.slice(37) === "share") && (
        <>
          <CustomNumberField
            title={
              location.pathname.slice(37) === "share"
                ? "Employee amount"
                : "Interest rate"
            }
            value={value1}
            onChangeText={setValue1}
          />
          <CustomNumberField
            title={
              location.pathname.slice(37) === "share"
                ? "Employer amount"
                : "Regular repayment"
            }
            value={value2}
            onChangeText={setValue2}
          />
        </>
      )}
      {location.pathname.slice(37) === "self-help_gruop_funds" && (
        <div>
          <CustomeCkeckboxField
            title="Override CDAC / ECF / SINDA"
            value={checkbox1}
            onChange={() => {
              if (!checkbox1) {
                setCheckbox2(false);
              }
              setCheckbox1((_) => !_);
            }}
          />
          {checkbox1 && (
            <CustomNumberField
              title={"Race based override"}
              value={amount}
              onChangeText={setAmount}
            />
          )}

          <CustomeCkeckboxField
            title="Override MBMF"
            value={checkbox2}
            onChange={() => {
              if (!checkbox2) {
                setCheckbox1(false);
              }
              setCheckbox2((_) => !_);
            }}
          />

          {checkbox2 && (
            <>
              <CustomNumberField
                title={"Mbmf mbf override"}
                value={value1}
                onChangeText={setValue1}
              />
              <CustomNumberField
                title={"Mbmf mendaki override"}
                value={value2}
                onChangeText={setValue2}
              />
            </>
          )}
        </div>
      )}
      {location.pathname.slice(37) === "pension_provident_fund" && (
        <div>
          <CustomSelectField
            title="Calculation"
            option={
              JSON.parse(
                (location?.state &&
                  location?.state?.length > 0 &&
                  location?.state
                    ?.filter((item: any) => item.field_id === "calculation")[0]
                    ?.defualt_value?.replaceAll(`'`, `"`)) ||
                  '["Fixed amount per perio"]'
              )?.map((item: string) => ({ key: item, value: item })) || []
            }
            value={select1}
            onChangeOption={setSelect1}
            empty
          />
          {select1 === "Fixed amount per period" && (
            <>
              <CustomNumberField
                title={"Fixed Contribution by Employee"}
                value={value1}
                onChangeText={setValue1}
              />
              <CustomNumberField
                title={"Fixed Contribution by Employer"}
                value={value2}
                onChangeText={setValue2}
              />
            </>
          )}
        </div>
      )}
      {location.pathname.slice(37) === "employer_loan" && (
        <>
          <CustomNumberField
            title={"Interest rate"}
            value={value1}
            onChangeText={setValue1}
            onBlur={() =>
              setValue1(() => Utils.addDecimalAndZeroes(value1))
            }
          />
          <CustomNumberField
            title={"Regular repayment"}
            value={value2}
            onChangeText={setValue2}
            onBlur={() =>
              setValue2(() => Utils.addDecimalAndZeroes(value2))
            }
          />
          <p style={{ marginTop: 10 }}>The Balance Increase and Once-off Repayment may be entered under Payslip Inputs</p>
        </>
      )}
      {location.pathname.slice(37) === "cpf_at_higher_rates" && (
        <div>
          <CustomSelectField
            title="Calculation"
            option={
              JSON.parse(
                (location?.state &&
                  location?.state?.length > 0 &&
                  location?.state
                    ?.filter((item: any) => item.field_id === "contribution")[0]
                    ?.defualt_value?.replaceAll(`'`, `"`)) ||
                  `["Full employer and employee", "Full employer, partial employee"]`
              )?.map((item: string) => ({
                key: item,
                value: item,
              })) || []
            }
            value={select1}
            onChangeOption={setSelect1}
            empty
          />
          <CustomDateField
            label="Date effective"
            date={date || null}
            setDate={setDate}
          />
        </div>
      )}
      {location.pathname.slice(37) === "basic_salary" && (
        <>
          <div>
            <CustomeCkeckboxField
              title="Hourly Paid"
              value={hourly_paid_bool}
              onChange={() => setHourly_paid_bool((_) => !_)}
            />
            {hourly_paid_bool && (
              <CustomNumberField
                title={"Hourly Rate"}
                value={hourly_amount}
                onChangeText={(val) => setHourly_amount(val)}
                onBlur={handleHourlyRateBlur}
              />
            )}
            {!hourly_paid_bool && (
              <CustomNumberField
                title={"Fixed Amount"}
                value={fixed_amount}
                onChangeText={(val) => setFixed_amount(val)}
                onBlur={handleFixedAmountBlur}
              />
            )}
            {!hourly_paid_bool && (
              <CustomeCkeckboxField
                title="Override calculated hourly rate"
                value={reateOverride}
                onChange={() => setReateOverride((_) => !_)}
              />
            )}
            {!!!hourly_paid_bool && (
              <>
                {reateOverride && (
                  <CustomNumberField
                    title={"Rate Override"}
                    value={rate_overide_hourly_amount}
                    onChangeText={(val) => setRate_overide_hourly_amount(val)}
                  />
                )}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "1rem",
                  }}
                >
                  <label style={{ width: "250px", paddingBottom: 0 }}>
                    Hourly rate for monthly rated
                  </label>
                  <Text style={{ height: "auto" }}>
                    <>
                    {/* {parseFloat(
                      `${
                        (parseFloat(fixed_amount) * 12) /
                        (52 * 5 * (employee?.working_hours_per_day || 8))
                      }`
                    ).toFixed(2)} */}
                    {monthlyRate || ""}
                    </>
                  </Text>
                </div>
              </>
            )}
          </div>
          {hourly_paid_bool && (
            <p className="text">
              You will be prompted on every payslip for the Normal hours
            </p>
          )}
        </>
      )}
      {location.pathname.slice(37) === "savings" && (
        <div>
          <p style={{ marginTop: "1rem" }}>
            The Balance Increase and Once-off Deduction may be entered under
            Payslip Inputs
          </p>
        </div>
      )}
      {location.pathname.slice(37) === "employee_loan" && (
        <div>
          <p style={{ marginTop: "1rem" }}>
            The Balance Increase and Once-off Repayment may be entered under
            Payslip Inputs
          </p>
        </div>
      )}

      <div style={{ display: "flex", marginTop: "1rem" }}>
        <CustomButton title="Cancel" type={"secondary"} onClick={goBack} />
        <CustomButton title="Save" onClick={() => handleDeleteInput(false)} disable={loading} loading={loading} />
      </div>
      <div style={{ marginTop: "1rem" }}>
        {isUpdate && location.pathname.slice(37) !== "basic_salary" && (
          <CustomButton
            title="Delete"
            type={"secondary"}
            del
            icon={<RiDeleteBinLine />}
            onClick={() => handleDeleteInput(true)}
            disable={loadingDel}
            loading={loadingDel}
          />
        )}
      </div>
    </div>
  );
};

export default RegularInputItemsAddField;

const Text = styled.p`
  height: 40px;
  width: 100%;
  max-width: 500px;
`;
