import { AiFillDelete, AiFillEdit, AiFillEye } from "react-icons/ai";
import {
  CustomButton,
  DeactivateModal,
  Loader,
  Tooltip,
} from "../../../components";
import { TableHeader } from "../../../components/table";
import { IRASTable } from "../../filing/CPFFilingStyles";
import { TableRow } from "../../payslip/PayslipStyles";
import { Heading } from "../../appSettings/AppSettingsStyles";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { TemplateService } from "../../../_service";
import { Notify, Utils } from "../../../utils";
import { Constant } from "../../../config";
import { LoaderContainer } from "../../../CommonStyles";
import { useAppSelector } from "../../../_app";
import { SearchField, TablePaginationHeader } from "../../dashboard/component";
import { current } from "@reduxjs/toolkit";

const ManageHRTemplate = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hrTemplates, setHrTemplates] = useState<any>([]);
  const [filteredHrTemplates, setFilteredHrTemplates] = useState<any>([]);
  const [isDeleteTemplate, setIsDeleteTemplate] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<any>();
  const [pageInfo, setPageInfo] = useState<any>({
    currentPageNumber: 1,
    dataPerPage: 10,
    totalLength: 0,
  });
  const [searchText, setSearchText] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState("All");
  // const handleChange = (event: any) => {
  //   setSelectedStatus(event.target.value);
  // };

  console.log("hr12", hrTemplates, filteredHrTemplates);

  const statusCode = [
    { id: 10, name: 'All' },
    { id: 9, name: 'Payroll' },
    { id: 1, name: 'HR matters' },
    { id: 2, name: 'Probation' },
    { id: 3, name: 'Self Service' },
    { id: 4, name: 'Instructions' },
    { id: 5, name: 'Leave' },
    { id: 6, name: 'Expense Claim' },
    { id: 7, name: 'TMS' },
    { id: 8, name: 'Others' }

  ]


  const templateTableHeaders = [
    {
      name: "Type",
      width: "10%",
    },
    {
      name: "Email Title",
      width: "20%",
    },

    {
      name: "Code",
      width: "10%",
    },
    // {
    //   name: "Status",
    //   width: "15%",
    // },
    {
      name: "Created Date",
      width: "15%",
    },
    {
      name: "Latest Update",
      width: "15%",
    },
    {
      name: "Updated By",
      width: "20%",
    },
    {
      name: "Action",
    },
  ];

  const companyId = useAppSelector((state) => state.user.defaultCompnayId);

  const getHrTemplates = async () => {
    setIsLoading(() => true);
    try {
      const { data, status } = await TemplateService._getAllTemplates(
        companyId
      );

      if (status === 200) {
        setHrTemplates(() => data.data);
        setFilteredHrTemplates(() => data.data);
        setPageInfo((info: any) => ({
          ...info,
          totalLength: data.data.length,
        }));
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong);
    }
    setIsLoading(() => false);
  };

  const deleteTemplate = async () => {
    try {
      const { status } = await TemplateService._deleteTemplate(deleteId);

      if (status === 200) {
        Notify("HR Template Deleted Successfully", 1);
        setHrTemplates((prevHrTemplates: any) =>
          prevHrTemplates.filter(
            (hrTemplate: any) => hrTemplate.id !== deleteId
          )
        );
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsDeleteTemplate(() => false);
  };

  const handlePageChange = (pageNo: number) => {
    setPageInfo((info: any) => ({
      ...info,
      currentPageNumber: pageNo,
    }));
  };

  const changeSearchText = (value: string) => {
    setSearchText(() => value);
    const allTemplates = hrTemplates;
    const applyFilter = allTemplates.filter((hrTemplate: any) =>
      hrTemplate.title.toLowerCase().includes(value.toLowerCase().trim())
    );
    setPageInfo((info: any) => ({
      ...info,
      totalLength: applyFilter.length,
    }));
    setFilteredHrTemplates(() => applyFilter);
  };

  const handleChange = (event: any) => {
    setSearchText("")
    const selectedStatusId = event.target.value;
    pageInfo.currentPageNumber = 1

    setSelectedStatus(selectedStatusId);

    const allTemplates = hrTemplates;
    let applyFilter = allTemplates;


    if (selectedStatusId !== "All") {
      applyFilter = applyFilter.filter(
        (hrTemplate: any) => hrTemplate.type === selectedStatusId
      );
    }
    if (searchText) {
      applyFilter = applyFilter.filter((hrTemplate: any) =>
        hrTemplate.title.toLowerCase().includes(searchText.toLowerCase().trim())
      );
    }

    setPageInfo((info: any) => ({
      ...info,
      totalLength: applyFilter.length,
    }));
    setFilteredHrTemplates(() => applyFilter);
  };

  useEffect(() => {
    const startIndex =
      pageInfo.currentPageNumber * pageInfo.dataPerPage - pageInfo.dataPerPage;
    const endIndex =
      pageInfo.currentPageNumber * pageInfo.dataPerPage > hrTemplates.length
        ? hrTemplates.length
        : pageInfo.currentPageNumber * pageInfo.dataPerPage;

    let applyFilter = hrTemplates.filter((hrTemplateData: any) =>
      hrTemplateData.title
        .toLowerCase()
        .includes(searchText.toLowerCase().trim())
    );


    if (selectedStatus !== "All") {
      applyFilter = applyFilter.filter(
        (hrTemplate: any) => hrTemplate.type === selectedStatus
      );
    }

    // setPageInfo((info: any) => ({
    //   ...info,
    //   totalLength: applyFilter.length,
    // }));
    setFilteredHrTemplates(() => applyFilter.slice(startIndex, endIndex));
  }, [pageInfo]);

  console.log("hr12", pageInfo);

  useEffect(() => {
    getHrTemplates();
  }, []);

  return (
    <div>
      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Heading>HR Templates</Heading>
            <CustomButton
              title="Add Template"
              type="secondary"
              onClick={() => navigate("add")}
            />
          </div>
          <div style={{
            display: "flex",
            alignContent: "center",
            gap: "1rem",
            margin: "5px"

          }}>
            <SearchField
              value={searchText}
              onFocus={() => {
                setSelectedStatus("All");
                changeSearchText("");
              }}
              onChangeText={changeSearchText} />
            <select
              style={{
                display: "flex",
                width: "200px",
                color: " #495057",
                border: "1px solid #ced4da",
                font: "1rem",
                backgroundColor: "#fff",
                textTransform: "none",
                alignItems: "center",
                padding: "0 5px",
                borderRadius: "3px 0 0 3px",
                fontSize: "15px",
                cursor: "pointer",
                height: "28px"
              }}
              value={selectedStatus}
              onChange={handleChange}
            >
              <option value="" disabled>Filter by Type</option>
              {statusCode.map((status) => (
                <option key={status.id} value={status.name}>
                  {status.name}
                </option>
              ))}
            </select>
          </div>
          <div style={{ width: "100%" }}>
            <TablePaginationHeader
              pageInfo={pageInfo}
              name="HR Templates"
              handlePageValueChange={handlePageChange}
            />
          </div>
          <IRASTable>
            <TableHeader
              headerColumns={templateTableHeaders}
              variant="filled"
            />
            <tbody>
              {filteredHrTemplates?.map((hrTemplate: any) => (
                <TableRow>
                  <td style={{ padding: 10 }}>{hrTemplate.type}</td>
                  <td style={{ padding: 10 }}>{hrTemplate.title}</td>
                  <td style={{ padding: 10 }}>{hrTemplate.code}</td>
                  {/* <td style={{ padding: 10 }}>{hrTemplate.status ? "Active" : "Inactive"}</td> */}
                  <td style={{ padding: 10 }}>
                    {Utils._dateToShow(hrTemplate.created_at)}
                  </td>
                  <td style={{ padding: 10 }}>
                    {Utils._dateToShow(hrTemplate.updated_at)}
                  </td>
                  <td style={{ padding: 10 }}>
                    {(hrTemplate.updated_by)}
                  </td>
                  <td style={{ padding: 10 }}>
                    <div style={{ display: "flex", gap: 20 }}>
                      <Tooltip text="View">
                        <CustomButton
                          size="small"
                          width="30px"
                          title={""}
                          onClick={() => {
                            navigate("view", {
                              state: {
                                hrTemplate,
                              },
                            });
                          }}
                          icon={<AiFillEye />}
                        />
                      </Tooltip>
                      <Tooltip text="Edit">
                        <CustomButton
                          type={"secondary"}
                          size="small"
                          width="30px"
                          title={""}
                          onClick={() => {
                            navigate("edit", {
                              state: {
                                hrTemplate,
                              },
                            });
                          }}
                          icon={<AiFillEdit />}
                        />
                      </Tooltip>
                      <Tooltip text="Delete" del>
                        <CustomButton
                          type={"secondary"}
                          size="small"
                          del
                          width="30px"
                          title={""}
                          onClick={() => {
                            setDeleteId(() => hrTemplate.id);
                            setIsDeleteTemplate(() => true);
                          }}
                          icon={<AiFillDelete />}
                        />
                      </Tooltip>
                    </div>
                  </td>
                </TableRow>
              ))}
              {filteredHrTemplates.length === 0 && <p style={{ textAlign: "center", whiteSpace: "nowrap", padding: "6px" }}>No record founds</p>}
            </tbody>
          </IRASTable>
          {isDeleteTemplate && (
            <DeactivateModal
              open={isDeleteTemplate}
              closeModal={() => setIsDeleteTemplate(() => false)}
              approveAction={() => deleteTemplate()}
              variant="deleteHrTemplate"
            />
          )}
        </>
      )}
    </div>
  );
};

export default ManageHRTemplate;
