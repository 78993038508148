import apiClient from "./BaseService";

import { URL } from "../config";

const _getEmployeeListByCompany = async (id: string, type: any = 0) =>
  apiClient.get(URL.GET_EMPLOYEE_LIST + id + `&action_type=${type}`);

const _getEmployeeListByChar = async (id: string, char: any, type: any = 0) =>
  apiClient.get(
    URL.GET_EMPLOYEE_LIST + id + `&starts_with=${char}&action_type=${type}`
  );

const _getEmployeePayrollListByCompany = async (id: string, payslipDate: any) =>
  apiClient.get(URL.GET_EMPLOYEE_PAYROLL_LIST + id + `&payslip_date=${payslipDate}`);

const _getEmployeeAnnualBonusByCompany = async (id: string, year: any, month: any) =>
  apiClient.get(URL.GET_ANNUAL_BONUS + id + `&year=${year}` + `&month=${month}`);

// --- no longer in use
const _getEmployeeAnnualIncrementByCompany = async (id: string, year: any, month: any) =>
  apiClient.get(URL.GET_ANNUAL_INCREMENT + id + `&year=${year}` + `&effective_month=${month}`);

const _getInitialEmployeeAnnualIncrementData = async (id: string, periodFrom: any, periodTo: any) =>
  apiClient.get(URL.GET_ANNUAL_INCREMENT_INITIAL_DATA + id + `&period_to=${periodTo}`);

const _getEmployeeAnnualIncrementEditData = async (id: string,) =>
  apiClient.get(URL.GET_ANNUAL_INCREMENT_EDIT_DATA + id);

// bonus
const _getInitialEmployeeAnnualBonusData = async (id: string, periodFrom: any, periodTo: any) =>
  apiClient.get(URL.GET_ANNUAL_BONUS_INITIAL_DATA + id + `&period_to=${periodTo}`);

const _getEmployeeAnnualBonusEditData = async (id: string,) =>
  apiClient.get(URL.GET_ANNUAL_BONUS_EDIT_DATA + id);

const _getAnnualIncrementListing = async (id: string) =>
  apiClient.get(URL.ANNUAL_INCREMENT_LISTING + id);

const _getAnnualBonusListing = async (id: string) =>
  apiClient.get(URL.ANNUAL_BONUS_LISTING + id);

const _saveAnnualIncrement = async (data: any) =>
  apiClient.post(URL.SAVE_ANNUAL_INCREMENT, data);

const _updateAnnualIncrement = async (data: any) =>
  apiClient.patch(URL.SAVE_ANNUAL_INCREMENT, data);

const _saveAnnualBonus = async (data: any) =>
  apiClient.post(URL.SAVE_ANNUAL_BONUS, data);

const _updateAnnualBonus = async (data: any) =>
  apiClient.patch(URL.SAVE_ANNUAL_BONUS, data);

const _sendForTellbossApprovalBonus = async (Id: any, action: String) =>
  apiClient.patch(`${URL.SEND_TO_TELLBOSS_APPROVAL_BONUS}${Id}/approval/?action=${action}`)

const _sendForTellbossApprovalIncrement = async (Id: any, action: String) =>
  apiClient.patch(`${URL.SEND_TO_TELLBOSS_APPROVAL_INCREMENT}${Id}/approval/?action=${action}`)

const _getEmployeePayrollListByChar = async (
  id: string,
  char: any,
  payslipDate: any
) =>
  apiClient.get(
    URL.GET_EMPLOYEE_PAYROLL_LIST +
    id +
    `&starts_with=${char}&payslip_date=${payslipDate}`
  );

const _addNewEmployee = async (data: any) =>
  apiClient.post(URL.ADD_NEW_EMPLOYEE, data);

const _addEmployeeRegularHors = async (
  emp_id: string,
  com_id: string,
  data: any
) =>
  apiClient.post(
    URL.ADD_REGULAR_HOURS + `?employee_id=${emp_id}&company_id=${com_id}`,
    data
  );

const _validateEmployeeData = async (queryString: string) =>
  apiClient.get(`${URL.VALIDATE_EMP_DATA}?${queryString}`);

const _sendForApproval = async (data: any) =>
  apiClient.post(URL.SEND_FOR_APPROVAL, data);

const _deleteAnnualIncrement = async (id: any) =>
  apiClient.delete(URL.DELETE_ANNUAL_INCREMENT + `${id}/`);

const _deleteAnnualBonus = async (id: any) =>
  apiClient.delete(URL.DELETE_ANNUAL_BONUS + `${id}/`);

const _getEmployeeChangeLogs = async (empId: any) =>
  apiClient.get(`${URL.GET_EMPLOYEE_CHANGE_LOGS}?employee_id=${empId}`);

const _getEmployeePayslipHistory = async (companyId: any, empId: any) =>
  apiClient.get(`${URL.GET_EMPLOYEE_PAYSLIP_HISTORY}${companyId}&employee_id=${empId}`);

export const EmployeeService = {
  _getEmployeeListByCompany,
  _getEmployeeListByChar,
  _addNewEmployee,
  _addEmployeeRegularHors,
  _validateEmployeeData,
  _sendForApproval,
  _getEmployeePayrollListByCompany,
  _getEmployeePayrollListByChar,
  _getEmployeeAnnualBonusByCompany,
  _getAnnualIncrementListing,
  _deleteAnnualIncrement,
  _getEmployeeAnnualIncrementByCompany,
  _saveAnnualBonus,
  _saveAnnualIncrement,
  _updateAnnualIncrement,
  _getInitialEmployeeAnnualIncrementData,
  _getEmployeeAnnualIncrementEditData,
  _getInitialEmployeeAnnualBonusData,
  _getEmployeeAnnualBonusEditData,
  _getEmployeeChangeLogs,
  _getEmployeePayslipHistory,
  _getAnnualBonusListing,
  _deleteAnnualBonus,
  _updateAnnualBonus,
  _sendForTellbossApprovalBonus,
  _sendForTellbossApprovalIncrement,
};
