import { useEffect, useState } from "react";
import { useAppSelector } from "../../_app";
import { useLocation, useNavigate } from "react-router-dom";
import { PayrollService } from "../../_service";
import { Notify, Utils } from "../../utils";
import { RiDeleteBinLine } from "react-icons/ri";
import { DateField } from "../formField";
import NumberField from "../customFormField/NumberField";
import TextField from "../customFormField/TextField";
import { CustomButton } from "../customButton";
import { CommonModal } from "../modals";
import { Constant } from "../../config";

const EmployeePayslipAddField = () => {
  let location = useLocation();
  const navigate = useNavigate();

  const pathInfo: any = location.pathname.slice(35).split("/");

  const [isUpdate, setIsUpdate] = useState(false);
  const [values, setValues] = useState<any>();
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);

  const handleDateUpdate = (key: string, value: any) => {
    setValues((prev: any) => ({ ...prev, [key]: value, }));
  };

  const updateData = () => {
    console.log('updating data', location.state.calculation_attributes_formated, location.state.attributes
    )
    if (location.state.calculation_attributes_formated) {
      setData(location.state.calculation_attributes_formated);
      const _data: any = {};
      if (location.state.attributes) {
        Object.entries(location.state.attributes).forEach((_: any) => {
          _data[_[0]] = _[1];
        });
        setIsUpdate(true);
      } else {
        Object.entries(location.state.calculation_attributes_formated).forEach(
          (_: any) => {
            _data[_[0]] =
              _[1] === "float"
                ? ""
                : (_[1] === "boolean" || _[1] === "bool")
                ? false
                : _[1] === "date"
                ? new Date().toISOString().split("T")[0]
                : "";
          },
        );
      }
      console.log("data values", _data);
      setValues(_data);
    }
  };
  useEffect(() => {
    if (location.state) {
      updateData();
    }
  }, []);

  const employee = useAppSelector((state) => state.employee);

  const handleNavigateBack = () => {
    navigate("/dashboard/payroll/emp", { state: { reload: true } });
  };
  const goBack = () => {
    navigate(-1);
  };
  const [openModal, setOpenModal] = useState(false);
  const handleDeleteInput = (remove: Boolean) => {
    if (remove) {
      setOpenModal(true);
    } else {
      handleDataUpdate(false);
    }
  };
  const handleDataUpdate = async (remove: Boolean) => {
    // console.log("insideHell handleDataUpdate", {
    //   ...values,
    //   archived: remove,
    //   "balance_increase_is_at_beginning_of_period": values["dont_pay_out_balance_increase"] ? values["balance_increase_is_at_beginning_of_period"] : false
    // },)

    let payload = {
      ...values,
      archived: remove,
    }

    if(location.state.code === "savings" ) {
      payload.pay_out_to_third_party = payload?.pay_out_on_current_payslip ? payload?.pay_out_to_third_party : false;
      payload.payout_amount = payload?.pay_out_on_current_payslip ? payload?.payout_amount : 0;
    }
    
    if(location.state.code === "employer_loan" ) {
      payload.balance_increase_is_at_beginning_of_period = payload?.dont_pay_out_balance_increase ? payload.balance_increase_is_at_beginning_of_period : false;
    }

    try {
      setLoading(true);
      const month = employee.last_date_of_current_month.replaceAll("-", "");
      const res = await PayrollService._updatePayslipCalculationData({
        id: `${pathInfo[1]}/` + employee.employee_info.id + "/" + month + "/",
        params: payload,
      });
      setLoading(false);

      if (res.status === 200) {
        // Notify("Annual Bonus added successfully", 1);
        Notify("Updated successfully!", 1);
        handleNavigateBack();
      } else {
        Notify(Constant.common.something_went_wrong, 0);
      }
    } catch (e: any) {
      setLoading(false);
      if (e.response.status === 401) {
        // disaptch(resetUser());
      } else {
        Notify(Constant.common.something_went_wrong, 0);
      }
    }
  };

  return (
    <div>
      <h2 style={{ color: "#476575" }}>
        {isUpdate ? "Edit" : "ADD"}{" "}
        {isUpdate ? location.state.name : location.state.calculation_name}
        {` - `}
        {employee.currentEmployee.name}
        {` - `}
        {employee.last_date_of_current_month}
      </h2>
      <CommonModal
        handleClose={() => setOpenModal(false)}
        open={openModal}
        updateData={() => handleDataUpdate(true)}
        body={`Are you sure you want to delete the Payslip Input
        ${location?.state?.name || location?.state || ""}?`}
        loading={loading}
      />
      {Object.entries(data || {}).map((item) => {
        return (
          <div key={item[0]}>
            {`${item[1]}` === "float" ? (
              <div>
                {`${item[0]}` === "payout_amount" ? (
                  <div>
                    {values["pay_out_on_current_payslip"] && (
                      <NumberField
                        title={item[0].split("_").join(" ")}
                        onChangeText={(value) =>
                          handleDateUpdate(item[0], value)
                        }
                        value={
                          Utils.getPriceFromString(values?.[item[0]]) || ""
                        }
                      />
                    )}
                  </div>
                ) : (
                  <NumberField
                    title={item[0].split("_").join(" ")}
                    onChangeText={(value) => handleDateUpdate(item[0], value)}
                    value={Utils.getPriceFromString(values?.[item[0]]) || ""}
                    // disabled={item[0] === "balance_increase" ? true : false}
                  />
                )}
              </div>
            ) : `${item[1]}` === "bool" ? (
              `${item[0]}` === "dont_pay_out_balance_increase" ||
              `${item[0]}` === "balance_increase_is_at_beginning_of_period" ||
              `${item[0]}` === "pay_out_on_current_payslip" ||
              `${item[0]}` === "pay_out_to_third_party" ? (
                <div>
                  {(`${item[0]}` === "dont_pay_out_balance_increase" ||
                    `${item[0]}` === "pay_out_on_current_payslip") && (
                      <div
                        style={{
                          display: "flex",
                          marginTop: "1rem",
                          alignItems: "center",
                        }}
                      >
                        <input
                          style={{ width: "1rem" }}
                          type="checkbox"
                          checked={values?.[item[0]] || false}
                          onChange={(e) =>
                            handleDateUpdate(item[0], !values?.[item[0]])
                          }
                        />
                        <label htmlFor="amount" style={{ marginLeft: "1rem" }}>
                          {item[0].split("_").join(" ")}
                        </label>
                      </div>
                    )}

                  {`${item[0]}` ===
                    "balance_increase_is_at_beginning_of_period" &&
                    values["dont_pay_out_balance_increase"] && (
                      <div
                        style={{
                          display: "flex",
                          marginTop: "1rem",
                          alignItems: "center",
                        }}
                      >
                        <input
                          style={{ width: "1rem" }}
                          type="checkbox"
                          checked={values?.[item[0]] || false}
                          onChange={(e) =>
                            handleDateUpdate(item[0], !values?.[item[0]])
                          }
                        />
                        <label htmlFor="amount" style={{ marginLeft: "1rem" }}>
                          {item[0].split("_").join(" ")}
                        </label>
                      </div>
                    )}

                  {`${item[0]}` === "pay_out_to_third_party" &&
                    values["pay_out_on_current_payslip"] && (
                      <div
                        style={{
                          display: "flex",
                          marginTop: "1rem",
                          alignItems: "center",
                        }}
                      >
                        <input
                          style={{ width: "1rem" }}
                          type="checkbox"
                          checked={values?.[item[0]] || false}
                          onChange={(e) =>
                            handleDateUpdate(item[0], !values?.[item[0]])
                          }
                        />
                        <label htmlFor="amount" style={{ marginLeft: "1rem" }}>
                          {item[0].split("_").join(" ")}
                        </label>
                      </div>
                    )}
                </div>
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "1rem",
                      alignItems: "center",
                    }}
                  >
                    <input
                      style={{ width: "1rem" }}
                      type="checkbox"
                      checked={values?.[item[0]] || false}
                      onChange={(e) =>
                        handleDateUpdate(item[0], !values?.[item[0]])
                      }
                    />
                    <label htmlFor="amount" style={{ marginLeft: "1rem" }}>
                      {item[0].split("_").join(" ")}
                    </label>
                  </div>
                  {(isUpdate
                    ? location.state.code
                    : location.state.calculation_code) ===
                    "termination_lump_sums" &&
                    values?.[item[0]] && (
                      <div style={{ display: "flex", marginTop: "3rem" }}>
                        <DateField
                          label={item[0].split("_").join(" ")}
                          date={values?.iras_approval_date || new Date()}
                          setDate={(e: any) =>
                            handleDateUpdate("iras_approval_date", e)
                          }
                        />
                      </div>
                    )}
                </div>
              )
            ) : `${item[1]}` === "date" &&
              (isUpdate
                ? location.state.code
                : location.state.calculation_code) !==
                "termination_lump_sums" ? (
              <div style={{ display: "flex", marginTop: "3rem" }}>
                <DateField
                  label={item[0].split("_").join(" ")}
                  date={values?.[item[0]] || new Date()}
                  setDate={(e: any) => handleDateUpdate(item[0], e)}
                />
              </div>
            ) : `${item[1]}` === "str" ? (
              <TextField
                title={item[0].split("_").join(" ")}
                value={values?.[item[0]] || ""}
                onChangeText={(value) => handleDateUpdate(item[0], value)}
              />
            ) : null}
          </div>
        );
      })}

      {/* ----------- Buttons section ------------- */}

    { ( location.state.name==="Employer Loan" || location.state.calculation_name==="Employer Loan")
        ? <p style={{color: '#495057',marginTop:'10px'}}>{"Cash/transfer repayment {No effect on payroll}"}</p>
      : ""
      }
      <div style={{ display: "flex", marginTop: "1rem" }}>
        <CustomButton type="secondary" title="Cancel" onClick={goBack} />
        <CustomButton title="Save" onClick={() => handleDeleteInput(false)} />
      </div>
      <div style={{ display: "flex", marginTop: "1rem" }}>
        {isUpdate && pathInfo[0] !== "normal_hours" && 
        location.state.code !== "employer_loan" && location.state.code !== "savings" && (
          <CustomButton
            del
            type="secondary"
            title="Delete"
            onClick={() => handleDeleteInput(true)}
            icon={<RiDeleteBinLine />}
          />
        )}
      </div>
    </div>
  );
};

export default EmployeePayslipAddField;
