import { useState, ChangeEvent, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  FormControl,
  Select,
  SelectChangeEvent,
  MenuItem,
  styled,
  List,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import { useQuery, useMutation } from "react-query";

import { CompanyService } from "../../_service";
import {
  OrganisationIdTypeInterface,
  FilingDetailsInterface,
} from "../../_types";
import { useAppSelector } from "../../_app";
import { AxiosError } from "axios";
import { CustomButton } from "../../components/customButton";
import { useNavigate } from "react-router-dom";
import { Notify } from "../../utils";

const Row = styled(Box)(({theme}) => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "20px",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}))

const Label = styled(Typography)(({theme}) => ({
  [theme.breakpoints.up("md")]: {
    width: "400px",
  }
}))

const InputField = styled(TextField)(() => ({
  background: "white",
  textTransform: "none",
}))

const ErrorTop = styled(Box)(() => ({
  background: "#f8d7da",
  color: "#721c24",
  padding: "12px 20px"
}))

const SuccessTop = styled(Typography)(() => ({
  background: green[300],
  color: "#000",
  padding: "12px 20px"
}))

const ErrorContainer = styled(Typography)(({theme}) => ({
  color: theme.palette.error.main,
}))

const ErrorList = styled(List)(() => ({
  marginTop: "10px",
  marginLeft: "28px",
}))

interface formErrorInterface {
  [key: string]: string | undefined;
}

const EmployeeFilingDetails = (props:any) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const defaultCompanyId = useAppSelector((state) => +state.user.defaultCompnayId);
  const appSettingId = useAppSelector((state) => state.employee.appSettingId);
  const [showSuccess, setShowSuccess] = useState(false);
  const [data, setData] = useState<OrganisationIdTypeInterface[] | null>(null);

  const [formData, setFormData] = useState<FilingDetailsInterface>({
    id_type: "",
    id_no: "",
    authorised_person_name: "",
    authorised_person_email: "",
    authorised_person_tel_no: "",
    company: defaultCompanyId,
    authorised_person_designation: "",
    cpf_payment_code: "PTE01",

    is_ais_indicator: true,
  });

  const [formErrors, setFormErrors] = useState<formErrorInterface>({
    id_type: "",
    id_no: "",
    authorised_person_name: "",
    authorised_person_email: "",
    authorised_person_tel_no: "",
    cpf_payment_code: "",
  });
  const [responseErrors, setResponseErrors] = useState<any>({});
  const resetForm = () => {
    setFormData({
      id_type: "",
      id_no: "",
      authorised_person_name: "",
      authorised_person_email: "",
      authorised_person_tel_no: "",
      company: defaultCompanyId,
      authorised_person_designation: "",
      cpf_payment_code: "PTE01",
      is_ais_indicator: true,
    });
  };

  const filingDetails = useQuery<FilingDetailsInterface>(
    "filing-details",
    () => CompanyService._getFileDetails(appSettingId),
    {
      enabled: Boolean(appSettingId),
      cacheTime: 0,
    },
  );

  const mutation = useMutation({
    mutationFn: CompanyService._fileDetails,
    onSuccess: () => {
      setShowSuccess(true);
      Notify("Updated successfully", 1);
      filingDetails.refetch();
    },
    onError: (err: AxiosError) => {
      if (err.response) {
        setResponseErrors(err.response.data);
      }
    },
  });

  const updateMutation = useMutation({
    mutationFn: CompanyService._updateFilingDetails,
    onSuccess: () => {
      setShowSuccess(true);
      Notify("Updated successfully", 1);
      filingDetails.refetch();
    },
    onError: (err: AxiosError) => {
      if (err.response) {
        setResponseErrors(err.response.data);
      }
    },
  });

  useEffect(() => {
    if (defaultCompanyId) {
      if (!filingDetails.isError && filingDetails.data) {
        setFormData(filingDetails.data);
      }
    }
  }, [filingDetails.data, defaultCompanyId, filingDetails.isError]);
  useEffect(() => {
    async function fetchData() {
      const res = await CompanyService._getOrgIdTypeList();
      setData(res);
    }
    fetchData();
  }, []);
  const handleInputChange = (value: string | boolean, target: string) => {
    setShowSuccess(false);
    setFormData({
      ...formData,
      [target]: value,
    });
    setFormErrors({
      ...formErrors,
      [target]: "",
    });
    return () => {
      setFormErrors({});
      resetForm();
    };
  };

  useEffect(()=>{
    if(props.setFilingData){
      props.setFilingData(formData);
    }
  }, [formData])

  useEffect(()=>{
    setFormErrors(props.formErrors);
  }, [props.formErrors])
  
  console.log("FormErrors in Filing Comp", formErrors);

  const saveData = () => {
    setShowSuccess(false);
    if (!appSettingId) return;
    let errors: formErrorInterface = {
      id_type: "",
      id_no: "",
      authorised_person_name: "",
      authorised_person_email: "",
      authorised_person_tel_no: "",
      cpf_payment_code: "",
    };


    if (!formData.id_type) {
      errors.id_type = "Organisation ID Type is required";
    }
    if (!formData.id_no) {
      errors.id_no = "Organisation ID No. is required";
    } else {
      if (formData.id_no.length < 10) {
        errors.id_no = "Organisation ID No. Minimum length should be 10";
      }
      const regex=/^[a-zA-Z0-9]+$/;
        if (!regex.test(formData.id_no))
        {
            errors.id_no = "Organisation ID No. must not contain any special characters";
        }
      
     
    }
    if (!formData.authorised_person_name) {
      errors.authorised_person_name = "Authorised Person Name is required";
    }
    if (!formData.authorised_person_email) {
      errors.authorised_person_email = "Authorised Person Email is required";
    } else {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!regex.test(formData.authorised_person_email)) {
        errors.authorised_person_email = "Email is invalid";
      }
    }
    if (!formData.authorised_person_tel_no) {
      errors.authorised_person_tel_no =
        "Authorised Person Telephone is required";
    }
      if (formData?.authorised_person_tel_no?.length > 10 || formData?.authorised_person_tel_no?.length < 8)  {
        errors.authorised_person_tel_no = "Authorised Person Telephone number should have length between 8-10 ";
      }
    
    
    if (!formData.cpf_payment_code) {
      setFormData((prev) => ({ ...prev, cpf_payment_code: "PTE01" }));
    }

    setFormErrors(errors);

    if (Object.values(errors).every((value) => value === "")) {
      if (Object.keys(filingDetails?.data || {}).length === 0) {
        mutation.mutate({ ...formData, company: appSettingId });
      } else {
        updateMutation.mutate({ ...formData, company: appSettingId });
      }
    }
  };


  if (!data) {
    return <></>;
  } else {
    return (
      <Box
        sx={{
          [theme.breakpoints.up("md")]: {
            // paddingRight: "14vw",
          },
        }}
      >
        {mutation.isError && (
          <ErrorTop>
            <Typography fontWeight="bold" fontSize={22}>
              {Object.keys(responseErrors).length} error
              {Object.keys(responseErrors).length > 1 ? "s" : ""} prohibited
              this client from being saved
            </Typography>
            <Typography marginTop="6px">
              There were problems with the following fields:
            </Typography>
            <ErrorList>
              {Object.keys(formErrors).length > 0 &&
                Object.keys(formErrors).map((key) => (
                  <>
                    {responseErrors[key] &&
                      responseErrors[key].map((error: string) => (
                        <li key={error}>
                          <span>{key}</span> - <span>{error}</span>
                        </li>
                      ))}
                  </>
                ))}
            </ErrorList>
          </ErrorTop>
        )}
        {showSuccess && (
          <SuccessTop>
            {appSettingId && "Filing details Updated successfully"}
            {!appSettingId && "Filing details Added successfully"}
          </SuccessTop>
        )}

        <Row>
          <Label>
            Organisation ID Type
          </Label>
          <Box sx={{ width: "100%" }}>
            <FormControl sx={{ width: "100%" }} size="small">
              <Select
                value={formData.id_type}
                sx={{
                  "&": {
                    background: "white",
                  },
                }}
                onChange={(event: SelectChangeEvent<unknown>) => {
                  handleInputChange(event.target.value as string, "id_type");
                }}
                onBlur={() => setFormErrors({ ...formErrors, id_type: "" })}
              >
                {data &&
                  data.length > 0 &&
                  data.map((item) => {
                    return (
                      <MenuItem
                        key={item.item_code}
                        sx={{ background: "" }}
                        value={item.item_code}
                      >
                        {item.item_name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            {formErrors.id_type && (
              <ErrorContainer>{formErrors.id_type}</ErrorContainer>
            )}
          </Box>
        </Row>
        <Row>
          <Label>{'Organisation ID No. (UEN)'}</Label>
          <Box sx={{ width: "100%" }}>
            <InputField
              id="id_no"
              variant="outlined"
              size="small"
              fullWidth
              value={formData.id_no}
              required
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.value, e.target.id)
              }
              onBlur={() => setFormErrors({ ...formErrors, id_no: "" })}
              inputProps={{ style: { textTransform: "none" } }}
            />
            {formErrors.id_no && (
              <ErrorContainer>{formErrors.id_no}</ErrorContainer>
            )}
          </Box>
        </Row>
        <Row>
          <Label>
            Authorised Person Name
          </Label>
          <Box sx={{ width: "100%" }}>
            <InputField
              id="authorised_person_name"
              variant="outlined"
              size="small"
              fullWidth
              value={formData.authorised_person_name}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.value, e.target.id)
              }
              onBlur={() =>
                setFormErrors({ ...formErrors, authorised_person_name: "" })
              }
              inputProps={{ style: { textTransform: "none" } }}
            />
            {formErrors.authorised_person_name && (
              <ErrorContainer>
                {formErrors.authorised_person_name}
              </ErrorContainer>
            )}
          </Box>
        </Row>
        <Row>
          <Label>
            Authorised Person Designation
          </Label>
          <InputField
            // placeholder="76 North Bridge Rd."
            id="authorised_person_designation"
            variant="outlined"
            size="small"
            fullWidth
            value={formData.authorised_person_designation}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.value, e.target.id)
            }
            inputProps={{ style: { textTransform: "none" } }}
          />
        </Row>
        <Row>
          <Label>
            Authorised Person Email
          </Label>
          <Box sx={{ width: "100%" }}>
            <InputField
              id="authorised_person_email"
              variant="outlined"
              size="small"
              fullWidth
              value={formData.authorised_person_email}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.value, e.target.id)
              }
              onBlur={() =>
                setFormErrors({ ...formErrors, authorised_person_email: "" })
              }
              inputProps={{ style: { textTransform: "none" } }}
            />
            {formErrors.authorised_person_email && (
              <ErrorContainer>
                {formErrors.authorised_person_email}
              </ErrorContainer>
            )}
          </Box>
        </Row>
        <Row>
          <Label>
            Authorised Person Telephone Number
          </Label>
          <Box sx={{ width: "100%" }}>
            <InputField
              id="authorised_person_tel_no"
              variant="outlined"
              size="small"
              fullWidth
              value={formData.authorised_person_tel_no}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
              
                handleInputChange(e.target.value, e.target.id)
              }
              onBlur={() =>
                setFormErrors({ ...formErrors, authorised_person_tel_no: "" })
              }
              inputProps={{ style: { textTransform: "none" } }}
            />
            {formErrors.authorised_person_tel_no && (
              <ErrorContainer>
                {formErrors.authorised_person_tel_no}
              </ErrorContainer>
            )}
          </Box>
        </Row>
        <Box
          sx={{
            [theme.breakpoints.up("md")]: {
              width: "400px",
            },
            display: "flex",
            mt: 2.5,
          }}
        >
          <Typography sx={{ mr: 1 }}>In the Auto Inclusion Scheme?</Typography>
          <input
            type="checkbox"
            checked={formData.is_ais_indicator}
            onChange={(e) =>
              handleInputChange(e.target.checked, "is_ais_indicator")
            }
          />
        </Box>
        <Box sx={{ mt: 2.5 }}>
          <Typography color="#204ca0" sx={{ fontWeight: "bold" }}>
            CPF Payment Codes
          </Typography>
        </Box>
        <Row>
          <Label>
            Mandatory Contributions
          </Label>
          <Box sx={{ width: "100%" }}>
            <InputField
              id="cpf_payment_code"
              variant="outlined"
              size="small"
              fullWidth
              value={formData.cpf_payment_code || "PTE01"}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.value, e.target.id)
              }
              inputProps={{ style: { textTransform: "none" } }}
            />
            {formErrors.cpf_payment_code && (
              <ErrorContainer>{formErrors.cpf_payment_code}</ErrorContainer>
            )}
            {/* <Typography
              variant="body2"
              sx={{ marginTop: 0.5, color: "#6c757d" }}
            >
              defaults to PTE01 if left blank
            </Typography> */}
          </Box>
        </Row>
        
       {/* { <Box sx={{ marginTop: 2.5, display: "flex" }}>
          <CustomButton
            title="Cancel"
            type="secondary"
            onClick={() => navigate("/dashboard/app-settings/employer-details")}
          />
          <CustomButton title="Save" onClick={saveData} />
        </Box>  } */}
      </Box>
    );
  }
};

export default EmployeeFilingDetails;
