import { ChangeEvent, useState, useEffect } from "react";
import { Box, Typography, TextField, styled, List } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useQuery, useMutation } from "react-query";
import { AxiosError } from "axios";
import { green } from "@mui/material/colors";
import { useDispatch } from "react-redux";

import { useAppSelector } from "../../_app";
import { CompanyService } from "../../_service";
import { updateAppSettingId, updateUserIntialState } from "../../_features";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../../components/customButton";
import {
  CommonModal,
  CustomeCkeckboxField,
  CustomSelectField,
  Loader,
  NavigateBack,
  Tooltip,
} from "../../components";
import { TableHeader } from "./../../components/table";
import { IRASTable } from "./../filing/CPFFilingStyles";
import { TableRow } from "./../payslip/PayslipStyles";
import { Utils, Notify } from "../../utils";
import { FaCheck } from "react-icons/fa";
import { AiFillDelete, AiOutlineReload, AiFillEdit } from "react-icons/ai";
import { LoaderContainer } from "../../CommonStyles";
import { Constant } from "../../config";
import { ServiceCodeService } from "./../../_service";
import useFirebaseAnalytics from "../../hooks/useFirebaseAnalytics";
import moment from "moment";
import cloneDeep from "lodash.clonedeep";
import SelectField from "../../components/customFormField/SelectField";
import { microAgent } from "../../newRelicConfig";
import { AppSettingsEmployeeFilingDetails } from ".";
import { FilingDetailsInterface } from "../../_types";

const Row = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "20px",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const Label = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    width: "240px",
  },
}));

const InputField = styled(TextField)(() => ({
  background: "white",
  textTransform: "none",
}));

const ErrorTop = styled(Box)(() => ({
  background: "#f8d7da",
  color: "#721c24",
  padding: "12px 20px",
}));

const SuccessTop = styled(Typography)(() => ({
  background: green[300],
  color: "#000",
  padding: "12px 20px",
}));

const ErrorList = styled(List)(() => ({
  marginTop: "10px",
  marginLeft: "28px",
}));

const ErrorContainer = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
}));

// interface formErrorInterface {
//   name: string;
//   address_l1: string;
//   address_code: string;
//   payrun_start: string;
//   service_provider: string;
// }
interface formErrorInterface {
  [key: string]: string | undefined;
}

interface FormDataInterface {
  name: string;
  address_l1: string;
  address_l2: string;
  address_l3: string;
  address_code: "" | number;
  logo: string;
  payrun_start_date: string | null;
  service_provider: "" | number;
}

interface serviceCodesInterface {
  id: number;
  name: string;
  fixed_amount: number;
  variable_amount: number;
  description: string;
}

const repeateInvoiceHeaders = [
  { name: "SPC", width: "20%" },
  { name: "Reference", width: "15%" },
  { name: "Amount", width: "16%" , textAlign : "right" },
  { name: "Repeating", width: "15%" },
  { name: "Next Invoice Date", width: "15%" },
];
const EmployeeDetails = () => {
  const navigation = useNavigate();
  
  const theme = useTheme();
  const date = new Date();

  const logFirebaseEvent = useFirebaseAnalytics();
  const defaultCompanyId = useAppSelector((state) => +state.user.defaultCompnayId);


  const dispatch = useDispatch();
  const appSettingId = useAppSelector((state) => state.employee.appSettingId);
  const [month, setMonth] = useState("");
  const [companyNameValue, setCompanyNameValue] = useState("");
  const [year, setYear] = useState(date.getFullYear());
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [isPayrunReset, setIsPayrunReset] = useState(false);
  const [companyName, setCompanyName] = useState([]);
  const [repeatInvoiceData,setRepeatInvoiceData] = useState([])
  console.log("repeatInvoiceData",repeatInvoiceData );
  
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState<FormDataInterface>({
    name: "",
    address_l1: "",
    address_l2: "",
    address_l3: "",
    address_code: "",
    logo: "",
    payrun_start_date: "",
    service_provider: "",
  });
  const [serviceCodes, setServiceCodes] = useState<serviceCodesInterface[]>([]);
  const [serviceCodesList, setServiceCodesList] = useState<
    serviceCodesInterface[]
  >([]);
  const [selectedServiceCode, setSelectedServiceCode] =
    useState<serviceCodesInterface | null>(null);

  const [showSuccess, setShowSuccess] = useState(false);
  const [formErrors, setFormErrors] = useState<formErrorInterface>({
    name: "",
    address_l1: "",
    address_code: "",
    payrun_start: "",
    service_provider: "",

    id_type: "",
    id_no: "",
    authorised_person_name: "",
    authorised_person_email: "",
    authorised_person_tel_no: "",
    cpf_payment_code: "",
  });
  const [responseErrors, setResponseErrors] = useState<any>({});

  const [filingData, setFilingData] = useState<FilingDetailsInterface>({
    id_type: "",
    id_no: "",
    authorised_person_name: "",
    authorised_person_email: "",
    authorised_person_tel_no: "",
    company: defaultCompanyId,
    authorised_person_designation: "",
    cpf_payment_code: "PTE01",

    is_ais_indicator: true,
  });

  const serviceCodeTableHeaders = [
    {
      name: "Service code",
      width: "20%",
    },
    {
      name: "Description",
      width: "40%",
    },
    {
      name: "Fixed amount",
      width: "20%",
    },
    {
      name: "Amount per employee",
      width: "20%",
    },
    // {
    //   name: "Action",
    //   width: "10%",
    // },

  ];

  // showing -3 to +6 years
  const yearsList = Array.from({length: 10}, (_, i) => date.getFullYear() - 3 + i);
  const monthsOptions = Utils.MONTH_OPTIONS;
  const user = useAppSelector((state) => state.user);

  // const id = useAppSelector((state) => state.user.defaultCompnayId);

  const payFrequency = useMutation({
    mutationFn: CompanyService._createpayFrequency,
  });

  const employeeNumber = useMutation({
    mutationFn: CompanyService._addEmployeeNumber,
  });

  const mutation = useMutation({
    mutationFn: isEdit
      ? CompanyService._patchCompany
      : CompanyService._addNewCompany,
    onSuccess: (res) => {
      !isEdit && resetForm();
      setShowSuccess(true);
      Notify("Updated successfully", 1);


      if (!isEdit) {
        // firebase log here
        let key = "create_company";
        let data = {
          user_id: user.id,
          name: user.name,
          // device_fingerprint: "using_client_js",
          event_date_time: moment().utc().format("DD/MM/YYYY hh:mm A"),
          platform: "web",
        };
        // logFirebaseEvent(key, data);
        microAgent.addPageAction(key, data);

        console.log('inside AddCompany response', res);

        const id = res.data.data.employer_details.id;
        payFrequency.mutate({ id });
        employeeNumber.mutate({ id });
        dispatch(updateAppSettingId(id));
        navigation("/dashboard/manage-company")
        // navigation("/dashboard/app-settings/employer-filing-details");

      } else {
        // navigation("/dashboard/app-settings/employer-details");
        setIsConfirmModal(() => false);
        setIsPayrunReset(() => false);
        dispatch(
          updateUserIntialState({
            ...user,
            current_payrun_date:
              res.data?.current_payrun_date ||
              res.data?.payrun_start_date ||
              null,
            defaultCompanyPayrunStartDate: res.data?.payrun_start_date,
          })
        );
        window.location.reload();
      }
    },
    onError: (err: AxiosError) => {
      console.log('Roman Err', err);
      if (err.response?.data && typeof err.response.data === 'object' && 'data' in err.response.data) {
        setResponseErrors((err.response.data as { data: unknown }).data);
      }
      setIsConfirmModal(() => false);
      setIsPayrunReset(() => false);
    },
  });

  const { data, isLoading, isError } = useQuery(
    "company_details",
    () => CompanyService._getSingleCompany({ id: appSettingId }),
    {
      enabled: Boolean(appSettingId),
      cacheTime: 0,
    }
  );

  console.log("theData", { formData, filingData, formErrors })

  const resetForm = () => {
    setMonth(() => "");
    setYear(() => date.getFullYear());
    setFormData({
      name: "",
      address_l1: "",
      address_l2: "",
      address_l3: "",
      address_code: "",
      logo: "",
      payrun_start_date: "",
      service_provider: "",
    });
    setFilingData({
      id_type: "",
      id_no: "",
      authorised_person_name: "",
      authorised_person_email: "",
      authorised_person_tel_no: "",
      company: defaultCompanyId,
      authorised_person_designation: "",
      cpf_payment_code: "PTE01",
      is_ais_indicator: true,
    });
  };

  const getServiceCodesList = async () => {
    try {
      const { data, status } = await ServiceCodeService._getServiceCodeList();
      if (status === 200) {
        setServiceCodesList(() =>
          data.data.map((dataObj: any) => ({
            id: dataObj.id,
            name: dataObj.name,
            fixed_amount: dataObj.fixed_amount,
            variable_amount: dataObj.variable_amount,
            description: dataObj.description,
          }))
        );
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  const getSPCList = async () => {
    try {
      const { data, status } = await ServiceCodeService._getSpcList();

      if (status === 200) {
        setCompanyName(data.data);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  useEffect(() => {
    let formattedDate = "";
    if (isEdit) {
      if (!!month && isConfirmed) {
        formattedDate = Utils.getLastDayOfMonth(year, month);
      }
    } else {
      if (!!month) {
        formattedDate = Utils.getLastDayOfMonth(year, month);
      }
    }
    handleInputChange(formattedDate, "payrun_start_date");
  }, [month, year]);

  const getRepeatInvoiceList = async () => {
    try {
      const { data, status } = await ServiceCodeService._getRepeatInvoiceList(defaultCompanyId);
      if (status === 200) {
        setRepeatInvoiceData(data?.data);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }


  }

  console.log('ResetYearAndMonth', { year, month });

  useEffect(()=>{
    getRepeatInvoiceList();
  },[])

  useEffect(() => {
    getSPCList();
    getServiceCodesList();

    if (appSettingId) {
      if (!isError && data) {
        data.service_provider = data?.service_provider?.id;
        console.log("company data being fetched", appSettingId, data.payrun_start_date)
        setFormData(data);
        setServiceCodes(data.services);
        setYear(() =>
          data?.payrun_start_date
            ? data?.payrun_start_date?.split("-")[0]
            : date.getFullYear()
        );
        console.log("the year being set", data?.payrun_start_date
          ? data?.payrun_start_date?.split("-")[0]
          : date.getFullYear())
        setMonth(() =>
          data?.payrun_start_date
            ? `${parseInt(data.payrun_start_date?.split("-")[1])}`
            : ""
        );
        setIsConfirmed(() => (data?.payrun_start_date ? true : false));
      }
      setIsEdit(true);

      dispatch(
        updateUserIntialState({
          ...user,
          current_payrun_date:
            data?.current_payrun_date || null,
          defaultCompanyPayrunStartDate: data?.payrun_start_date || null,
        })
      );
    }
  }, [data, appSettingId, isError]);

  const handleMonthChange = (value: any) => {
    setMonth(() => value);
  };
  const handleCompanyChange = (value: any) => {
    setCompanyNameValue(() => value);
  };

  const handleInputChange = (value: any, target: string) => {
    setShowSuccess(false);

    setFormData({
      ...formData,
      [target]: value,
    });
    setFormErrors({ ...formErrors, [target]: "" });
  };

  const confirmPayrunDate = (isReset = false) => {

    let payload: any = {
      'id': defaultCompanyId,
      'employer_details': {
        id: appSettingId,
        payrun_start_date: null,
        current_payrun_date: null,
      },
    }

    if (!isReset) {
      setIsConfirmed(() => true);
    }
    if (isReset) {
      mutation.mutate(payload);
    } else {
      // mutation.mutate({
      //   id: appSettingId,
      //   payrun_start_date: Utils.getLastDayOfMonth(year, month),
      // });
      mutation.mutate({
        'id': defaultCompanyId,
        'employer_details': {
          id: appSettingId,
          payrun_start_date: Utils.getLastDayOfMonth(year, month),
        },
      })
    }

    if (isReset) {
      setIsPayrunReset(() => false);
    }
    // mutation.mutate({ id, payrun_start_date: null});
  };

  const saveData = () => {
    setShowSuccess(false);
    let errors: any = {
      name: "",
      address_l1: "",
      address_code: "",
      payrun_start: "",
      service_provider: "",

      id_type: "",
      id_no: "",
      authorised_person_name: "",
      authorised_person_email: "",
      authorised_person_tel_no: "",
      cpf_payment_code: "",
    };
    if (!formData.name) {
      errors.name = "Name is required";
    }
    if (!formData.address_l1) {
      errors.address_l1 = "Address_l1 is required";
    }
    if (formData.address_code === "" || formData.address_code === null) {
      errors.address_code = "Address Code is required";
    } else {
      if (isNaN(formData.address_code)) {
        errors.address_code = "Address Code should be integer";
      }
    }
    if (!formData.payrun_start_date) {
      if (isEdit) {
        errors.payrun_start =
          "Payrun Start Date must be selected and confirmed";
      } else {
        errors.payrun_start = "Payrun Start Date must be selected";
      }
    }
    if (!formData.service_provider) {
      errors.service_provider = "Service Provider company is required";
    }


    // Checking for filingData errors
    if (!filingData.id_type) {
      errors.id_type = "Organisation ID Type is required";
    }
    if (!filingData.id_no) {
      errors.id_no = "Organisation ID No. is required";
    } else {
      if (filingData.id_no.length < 10) {
        errors.id_no = "Organisation ID No. Minimum length should be 10";
      }
      const regex = /^[a-zA-Z0-9]+$/;
      if (!regex.test(filingData.id_no)) {
        errors.id_no = "Organisation ID No. must not contain any special characters";
      }


    }
    if (!filingData.authorised_person_name) {
      errors.authorised_person_name = "Authorised Person Name is required";
    }
    if (!filingData.authorised_person_email) {
      errors.authorised_person_email = "Authorised Person Email is required";
    } else {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!regex.test(filingData.authorised_person_email)) {
        errors.authorised_person_email = "Email is invalid";
      }
    }
    if (!filingData.authorised_person_tel_no) {
      errors.authorised_person_tel_no =
        "Authorised Person Telephone is required";
    }
    if (filingData?.authorised_person_tel_no?.length > 10 || filingData?.authorised_person_tel_no?.length < 8) {
      errors.authorised_person_tel_no = "Authorised Person Telephone number should have length between 8-10 ";
    }


    if (!filingData.cpf_payment_code) {
      setFormData((prev) => ({ ...prev, cpf_payment_code: "PTE01" }));
    }

    setFormErrors(errors);

    if (Object.values(errors).every((value) => value === "")) {


      let payload: any = {
        'id': defaultCompanyId,
        'employer_details': { ...formData },
        'filling_details': { ...filingData }
      }

      if (!isEdit) {
        delete payload.id;
        delete payload.filling_details.company;
      }

      console.log('payload', payload);

      mutation.mutate(payload);
    }
  };

  const handleRemoveService = async (serviceId: any) => {
    try {
      const payload = {
        company_id: appSettingId,
        service_id: serviceId,
      };
      const { status } = await ServiceCodeService._manageService(
        payload,
        "unsubscribe"
      );
      if (status === 200) {
        setServiceCodes(
          serviceCodes.filter((service) => service.id !== serviceId)
        );
        Notify("Service code removed Successfully", 1);
      }
    } catch (error: any) {
      Notify("Something went wrong", 0);
    }
  };

  const handleToggleService = async (serviceId: any, isCheck: boolean) => {
    try {
      const payload = {
        company_id: appSettingId,
        service_id: serviceId,
      };
      const { status } = await ServiceCodeService._manageService(
        payload,
        isCheck ? "activate" : "deactivate"
      );
      if (status === 200) {
        let data: any = cloneDeep(serviceCodes);
        let index = data?.findIndex((item: any) => item.id === serviceId);
        if (index >= 0) {
          data[index].is_active = isCheck;
        }
        setServiceCodes(data);
        Notify(isCheck ? "Service code enabled" : "Service code disabled", 1);
      }
    } catch (error: any) {
      Notify("Something went wrong", 0);
    }
  };

  const handleAddService = async (serviceId: any) => {
    if (serviceId === null || serviceId === undefined) {
      Notify("No service selected", 0);
      return;
    }

    try {
      const payload = {
        company_id: appSettingId,
        service_id: serviceId,
      };
      const { status } = await ServiceCodeService._manageService(
        payload,
        "subscribe"
      );
      if (status === 200 && selectedServiceCode) {
        setServiceCodes((prevServiceCodes) => [
          ...prevServiceCodes,
          { ...selectedServiceCode, is_active: true },
        ]);
        setSelectedServiceCode(null);
        Notify("Added service code Successfully", 1);
      }
    } catch (error: any) {
      Notify("Something went wrong", 0);
    }
  };

  console.log("year and month paris", {month, year, yearsList} )

  return (
    <Box
      sx={{
        [theme.breakpoints.up("md")]: {
          padding: '0 1rem'
          // paddingRight: "14vw",
        },
        pointerEvents: mutation?.isLoading || isLoading ? "none" : "auto",
      }}
    >
      {(mutation?.isLoading || isLoading) && <Loader />}
      {mutation.isError && (
        <ErrorTop>
          <Typography fontWeight="bold" fontSize={22}>
            {Object.keys(responseErrors).length} error
            {Object.keys(responseErrors).length > 1 ? "s" : ""} prohibited this
            client from being saved
          </Typography>
          <Typography marginTop="6px">
            There were problems with the following fields:
          </Typography>
          <ErrorList>
            {Object.keys(formErrors).length > 0 &&
              Object.keys(formErrors).map((key) => (
                <>
                  {responseErrors[key] &&
                    responseErrors[key].map((error: string) => (
                      <li key={error}>
                        <span>{key}</span> - <span>{error}</span>
                      </li>
                    ))}
                </>
              ))}
          </ErrorList>
        </ErrorTop>
      )}
      {showSuccess && (
        <SuccessTop>
          Employer details {isEdit ? "Updated" : "Added"} successfully.{" "}
          {!isEdit && "Please add the filing details"}
        </SuccessTop>
      )}

      <Box
        sx={{
          marginTop: 2.5,
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
        }}
      >
        <CustomButton
          title="Back"
          type="secondary"
          onClick={() => navigation("/dashboard/app-settings")}
        />
        <CustomButton title="Save" onClick={saveData} />
      </Box>

      <Box
        style={{
          display: "flex",
          width : "99%",
          gap:"1.5rem"
        }}
      >
        <Box style={{ width : "50%" }}>
          <Row>
            <Label>Trading Name</Label>
            <Box sx={{ width: "100%" }}>
              <InputField
                id="outlined-basic"
                variant="outlined"
                size="small"
                fullWidth
                value={formData?.name}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(e.target.value, "name")
                }
                onBlur={() => setFormErrors({ ...formErrors, name: "" })}
                inputProps={{ style: { textTransform: "none" } }}
              />
              {formErrors.name && (
                <ErrorContainer>{formErrors.name}</ErrorContainer>
              )}
            </Box>
          </Row>
          <Row>
            <Label>Payrun Start Month and Year</Label>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: 2,
                alignItems: "center",
              }}
            >
              <CustomSelectField
                margin="0rem"
                placeholder="Select Month"
                value={month}
                onChangeOption={handleMonthChange}
                option={monthsOptions.map((month: any) => ({
                  key: month.key,
                  value: month.value,
                }))}
                disabled={isConfirmed}
                isDate={true}
                width="150px"
              />
              <CustomSelectField
                margin="0rem"
                placeholder="Select Year"
                value={year}
                onChangeOption={(value: any) => setYear(value)}
                option={yearsList?.map((years) => ({
                  key: years,
                  value: years,
                }))}
                disabled={isConfirmed}
                isDate={true}
                width="150px"
              />
              {!isConfirmed && !!month && isEdit && (
                <Tooltip text="Confirm">
                  <CustomButton
                    type="secondary"
                    size="small"
                    width="30px"
                    onClick={() => setIsConfirmModal(() => true)}
                    title={""}
                    icon={<FaCheck />}
                  />
                </Tooltip>
              )}
              {!isConfirmed && !!month && isEdit && (
                <Tooltip text="Delete" del>
                  <CustomButton
                    type={"secondary"}
                    size="small"
                    del
                    width="30px"
                    title={""}
                    onClick={() => setMonth("")}
                    icon={<AiFillDelete />}
                  />
                </Tooltip>
              )}
              {user.groups.length === 0 && isEdit && !!month && isConfirmed && (
                <Tooltip text="Reset">
                  <CustomButton
                    type={"secondary"}
                    size="small"
                    width="30px"
                    title={""}
                    onClick={() => setIsPayrunReset(() => true)}
                    icon={<AiOutlineReload />}
                  />
                </Tooltip>
              )}
            </Box>
          </Row>
          <p style={{ color: "gray" }}>
            <em>
              Once you confirm, you cannot change the Payrun start date and month
            </em>
          </p>
          {formErrors.payrun_start && (
            <ErrorContainer>{formErrors.payrun_start}</ErrorContainer>
          )}
          <Box sx={{ mt: 2.5 }}>
            <Typography color="#204ca0" sx={{ fontWeight: "bold" }}>
              Physical Address
            </Typography>
          </Box>
          <Row>
            <Label>Line 1</Label>
            <Box sx={{ width: "100%" }}>
              <InputField
                id="address_li"
                variant="outlined"
                size="small"
                fullWidth
                value={formData?.address_l1}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(e.target.value, "address_l1")
                }
                onBlur={() => setFormErrors({ ...formErrors, address_l1: "" })}
                inputProps={{ style: { textTransform: "none" } }}
              />
              {formErrors.address_l1 && (
                <ErrorContainer>{formErrors.address_l1}</ErrorContainer>
              )}
            </Box>
          </Row>
          <Row>
            <Label>Line 2</Label>
            <InputField
              id="address_l2"
              variant="outlined"
              size="small"
              fullWidth
              value={formData?.address_l2}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.value, "address_l2")
              }
              inputProps={{ style: { textTransform: "none" } }}
            />
          </Row>
          <Row>
            <Label>Line 3</Label>
            <InputField
              id="address_l3"
              variant="outlined"
              size="small"
              fullWidth
              value={formData?.address_l3}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.value, "address_l3")
              }
              inputProps={{ style: { textTransform: "none" } }}
            />
          </Row>
          <Row>
            <Label>Postal</Label>
            <Box sx={{ width: "100%" }}>
              <InputField
                id="address_code"
                variant="outlined"
                size="small"
                fullWidth
                value={formData?.address_code}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(e.target.value, "address_code")
                }
                onBlur={() => setFormErrors({ ...formErrors, address_code: "" })}
                inputProps={{ style: { textTransform: "none" } }}
              />
              {formErrors.address_code && (
                <ErrorContainer>{formErrors.address_code}</ErrorContainer>
              )}
            </Box>
          </Row>

          <Row>
            <Label>Service Provider Company</Label>
            <Box sx={{ width: "100%" }}>
              <select
                value={formData.service_provider}
                onChange={(e) =>
                  handleInputChange(parseInt(e.target.value), "service_provider")
                }
                style={{
                  padding: "5px",
                  width: "300px",
                  borderRadius: "0.2rem",
                  border: "1px solid #ccc",
                }}
              >
                <option value="" disabled>
                  Select Company Name
                </option>
                {companyName?.sort()?.map((company: any) => (
                  <option key={company.id} value={company.id}>
                    {company.company_name}
                  </option>
                ))}
              </select>
              {formErrors.service_provider && (
                <ErrorContainer>{formErrors.service_provider}</ErrorContainer>
              )}
            </Box>
          </Row>

          <Box
            sx={{
              [theme.breakpoints.down("md")]: {
                marginTop: 2.5,
              },
            }}
          >
            <Typography color="#204ca0" sx={{ fontWeight: "bold" }}>
              Optional
            </Typography>
          </Box>

          <Row>
            <Label>Logo</Label>
            <Box sx={{ width: "100%" }}>
              <input style={{ border: 0, width: "100%" }} type="file" />
              <br />
              <span>In .jpg or .png format</span>
            </Box>
          </Row>
        </Box>

        <div style={{
          width : "50%"
        }}>
          {<AppSettingsEmployeeFilingDetails setFilingData={setFilingData} formErrors={formErrors} />}
        </div>
      </Box>

      {/* Service Codes Table */}

      {isEdit && (
        <Row style={{ marginTop: "50px" }}>
          {serviceCodes.length > 0 &&
            <Label>
              <Typography color="#204ca0" sx={{ fontWeight: "bold" }}>
                Service Codes
              </Typography>
            </Label>

          }
          <IRASTable style={{ width: "100%" }}>
            <TableHeader
              headerColumns={repeateInvoiceHeaders}
              variant="filled"
              styles={{ padding: "5px", textAlign: "left" }}
            />
            <tbody>
              {repeatInvoiceData.length > 0 ? repeatInvoiceData?.map((item: any) => (
                <TableRow key={item.id} style={{ cursor: 'pointer' }} onClick={() => navigation('/dashboard/manage_invoicing/repeatedinvoice/addrepeatedinvoice', { state: { ...item, isEdit: true } })} >



                  {/* SPC */}
                  <td style={{ padding: '0.5rem' }}>
                    <span style={{
                      padding: "0.5rem",
                      color: "#0000FF",
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                      onMouseEnter={(e: any) => (e.target.style.textDecoration = "underline")}
                      onMouseLeave={(e: any) => (e.target.style.textDecoration = "none")}>
                      {item?.company_data?.spc_details?.company_name}
                    </span>
                  </td>

                  {/* Reference */}
                  <td style={{ padding: '0.5rem' }}>
                    {item.reference || "-"}
                  </td>

                  {/* Grand Amount */}
                  <td style={{ padding: '0.5rem', textAlign: "right", paddingRight: "20%" }}>
                    {item?.company_data?.billing_details?.currency_details?.symbol}{Utils.getCommanSepartedNumericString(item?.json_data?.sub_total) || ""}
                  </td>

                  {/* Repeat */}
                  <td style={{ padding: '0.5rem', }}>
                    {item.recurrence_text || "-"}
                  </td>

                  {/* Next Invoice Date */}
                  <td style={{ padding: '0.5rem' }}>
                    {item.next_invoice_date || "-"}
                  </td>


                </TableRow>
              )) : <div
              style={{
                width : "200%",
                textAlign : "center",
                padding : "1rem",
                display  :"flex",
                justifyContent  :"flex-end"

              }}  
              >No records Found</div>
              }
            </tbody>
          </IRASTable>
        </Row>
      )}

      {/* <Box sx={{ marginTop: 2.5, display: "flex" }}>
        <CustomButton
          title="Back"
          type="secondary"
          onClick={() => navigation("/dashboard/app-settings")}
        />
        <CustomButton title="Save All Rows" onClick={saveData} />
      </Box> */}
      <CommonModal
        handleClose={() => setIsConfirmModal(false)}
        open={isConfirmModal}
        updateData={() => confirmPayrunDate()}
        body={`Are you sure want to make ${Utils._dateToShow(
          Utils.getLastDayOfMonth(year, month)
        )} as your Payrun start date? You cannot undo this action.`}
        heading="Cofnrim Payrun Date"
        conformButtonText="Confirm"
        cancelButtonText="Cancel"
      />
      <CommonModal
        handleClose={() => setIsPayrunReset(false)}
        open={isPayrunReset}
        updateData={() => confirmPayrunDate(true)}
        body={`Are you sure want to reset your Payrun start date?`}
        heading="Reset Payrun Date"
        conformButtonText="Reset"
        cancelButtonText="Cancel"
      />
    </Box>
  );
};

export default EmployeeDetails;
