import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../_app";
import { EmployeeService } from "../../_service";
import { Notify, Utils } from "../../utils";
import { H3Heading, Heading } from "../appSettings/AppSettingsStyles";
import {
  CommonModal,
  CustomButton,
  CustomNumberField,
  CustomSelectField,
  CustomTextField,
  Loader,
  NavigateBack,
} from "../../components";
import { LoaderContainer } from "../../CommonStyles";
import { DisplayFlex, RecordSeletedText } from "../payslip/PayslipStyles";
import { TablePaginationHeader } from "./component";
import { TableHeader } from "../../components/table";
import { TableRow } from "@mui/material";
import { IRASTable, IRASTableCol } from "../filing/CPFFilingStyles";
import { TableColumn } from "../users/styles";
import { Constant } from "../../config";
import CustomTooltip from "../../components/CustomTooltip";
import moment from "moment";

const AnnualIncrementListing = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const date = new Date();
  const monthsOptions = Utils.MONTH_OPTIONS;
  const yearOptions = Utils.generateYearArrayTillNow(2010);

  const user = useAppSelector((state) => state.user);
  const companyId = useAppSelector((state) => state.user.defaultCompnayId);
  const currentPayrunDate = useAppSelector(
    (state) => state.user.current_payrun_date
  );

  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);

  const [AnnualIncrementListingData, setAnnualIncrementListingData] =
    useState<any>([]);

  const getEmployeeIncrementData = async () => {
    const defaultCompanyId = user.defaultCompnayId;

    if (defaultCompanyId) {
      setIsLoading(() => true);
      try {
        const res = await EmployeeService._getAnnualIncrementListing(
          defaultCompanyId
        );
        if (res.status >= 200 && res.status < 300) {
          let data = res?.data?.data?.increments;
          console.log("inc listing data", data);
          setAnnualIncrementListingData(data);
        }
      } catch (e: any) {
        console.log("err", e);
      }
      setIsLoading(() => false);
    }
  };

  const handleApprove = async (id: any, action: String) => {
    try {
      const { status, data } = await EmployeeService._sendForTellbossApprovalIncrement(id, action);
      if (status === 200) {
        Notify(data.message, 1)
        getEmployeeIncrementData();
        setOpenConfirm(false);
      }

    } catch (error) {
      Notify("Something went wrong", 0)
    }

  }


  const deleteAnnualIncrement = async (id: any) => {
    try {
      const res = await EmployeeService._deleteAnnualIncrement(id);
      if (res.status >= 200 && res.status < 300) {
        Notify("Deleted Successfully");
        getEmployeeIncrementData()
        setOpen(false)
      }
    } catch (e: any) {
      console.log("err", e)
    }

  }

  useEffect(() => {
    getEmployeeIncrementData();
  }, []);

  {
    console.log("main D", AnnualIncrementListingData);
  }
  return (
    <div>
      {/* {isLoading && <Loader />} */}
      {/* <hr/> */}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{
          display: "flex",
          alignItems: "center",
          gap: ".4rem"
        }}>
          <NavigateBack />
          <Heading>{Utils.getTitleWithCompanyName("Annual Increments")}</Heading>
        </div>


        <CustomButton
          title="Add To Calculate New Annual Increments"
          onClick={() => navigate("add")}
          styles={{ marginRight: "1rem", width: "350px", whiteSpace: "nowrap" }}
        />
      </div>

      <div className="table" style={{ marginTop: "1rem" }}>
        <table className="company-table">
          <thead className="company-table-head">
            <tr>
              <th style={{ textAlign: "left", width: "10%" }}>Year</th>
              <th style={{ textAlign: "left", width: "15%" }}>Worked Till</th>
              <th style={{ textAlign: "left", width: "5%" }}>Effective Month</th>
              <th style={{ textAlign: "right", width: "15%" }}>Total Salary</th>
              <th style={{ textAlign: "right", width: "15%" }}> Total Increment</th>
              <th style={{ textAlign: "right", width: "15%" }}>Average Increment(%)</th>
              <th style={{ textAlign: "center", width: "15%" }}>Status</th>
              <th style={{ textAlign: "left", width: "15%" }}>Action</th>
            </tr>
          </thead>

          <thead>
            {AnnualIncrementListingData?.length > 0 &&
              AnnualIncrementListingData?.map((item: any) => (
                <tr>
                  <td style={{ textAlign: "left", width: "10%" }}>
                    {item?.year}
                  </td>
                  <td>
                    {/* {`${moment(item?.period_from).format(
                    "MM-DD-YYYY"
                  )} to */}
                    {`${moment(item?.period_to).format("DD-MM-YYYY")}`}
                  </td>
                  <td style={{ textAlign: "center", width: "10%" }}>
                    {moment(item?.effective_month, "M").format('MMMM')}
                  </td>
                  <td style={{ textAlign: "right", width: "5%" }}>
                    {"$" +
                      Utils.getNumberWithCommas(item?.total_salary?.toFixed(2))}
                  </td>
                  <td style={{ textAlign: "right", width: "10%" }}>
                    {"$" +
                      Utils.getNumberWithCommas(
                        item?.total_increment?.toFixed(2)
                      )}
                  </td>
                  <td style={{ textAlign: "right", width: "10%" }}>
                    {item?.avg_increment_percentage.toFixed(2)}
                  </td>
                  <td style={{ textAlign: "center", width: "10%" }}>
                    {item?.status === "preview" && <p style={{ color: "#f4c104", padding: "0.5rem", fontWeight: "bold", outline: "black" }}>Draft</p>}

                    {item?.status === "finalized" && <p style={{ color: "orangered", fontWeight: "bold", padding: "0.5rem", outline: "black" }}>Confirmed</p>}
                    {item?.status === "executed" && <p style={{ color: "rgb(30, 81, 40)", fontWeight: "bold", padding: "0.5rem", outline: "black" }}>Executed</p>}
                    {item?.status === "sent_for_approval" && <p style={{ color: "rgb(30, 81, 40)", fontWeight: "bold", padding: "0.5rem", outline: "black" }}>Sent to TellBoss</p>}

                  </td>
                  <td style={{ textAlign: "right", width: "30%" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "left",
                        justifyContent: "left",
                        gap: "1rem",
                      }}
                    >
                      <p
                        style={{
                          padding: "0.25rem 0.75rem",
                          background:
                            item?.status === "preview" ? "#059BBF" : "#03B381",
                          cursor: "pointer",
                          color: "white",
                          fontWeight: 400,
                          borderRadius: "0.25rem",
                        }}
                        onClick={() => {
                          navigate(
                            `${item?.status === "preview" ? "edit" : "view"}`,
                            {
                              state: {
                                isEdit:
                                  item?.status === "preview" ? true : false,
                                id: item?.id,
                                isFinalized: (item?.status === "finalized" || item?.status === "sent_for_approval" || item?.status === "executed") ? true : false,
                              },
                            }
                          );
                        }}
                      >
                        {item?.status === "preview" ? "Edit" : "View"}
                      </p>
                      {
                        (item?.status === "preview" || item?.status === "finalized") && <p
                          style={{
                            padding: "0.25rem 0.75rem",
                            background: "#ff6665",
                            cursor: "pointer",
                            color: "white",
                            fontWeight: 400,
                            borderRadius: "0.25rem",
                            alignContent: "left"
                          }}
                          onClick={() => { setOpen(true); setDeleteId(item?.id) }}
                        >
                          Delete
                        </p>
                      }
                      {item?.status === "sent_for_approval" && <p
                        style={{
                          padding: "0.25rem 0.75rem",
                          background: "#059bbf",
                          cursor: "pointer",
                          color: "white",
                          fontWeight: 400,
                          borderRadius: "0.25rem",
                        }}
                        onClick={() => { setOpenConfirm(true); setDeleteId(item?.id) }}
                      >
                        Confirm
                      </p>}
                    </div>
                  </td>
                </tr>
              ))}
          </thead>
        </table>
        <CommonModal
          handleClose={() => setOpen(false)}
          open={open}
          updateData={() => deleteAnnualIncrement(deleteId)}
          body={`Are you sure wanted to delete increment record?`}
          heading={"Are you sure wanted to delete?"}
          conformButtonText={"Yes"}
          cancelButtonText={"No"}
          onCancel={() => setOpen(false)}

        />
        <CommonModal
          handleClose={() => setOpenConfirm(false)}
          open={openConfirm}
          updateData={() => handleApprove(deleteId, "approve")}
          body={`Proceed to confirm Draft Annual Increment as Final and send to payslips in payroll?`}
          heading={"Ready to confirm Draft Annual Increment?"}
          conformButtonText={"Proceed to confirm"}
          cancelButtonText={"No - Stop"}
          onCancel={() => setOpenConfirm(false)}

        />
      </div>
    </div>
  );
};

export default AnnualIncrementListing;
