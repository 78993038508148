import { useState } from "react";

import { ManageCPFCal } from "../../_service";
import { Notify, Utils } from "../../utils";
import { CPFTableDataProps } from "../../_types";
import { CustomButton } from "../customButton";
import { CustomTextField } from "../customFormField";
import { useAppSelector } from "../../_app";
import { AiFillEdit } from "react-icons/ai";
import CustomTooltip from "../CustomTooltip";

interface ManageCPFTableRowProps {
  s_no: number;
  data: CPFTableDataProps;
  syncData: () => void;
}

const ManageCPFTableRow = (props: ManageCPFTableRowProps) => {
  const { data, s_no, syncData } = props;

  const [edit, setEdit] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);

  const [age_min, setAge_min] = useState<string>(data.age_min);
  const [age_max, setAge_max] = useState<string>(data.age_max);
  const [salary_min, setSalary_min] = useState<string>(data.salary_min);
  const [salary_max, setSalary_max] = useState<string>(data.salary_max);
  const [total_cpf, setTotal_cpf] = useState<string>(data.total_cpf);
  const [employee_cpf, setEmployee_cpf] = useState<string>(data.employee_cpf);

  const user = useAppSelector((state) => state.user);

  const toggle = () => setEdit((prev) => !prev);

  const handleCpfTableDataUpdate = async () => {
    setUpdating(true);
    const formData = {
      ...data,
      age_max,
      age_min,
      salary_max,
      salary_min,
      total_cpf,
      employee_cpf,
    };
    try {
      const res = await ManageCPFCal._updateCpfCalData({
        id: data.id,
        data: formData,
      });
  
      if (res.status === 200) {
        syncData();
        Notify("Data Updated Successfully", 1);
      } else {
        Notify("Data Updating Failed", 0);
      }

    } catch (e: any) {
      Utils.showErrorMessage(e);
    } finally {
      setUpdating(false);
      setEdit(false);
    }

  };


  return (
    <tr>
      {edit ? (
        <>
          <td style={{padding: '0.3rem'}} >{s_no + 1}</td>
          <td style={{padding: '0.3rem'}} >
            <CustomTextField
              width="40px"
              type="number"
              value={age_min}
              onChangeText={setAge_min}
              inputSize="12px"
            />
          </td>
          <td style={{padding: '0.3rem'}} >
            <CustomTextField
              width="40px"
              type="number"
              value={age_max}
              onChangeText={setAge_max}
              inputSize="12px"
            />
          </td>
          <td style={{padding: '0.3rem'}} >
            <CustomTextField
              width="60px"
              type="number"
              value={salary_min}
              onChangeText={setSalary_min}
              inputSize="12px"
            />
          </td>
          <td style={{padding: '0.3rem'}} >
            <CustomTextField
              width="60px"
              type="number"
              value={salary_max}
              onChangeText={setSalary_max}
              inputSize="12px"
            />
          </td>
          <td style={{padding: '0.3rem'}} >
            <CustomTextField
              width="200px"
              value={total_cpf}
              onChangeText={setTotal_cpf}
              inputSize="12px"
            />
          </td>
          <td style={{padding: '0.3rem'}} >
            <CustomTextField
              width="200px"
              value={employee_cpf}
              onChangeText={setEmployee_cpf}
              inputSize="12px"
            />
          </td>
          <td style={{padding: '0.3rem'}} >
            {/* <CustomButton
              size="small"
              width="70px"
              title="Update"
              onClick={handleCpfTableDataUpdate}
              disable={updating}
            /> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: '1rem'
              }}
            >
              <img
                onClick={() => {
                  if (updating) {
                    return null;
                  } else {
                    handleCpfTableDataUpdate();
                  }
                }}
                alt="update"
                title="Update"
                style={{ width: "1.6rem", cursor: "pointer" }}
                src="/check-mark.svg"
                
              />
              <img
                onClick={() => setEdit(false)}
                alt="cancel"
                title="Cancel"
                style={{ width: "1.8rem", cursor: "pointer" }}
                src="/cancel.svg"
              />
            </div>
          </td>
        </>
      ) : (
        <>
          <td style={{padding: '0.3rem'}} >{s_no + 1}</td>
          <td style={{padding: '0.3rem'}} >{data.age_min}</td>
          <td style={{padding: '0.3rem'}} >{data.age_max}</td>
          <td style={{padding: '0.3rem'}} >{data.salary_min}</td>
          <td style={{padding: '0.3rem'}} >{data.salary_max}</td>
          <td style={{padding: '0.3rem'}} >{data.total_cpf}</td>
          <td style={{padding: '0.3rem'}} >{data.employee_cpf}</td>
          {user.permission === "full" && (
            <td style={{padding: '0.3rem'}} >
              {/* <CustomButton
                type="secondary"
                size="small"
                width="50px"
                title="Edit"
                onClick={toggle}
              /> */}
              <CustomTooltip title="Edit" placement="left">
                <div
                  style={{
                    border: "1.5px solid #059bbf",
                    borderRadius: "0.25rem",
                    padding: "5px",
                    width: "fit-content",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={toggle}
                >
                  <AiFillEdit color="#059bbf" />
                </div>
              </CustomTooltip>
            </td>
          )}
        </>
      )}
    </tr>
  );
};

export default ManageCPFTableRow;
