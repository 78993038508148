import { GenericEvents } from '@newrelic/browser-agent/features/generic_events';
import { MicroAgent } from '@newrelic/browser-agent/loaders/micro-agent'
const accountID = process.env.ACCOUNT_ID;
const trustKey = process.env.TRUST_KEY;
const agentID = process.env.AGENT_ID;
const licenseKey = process.env.LICENSE_KEY;
const applicationID = process.env.APPLICATION_ID;

const options = {
  info: {
    beacon: "bam.nr-data.net",
    errorBeacon: "bam.nr-data.net",
    licenseKey: licenseKey,
    applicationID: applicationID,
  },
  loader_config: {
    accountID: accountID,
    trustKey: trustKey,
    agentID: agentID,
    licenseKey: licenseKey,
    applicationID: applicationID,
  },
  init: {
    distributed_tracing: { enabled: true },
    privacy: { cookies_enabled: true },
  },
}
export const microAgent = new MicroAgent(options)