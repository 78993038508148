import React from "react";
import { useNavigate } from "react-router-dom";
import { FiAlertOctagon } from "react-icons/fi";
import { Typography, Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { BiSolidError } from "react-icons/bi";
import { IoWarningOutline } from "react-icons/io5";

const EmployeeOnlyPage: React.FC = () => {
  const navigate = useNavigate();

  const user = useSelector((state: any) => state.user);

  let isReadOnly = user.permission === "read";

  console.log("on403", user.permission === "read");

  return (
    <div>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        bgcolor: "#ffffff",
        padding: 3,
        background: "linear-gradient(145deg, #ffffff 0%, #f5f5f5 100%)",
      }}
    >
      <IoWarningOutline  
        style={{
        fontSize: "16rem",
        color: "#ff4444",
        filter: "drop-shadow(2px 2px 4px rgba(0,0,0,0.1))"
        }}
      />
      <Typography
        variant="h2"
        component="h1"
        gutterBottom
        sx={{
        mt: 2,
        color: "#333333",
        textShadow: "2px 2px 4px rgba(0,0,0,0.1)",
        fontWeight: "bold",
        letterSpacing: "2px",
        }}
      >
        Access Denied
      </Typography>
      {
        <>
        <Typography
          component="h3"
          align="center"
          sx={{
            mb: 2,
            color: "#dc3545",
            maxWidth: "80%",
            fontSize: "1.5rem",
            textAlign: "center",
            fontWeight: "600",
            letterSpacing: "0.5px",
            textShadow: "1px 1px 2px rgba(0,0,0,0.05)",
          }}
        >
          You are currently logged in as an employee. To access the Employee Login Dashboard, please use the Employee Payroll Mobile App to log in and manage your payslip and leave features.
        </Typography>
        <Box sx={{ 
          display: 'flex', 
          gap: 2, 
          justifyContent: 'center',
          alignItems: 'center'
        }}>
            <Box
              component="img"
              src="/playstore.png"
              alt="Get it on Google Play"
              sx={{
          width: 200,
          height: 60,
          cursor: 'pointer',
          transition: 'transform 0.2s',
          '&:hover': {
              transform: 'scale(1.05)',
          }
              }}
              onClick={() => window.location.href = 'https://play.google.com/store/apps/details?id=com.telboss.companyadmin'}
            />
            <Box
              component="img"
              src="/appstore.png"
              alt="Download on App Store"
              sx={{
          width: 200,
          height: 60,
          cursor: 'pointer',
          transition: 'transform 0.2s',
          '&:hover': {
              transform: 'scale(1.05)',
          }
              }}
              onClick={() => window.location.href = 'https://play.google.com/store/apps/details?id=com.telboss.companyadmin'}
            />
        </Box>
        </>
      }
    </Box>
    </div>
  );
};

export default EmployeeOnlyPage;

// 404 page
