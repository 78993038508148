import apiClient from "../_service/BaseService";

import { URL } from "../config";
import {
  GetSingleCompany,
  UpdateDefaultCompany,
  UpdateSingleCompany,
  OrganisationIdTypeInterface,
  FilingDetailsInterface,
  PayFrequencyInterface,
} from "../_types";

const _getAllComanies = async (type: any) =>
  apiClient.get(URL.GET_ALL_COMPANIES + `?action_type=${type}`);

const _getAllCompanyByChar = async (val: string) =>
  apiClient.get(URL.GET_ALL_COMPANIES + `?starts_with=${val}`);

const _addCompany = async (data: any) =>
  apiClient.post(URL.GET_ALL_COMPANIES, data);

const _addNewCompany = async (data: any) =>
  apiClient.post(URL.UPDATE_FILING_DETAILS, data);

const _activateCompany = async (companyId: any,) =>
  apiClient.post(URL.ACTIVATE_COMPANY + `${companyId}/activate_or_deactivate_company/` );

const _deactivateCompany = async (companyId: any, data: any) =>
  apiClient.delete(URL.DEACTIVATE_COMPANY + `${companyId}/activate_or_deactivate_company/`, {data});

// const _patchCompany = async (data: any) =>
//   apiClient.patch(URL.GET_COMPANY + data.id + "/", data);
const _patchCompany = async (data: any) =>
  apiClient.patch(URL.UPDATE_FILING_DETAILS + data.id + "/", data);

const _getSingleCompany = async (params: GetSingleCompany) => {
  const response = await apiClient.get(URL.GET_COMPANY + params.id + "/");
  return response.data;
};

const _updateCompany = async (params: UpdateSingleCompany) =>
  apiClient.patch(URL.UPDATE_COMPANY + params.id + "/", params.data);

const _deleteCompany = async (id: string) =>
  apiClient.delete(URL.DELETE_COMPANY + id + "/");

const _getDefaultCompany = async () => apiClient.get(URL.MAKE_DEFAULT_COMPANY);

const _updateDefaultCompany = async (data: UpdateDefaultCompany) =>
  apiClient.post(URL.UPDATE_DEFAULT_COMPANY, data);

const _getOrgIdTypeList = async (): Promise<OrganisationIdTypeInterface[]> => {
  const response = await apiClient.get(URL.GET_ORG_ID_TYPE_LIST);
  return response.data.data.input_items;
};

const _fileDetails = async (data: any) =>
  apiClient.post(URL.FILE_DETAILS, data);

const _updateFilingDetails = async (data: FilingDetailsInterface) =>
  apiClient.put(
    URL.UPDATE_FILING_DETAILS + `${data.id}/update_filling_details/`,
    data,
  );

const _getFileDetails = async (id: string): Promise<FilingDetailsInterface> => {
  const response = await apiClient.get(
    `${URL.GET_FILING_DETAILS}?company=${id}`,
  );
  return response.data?.data;
};

const _addEmployeeNumber = async ({
  id,
  setting = {
    mode: "Automatic",
    first_emp_no: "001",
    is_sort_by_emp: true,
  },
}: {
  id: string;
  setting?: any;
}) => {
  const data = {
    company: id,
    code: "employee_number",
    name: "Employee Nmber",
    setting,
  };
  return apiClient.post(URL.ADD_EMPLOYEE_NUMBER, data);
};

const _getEmployeeNumber = async (id: string) => {
  const response = await apiClient.get(
    `${URL.GET_EMPLOYEE_NUMBER}?code=employee_number&company=${id}`,
  );

  return response.data.data;
};

const _createpayFrequency = async ({ id }: { id: number }) => {
  const data = {
    company: id,
    name: "Monthly, ending on the 31st",
    frequency: "monthly",
    last_day_of_period: "month 31",
    first_payroll_end_date: "2022-07-31",
  };
  return apiClient.post(URL.CREATE_PAY_FREQUENCY, data);
};

const _getPayFrequency = async (
  company: string,
): Promise<PayFrequencyInterface[]> => {
  const { data }: { data: { data: PayFrequencyInterface[] } } =
    await apiClient.get(`${URL.GET_PAY_FREQUENCY}?company=${company}`);

  return data.data;
};

// add employee by company id
// get employee by option
// Todos
// 1 add put api in employee details
// 2 call get pay frequency in pay frequency section
// 3 handle new employee
//4 finalize data fill
export const CompanyService = {
  _addCompany,
  _patchCompany,
  _deleteCompany,
  _getAllComanies,
  _getAllCompanyByChar,
  _getDefaultCompany,
  _getSingleCompany,
  _updateCompany,
  _updateDefaultCompany,
  _getOrgIdTypeList,
  _fileDetails,
  _addEmployeeNumber,
  _getEmployeeNumber,
  _createpayFrequency,
  _getFileDetails,
  _updateFilingDetails,
  _getPayFrequency,
  _deactivateCompany,
  _activateCompany,
  _addNewCompany,
};
