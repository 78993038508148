import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomButton, Loader, NavigateBack } from "../../components";
import {
  CustomTextField,
  CustomSelectField,
} from "../../components/customFormField";
import { Notify, Utils } from "../../utils";
import { CompanyService, RoleService, UserService } from "../../_service";

import "./style.css";
import { CopyText } from "./styles";
import { FormSubmitButtonContainer, LoaderContainer } from "../../CommonStyles";
import { Chip } from "../../components/chip";
import validator from "validator";
import { FilterByChar, SearchField } from "../dashboard/component";
import FilterByCharacterBar from "./components/FilterByCharacterBar";
import cloneDeep from "lodash.clonedeep";
import { IoWarningOutline } from "react-icons/io5";
import { GrSearch } from "react-icons/gr";
import { MdClose } from "react-icons/md";
import styled from "styled-components";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


const ViewButton = styled.button`
  background-color: #f8fafc;
  color: #334155;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);

  &:hover {
    background-color: #059bbf;
    color: #ffffff;
    border-color: #cbd5e1;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }

  &:active {
    background-color: #e2e8f0;
    transform: translateY(0);
    box-shadow: 0 1px 2px rgba(0,0,0,0.1);
  }

  &:focus {
    outline: 2px solid #60a5fa;
    outline-offset: 2px;
  }
`;

const ScrollableContent = styled.div`
  max-height: 400px;
  overflow-y: auto;
  padding: 1.5rem;
  scrollbar-width: thin;
  scrollbar-color: #94a3b8 #e2e8f0;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #e2e8f0;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #94a3b8;
    border-radius: 4px;
    &:hover {
      background-color: #64748b;
    }
  }
  
  &::-webkit-scrollbar-button {
    display: none;
  }
`;


const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #e2e8f0;
  background-color: #f8fafc;
  padding: 1rem;
  border-radius: 8px 8px 0 0;
`;

const ModalTitle = styled.p`
  color: #059bbf;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.75rem;
`;

const CloseButton = styled.button`
  padding: 0.5rem;
  border-radius: 50%;
  border: none;
  background-color: #f1f5f9;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.15s ease-in-out;
  outline: none;

  &:hover {
    background-color: #e2e8f0;
  }
`;


const CompanyCard = styled.div`
  padding: 0.5rem;
  margin: 0.5rem 0;
  border-radius: 8px;
  font-size: 1rem;
  color: #374151;
  background-color: #f8fafc;
  border: 1px solid #e2e8f0;
  transition: all 0.2s ease;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;


interface AccessBadgeProps {
  accessType: 'full' | 'read' | 'none';
}

const AccessBadge = styled.span<AccessBadgeProps>`
  font-size: 0.875rem;
  padding: 0.25rem 0.75rem;
  border-radius: 4px;
  background-color: ${props => 
    props.accessType === 'full' ? '#ecfdf5' : 
    props.accessType === 'read' ? '#eff6ff' :
    '#fef2f2'
  };
  color: ${props => 
    props.accessType === 'full' ? '#059669' : 
    props.accessType === 'read' ? '#3b82f6' :
    '#ef4444'
  };
  font-weight: 500;
`;

interface CompanyData {
  company_id: number | string;
  name: string;
  access_type: "full" | "read" | "none";
  isSelected?: boolean;
}

interface CompanyResponse {
  id: number;
  name: string;
}

interface RoleData {
  id: number;
  name: string;
}

const RESTRICT_ACCESS_OPTIONS = [
  {
    name: "Full",
    value: "full",
  },
  {
    name: "Read Only",
    value: "read",
  },
  {
    name: "None",
    value: "none",
  },
];

const FIRST_ROW_ROLES = ["CSO", "Data Entry", "Finalizer", "Approver", "Client"]; // Roles that should be displayed in the first row

const AddUsers = () => {
  const { state } = useLocation();
  const [roles, setRoles] = useState<RoleData[]>([]);
  const [isCompanyRestrict, setIsCompanyRestrict] = useState(true);
  const [companies, setCompanies] = useState<CompanyData[]>([]);

  const [openModal, setOpenModal] = useState(false);

  const[allCompanies, setAllCompanies] = useState<any[]>([])
  const [renderedCompanies, setRenderedCompanies] = useState<CompanyData[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<any[]>([]);
  const [modalListCompanies, setModalListCompanies] = useState<any[]>([]);

  const [userEmail, setUserEmail] = useState("");
  const [originalEmail, setOriginalEmail] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");

  const [userRole, setUserRole] = useState<any>([]);
  const [userRoleRowOne, setUserRoleRowOne] = useState<any>([]);
  const [userRoleRowTwo, setUserRoleRowTwo] = useState<any>([]);
  
  const [defaultAccess, setDefaultAccess] = useState<"full" | "read" | "none">(
    "full"
  );
  const [isDisplayCopyMessage, setIsDisplayCopyMessage] = useState(false);

  const [isEmailError, setIsEmailError] = useState(false);
  const [isFirstNameError, setIsFirstNameError] = useState(false);
  const [isLastNameError, setIsLastNameError] = useState(false);
  const [isUserRoleRowOneError, setIsUserRoleRowOneError] = useState(false);
  const [isUserRoleError, setIsUserRoleError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [userId, setUserId] = useState<any>(null)
  const [char, setChar] = useState<string>('All')
  const [showWarning, setShowWarning] = useState(false);
  const [searchString,setSearchString] = useState("")

  const [isEmailExists, setIsEmailExists] = useState(false);

  const emailPattern = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

  const navigate = useNavigate();
  console.log("companies", companies);

  const getAllRoles = async () => {
    try {
      const res = await RoleService._getRoles(0);
      if (res.status === 200) {
        setRoles(() => [...res.data]);
      }
    } catch (e: any) {
      console.log("error", e);
      // Notify("Something went wrong, please try again!");
    }
  };

  const getUserInformation = async () => {
    setIsLoading(() => true);
    const res = await UserService._getUserInformation(state?.userId);
    const resAllCompanies = await CompanyService._getAllComanies(1);

    if ((res.status >= 200 && res.status < 300) && (resAllCompanies.status >= 200 && resAllCompanies.status < 300)) {
      setUserEmail(() => res.data.email);
      setOriginalEmail(() => res.data.email);
      setUserFirstName(() => res.data.first_name);
      setUserLastName(() => res.data.last_name);
      setUserRole(() => res.data.groups.map((group: any) => group.id) || []);

      res.data.groups.forEach((group: any) => {
        if (FIRST_ROW_ROLES.includes(group.name)) {
          setUserRoleRowOne((prev: any) => [...prev, group.id]);
        } else {
          setUserRoleRowTwo((prev: any) => [...prev, group.id]);
        }
      }
      );


      setIsCompanyRestrict(() => res.data.restricted);
      
      let userCompanies = cloneDeep(res.data.restricted_company);
      setModalListCompanies(() => res.data.restricted_company);


      // for setting companies
      // const allCompanies = resAllCompanies.data;
      // const existingCompanyIds = userCompanies.map((x:any) => x.company_id);
      // const missingCompanies = allCompanies
      //   .filter((company:any) => !existingCompanyIds.includes(company.id))
      //   .map((company:any) => ({
      //       company_id: company.id,
      //       access_type: "none",
      //       name: company.name,
      //   }));
      //   userCompanies.push(...missingCompanies);

      setCompanies(() => userCompanies);
      setAllCompanies(() => resAllCompanies.data);
      setRenderedCompanies(() => userCompanies);
    }
    setIsLoading(() => false);
  };

  // useEffect(() => {
  //   if (searchString !== "" && char !== "") {
  //     setChar("");
  //   }
  // }, [searchString]);
  
  useEffect(() => {
    console.log('char useEffect', char, searchString);
    if (searchString !== "") {
      setSearchString("");
    }
  }, [char]);

  
  const checkIfEmailExist = async (email: string) => {
    console.log("checkEmail original", email);

    try {
      const res = await UserService._checkUserExist(email);
      if (res.status >= 200 && res.status < 300) {
        console.log("checkEmail res", res?.data?.data, res?.data?.data?.user_data);

        // Email alraedy exists as a system user, switch to "EDIT"
        if (res?.data?.data?.is_exists) {
          setIsEmailExists(true);
          setShowWarning(false);
        }

        // Email already exists but as Employee, switch to "EDIT"
        if (!res?.data?.data?.is_exists && res?.data?.data?.is_employee) {
          // "Warn the user that the email already exist as employee. Does he/she wanna create (on Yes, call EDIT API) )" 
          setIsEdit(() => true);
          setShowWarning(true);

          setUserId(() => res?.data?.data?.user_data?.id)
          setUserEmail(() => res?.data?.data?.user_data?.email);
          setUserFirstName(() => res?.data?.data?.user_data?.first_name);
          setUserLastName(() => res?.data?.data?.user_data?.last_name);
          setUserRole(() => res?.data?.data?.user_data?.groups.map((group: any) => group.id) || []);

          res.data.groups.forEach((group: any) => {
            if (FIRST_ROW_ROLES.includes(group.name)) {
              setUserRoleRowOne(() => [group.id]);
            } else {
              setUserRoleRowTwo((prev: any) => [...prev, group.id]);
            }
          }
          );

          setIsCompanyRestrict(() => res?.data?.data?.user_data?.restricted);
          // setCompanies(() => res?.data?.data?.user_data?.restricted_company);

          // let userCompanies = cloneDeep(res?.data?.data?.user_data?.restricted_company);
      // for setting companies
          // const existingCompanyIds = userCompanies.map((x:any) => x.company_id);
          // const missingCompanies = allCompanies
          //   .filter((company:any) => !existingCompanyIds.includes(company.id))
          //   .map((company:any) => ({
          //       company_id: company.id,
          //       access_type: "none",
          //       name: company.name,
          //   }));
          //   userCompanies.push(...missingCompanies);

          //   console.log("after checkEmail update",existingCompanyIds, allCompanies, userCompanies, missingCompanies)
          // setCompanies(() => userCompanies);
        }

        // Email does not exist proceed with "ADD"
        if (!res?.data?.data?.is_exists && !res?.data?.data?.is_employee) {
          setIsEmailExists(false);
          setIsEdit(() => false);
          setUserId(() => null)
          setShowWarning(false);

          setUserFirstName(() => "");
          setUserLastName(() => "");
          setUserRole(() => []);
          setUserRoleRowOne(() => []);
          setUserRoleRowTwo(() => []);
          setIsCompanyRestrict(() => true);
          // setCompanies(() => []);
        }

        // if (res?.data?.data?.is_exists) {
        //   setIsEmailExists(true);
        //   setIsEdit(()=>true);
        //   setUserId(()=>res?.data?.data?.user_data?.id)
        //   setUserEmail(() => res?.data?.data?.user_data?.email);
        //   setUserFirstName(() => res?.data?.data?.user_data?.first_name);
        //   setUserLastName(() => res?.data?.data?.user_data?.last_name);
        //   setUserRole(() => res?.data?.data?.user_data?.groups.map((group: any) => group.id) || []);
        //   setIsCompanyRestrict(() => res?.data?.data?.user_data?.restricted);
        //   setCompanies(() => res?.data?.data?.user_data?.restricted_company);
        // } else {
        //   setIsEmailExists(false);
        //   setIsEdit(() => false);
        //   setUserId(() => null)
        // }

      }

    } catch (error: any) {
      Utils.showErrorMessage(error);
    }
  }

  const setAllCompanyAccess = async () => {
    try {
      const res = await CompanyService._getAllComanies(1);

      const companyAccess = res.data.map((company: CompanyResponse) => ({
        company_id: company.id,
        access_type: "full",
        name: company.name,
      }));
      setCompanies(companyAccess);
    } catch (e: any) {
      Notify("Some Error Occurred");
    }
  };

  // const toggleRestrictCompany = async () => {
  //   if (!isCompanyRestrict) {
  //     setIsCompanyRestrict(() => true);
  //     await setAllCompanyAccess();
  //   } else {
  //     setIsCompanyRestrict(() => false);
  //   }
  // };

  // useEffect(() => {
  //   if (!state?.userId){
  //     console.log("setAllCompnay called")
  //     setAllCompanyAccess();
  //   }
  // },[])

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(() => true);
      const res = await CompanyService._getAllComanies(1);
      if (res.status >= 200 && res.status < 300) {
        setAllCompanies(() => res.data);
      }
    }

    if(!state?.userId) {
      fetchData();
      setIsLoading(() => false);
    }


  }, [])

  // const changeUserRole = (value: string) => {
  //   if (parseInt(value) === 0) {
  //     setIsRoleError(true);
  //     setUserRole(() => parseInt(value));
  //   } else {
  //     setUserRole(() => parseInt(value));
  //     setIsRoleError(false);
  //   }
  // };

  const createUser = async (e: any) => {
    e.preventDefault();

    // if (!emailPattern.test(userEmail)) {
    //   Notify("Please Enter a valid Email");
    //   setIsEmailError(true);
    //   return;
    // }
    if (isEmailExists) {
      Notify("User with this email already exists! Please enter a different email", 0);
      return;
    }

    if (!userFirstName) {
      Notify("Please Enter a valid First name", 0);
      setIsFirstNameError(true);
      return;
    }
    // if (!userLastName) {
    //   Notify("Please Enter a valid Last name");
    //   setIsLastNameError(true);
    //   return;
    // }

    if (!validator.isEmail(userEmail)) {
      Notify("Please Enter a valid Email", 0);
      setIsEmailError(true);
      return;
    }

    // if (userRole.length <= 0) {
    //   Notify("Please Enter a valid Role");
    //   return;
    // }
    if ([...userRoleRowOne, ...userRoleRowTwo].length <= 0) {
      setIsUserRoleError(true);
      Notify("Please select a role", 0);
      return;
    }

    if (userRoleRowOne.length > 1) {
      setIsUserRoleRowOneError(true);
      Notify("Please select only ONE role from row one", 0);
      return;
    }

    const userCreateData = {
      first_name: userFirstName,
      last_name: userLastName,
      email: userEmail,
      // groups: userRole,
      groups: [...userRoleRowOne, ...userRoleRowTwo],
      // restricted: isCompanyRestrict,
      restricted: true,
      // companies_access: companies.map(({ company_id, access_type }) => ({
      //   company_id: parseInt(company_id as any),
      //   access_type,
      // })),
      companies_access: [...renderedCompanies, ...selectedCompanies].map(({ company_id, access_type }) => ({
        company_id: parseInt(company_id as any),
        access_type,
      })),
    };

    console.log("userCreateData", userCreateData, state?.userId, userId, isEdit);

    try {
      if (state?.userId || isEdit) {
        console.log("BeforeUpdate", isEdit, userId, userCreateData)
        const res = await UserService._updateUser(isEdit ? userId : state.userId, userCreateData);
        if (res.status >= 200 && res.status < 300) {
          Notify("User Updated Successfully", 1);
          navigate("/dashboard/manage-users");
        }
      } else {
        const res = await UserService._createUser(userCreateData);
        if (res.status >= 200 && res.status < 300) {
          Notify("User Created Successfully", 1);
          navigate("/dashboard/manage-users");
        }
      }
    } catch (error:any) {
      Utils.showErrorMessage(error)
      // Notify("Something went wrong, please try again later");
    }
  };

  // const copyAccess = () => {
  //   setIsDisplayCopyMessage(() => false);
  //   const updatedCompanyAccess = companies.map((company) => ({
  //     ...company,
  //     access_type: defaultAccess,
  //   }));
  //   setCompanies(() => updatedCompanyAccess);
  // };


  const handleAccess = (access: "read" | "full" | "none") => {
    setIsDisplayCopyMessage(() => false);
    // const updatedCompanyAccess = companies?.filter((x) => (char === "All" ? x?.name : x?.name?.startsWith(char)))?.map((company) => ({
    //   ...company,
    //   access_type: access,
    // }));

    const updatedCompanyAccess = cloneDeep(companies);
    updatedCompanyAccess.forEach((company) => {
      if (char === "All" || company?.name?.startsWith(char)) {
        company.access_type = access;
      }
    })
    setCompanies(() => updatedCompanyAccess);
    setDefaultAccess(access);

  };

  const updateCompanyAccess = (value: "read" | "full" | "none", id: number | string) => {
    const updatedRenderedList = [...renderedCompanies].map((obj:any) => {
      if (obj.company_id === id) {
        return { ...obj, access_type: value };
      }
      return obj;
    });
    const updatedSelectedList = [...selectedCompanies].map((obj:any) => {
      if (obj.company_id === id) {
        return { ...obj, access_type: value };
      }
      return obj;
    });

    setRenderedCompanies(updatedRenderedList);
    setSelectedCompanies(updatedSelectedList);
  };

  const changeDefaultAccess = (value: any) => {
    setDefaultAccess(() => value);
    setIsDisplayCopyMessage(() => true);
  };

  // const toggleSelectedGroup = (e: any, groupId: any) => {
  //   e.preventDefault();

  //   const groupIndex = userRole.findIndex((roleId: any) => roleId === groupId);
  //   let groupData = [...userRole];

  //   if (groupIndex === -1) {
  //     groupData = [...groupData, groupId];
  //   } else {
  //     groupData.splice(groupIndex, 1);
  //   }
  //   setUserRole(() => groupData);
  // };

  // First row will only have one role selected at a time
  const handleFirstRowRole = (e: any, roleId: any) => {
    e.preventDefault();
    

    const groupIndex = userRoleRowOne.findIndex((role: any) => role === roleId);
    let groupData = [...userRoleRowOne];

    if(groupIndex >= 0) { 
      groupData.splice(groupIndex, 1);
    } else {
      groupData.push(roleId);
    }

    console.log('before row one update', {groupData, userRoleRowOne});

    setUserRoleRowOne(() => groupData);
    setIsUserRoleError(false);
    setIsUserRoleRowOneError(false);
  };

  // Second row can have multiple roles selected
  const handleSecondRowRole = (e: any, roleId: any) => {
    e.preventDefault();

    const groupIndex = userRoleRowTwo.findIndex((role: any) => role === roleId);
    let groupData = [...userRoleRowTwo];

    if(groupIndex >= 0) { 
      groupData.splice(groupIndex, 1);
    } else {
      groupData.push(roleId);
    }

    setUserRoleRowTwo(() => groupData);
    setIsUserRoleError(false);
    setIsUserRoleRowOneError(false);
  };

  const validateEmail = () => {
    setIsEmailError(!emailPattern.test(userEmail));
  };

  const validateFirstName = () => {
    setIsFirstNameError(!userFirstName);
  };

  const validateLastName = () => {
    setIsLastNameError(!userLastName);
  };

  useEffect(() => {
    getAllRoles();
    if (state?.userId) {
      setIsEdit(true);
      setUserId(state?.userId);
      getUserInformation();
    }
  }, []);

  const handleDataUpdateByChar = (letter: string) => {
    console.log("filter", char);
    setChar(letter);
  }

  const handleLiveSearchSelection = (company: any) => {
    console.log("clickedCompany", company);
    const updateCompanyList = renderedCompanies.filter((x) => x.company_id !== company.id); // remove the company from rendered list
    setSelectedCompanies([...selectedCompanies, {
      company_id: company.id,
      name: company.name,
      access_type: "full",
      isSelected: true,
    }]); // add the company to selected list
    setRenderedCompanies(updateCompanyList);

  }

  let allRoles = roles?.filter((item: any) => item?.name != "Employees")

  console.log('stateData', {
    userEmail,
    userFirstName,
    userLastName,
    isCompanyRestrict,
    userRole,
    userRoleRowOne,
    userRoleRowTwo,
    isEdit,
    userId,
    state,
    char,
    searchString,
  })

  console.log('userCompanies', companies)
  console.log('allCompanies', allCompanies)
  console.log('isEdit', isEdit)
  console.log('renderedCompanies', renderedCompanies);
  console.log('selectedCompanies', selectedCompanies);

  console.log('userRoleRowOne', userRoleRowOne);
  console.log('userRoleRowTwo', userRoleRowTwo);

  console.log("locationState", state, isEdit);


  {console.log("modalListCompanies", modalListCompanies)}



const modalStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "60%",
  minHeight: "30%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 0,
};

  return (
    <div>
      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
            width: '100%'
          }}>
            <NavigateBack />
              <FormSubmitButtonContainer style={{ marginTop: "12px" }}>
                <CustomButton
                  onClick={() => navigate("/dashboard/manage-users")}
                  title="Cancel"
                  type="secondary"
                />
                <CustomButton onClick={createUser} title="Save" />
              </FormSubmitButtonContainer>
          </div>
          
          {showWarning &&
            <div style={{
              display: 'flex',
              alignItems: 'center',
              gap: '1.5rem',
              backgroundColor: '#FFFBEB',
              padding: '1.5rem',
              borderRadius: '12px',
              border: '1px solid #F6B100',
              margin: '1.5rem 0',
              boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
              width: '65vw'
            }}>
              <IoWarningOutline size={70} color="#F6B100" />
              <div>
                <p style={{
                  color: '#B45309',
                  marginBottom: '0.25rem',
                  fontSize: '1.5rem',
                  fontWeight: '600',
                  lineHeight: '1.4'
                }}>{`Are you sure you want to add ${userFirstName || ""} ${userLastName || ""} a new user?`}
                </p>
                <p style={{
                  color: '#78350F',
                  fontSize: '1.25rem',
                  lineHeight: '1.25'
                }}>
                  {`This email (${userEmail}) is associated with the employee - ${userFirstName || ""} ${userLastName || ""}.`}
                </p>
              </div>
            </div>
          }

          
          <div>
            <form className="role-form">

              <CustomTextField
                title="Email"
                value={userEmail}
                onChangeText={(e) => {
                  setUserEmail(e);
                  setIsEmailError(false);
                  setIsEmailExists(false);
                  setShowWarning(false);
                }}
                error={isEmailError ? "Please Enter a valid email" : isEmailExists ? "User with this email already exists! Please enter a different email." : undefined}
                onBlur={() => {
                  validateEmail();
                  if(originalEmail !== userEmail) {
                    checkIfEmailExist(userEmail.trim());
                  }
                }}
              />
              <CustomTextField
                title="First Name"
                value={userFirstName}
                onChangeText={setUserFirstName}
                error={
                  isFirstNameError
                    ? "Please Enter a valid first name"
                    : undefined
                }
                onBlur={validateFirstName}
              />
              <CustomTextField
                title="Last Name"
                value={userLastName}
                onChangeText={setUserLastName}
                error={
                  isLastNameError ? "Please Enter a valid last name" : undefined
                }
              // onBlur={validateLastName}
              />

              {/* First row of roles */}
              <div style={{ display: "flex", marginTop: "0.8rem" }}>
                <p
                  style={{
                    color: "#495057",
                    width: "30%",
                    flex: 3,
                    maxWidth: 250,
                  }}
                >
                  Choose Only One
                </p>
                <div style={{
                  width: "60%",
                }}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(6, 1fr)",
                    gap: 20,
                    // width: "60%",
                  }}
                >

                  {allRoles.filter((role) => FIRST_ROW_ROLES.includes(role.name))
                    .map((role) => (
                      <Chip
                        title={role.name}
                        onClick={(e) => handleFirstRowRole(e, role.id)}
                        isSelected={userRoleRowOne.includes(role.id)}
                      />
                    ))}

                </div>
                {isUserRoleRowOneError && (
                      <p style={{ color: "red", fontSize: "0.9rem", marginTop: "0.5rem", marginBottom: '1rem' }}>
                        Please select only ONE role from the first row
                      </p>
                    )}
                </div>
              </div>
                    

              {/* Second Row of Roles */}
              <div style={{ display: "flex", marginTop: "0.8rem" }}>
                <p
                  style={{
                    color: "#495057",
                    width: "30%",
                    flex: 3,
                    maxWidth: 250,
                  }}
                >
                  Choose One or More
                </p>

                <div style={{
                  width: "60%",
                }}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(6, 1fr)",
                    gap: 20,
                    // width: "60%",
                  }}
                >

                  {allRoles.filter((role) => !FIRST_ROW_ROLES.includes(role.name))
                    .map((role) => (
                      <Chip
                        title={role.name}
                        onClick={(e) => handleSecondRowRole(e, role.id)}
                        isSelected={userRoleRowTwo.includes(role.id)}
                      />
                    ))}
                </div>

                {isUserRoleError && (
                      <p style={{ color: "red", fontSize: "0.9rem", marginTop: "0.5rem", marginBottom: '1rem' }}>
                        Please select at least one role
                      </p>
                    )}
                </div>
              </div>
              {/* <CustomSelectField
                title="Permission Role"
                value={userRole}
                onChangeOption={(value) => changeUserRole(value)}
                option={roles.map((role) => ({
                  key: role.name,
                  value: role.id,
                }))}
                error={isRoleError ? "Please add a valid Role" : undefined}
              /> */}
              {/* <div style={{ marginTop: "20px", marginBottom: 20 }}>
                <input
                  type="checkbox"
                  style={{ marginRight: "10px" }}
                  onChange={toggleRestrictCompany}
                  checked={isCompanyRestrict}
                />
                <span>Restrict to selected Companies</span>
              </div> */}


              {/* ----------------- Filter By Character Bar ------------------- */}
                {(
                <div
                  style={{
                  margin: '2rem 0',
                  border: '1px solid #e5e7eb',
                  borderRadius: '16px',
                  boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
                  transition: 'all 0.3s ease'
                  }}
                >
                  {/* Filter Section */}
                    <div
                    style={{
                    background: 'linear-gradient(to right, #f8fafc, #f1f5f9)',
                    padding: '1.75rem',
                    borderTopLeftRadius: '16px',
                    borderTopRightRadius: '16px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    gap: '1.75rem',
                    borderBottom: '1px solid #e5e7eb'
                    }}
                    >
                    <FilterByCharacterBar
                    activeCharacter={char}
                    handleUpdate={handleDataUpdateByChar}
                    selection={defaultAccess}
                    // showAccessControl={true}
                    // handleAccess={handleAccess}
                    />

                    {state?.userId && <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', marginLeft: 'auto' }}>
                      <ViewButton onClick={(e)=>{
                        e.preventDefault();
                        setOpenModal(true);
                      }}>View Saved Companies</ViewButton>
                    </div>}
                    <div style={{
                    position: 'relative',
                    width: '100%',
                    }}>
                    <input 
                      type='text'
                      value={searchString}
                      onChange={(e) => setSearchString(e.target.value)}
                      onFocus={() => setChar('All')}
                      placeholder="Search companies..."
                      style={{
                      width: '100%',
                      padding: '0.75rem 1rem',
                      paddingLeft: '2.5rem',
                      borderRadius: '8px',
                      border: '1px solid #e5e7eb',
                      fontSize: '0.875rem',
                      backgroundColor: '#ffffff',
                      transition: 'all 0.2s ease',
                      outline: 'none',
                      boxShadow: '0 1px 2px rgba(0,0,0,0.05)',
                      }}
                    />
                    <GrSearch   style={{
                      position: 'absolute',
                      left: '0.75rem',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      color: '#9ca3af',
                      width: '1.25rem',
                      height: '1.25rem'
                      }}
                      fill="none" 
                      stroke="currentColor" 
                      viewBox="0 0 24 24"/>
                    
                    {/* Live search dropdown */}
                    {searchString && (
                      <div style={{
                      position: 'absolute',
                      top: 'calc(100% + 4px)',
                      left: 0,
                      width: '100%',
                      maxHeight: '250px',
                      overflowY: 'auto',
                      backgroundColor: '#ffffff',
                      borderRadius: '8px',
                      boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                      border: '1px solid #e5e7eb',
                      zIndex: 10,
                      }}>
                      {allCompanies
                        .filter(company => 
                        company.name.toLowerCase().includes(searchString.toLowerCase())
                        )
                        .map((company, index) => (
                        <div 
                          key={company.company_id}
                          onClick={() => {
                          // setSearchString(company.name);
                          console.log("clickedCompany", company);
                          setChar("");
                          setSearchString('');
                          handleLiveSearchSelection(company);
                          }}
                          style={{
                          padding: '0.75rem 1rem',
                          borderBottom: index < allCompanies.filter(c => 
                            c.name.toLowerCase().includes(searchString.toLowerCase())
                          ).length - 1 ? '1px solid #e5e7eb' : 'none',
                          cursor: 'pointer',
                          transition: 'background-color 0.2s',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                          }}
                          onMouseEnter={(e) => {
                          e.currentTarget.style.backgroundColor = '#f9fafb';
                          }}
                          onMouseLeave={(e) => {
                          e.currentTarget.style.backgroundColor = '#ffffff';
                          }}
                        >
                          <span>{company.name}</span>
                          <span style={{
                          fontSize: '0.75rem', 
                          color: '#047857',
                          backgroundColor: '#ecfdf5',
                          // color: company.access_type === 'full' ? '#047857' : 
                          //      company.access_type === 'read' ? '#b45309' : '#ef4444',
                          fontWeight: '500',
                          padding: '0.25rem 0.5rem',
                          borderRadius: '4px',
                          // backgroundColor: company.access_type === 'full' ? '#ecfdf5' : 
                          //         company.access_type === 'read' ? '#fffbeb' : '#fef2f2',
                          }}>
                          {/* {company.access_type === 'full' ? 'Full' : 
                           company.access_type === 'read' ? 'Read Only' : 'None'} */}
                           Full
                          </span>
                        </div>
                        ))
                      }
                      {allCompanies.filter(company => 
                        company.name.toLowerCase().includes(searchString.toLowerCase())
                      ).length === 0 && (
                        <div style={{
                        padding: '0.75rem 1rem',
                        color: '#6b7280',
                        textAlign: 'center'
                        }}>
                        No companies found
                        </div>
                      )}
                      </div>
                    )}
                    </div>
                    </div>

                  {/* Company Grid */}
                  <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(340px, 1fr))',
                    gap: '1.25rem',
                    padding: '2rem',
                    backgroundColor: '#ffffff'
                  }}
                  >
                  {[...selectedCompanies, ...renderedCompanies]
                    ?.filter((x) => (char === "All" ? x.name : x?.name?.startsWith(char)))
                    ?.filter((x) => (x?.access_type !== "none"))
                    ?.map((company) => (
                    <div
                      key={company.company_id}
                      style={{
                      padding: '1.25rem',
                      border: '1px solid #e5e7eb',
                      borderRadius: '12px',
                      backgroundColor: '#ffffff',
                      transition: 'all 0.2s ease-in-out',
                      boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
                      
                      }}
                    >
                      <div
                      style={{
                        fontSize: '1rem',
                        fontWeight: '600',
                        color: '#1f2937',
                        marginBottom: '1rem',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem'
                      }}
                      >
                      {company.name}
                      {company.isSelected && (
                        <MdClose style={{
                          marginLeft: 'auto', 
                          cursor: 'pointer', 
                          color: '#ef4444',
                        }} size={24} 
                        onClick={() => {
                          // Remove from selected companies
                          const updatedSelected = selectedCompanies.filter(c => c.company_id !== company.company_id);
                          console.log('updatedSelected on removed', updatedSelected);
                          setSelectedCompanies(updatedSelected);
                        }} />
                      )}
                      </div>
                      <CustomSelectField
                      width="100%"
                      inputWidth="100%"
                      value={company.access_type}
                      onChangeOption={(value: any) =>
                        updateCompanyAccess(value, company.company_id)
                      }
                      option={RESTRICT_ACCESS_OPTIONS.map((option) => ({
                        key: option.name,
                        value: option.value,
                      }))}
                      />
                    </div>
                    ))}
                  </div>
                </div>
                )}

              {/* <FormSubmitButtonContainer style={{ marginTop: "12px" }}>
                <CustomButton
                  onClick={() => navigate("/dashboard/manage-users")}
                  title="Cancel"
                  type="secondary"
                />
                <CustomButton onClick={createUser} title="Save" />
              </FormSubmitButtonContainer> */}
            </form>
          </div>

          {/* previously saved companies listing modal */}
          <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{ ...modalStyles, borderRadius: "12px" }}>
              <ModalHeader>
                <ModalTitle>Previously Saved Companies</ModalTitle>
                <CloseButton onClick={() => setOpenModal(false)}>
                  <MdClose size={24} color="#64748b" />
                </CloseButton>
              </ModalHeader>
              <ScrollableContent>
                {modalListCompanies?.length > 0 ? (
                  modalListCompanies
                  ?.filter((x) => x?.access_type !== "none")
                  ?.sort((a, b) => a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase()))
                  ?.map((company: any) => (
                    <CompanyCard key={company.id}>
                    <span>{company.name}</span>
                    <AccessBadge accessType={company.access_type}>
                      {company.access_type === "full" ? "Full Access" : company.access_type === "read" ? "Read Only" : "None"}
                    </AccessBadge>
                    </CompanyCard>
                  ))
                ) : (
                  <div
                  style={{
                    textAlign: "center",
                    padding: "2rem",
                    color: "#6b7280",
                    fontSize: "1rem",
                  }}
                  >
                  No companies found
                  </div>
                )}
              </ScrollableContent>
            </Box>
          </Modal>
        </>
      )}

    </div>
  );
};

export default AddUsers;
