import { useContext, useEffect, useState } from "react";
import { resetUser, updateMessage, updateUserIntialState } from "../../_features";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash, FaExclamationCircle } from "react-icons/fa";
import { Button, Loader, Message, TextInput } from "../../components";
import { AuthService } from "../../_service";
import { Constant } from "../../config";
import { useAppDispatch, useAppSelector } from "../../_app";
import CreatePassword from "./CreatePassword";
import ResetPassword from "./ResetPassword";
import Signin from "./Signin";
import { AuthHeader } from "../../components/header";
import useFirebaseAnalytics from "../../hooks/useFirebaseAnalytics";
import moment from "moment";

import {
  BackToHomeLink,
  BackToHomeLinkWrap,
  Container,
  ContainerBox,
  DontRememberLink,
  DontRememberLinkSection,
  InputFieldWrapper,
  Title,
} from "./AuthStyles";
import { Utils } from "../../utils";
import { Icon } from "@mui/material";
import { microAgent } from "../../newRelicConfig";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.user);
  const loginMessage = useAppSelector((state) => state.message.login);
  const resetMessage = () => {
    dispatch(updateMessage({ message: "", page: "login", type: "error" }));
  };

  const [sending, setSending] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const logFirebaseEvent = useFirebaseAnalytics();
  const [showPassword, setShowPassword] = useState(false);

  const [showMessage, setShowMessage] = useState(false);



  const togglePassword = () => {
    setShowPassword(!showPassword);

  };

  const checkIfEmployee = (data:any) => {
    let groups = data.user.groups?.map((x:any) => x.name);
    console.log("Groups = ", groups, groups?.length === 1, groups[0], groups[0] === "Employees");
    if (groups?.length === 1 && groups[0] === "Employees") {
      console.log('inside groups, navigating', groups, groups[0]);
      return true;
    } else {
      return false;
    }
  }


  useEffect(() => {
    if (user.name !== "") {
      navigate("/dashboard/manage-company");
    }
  }, [user.name]);

  useEffect(() => {
    console.log("Timeout useEffect called")
    const storedShowLogoutMessage = localStorage.getItem('showLogoutMessage') === 'true';
    
    let timeoutId: NodeJS.Timeout;
    if (storedShowLogoutMessage) {
      localStorage.removeItem('showLogoutMessage');
      setShowMessage(true);
      
      timeoutId = setTimeout(() => {
        setShowMessage(false);
      }, 10000);
    }

    // Cleanup timeout on component unmount or when dependency changes
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  const handleLogin = async () => {
    if (email === "") {
      dispatch(
        updateMessage({
          message: `Email field can not be empty`,
          page: "login",
          type: "error",
        })
      );
    } else if (password === "") {
      dispatch(
        updateMessage({
          message: `Password field can not be empty`,
          page: "login",
          type: "error",
        })
      );
    } else {
      try {
        setSending(true);
        const res = await AuthService._userLogin({
          username: email,
          password,
        });
        if (res.status === 200) {
          Utils.resetStoreData();
          console.log("Server = ", process.env.SERVER);

          if(checkIfEmployee(res.data)) {
            dispatch(resetUser());
            navigate("/mobile-only", { replace: true });
          } else if (res.data.user.email) {
            let key = "login_user";
            let logData = {
              user_id: res.data.user.id,
              name: res.data.user.name,
              // device_fingerprint: "using_client_js",
              event_date_time: moment().utc().format("DD/MM/YYYY hh:mm A"),
              platform: "web",
            };
            // logFirebaseEvent(key, logData);
            microAgent.addPageAction(key, logData)

            dispatch(
              updateUserIntialState({
                email: res.data.user.email,
                id: res.data.user.id,
                name: res.data.user.name,
                refresh: res.data.refresh,
                access: res.data.access,
                defaultCompany:
                  res.data?.default_company?.default_company_info?.name ||
                  "undefined",
                defaultCompanyPayrunStartDate:
                  res.data?.default_company?.default_company_info?.payrun_start_date,
                defaultCompnayId: res.data?.default_company?.default_company,
                actualDefaultCompanyId:
                  res.data?.default_company?.default_company,
                lastLoggedInTime: Date.now(),
                lastActivityTime: Date.now(),
                isSessionActive: true,
                menu: res.data.menu,
                menu_permission: res.data.menu_permission,
                groups: res.data.user.groups,
                is_superuser: res.data.user.is_superuser,
                permission:
                  res.data?.default_company?.access &&
                    res.data?.default_company?.access !== null
                    ? res.data?.default_company?.access
                    : "full",
                current_payrun_date:
                  res.data?.default_company?.default_company_info
                    ?.current_payrun_date ||
                  res.data?.default_company?.default_company_info
                    ?.payrun_start_date,
              })
            );
            sessionStorage.setItem("activeTab", "true");
          }
        } else {
          dispatch(
            updateMessage({
              message: `Couldn't log you in as '${email}'. Please try again or reset your password if this persists.`,
              page: "login",
              type: "error",
            })
          );
        }
        setSending(true);
      } catch (e: any) {
        setSending(false);
        dispatch(
          updateMessage({
            message: `Couldn't log you in as '${email}'. Please try again or reset your password if this persists.`,
            page: "login",
            type: "error",
          })
        );
      }
    }
  };

  return (
    <>
      {sending && <Loader />}
      <AuthHeader />

      {showMessage && <div style={{
        textAlign: 'center',
        padding: '24px',
        margin: '3rem auto',
        marginBottom: 0,
        maxWidth: '40%',
        backgroundColor: '#e8f4ff',
        borderRadius: '12px',
        boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
        border: '1px solid #b8daff',
        display: 'flex',
        alignItems: 'center',
        gap: '16px'
      }}>
        <FaExclamationCircle size={32} style={{ color: '#004085' }} />
        <div>
          <p style={{
            color: '#004085',
            fontSize: '1rem',
            lineHeight: '1.6',
            margin: 0,
            fontWeight: '500',
            textAlign: 'left'
          }}>
            You have been inactive for a while. We have logged you out for security reasons.
            <br />
            Please sign in again to resume your session.
          </p>
        </div>
      </div>}

      <Container>
        <ContainerBox>
          <Title>{Constant.login.login}</Title>
          <InputFieldWrapper>
            <Message data={loginMessage} close={resetMessage} />
            <TextInput
              labal={Constant.login.email}
              type="email"
              placeholder="Email address associated with your account"
              value={email}
              onChange={setEmail}
              style={{ textTransform: "none" }}
              containerStyle={{ marginTop: "1rem" }}
            />

            <DontRememberLink tabIndex={-1}  to="/password-resets">
              {Constant.login.forgot_password}
            </DontRememberLink>
            <DontRememberLinkSection>

              <div style={{
                margin: "0 auto",
                borderColor: "#000",
                borderWidth: "1px"
              }}>
                <div style={{
                  width: " 100%",
                  position: "relative"
                }}>
                  <span style={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'absolute',
                    height: '100%',
                    top: "1rem",
                    right: "1rem",
                    paddingRight: '1px',
                  }}>
                    {(showPassword)
                      ? <FaEyeSlash onClick={togglePassword} style={{ cursor: 'pointer' }} />
                      : <FaEye onClick={togglePassword} style={{ cursor: 'pointer' }} />}
                  </span>

                  <TextInput
                    labal={Constant.login.password}
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter password (leave blank if not chosen yet)"
                    value={password}
                    onChange={setPassword}
                    style={{
                      textTransform: "none",
                      display: 'block',
                      width: '100%',
                      height: '45px',
                      padding: '0 5px',
                      borderRadius: '2px',
                    }}
                    onSubmit={handleLogin}
                  />
                </div>
              </div>
            </DontRememberLinkSection>
            <Button title={"Login"} onClick={handleLogin} />
          </InputFieldWrapper>
          <BackToHomeLinkWrap>
            <BackToHomeLink to="https://tellboss.co/">
              {Constant.common.back_to_home_page}
            </BackToHomeLink>
          </BackToHomeLinkWrap>
        </ContainerBox>

      </Container>
      
    </>
  );
};

export default Login;
export { CreatePassword, ResetPassword, Signin };
