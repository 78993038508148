import apiClient from "./BaseService";
import { URL } from "../config";

const _getServiceCodeList = async () => apiClient.get(URL.SERVICE_CODES);
const _addServiceCode = async (data: any) => {
    return apiClient.post(URL.SERVICE_CODES, data)
}
const _deleteServiceCode = async (serviceId: any) => {
    return apiClient.delete(URL.SERVICE_CODES + serviceId + '/')
}
const _editServiceCode = async (data: any, serviceId: any) => {
    return apiClient.patch(URL.SERVICE_CODES + serviceId + '/', data)
}

const _manageService = async (data: any, action: any) => {
    return apiClient.post(URL.MANAGE_SERVICE + action, data)
}

const _getInvoiceList = async (dateFrom: any, dateTo: any , companyId :any) =>
    apiClient.get(`${URL.GET_INVOICE_LIST}&company_id=${companyId}&date_from=${dateFrom}&date_to=${dateTo}&page_size=2000&p=1`);

const _getInvoicingCompanies = async (date: string) =>
    apiClient.get(`${URL.GET_INVOICING_COMPANIES}${date}`);

const _getInvoicePastMonthList = async (from: string, to: string, query: string, page: number, pagesize: number) =>
    apiClient.get(`${URL.GET_GLOBAL_INVOICE_DATA}?date_from=${from}&date_to=${to}&query=${query}&page_size=${pagesize}&p=${page}`);

const _getInvoiceCurrentMonthList = async (from: string, to: string) =>
    apiClient.get(`${URL.GET_GLOBAL_INVOICE_DATA}?date_from=${from}&date_to=${to}`);

const _getInvoiceData = async (companyId: string, date: string , type:string) =>
    apiClient.get(`${URL.GET_INVOICE_DATA}${companyId}&date=${date}&type=${type}`);

const _resendInvoice = async (invoiceId: any) => {
    return apiClient.post(`${URL.RESEND_INVOICE}${invoiceId}/send/`)
}

// action: 'save-send' or null
const _saveInvoice = async (action: any, data: any, type: String) => {
    return apiClient.post(`${URL.SAVE_INVOICE}${action}&type=${type}`, data);
}

const _reconcileInvoice = async (data: any) => {
    return apiClient.post(`${URL.RECONCILE_INVOICE}`, data);
}

const _downloadInvoice = async (invoiceId: any) => {
    return apiClient.get(`${URL.DOWNLOAD_INVOICE}${invoiceId}/pdf/`)
}

const _EditInvoice = async (id: any, action: any, data: any) => {
    return apiClient.patch(`${URL.SAVED_INVOICE}${id}/?action=${action}`, data);
}

const _viewInvoice = async (invoiceId: any) => {
    return apiClient.get(`${URL.VIEW_INVOICE}${invoiceId}/`)
}

const _getSpcList = async () => {
    return apiClient.get(`${URL.GET_SPC_DATA}`)
}
const _getsearchinvoiceList = async (from: string, to: string, searchPara: any, page: number, pagesize: number) => {
    return apiClient.get(`${URL.GET_SEARCH_DATA}${searchPara}&date_from=${from}&date_to=${to}&page_size=${pagesize}&p=${page}`)
}
const _addspc = async (data: any) => {
    return apiClient.post(`${URL.ADD_SPC_DATA}`, data);
}
const _voidinvoiceid = async (id: any, action: any, data: any) => {
    return apiClient.patch(`${URL.VOID_INVOICE}/${id}/take_action/?action_type=${action}`, data);
}
const _editspc = async (data: any, spcId: any) => {
    return apiClient.patch(URL.EDIT_SPC_DATA + spcId + '/', data)
}
const _deletespc = async (serviceId: any) => {
    return apiClient.delete(URL.SPC_CODE + serviceId + '/')
}

//_getRepeatInvoiceList
const _getRepeatInvoiceList = async (id:any) => {
    return apiClient.get(`${URL.GET_REPEATED_INVOICE_LIST}${id}`)
}
const _getRepeatInvoiceData = async (id:any) => {
    return apiClient.get(`${URL.GET_REPEATED_INVOICE_DATA}${id}`)
}
const _createRepeatInvoice = async (payload:any) => {
    return apiClient.post(`${URL.CREATE_REPEATED_INVOICE}`,payload)
}
const _updateRepeatInvoice = async (id: any ,payload : any) => {
    return apiClient.patch(`${URL.UPDATE_REPEATED_INVOICE}${id}/`,payload)
}
const _checkSpcAndBillingDetails = async (id:any) => {
    return apiClient.get(`${URL.CHECK_SPC_AND_BILLINGDETAILS}${id}`)
}

export const ServiceCodeService = {
    _getServiceCodeList,
    _addServiceCode,
    _deleteServiceCode,
    _editServiceCode,
    _manageService,
    _getInvoiceList,
    _downloadInvoice,
    _resendInvoice,
    _getInvoiceData,
    _saveInvoice,
    _viewInvoice,
    _reconcileInvoice,
    _getSpcList,
    _addspc,
    _editspc,
    _deletespc,
    _voidinvoiceid,
    _getsearchinvoiceList,
    _getInvoicePastMonthList,
    _getInvoiceCurrentMonthList,
    _getInvoicingCompanies,
    _EditInvoice,
    _getRepeatInvoiceList,
    _createRepeatInvoice,
    _updateRepeatInvoice,
    _getRepeatInvoiceData,
    _checkSpcAndBillingDetails,
};
