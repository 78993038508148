import { AppSettingService } from "../../_service";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { BsPlusCircleFill } from "react-icons/bs";
import { Card, Container, Heading, Navlink } from "../dashboard/Styles";
import { CustomButton } from "../../components/customButton";
import { Loader } from "../../components";

const CustomItems = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useQuery("custom_input_update_list", () =>
    AppSettingService._getUpdatedCustomInputs()
  );

  const keyOrder = [
    "income",
    "deduction",
    "allowance",
    "employer_contribution",
    "reimbursement",
    "benefit",
    "other"
  ];
  return (
    <div style={{ position: "relative" }}>
      {isLoading && <Loader />}
      <div>
        <p style={{ 
          fontSize: '1rem',
          color: '#1D1D1D',
          // marginBottom: '0.5rem',
          marginTop: '1rem',
          paddingLeft: '1rem',
          paddingRight: '1rem',
          marginLeft: '1rem',
          fontStyle: 'italic',
          border: '1px solid #e0e0e0',
          borderLeft: '4px solid #2196f3',
          padding: '1rem 1.5rem',
          borderRadius: '8px',
          fontWeight: 500,
          width: 'fit-content',
          backgroundColor: '#f8f9fa'
        }}>
          Admin can Add, Edit and Delete any custom items.
        </p>
        <Container>
          {keyOrder?.map((type: any) => {
            return (
              data?.data?.data?.input_items.filter(
                (item: any) => item.input_category === type
              )?.length > 0 &&
              <Card key={type} style={{ maxWidth: 500 }}>
                 <Heading>{type === "deduction" ? "Deductions" : type === "allowance" ? "Allowances" : (type?.replaceAll("_", " ") || "")}</Heading>
                {(
                  data?.data?.data?.input_items.filter(
                    (item: any) => item.input_category === type
                  ) || []
                )
                ?.sort((a:any, b:any)=>a.name?.toLowerCase()?.localeCompare(b.name?.toLowerCase()))
                ?.map((item: any) => {
                  return (
                    <Navlink
                      className="data card-row-link"
                      to={`/dashboard/add-item-fields/${type}`}
                      state={{ name: type, data: item }}
                      key={item?.name}
                    >
                      {item?.name}
                    </Navlink>
                  );
                })}
              </Card>
            );
          })}
        </Container>
      </div>
      <CustomButton
        title="Add"
        type="secondary"
        success
        onClick={() => {
          navigate("/dashboard/add-item");
        }}
        icon={<BsPlusCircleFill />}
        styles={{
          "marginTop": "0.75rem"
        }}
      />
    </div>
  );
};

export default CustomItems;
