import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomButton, CustomSelectField } from "../../components"; 
import { Notify } from "../../utils";
import { CompanyPayrollService } from "../../_service";
import { useAppSelector } from "../../_app";
import TextField from "../../components/customFormField/TextField";

const Billing = () => {
  const navigate = useNavigate();
  const currencyOptions = {
    "INR": { "name": "Indian Rupee", "symbol": "₹" },
    "MYR": { "name": "Malaysian Ringgit", "symbol": "RM" },
    "SGD": { "name": "Singapore Dollar", "symbol": "S$" }
  };

  const user = useAppSelector((state) => state.user);
  const [isEdit, setIsEdit] = useState(false);
  const [billingId, setBillingId] = useState("");

  const [currencyValue, setCurrencyValue] = useState("SGD");
  const [gstPercentage, setGstPercentage] = useState("");
  const [primaryName, setPrimaryName] = useState("");
  const [primaryEmail, setPrimaryEmail] = useState("");
  const [primaryPhone, setPrimaryPhone] = useState("");
  const [secondaryName, setSecondaryName] = useState("");
  const [secondaryEmail, setSecondaryEmail] = useState("");
  const [secondaryPhone, setSecondaryPhone] = useState("");

  const [errors, setErrors] = useState({
    gstPercentage: "",
    currencyValue: "",
    primaryName: "",
    primaryEmail: "",
    primaryPhone: "",
  });

  const validateForm = () => {
    const newErrors = {
      gstPercentage: !gstPercentage ? "GST Percentage is required" : "",
      currencyValue: !currencyValue ? "Currency is required" : "",
      primaryName: !primaryName ? "Primary Full Name is required" : "",
      primaryEmail: !primaryEmail ? "Primary Email is required" : "",
      primaryPhone: !primaryPhone ? "Primary Phone number is required" : "",
    };

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => !error);
  };

  const handleSave = async () => {
    if (!validateForm()) {
      return;
    }

    const payload = {
      company: user.defaultCompnayId,
      gst_percentage: gstPercentage,
      currency: currencyValue,
      primary_person_name: primaryName,
      primary_person_email: primaryEmail,
      primary_person_phone: primaryPhone,
      secondary_person_name: secondaryName || null,
      secondary_person_email: secondaryEmail || null,
      secondary_person_phone: secondaryPhone || null,
    };

    try {
      if (isEdit) {
        const { status } = await CompanyPayrollService._updateBillingData(billingId, payload);

        if (status === 200) {
          Notify("Billing details Updated Successfully", 1);
        }
      } else {
        const { status } = await CompanyPayrollService._saveBillingData(payload);

        if (status === 201) {
          Notify("Billing details Added Successfully", 1);
          getBillingData();
        }
      }
    } catch (error: any) {
      Notify(error.response.data.primary_person_email[0], 0);
    }
  };

  const getBillingData = async () => {
    try {
      const { status, data } = await CompanyPayrollService._getBillingData(parseInt(user?.defaultCompnayId));

      if (status === 200) {
        setBillingId(data.data.id);
        setIsEdit(!!data.data.id);
        setGstPercentage(data.data.gst_percentage);
        setCurrencyValue(data.data.currency || "SGD");
        setPrimaryEmail(data.data.primary_person_email);
        setPrimaryName(data.data.primary_person_name || "");
        setPrimaryPhone(data.data.primary_person_phone || "");
        setSecondaryName(data.data.secondary_person_name);
        setSecondaryEmail(data.data.secondary_person_email || "");
        setSecondaryPhone(data.data.secondary_person_phone || "");
      }
    } catch (error) {
      Notify("Something went wrong, please try again", 0);
    }
  };

  useEffect(() => {
    getBillingData();
  }, [user.defaultCompnayId]);

  return (
    <div style={{ padding: "5px" }}>
      {/* GST Percentage */}
      <TextField
        require
        title="GST Percentage"
        placeholder="Enter GST Percentage"
        value={gstPercentage}
        onChangeText={setGstPercentage}
        error={errors.gstPercentage}
      />

      {/* Currency */}
      <CustomSelectField
        require
        title="Currency"
        placeholder="Select Currency"
        value={currencyValue}
        onChangeOption={setCurrencyValue}
        option={Object.entries(currencyOptions).map(([key, value]) => ({
          key,
          value: key,
        }))}
      />
      {errors.currencyValue && <div style={{ color: "red" }}>{errors.currencyValue}</div>}

      <h3 style={{ color: "#204ca0", margin: "8px 0px" }}>Billing Person Details</h3>

      {/* Primary Person Details */}
      <div>
        <h4 style={{ color: "#204ca0", margin: "8px 0px" }}>Primary</h4>
        <TextField
          require
          title="Name"
          placeholder="Enter Name"
          value={primaryName}
          onChangeText={setPrimaryName}
          error={errors.primaryName}
        />
        <TextField
          require
          title="Email"
          placeholder="Enter Email"
          value={primaryEmail}
          onChangeText={setPrimaryEmail}
          error={errors.primaryEmail}
        />
        <TextField
          require
          title="Phone No"
          placeholder="Enter Phone No"
          value={primaryPhone}
          onChangeText={setPrimaryPhone}
          error={errors.primaryPhone}
        />
      </div>

      {/* Secondary Person Details */}
      <div>
        <h4 style={{ color: "#204ca0", margin: "8px 0px" }}>Secondary</h4>
        <TextField
          title="Name"
          placeholder="Enter Name"
          value={secondaryName}
          onChangeText={setSecondaryName}
        />
        <TextField
          title="Email"
          placeholder="Enter Email"
          value={secondaryEmail}
          onChangeText={setSecondaryEmail}
        />
        <TextField
          title="Phone No"
          placeholder="Enter Phone No"
          value={secondaryPhone}
          onChangeText={setSecondaryPhone}
        />
      </div>

      <div style={{ display: "flex", margin: "20px 0px" }}>
        <CustomButton title="Cancel" type="secondary" onClick={() => navigate(-1)} />
        <CustomButton
          title="Save"
          onClick={handleSave}
         
        />
      </div>
    </div>
  );
};

export default Billing;