import React from "react";
import { GrFormClose } from "react-icons/gr";
import styled from "styled-components";

interface SearchFieldProps {
  value: string;
  onChangeText: (e: string) => void;
}

const InvoiceSearchField = ({ onChangeText, value }: SearchFieldProps) => {
  return (
    <Container>
      <Input
        type="text"
        placeholder="Search here..."
        value={value}
        onChange={(e) => onChangeText(e.target.value)}
      />
      {value && (
        <IconWrap onClick={() => onChangeText("")}>
          <GrFormClose />
        </IconWrap>
      )}
    </Container>
  );
};

export default InvoiceSearchField;

const Container = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #ced4da;
  border-radius: 6px;
  overflow: hidden;
  background: #fff;
  width: 300px;
  transition: all 0.3s ease;
  
  &:focus-within {
    border-color: rgb(5, 155, 191);
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
  }
`;

const Input = styled.input`
  flex: 1;
  padding: 6px 12px;
  font-size: 1rem;
  color: #495057;
  border: none;
  outline: none;
  background: transparent;
  
  &::placeholder {
    color: #adb5bd;
  }
`;

const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    background: #f1f3f5;
  }
`;
