import React from "react";
import { useNavigate } from "react-router-dom";
import { FiAlertOctagon } from "react-icons/fi";
import { Typography, Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { BsExclamationTriangle } from "react-icons/bs";

const PayrunDateNotSetPage: React.FC = () => {
  const navigate = useNavigate();

  const user = useSelector((state: any) => state.user);

  let isReadOnly = user.permission === "read";

  console.log("on403", user.permission === "read");

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
          bgcolor: "#ffffff",
          padding: 3,
          background: "linear-gradient(145deg, #ffffff 0%, #f5f5f5 100%)",
        }}
      >
        <BsExclamationTriangle
          style={{
            color: "#ff4444",
            width: "12rem",
            height: "20rem",
            filter: "drop-shadow(2px 2px 4px rgba(0,0,0,0.1))",
          }}
        />
        {/* <FiAlertOctagon size={80} color="#ff4444" /> */}
        <Typography
          variant="h2"
          component="h1"
          gutterBottom
          sx={{
            color: "#333333",
            textShadow: "2px 2px 4px rgba(0,0,0,0.1)",
            fontWeight: "bold",
            letterSpacing: "2px",
          }}
        >
          Access Denied
        </Typography>
        {
          <Typography
            component="h2"
            align="center"
            sx={{
              mb: 2,
              color: "#dc3545",
              maxWidth: "600px",
              fontSize: "1.75rem",
              textAlign: "center",
              fontWeight: "600",
              letterSpacing: "0.5px",
              // textTransform: "uppercase",
              // background: "linear-gradient(45deg, #A6AEBF 30%, #3f51b5 90%)",
              // WebkitBackgroundClip: "text",
              // WebkitTextFillColor: "transparent",
              textShadow: "1px 1px 2px rgba(0,0,0,0.05)",
            }}
          >
            You have not set the payrun date yet. Please click on the button
            below to set the payrun date.
          </Typography>
        }
        <Button
          variant="contained"
          size="large"
          onClick={() => navigate("/dashboard/app-settings/employer-details")}
          sx={{
            background: "linear-gradient(45deg, #ff2222 30%, #ff6666 90%)",
            color: "white",
            padding: "12px 30px",
          }}
        >
          Set Payrun Date
        </Button>
      </Box>
    </div>
  );
};

export default PayrunDateNotSetPage;

// 404 page
