import React, { useEffect, useState } from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Box,
  Button,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import SaveIcon from "@mui/icons-material/Save";
import { LoaderContainer } from "../../CommonStyles";
import { CustomButton, Loader } from "../../components";
import { AppSettingService } from "../../_service";
import { Notify, Utils } from "../../utils";
import { useAppSelector } from "../../_app";
import cloneDeep from "lodash.clonedeep";

const data = {
  "regular-input": {
    income: [
      {
        ri_id: 41,
        code: "Performance_Bonus",
        name: "Performance Bonus",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 25,
        code: "OT_-Hr_@_1.5x",
        name: "OT -Hr @ 1.5x",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 29,
        code: "Incentive",
        name: "Incentive",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 1,
        code: "basic_salary",
        name: "Basic Salary",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 2,
        code: "basic_salary",
        name: "Basic Salary",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 3,
        code: "basic_salary",
        name: "Basic Salary",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 4,
        code: "basic_salary",
        name: "Basic Salary",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 5,
        code: "basic_salary",
        name: "Basic Salary",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 6,
        code: "monthly_comission",
        name: "Monthly Commission",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 7,
        code: "nsmen_pay",
        name: "NSmen Pay (non-DIRECT)",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 8,
        code: "nsmen_pay",
        name: "NSmen Pay (non-DIRECT)",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 100,
        code: "BackPay",
        name: "BackPay",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 97,
        code: "Leave_Encashment",
        name: "Leave Encashment",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 87,
        code: "OT_-Hr_@_2x",
        name: "OT -Hr @ 2x",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 102,
        code: "OT_Hr_@_1X",
        name: "OT Hr @ 1X",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 103,
        code: "OT_HR_@3X",
        name: "OT HR @3X",
        is_selected: false,
        source: "regular-input",
      },
    ],
    allowance: [
      {
        ri_id: 49,
        code: "Housing_Allowance",
        name: "Housing Allowance",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 52,
        code: "Meal_Allowance",
        name: "Meal Allowance",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 69,
        code: "Transport_Allowance",
        name: "Transport Allowance",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 56,
        code: "Mobile_Phone_Allowance",
        name: "Mobile Phone Allowance",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 64,
        code: "Entertainment_Allowance",
        name: "Entertainment Allowance",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 82,
        code: "Sales_Commission",
        name: "Sales Commission",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 91,
        code: "Healthcare",
        name: "Healthcare",
        is_selected: false,
        source: "regular-input",
      },
    ],
    reimbursement: [
      {
        ri_id: 30,
        code: "Medical_Claim",
        name: "Medical Claim",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 32,
        code: "Business_Entertainment",
        name: "Business Entertainment",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 33,
        code: "Local_Purchase",
        name: "Local Purchase",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 81,
        code: "Office_Expense",
        name: "Office Expense",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 101,
        code: "Transport_Claim",
        name: "Transport Claim",
        is_selected: false,
        source: "regular-input",
      },
    ],
    deduction: [
      {
        ri_id: 10,
        code: "self-help_gruop_funds",
        name: "Self-Help Group Funds",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 11,
        code: "self-help_gruop_funds",
        name: "Self-Help Group Funds",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 12,
        code: "self-help_gruop_funds",
        name: "Self-Help Group Funds",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 80,
        code: "Income_Withheld_(Tax_Clearance)",
        name: "Income Withheld (Tax Clearance)",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 9,
        code: "self-help_gruop_funds",
        name: "Self-Help Group Funds",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 89,
        code: "Override_CDAC_/_ECF_/_SINDA",
        name: "Override CDAC / ECF / SINDA",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 114,
        code: "PayBack_Loan",
        name: "PayBack Loan",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 13,
        code: "self-help_gruop_funds",
        name: "Self-Help Group Funds",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 19,
        code: "pension_provident_fund",
        name: "Pension / Provident Fund Outside Singapore",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 23,
        code: "share",
        name: "SHARE",
        is_selected: false,
        source: "regular-input",
      },
    ],
    employer_contribution: [
      {
        ri_id: 79,
        code: "FWL",
        name: "FWL",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 22,
        code: "fwl",
        name: "FWL",
        is_selected: false,
        source: "regular-input",
      },
    ],
    other: [
      {
        ri_id: 14,
        code: "cpf_at_higher_rates",
        name: "CPF at Higher Rates",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 17,
        code: "employer_loan",
        name: "Employer Loan",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 18,
        code: "pension_provident_fund",
        name: "Pension / Provident Fund Outside Singapore",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 15,
        code: "cpf_at_higher_rates",
        name: "CPF at Higher Rates",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 16,
        code: "employer_loan",
        name: "Employer Loan",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 20,
        code: "pension_provident_fund",
        name: "Pension / Provident Fund Outside Singapore",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 21,
        code: "savings",
        name: "Savings",
        is_selected: false,
        source: "regular-input",
      },
      {
        ri_id: 24,
        code: "share",
        name: "SHARE",
        is_selected: false,
        source: "regular-input",
      },
    ],
  },
  "payslip-input": {
    reimbursement: [
      {
        pc_id: 33,
        code: "Mobile_Phone_once-off_for_specified_payslips",
        name: "Mobile Phone",
        is_selected: false,
        source: "payslip-input",
      },
      {
        pc_id: 79,
        code: "Foreign_Travel_Expense_once-off_for_specified_payslips",
        name: "Foreign Travel Expense",
        is_selected: false,
        source: "payslip-input",
      },
      {
        pc_id: 66,
        code: "CPF_and_Donation_once-off_for_specified_payslips",
        name: "CPF and Donation",
        is_selected: false,
        source: "payslip-input",
      },
    ],
    deduction: [
      {
        pc_id: 53,
        code: "OverMBMF_mbf_once-off_for_specified_payslips",
        name: "OverMBMF mbf",
        is_selected: false,
        source: "payslip-input",
      },
      {
        pc_id: 54,
        code: "Override_MBMF_mendaki_once-off_for_specified_payslips",
        name: "Override MBMF mendaki",
        is_selected: false,
        source: "payslip-input",
      },
      {
        pc_id: 70,
        code: "Claims_Repayment_once-off_for_specified_payslips",
        name: "Claims Repayment",
        is_selected: false,
        source: "payslip-input",
      },
    ],
    income: [
      {
        pc_id: 78,
        code: "No-Pay-Leave_(-ve)_once-off_for_specified_payslips",
        name: "No-Pay-Leave (-ve)",
        is_selected: false,
        source: "payslip-input",
      },
      {
        pc_id: 2,
        code: "annual_bonus",
        name: "Annual Bonus",
        is_selected: false,
        source: "payslip-input",
      },
      {
        pc_id: 56,
        code: "Previous_Month_Salary_once-off_for_specified_payslips",
        name: "Previous  Month Salary",
        is_selected: false,
        source: "payslip-input",
      },
      {
        pc_id: 62,
        code: "Bonus_once-off_for_specified_payslips",
        name: "Bonus",
        is_selected: false,
        source: "payslip-input",
      },
      {
        pc_id: 7,
        code: "ir21_income_withheld",
        name: "Income Paid Out After Tax Clearance",
        is_selected: false,
        source: "payslip-input",
      },
      {
        pc_id: 4,
        code: "leave_encashment",
        name: "Leave Paid Out",
        is_selected: false,
        source: "payslip-input",
      },
      {
        pc_id: 5,
        code: "termination_lump_sums",
        name: "Termination Lump Sums",
        is_selected: false,
        source: "payslip-input",
      },
      {
        pc_id: 8,
        code: "irregular_commission",
        name: "Non-Monthly Commission",
        is_selected: false,
        source: "payslip-input",
      },
      {
        pc_id: 58,
        code: "AWS_once-off_for_specified_payslips",
        name: "AWS",
        is_selected: false,
        source: "payslip-input",
      },
    ],
    other: [
      {
        pc_id: 3,
        code: "cpf_adjustment",
        name: "CPF Adjustment",
        is_selected: false,
        source: "payslip-input",
      },
    ],
  },
};

const CompanySpecificItemsListing = () => {
  const user = useAppSelector((state) => state.user);
  const defaultCompanyId = user.defaultCompnayId;
  const [isLoading, setIsLoading] = useState(false);
  const [originalData, setOriginalData] = useState<any>([]);
  const [processedData, setProcessedData] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [selectedItems, setSelectedItems] = useState<any>({});
  const [categories, setCategories] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const itemOrder = [
    "income",
    "allowance",
    "gross_pay",
    "summary",
    "deduction",
    "deductions",
    "reimbursement",
    "other",
    "netpay",
    "employer_contribution",
    "quantity",
  ];

  const handleToggle = (code: string, isChecked: any) => {
    let data = cloneDeep(selectedItems);
    if (Object.keys(data).includes(code)) {
      data[code] = isChecked;
    } else {
      data[code] = isChecked;
    }
    setSelectedItems(data);
  };

  const getCompanySpecificPayItemsListing = async () => {
    setIsLoading(() => true);
    try {
      const res = await AppSettingService._getCompanySpecificPayItemsListing(
        defaultCompanyId
      );
      if (res.status >= 200 && res.status < 300) {
        let data = res?.data?.data;

        let keys = [
          ...new Set([
            ...Object.keys(data?.["regular-input"] || {}),
            ...Object.keys(data?.["payslip-input"] || {}),
          ]),
        ];
        setCategories(() => keys);
        setOriginalData(data);
      }
    } catch (e: any) {
      Utils.showErrorMessage(e);
    }
    setIsLoading(() => false);
  };

  const handleSave = async () => {
    setLoading(true);
    let clonedData = cloneDeep(originalData);
    let allItems = [
      ...Object.values(clonedData["regular-input"]).flat(),
      ...Object.values(clonedData["payslip-input"]).flat(),
    ];

    allItems.forEach((item: any) => {
      item.is_selected = selectedItems[item.code];
    });

    console.log("items", allItems, Array.isArray(allItems));

    let payload: any = {
      company_id: defaultCompanyId,
      selection_list: allItems.filter((x:any) => x.is_selected === true),
    };

    try {
      const res = await AppSettingService._saveCompanySpecificPayItemsListing(
        payload
      );
      if (res.status >= 200 && res.status < 300) {
        Notify("Updated Successful", 1);
      }
    } catch (e: any) {
      Utils.showErrorMessage(e);
    }
    setLoading(false);
  };

  // Function to remove duplicates based on 'code'
  const removeDuplicates = (arr: any) => {
    const seen = new Map();
    return arr.filter((item: any) => {
      if (!seen.has(item.code)) {
        seen.set(item.code, true);
        return true;
      }
      return false;
    });
  };

  const processData = () => {
    let clonedData = cloneDeep(originalData);
    let payItems: any = {};

    for (let key in clonedData) {
      if (key === "regular-input") {
        for (let category in clonedData[key]) {
          payItems[category] = removeDuplicates(clonedData[key][category]);
        }
        console.log("PayItems after regular", payItems);
      } else {
        for (let category in clonedData[key]) {
          payItems[category] = [
            ...(payItems[category] || []),
            ...clonedData[key][category],
          ];
        }
      }
    }

    let isCheckStatus: any = {};
    console.log(
      "flat",
      Object.values(payItems)
        .flat()
        .forEach((item: any) => {
          isCheckStatus[item.code] = item.is_selected;
        })
    );
    setFilteredData(payItems);
    setSelectedItems(isCheckStatus);
  };

  const sortedEntries = Object.entries(filteredData).sort((a, b) => {
    const indexA = itemOrder.indexOf(a[0]);
    const indexB = itemOrder.indexOf(b[0]);
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;
    return indexA - indexB;
  });

  console.log("sortedEntries", sortedEntries);

  useEffect(() => {
    processData();
  }, [originalData]);

  // console.log("uniqueData", removeDuplicates(data));
  console.log("categories", categories);

  useEffect(() => {
    getCompanySpecificPayItemsListing();
  }, []);

  console.log("originalData", originalData);
  console.log("selectedItems", selectedItems);

  if (isLoading) {
    return (
      <div>
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      </div>
    );
  } else {
    return (
      <Box sx={{ margin: "0 auto", padding: 3 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "85%",
            marginBottom: "1rem",
          }}
        >
          <CustomButton
            title="Save"
            onClick={handleSave}
            styles={{ fontWeight: 600 }}
            disable={loading}
          />
        </div>

        <Box sx={{ display: "flex", flexWrap: "wrap", columnGap: 8 }}>
          {sortedEntries.map(([key, value]: any) => (
            <Box
              key={key}
              sx={{
                mb: 4,
                borderRadius: 2,
                boxShadow: 3,
                overflow: "hidden",
                minWidth: "40%",
              }}
            >
              <Box
                sx={{
                  p: 2,
                  backgroundColor: "#61677a",
                  color: "primary.contrastText",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    textTransform: "capitalize",
                    fontWeight: 700,
                    fontSize: "1.5rem",
                  }}
                >
                  {key.replace("_", " ")}
                </Typography>
                <Box>
                  <Button
                    size="small"
                    variant="outlined"
                    sx={{
                      mr: 1,
                      borderColor: "#fff",
                      color: "#fff",
                      outline: "none",
                      fontWeight: 700,
                      ":hover": {
                        borderColor: "#fff",
                        color: "#fff",
                      },
                    }}
                    onClick={() => {
                      const newSelectedItems = { ...selectedItems };
                      value.forEach((item: any) => {
                        newSelectedItems[item.code] = true;
                      });
                      setSelectedItems(newSelectedItems);
                    }}
                  >
                    All
                  </Button>
                  <Button
                    size="small"
                    variant="outlined"
                    sx={{
                      mr: 1,
                      borderColor: "#fff",
                      color: "#fff",
                      outline: "none",
                      fontWeight: 700,
                      ":hover": {
                        borderColor: "#fff",
                        color: "#fff",
                      },
                    }}
                    onClick={() => {
                      const newSelectedItems = { ...selectedItems };
                      value.forEach((item: any) => {
                        newSelectedItems[item.code] = false;
                      });
                      setSelectedItems(newSelectedItems);
                    }}
                  >
                    None
                  </Button>
                </Box>
              </Box>
              <Box sx={{ p: 1 }}>
                {[...value].sort((a, b) => a.name.localeCompare(b.name)).map((item: any) => (
                  <ListItem
                  key={item.code}
                  sx={{
                    "&:hover": {
                    backgroundColor: "action.hover",
                    },
                  }}
                  >
                  <Checkbox
                    checked={selectedItems[item.code]}
                    onChange={(event) =>
                    handleToggle(item.code, event.target.checked)
                    }
                    sx={{
                    "&.Mui-checked": {
                      color: "primary.main",
                    },
                    }}
                    disabled={item.code === "basic_salary" ? true : false}
                  />
                  <ListItemText
                    primary={item.name}
                    sx={{
                    "& .MuiTypography-root": {
                      fontSize: "1rem",
                      fontWeight: selectedItems[item.code] ? 500 : 400,
                    },
                    }}
                  />
                  </ListItem>
                ))}
              </Box>
            </Box>
          ))}
        </Box>

        {/* <Button
          variant="contained"
          // startIcon={<SaveIcon />}
          sx={{
            mt: 2,
            bgcolor: 'primary.main',
            color: 'white',
            fontWeight: 700,
            '&:hover': {
              bgcolor: 'primary.dark',
            }
          }}
        >
          Save
        </Button> */}
      </Box>
    );
  }
};

export default CompanySpecificItemsListing;
