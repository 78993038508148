import { useEffect, useState } from "react";
import {
  CustomButton,
  CustomTextField,
  CustomNumberField,
  NavigateBack,
  CustomSelectField,
  Loader,
  Tooltip,
} from "../../../components";
import { Heading } from "../AppSettingsStyles";
import { Notify, Utils } from "../../../utils";
import { Constant } from "../../../config";
import { CompanyService, ServiceCodeService } from "../../../_service";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { ReportSectionHeading } from "../../reports/ReportFilterPageStyles";
import { useAppDispatch, useAppSelector } from "../../../_app";
import { LoaderContainer } from "../../../CommonStyles";
import { IRASTable } from "../../filing/CPFFilingStyles";
import { TableHeader } from "../../../components/table";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material";
import { updateAppSettingId } from "../../../_features";
import { AiFillDelete } from "react-icons/ai";
import { tr } from "date-fns/locale";
import SimorEffectInvoiceTable from "../../invoice/SimorEffectInvoiceTable";
import { X } from "@mui/icons-material";

interface serviceCodesInterface {
  id: number;
  name: string;
  fixed_amount: number;
  variable_amount: number;
  description: string;
}
interface Company {
  id: number;
  name: string;
}
const GenerateInvoice = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const date = new Date();
  let isViewOnly = location?.state?.isViewOnly;
  let isEdit = location?.state?.isEdit;
  const invoiceType = location?.state?.invoiceType
  console.log("location", invoiceType);

  const companyId = useAppSelector((state) => state.user.defaultCompnayId);
  const currentPayrunDate = useAppSelector(
    (state) => state.user.current_payrun_date
  );

  const [serviceCodes, setServiceCodes] = useState<serviceCodesInterface[]>([])
  const [serviceCodesList, setServiceCodesList] = useState<serviceCodesInterface[]>([])
  const [selectedServiceCode, setSelectedServiceCode] = useState<serviceCodesInterface | null>(null)
  const [reference, setReference] = useState("");
  const [hideContent, setHideContent] = useState({
    hide: false,
    message: "",
  });
  const [Error, setError] = useState({
    hide: false,
    message: ""
  })

  console.log("hideContent", hideContent);



  const [invoiceNumber, setInvoiceNumber] = useState<string>("");
  const [invoiceData, setInvoiceData] = useState<any>({});
  const [scInvoiceData, setScInvoiceData] = useState<any>([]);
  const [companyData, setCompanyData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(companyId);
  interface ErrorInvoiceData {
    is_service_provider_selected?: boolean;
    is_billing_details?: boolean;
  }

  const [errorInvoiceData, setErrorInvoiceData] = useState<ErrorInvoiceData>({});
  console.log("companyselectedCompany", companyData, selectedCompany, errorInvoiceData);

  const [noOfEmployee, setnoOfEmployee] = useState("0")
  let [totalAmount, settotalAmount] = useState(0.00)
  const [grandTotalUpdated, setGrandTotalUpdated] = useState<number>(scInvoiceData?.total_amount?.map((x: any) => x += x));
  const [afterGstTaotalAmount, setAfterGstTaotalAmount] = useState<number>(0)
  const [gstTaotalAmount, setGstTaotalAmount] = useState<number>(0)

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const monthsOptions = Utils.MONTH_OPTIONS;
  const yearOptions = Utils.getDefaultYearRange(parseInt(currentPayrunDate?.split('-')[0]));
  console.log("scInvoiceData", scInvoiceData);

  const currencySymbol = invoiceData?.company_data?.billing_details?.currency_details?.symbol

  console.log("currencySymbol", currencySymbol);
  const filteredCompanies: Company[] = companyData.filter((x: any) => x.id === parseInt(selectedCompany));

  // Get the first matched company name or fallback
  const selectedCompanyName: string = filteredCompanies.length > 0 ? filteredCompanies[0].name : "";

  console.log("selectedCompanyName", selectedCompanyName);
  const handleAddService = (servicecode: any) => {
    setnoOfEmployee('0')
    settotalAmount(0)
    if (totalAmount === 0) {
      totalAmount = totalAmount + servicecode.fixed_amount
    }
    const updatedFormData = {
      service_id: servicecode.id,
      name: servicecode.name,
      description: servicecode.description,
      fixed_amount: servicecode.fixed_amount,
      variable_amount: servicecode.variable_amount,
      no_of_employees: noOfEmployee,
      total_amount: totalAmount,
    };


    const isDuplicate = scInvoiceData.some((item: any) => item.service_id === servicecode.id);
    if (isDuplicate) {
      Notify("This service is already added!", 0);

    } else {
      setScInvoiceData((prev: any) => [...prev, updatedFormData]);
      setError({
        hide: false,
        message: ""
      })
      Notify("Service added Successfully", 1);

    }
  };

  const [year, setYear] = useState<any>(moment().format("YYYY"));
  const [month, setMonth] = useState<any>(moment().format("MM"));

  const serviceCodeTableHeaders = [
    {
      name: "Service code",
      width: "17%",
    },
    {
      name: "Discription",
      width: "21%",
    },
    {
      name: "Fixed amount",
      width: "17%",
    },
    {
      name: "Variable Amount",
      width: "17%",
    },
    {
      name: "No. of Employees",
      width: "17%",
    },
    {
      name: "Total",
      width: "17%",
    },
    {
      name: "Delete",
      width: "",
    },
  ];



  const getAllCompany = async () => {
    setIsLoading(() => true);

    try {
      const { data } = await CompanyService._getAllComanies(1);
      setCompanyData(data)
    } catch (error) {
      Notify("Something went wrong, can't get company", 0);
    }
    setIsLoading(() => false);

  };


  const getInvoiceData = async () => {
    setIsLoading(() => true);
    try {
      const { data, status } = await ServiceCodeService._getInvoiceData(
        selectedCompany === "" ? companyId : selectedCompany,
        `01-${month}-${year}`,
        invoiceType === "INV" ? "INV" : "CN"
      );


      if (status >= 200 && status < 300) {
        setHideContent({
          ...hideContent,
          hide: false,
          message: "",
        });
        setInvoiceData(data?.data);
        setScInvoiceData(data?.data.json_data.items)
      }
    } catch (e: any) {
      if (e?.response?.data?.message) {
        setInvoiceData({})
        console.log("Error Data:is_service_provider_selected", e?.response?.data?.data?.is_service_provider_selected);
        console.log("Error Data:is_billing_details", e?.response?.data?.data?.is_billing_details);
        const errorData = e?.response?.data?.data || {};


        if (errorData.is_service_provider_selected === false) {
          setHideContent({
            hide: true,
            message: `SPC not saved for ${selectedCompanyName}. Please switch to the ${selectedCompanyName} and save the SPC in Settings -> Manage Set-up Settings -> Employer Details.`,
          });
        }

        if (errorData.is_billing_details === false) {
          setHideContent({
            hide: true,
            message: `Billing Details not saved for ${selectedCompanyName}. Please switch to the ${selectedCompanyName} and save the billing details in Settings -> Manage Set-up Settings -> Billing`,
          });
        }
      }
    }

    setIsLoading(() => false);
  };

  const getInvoiceViewData = async () => {
    setIsLoading(() => true);
    try {
      const { data, status } = await ServiceCodeService._viewInvoice(
        location?.state?.id
      );

      if (status >= 200 && status < 300) {
        console.log("view res", data?.data);
        setInvoiceData(data?.data);
        setScInvoiceData(data?.data.json_data.items)

      }
    } catch (e: any) {
      if (e?.response?.data?.message) {
        Notify(e?.response?.data?.message, 0);
      }
      else {
        Notify(Constant.common.something_went_wrong, 0);
      }
    }
    setIsLoading(() => false);
  };

  const ApproveActionHandler = async (id: any, actions: any, data: any) => {

    try {
      const { status } = await ServiceCodeService._voidinvoiceid(id, actions, data)
      if (status === 200) {
        Notify("Approved  Successful!", 1);

      } else {


      }
    }
    catch (error: any) {
      if (error.response.data?.message) {
        Notify(error.response.data?.message, 0);
      } else {
        Notify(Constant.common.something_went_wrong, 0);
      }
    }
  }

  const handleSave = async (action: any, type: String) => {


    if (scInvoiceData.length <= 0) {
      setError({
        hide: true,
        message: "* Atleast Select One Service Code"
      }
      )
      return
    }

    if (!isEdit) {

      let payload = {
        company: selectedCompany === "" ? companyId : selectedCompany,
        date: `${year}-${month}-01`,
        reference: reference,
        json_data: {
          sub_total: grandTotalUpdated,
          items: scInvoiceData,
          gst: invoiceData?.company_data?.billing_details?.gst_percentage,
          grand_total: afterGstTaotalAmount,
          gst_amount: gstTaotalAmount
        },
      };

      try {
        const { status } = await ServiceCodeService._saveInvoice(action, payload, type);

        if (status >= 200 && status < 300) {
          Notify("Credit Note Created Successfully", 1);
          navigate(-1);
        }
      } catch (e: any) {
        if (e?.response?.data?.message || e?.response?.data?.number[0]) {
          console.log("error", e?.response?.data?.message);
          Notify(e?.response?.data?.message || e?.response?.data?.number[0], 0);
        } else {
          Notify(Constant.common.something_went_wrong, 0);
        }
      }

    }
    else {
      let payload = {
        json_data: {
          sub_total: grandTotalUpdated,
          items: scInvoiceData,
          gst: invoiceData?.company_data?.billing_details?.gst_percentage,
          grand_total: afterGstTaotalAmount,
          gst_amount: gstTaotalAmount

        },
      }

      try {
        const { status } = await ServiceCodeService._EditInvoice(location?.state?.id, action, payload);
        if (status >= 200 && status < 300) {
          if (status >= 200 && status < 300) {
            Notify(Constant.invoice.send, 1);
          }
          navigate(-1);
        }
      } catch (e: any) {
        if (e?.response?.data?.message || e?.response?.data?.number[0]) {
          console.log("error", e?.response?.data?.message);
          Notify(e?.response?.data?.message || e?.response?.data?.number[0], 0);
        } else {
          Notify(Constant.common.something_went_wrong, 0);
        }
      }
    }
  };


  // Resend and Download
  const handleResend = async (invoiceId: any) => {
    setIsLoading(() => true);
    try {
      const { status } = await ServiceCodeService._resendInvoice(invoiceId);

      if (status >= 200 && status < 300) {
        Notify("Send Successfully", 1);
      }
    } catch (error: any) {
      if (error?.response?.status === 400) {
        Notify(error.response.data?.message, 0);
      } else {
        Notify(Constant.common.something_went_wrong, 0);
      }
    }
    setIsLoading(() => false);
  };

  const handlePDFDownload = async (invoiceId: any) => {
    try {
      let response = await ServiceCodeService?._downloadInvoice(invoiceId);
      if (response.status >= 200 && response.status < 300) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `invoice.pdf`; // Set the desired filename for the downloaded file
        document.body.appendChild(a);
        a.click();
      }
    } catch (error) {
      // Notify(Constant.common.something_went_wrong, 0);
      Notify("No PDF Available", 0);
    }
  };
  useEffect(() => {
    getServiceCodesList()
    getAllCompany()
  }, [])

  useEffect(() => {
    if (isEdit) {
      setInvoiceData((prev: any) => {
        return { ...prev, invoice_data: {} }
      });
      getInvoiceViewData();
    } else {
      setInvoiceData((prev: any) => {
        return { ...prev, invoice_data: {} }
      });
      getInvoiceData();
    }
  }, [isEdit, selectedCompany]);

  useEffect(() => {
    if (Array.isArray(scInvoiceData)) {
      const total = scInvoiceData.reduce(
        (acc, curr) => acc + (curr.total_amount || 0),
        0
      );
      setGrandTotalUpdated(total);
      setAfterGstTaotalAmount(total * invoiceData?.company_data?.billing_details?.gst_percentage / 100 + total)
      setGstTaotalAmount(total * invoiceData?.company_data?.billing_details?.gst_percentage / 100)
    } else {
      console.error("scInvoiceData is not an array:", scInvoiceData);
      setGrandTotalUpdated(0); // Default fallback
    }
  }, [scInvoiceData, invoiceData]);

  let dataToMap = isViewOnly ? invoiceData?.json_data?.items : invoiceData?.invoice_data?.items;
  let grandTotal = isViewOnly ? scInvoiceData?.invoice_data?.json_data?.grand_total : scInvoiceData?.invoice_data?.grand_total;

  const getServiceCodesList = async () => {
    try {
      const { data, status } = await ServiceCodeService._getServiceCodeList();
      if (status === 200) {
        setServiceCodesList(() =>
          data.data.map((dataObj: any) => ({
            id: dataObj.id,
            name: dataObj.name,
            fixed_amount: dataObj.fixed_amount,
            variable_amount: dataObj.variable_amount,
            description: dataObj.description,
          }))
        );
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }

  };


  const handleChange = (e: any, id: any, field: any) => {

    const newData = scInvoiceData.map((row: any) => {
      if (row.service_id === id) {
        const updatedValue = field === "description" ? e?.target?.value : parseFloat(e?.target?.value) || 0;

        const updatedRow = { ...row, [field]: updatedValue };

        if (field === "fixed_amount" || field === "variable_amount" || field === "no_of_employees") {
          updatedRow.total_amount =
            (parseFloat(updatedRow.fixed_amount) || 0) +
            (parseFloat(updatedRow.variable_amount) || 0) *
            (parseFloat(updatedRow.no_of_employees) || 0);
        }

        return updatedRow;
      }
      return row;
    });

    setScInvoiceData(newData);

  };

  const onDeleteButton = (sid: any) => {
    const updatedData = scInvoiceData.filter((item: any) => item.service_id !== sid);
    setScInvoiceData(updatedData);
  };

  if (isLoading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  return (
    <div style={{ fontSize: '.9rem' }}>
      <div style={{ padding: "1rem", position: "relative" }}>
        {/* Header Section */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            marginBottom: ".2rem",
          }}
        >
          <NavigateBack />
          <Heading>
            {invoiceType == "INV" ? `${(`${!isEdit ? "Create Invoice" : `${ isViewOnly ? "View Invoice" : "Edit Invoice"}`}`)}` : `${(`${!isEdit ? "Create Credit Note" : `${isViewOnly ? "View Cedit Note" :"Edit Credit Note"}`}`)}`}
          </Heading>
        </div>

        <div>
          {/* Customer Selection */}
          {
            !isEdit && <div style={{ marginBottom: ".5rem", color: "rgb(71, 101, 117)" }}>
              <CustomSelectField
                require
                title="Select Customer"
                placeholder="Select Customer"
                value={selectedCompany}
                onChangeOption={setSelectedCompany}
                option={companyData.map((value: any) => ({
                  key: value.name,
                  value: value.id,
                }))}
              />
            </div>
          }

          <div style={{ display: "flex", gap: "9.1rem", marginBottom: ".2rem", marginTop: ".5rem" }}>
            <span
              style={{
                color: "#476575",
                fontWeight: 600,
                fontSize: "1rem",
                whiteSpace: "nowrap",
              }}
            >
              SPC Company:
            </span>
            <span style={{ color: "#333", fontWeight: "500" }}>
              {invoiceData?.company_data?.spc_details?.company_name || "N/A"}
            </span>
          </div>
        </div>

        {
          hideContent.hide && <div style={{
            padding: "10px",
            margin: "10px 0",
            borderRadius: "5px",
            fontWeight: "bold",
            backgroundColor: "#f8d7da",
            color: "#856404",
            border: "1px solid #f5c6cb",
          }}>{hideContent.message}</div>
        }


        {!hideContent.hide &&
          <div style={{
            top: "4.8rem",
            position: "absolute",
            right: '5%',
          }}>
            {/* Invoice Date Section */}
            {!isEdit && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "7rem",
                  marginBottom: ".3rem",
                }}
              >
                <p
                  style={{
                    color: "#476575",
                    fontWeight: 600,
                    fontSize: "1rem",
                  }}
                >
                  {invoiceType === "INV" ? "Invoice Date:" : "Credit Note Date:"}<span style={{ color: "red", fontSize: ".8rem" }}>*</span>
                </p>
                <div style={{ display: "flex", gap: "1.5rem" }}>
                  <CustomSelectField
                    value={month}
                    onChangeOption={setMonth}
                    option={monthsOptions.map((month) => ({
                      key: month.key,
                      value: month.value,
                    }))}
                    disabled={isLoading}
                    isDate
                  />
                  <CustomSelectField
                    value={year}
                    onChangeOption={setYear}
                    option={yearOptions.map((year) => ({
                      key: year,
                      value: year,
                    }))}
                    disabled={isLoading}
                    isDate
                  />
                </div>
              </div>
            )}

            {/* Invoice Number Section */}
            {!isEdit && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "6rem",
                  marginBottom: ".5rem",
                  marginTop: ".2rem",
                }}
              >
                <span
                  style={{
                    color: "#476575",
                    fontWeight: 600,
                    fontSize: "1rem",
                  }}
                >
                  {invoiceType === "INV" ? "Invoice Number:" : "Credt Note Number:"}
                </span>
                <div>{invoiceData.invoice_data.invoice_number}</div>
              </div>
            )}
          </div>
        }
        {!hideContent.hide &&
          <div>
            {/* SPC Company */}



            <h3 style={{
              paddingTop: "1rem",
              margin: "1rem 0rem",
              borderTop: "2px solid gray"
            }}>Customer Information</h3>
            {/* Customer Information */}
            <div style={{
              display: 'flex',
              justifyContent: "space-between",

            }}>

              <div
                style={{
                  display: "flex",
                  alignItems: "self-start",
                  justifyContent: "space-between",
                }}
              >


                <div style={{
                  display: "flex",
                  alignItems: "self-start",
                  justifyContent: "space-between",
                  gap: "10rem"

                }}>
                  <div>
                    {/* Company Information */}
                    <div style={{ display: "flex", gap: "4rem", }}>
                      <p style={{ color: "#495057", width: "5rem", fontWeight: 600 }}>Company:</p>
                      <p style={{ color: "#333" }}>{invoiceData?.company_data?.company_details?.name || "N/A"}</p>
                    </div>
                    {/* Address Section */}
                    <div style={{ display: "flex", gap: "4rem", marginBottom: '.2rem' }}>
                      <p style={{ color: "#495057", width: "5rem", fontWeight: 600 }}>Address:</p>
                      <div style={{ color: "#333" }}>
                        <p>{invoiceData?.company_data?.company_details?.address_l1 || ""}</p>
                        <p>{invoiceData?.company_data?.company_details?.address_l2 || ""}</p>
                        <p>{invoiceData?.company_data?.company_details?.address_l3 || ""}{"  "}{invoiceData?.company_data?.company_details?.address_code || ""}</p>
                      </div>
                    </div>
                  </div>



                  <div>
                    {/* Authorised Person */}
                    <div style={{ display: "flex", gap: "1rem", marginBottom: '.2rem' }}>
                      <p style={{ color: "#495057", width: "8rem", fontWeight: 600 }}>Contact Name:</p>
                      <p style={{ color: "#333" }}>{invoiceData?.company_data?.billing_details?.primary_person_name || "N/A"}</p>
                    </div>

                    <div style={{ display: "flex", gap: "1rem", marginBottom: '.2rem' }}>
                      <p style={{ color: "#495057", width: "8rem", fontWeight: 600 }}>Email Address:</p>
                      <p style={{ color: "#333" }}>{invoiceData?.company_data?.billing_details?.primary_person_email || "N/A"}</p>
                    </div>
                    {/* <div style={{ display: "flex", gap: "1rem", marginBottom: '.2rem' }}>
                      <p style={{ color: "#495057", width: "8rem", fontWeight: 600 }}>Telephone No:</p>
                      <p style={{ color: "#333" }}>{invoiceData?.company_data?.billing_details?.primary_person_phone || "N/A"}</p>
                    </div> */}
                  </div>
                </div>



              </div>



              <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "end",
                paddingTop: "3rem",
              }}>
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: '0.6rem',
                  alignContent: 'no-wrap',
                  width: "100%",
                  marginBottom : "1rem"

                }}>
                  <div style={{
                    fontWeight: 500,
                    color: "#333",
                    width: "100%",
                    whiteSpace: "nowrap",
                  }}>
                    {isEdit
                      ? `${invoiceType === "INV" ? "Invoice Date" : "Credit Note Date:"} ${moment(invoiceData.formatted_date, "DD-MM-YYYY").format("D MMMM YYYY")}`
                      : ""}
                  </div>
                  {/* Invoice Number (View-Only Mode) */}

                  {isEdit && (
                    <div
                      style={{
                        display: "flex",
                        gap: "1.8rem",
                        fontWeight: 500,
                        color: "#333",
                        justifyItems: 'start',
                        width: "60%",
                        whiteSpace: "nowrap"

                      }}
                    >
                      <p style={{ width: "10rem" }}>{invoiceType === "INV" ? "Invoice Number" : "Credt Note Number:"}</p>
                      <p>{location?.state?.invoice_number}</p>
                    </div>
                  )}


                </div>




              </div>

            </div>
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}>
              {!isEdit ? (
                <div style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}>
                  <label style={{ width: "145px", color: "rgb(73, 80, 87)", fontSize: "15px", fontWeight: 600 }}>Reference</label>
                  <input
                    type="text"
                    style={{ padding: "4px", borderRadius: "4px", border: "1px solid #ccc" }}
                    placeholder="Enter reference"
                    value={reference}
                    onChange={(e) => setReference(e.target.value)}
                  />
                </div>
              ) : <div></div>}

              {(!location?.state?.isViewOnly || invoiceData.status == "saved") && <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                  <label style={{ marginBottom: "3px", fontSize: "15px", whiteSpace: "nowrap" }}>
                    Service Code{!isEdit && <span style={{ color: "red", fontSize: ".8rem" }}>*</span>}
                  </label>
                  <select
                    value={selectedServiceCode?.id || ""}
                    onChange={(e) => {
                      const selectedId = Number(e.target.value);
                      const selectedService =
                        serviceCodesList.find((service: any) => service.id === selectedId) || null;
                      setSelectedServiceCode(selectedService);
                    }}
                    style={{
                      padding: "5px",
                      borderRadius: "0.2rem",
                      border: "1px solid #ccc",
                      minWidth: "180px"

                    }}
                  >
                    <option value="" disabled>
                      Select service code
                    </option>
                    {serviceCodesList.map((option: any) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>



                <div style={{}}>
                  <CustomButton
                    title="Add"
                    onClick={() => handleAddService(selectedServiceCode)}
                    size="small"
                    width="80px"
                    disable={selectedServiceCode === null ? true : false}

                  />

                </div>

                <div>
                  {Error?.hide && (
                    <div style={{
                      color: "#d8000c",
                    }}>
                      {Error?.message}
                    </div>
                  )}
                </div>

              </div>

              }

            </div>

          </div>
        }

      </div>

      {!hideContent.hide &&
        <div>
          {!isViewOnly && <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow
                  sx={{
                    background: "#b8daff",
                  }}
                >
                  <TableCell sx={{ fontWeight: 600 }} align="left">
                    Code
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600 }} align="left">
                    Description
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600 }} align="right">
                    Fixed Amount
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600 }} align="right">
                    Per Employees
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600 }} align="right">
                    {" "}
                    Number
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600 }} align="right">
                    {" "}
                    Amount
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600 }} align="center">
                    {" "}
                    Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {scInvoiceData?.length > 0 ? scInvoiceData?.map((item: any) => (
                  <TableRow
                    key={item.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      // background: "#e9f3ff",
                    }}
                  >
                    <TableCell align="left">{item.name}</TableCell>
                    <TableCell align="left">

                      <input
                        value={item.description}
                        onChange={(e) =>
                          handleChange(e, item.service_id, "description")
                        }
                        style={{ textAlign: "left", padding: "5px 5px", borderRadius: "4px", width: "450px" }}

                      />
                    </TableCell>
                    <TableCell align="right">
                      <input
                        value={item.fixed_amount}
                        onChange={(e) =>
                          handleChange(e, item.service_id, "fixed_amount")
                        }
                        style={{ textAlign: "right", padding: "10px 2px", borderRadius: "4px" }}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <input
                        value={item.variable_amount}
                        onChange={(e) =>
                          handleChange(e, item.service_id, "variable_amount")
                        }
                        style={{ textAlign: "right", padding: "10px 2px", borderRadius: "4px" }}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <input
                        value={item.no_of_employees}
                        onChange={(e) =>
                          handleChange(e, item.service_id, "no_of_employees")
                        }
                        style={{ textAlign: "right", padding: "10px 2px", borderRadius: "4px" }}
                      />
                    </TableCell>
                    <TableCell align="right">{currencySymbol}{`${Utils.getCommanSepartedNumericString(
                      item?.total_amount || "0"
                    )}`}</TableCell>
                    <TableCell align="right" style={{
                      paddingLeft: "3rem"
                    }}>
                      <Tooltip text="Remove" del>
                        <CustomButton
                          type={"secondary"}
                          size="small"
                          del
                          width="30px"
                          title={""}
                          onClick={() => {
                            onDeleteButton(item.service_id)
                          }}
                          icon={<AiFillDelete />}
                        />
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                )) : <div>

                </div>}
                {grandTotalUpdated ? (
                  <TableRow
                    sx={{
                      background: "#b8daff",
                    }}
                  >
                    <TableCell
                      sx={{ fontWeight: 600, fontSize: "1rem" }}
                      colSpan={6}
                      align="right"
                    >{`Sub Total:`}</TableCell>
                    <TableCell
                      sx={{ fontWeight: 600, fontSize: "1rem" }}
                      colSpan={0}
                      align="right"
                    >
                      {`${currencySymbol}${Utils.getCommanSepartedNumericString(
                        grandTotalUpdated || ""
                      )}`}</TableCell>
                  </TableRow>
                ) : <SimorEffectInvoiceTable />}
                {grandTotalUpdated > 0 && (
                  <TableRow
                    sx={{
                    }}
                  >
                    <TableCell
                      sx={{ fontWeight: 600, fontSize: "1rem" }}
                      colSpan={6}
                      align="right"
                    >{`GST ${invoiceData?.company_data?.billing_details?.gst_percentage}%`}</TableCell>
                    <TableCell
                      sx={{ fontWeight: 600, fontSize: "1rem" }}
                      colSpan={1}
                      align="right"
                    >
                      {currencySymbol}{Utils.getCommanSepartedNumericString(gstTaotalAmount)}
                    </TableCell>
                  </TableRow>
                )}

                {grandTotalUpdated > 0 && (
                  <TableRow
                    sx={{
                      background: "#b8daff",
                    }}
                  >
                    <TableCell
                      sx={{ fontWeight: 600, fontSize: "1rem" }}
                      colSpan={6}
                      align="right"
                    >{`Total:`}</TableCell>
                    <TableCell
                      sx={{ fontWeight: 600, fontSize: "1rem" }}
                      colSpan={1}
                      align="right"
                    >{`${currencySymbol}${Utils.getCommanSepartedNumericString(
                      afterGstTaotalAmount || ""
                    )}`}</TableCell>
                  </TableRow>
                )}

              </TableBody>
            </Table>
          </TableContainer>
          }
        </div>
      }

      {location?.state?.isViewOnly && <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow
              sx={{
                background: "#b8daff",
              }}
            >
              <TableCell sx={{ fontWeight: 600 }} align="left">
                Code
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }} align="left">
                Description
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }} align="right">
                Fixed Amount
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }} align="right">
                Per Employees
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }} align="right">
                {" "}
                Number
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }} align="right">
                {" "}
                Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataToMap?.length > 0 && dataToMap?.map((item: any) => (
              <TableRow
                key={item.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  // background: "#e9f3ff",
                }}
              >
                <TableCell component="th" scope="item" align="left">
                  {item.name}
                </TableCell>
                <TableCell component="th" scope="item" align="left">
                  {item.description || ""}
                </TableCell>
                <TableCell align="right">{`${currencySymbol}${Utils.getCommanSepartedNumericString(
                  item?.fixed_amount || ""
                )}`}</TableCell>
                <TableCell align="right">{`${currencySymbol}${Utils.getCommanSepartedNumericString(
                  item?.variable_amount || ""
                )}`}</TableCell>
                <TableCell align="right">{`${(
                  item?.no_of_employees || ""
                )}`}</TableCell>
                <TableCell align="right">{`${currencySymbol}${Utils.getCommanSepartedNumericString(
                  item?.total_amount || ""
                )}`}</TableCell>
              </TableRow>
            ))}
            {grandTotalUpdated && (
              <TableRow
                sx={{
                  background: "#b8daff",
                }}
              >
                <TableCell
                  sx={{ fontWeight: 600, fontSize: "1rem" }}
                  colSpan={5}
                  align="right"
                >{`Sub Total`}</TableCell>
                <TableCell
                  sx={{ fontWeight: 600, fontSize: "1rem" }}
                  colSpan={1}
                  align="right"
                >{`${currencySymbol}${Utils.getCommanSepartedNumericString(
                  grandTotalUpdated || ""
                )}`}</TableCell>
              </TableRow>
            )}

            {grandTotalUpdated && (
              <TableRow
                sx={{
                }}
              >
                <TableCell
                  sx={{ fontWeight: 600, fontSize: "1rem" }}
                  colSpan={5}
                  align="right"
                >{`GST ${invoiceData?.company_data?.billing_details?.gst_percentage}%`}</TableCell>
                <TableCell
                  sx={{ fontWeight: 600, fontSize: "1rem" }}
                  colSpan={1}
                  align="right"
                >
                  {currencySymbol}{Utils.getCommanSepartedNumericString(gstTaotalAmount)}
                </TableCell>
              </TableRow>
            )}

            {grandTotalUpdated && (
              <TableRow
                sx={{
                  background: "#b8daff",
                }}
              >
                <TableCell
                  sx={{ fontWeight: 600, fontSize: "1rem" }}
                  colSpan={5}
                  align="right"
                >{`Total`}</TableCell>
                <TableCell
                  sx={{ fontWeight: 600, fontSize: "1rem" }}
                  colSpan={1}
                  align="right"
                >{`${currencySymbol}${Utils.getCommanSepartedNumericString(
                  afterGstTaotalAmount || ""
                )}`}</TableCell>
              </TableRow>
            )}

          </TableBody>
        </Table>
      </TableContainer>
      }

      {!hideContent.hide &&
        <div>
          {!isViewOnly && <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
              marginTop: "1.5rem",
              marginLeft: "auto",
              width: "100%",
            }}
          >
            <CustomButton title="Back" onClick={() => navigate(-1)} loading={isLoading} disable={isLoading} />
            <CustomButton title="Save as Draft" onClick={() => handleSave("save", invoiceType === "INV" ? "INV" : "CN")} loading={isLoading} disable={isLoading} />
            {invoiceData.status == "saved" &&
              // <CustomButton title="Save & Approve" onClick={() => ApproveActionHandler(selectedCompany === "" ? companyId : selectedCompany, "approved", null)} loading={isLoading} disable={isLoading} />
              <CustomButton title="Save & Approve" onClick={() => handleSave("save-approve", invoiceType === "INV" ? "INV" : "CN")} loading={isLoading} disable={isLoading} />

            }
          </div>}
        </div>
      }

      {dataToMap?.length > 0 && location?.state?.isViewOnly && <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "right",
          marginTop: "1.5rem",
          marginLeft: "auto",
          width: "100%",
        }}
      >
        <CustomButton title="Download PDF" onClick={() => handlePDFDownload(location?.state?.id)} loading={isLoading} disable={isLoading} />
        {invoiceData.status == "saved" && <CustomButton
          title="Send"
          onClick={() => handleResend(location?.state?.id)}
          loading={isLoading}
          disable={isLoading}
        />}
      </div>}
    </div>
  );
};

export default GenerateInvoice;
