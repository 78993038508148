
const SimorEffectInvoiceTable = () => {
    return (
        <div style={{ 
            
            color: '#888', 
            fontSize: '16px', 
            fontStyle: 'italic', 
            padding: '20px', 
            marginLeft : "130%",
            whiteSpace : "nowrap"
          }}>
            <p>Please Select Service Code</p>
          </div>
    )
}

export default SimorEffectInvoiceTable