import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";

import "./payroll.css";
import { useAppSelector } from "../../../_app";
import { NavOptions } from "../component";
import { useState } from "react";
import styled from "styled-components";
import { IoMdClose } from "react-icons/io";
import { Utils } from "../../../utils";

const activeStyle = {
  color: "#212529",
  borderBottom: "3px solid #25b481",
};

const PayrollManage = () => {
  const emp = useAppSelector((state) => state.employee.currentEmployee);
  // const employeeEOS = useAppSelector((state)=> state.employee?.currentEmployee?.eos);

  const navigate = useNavigate();
  const location = useLocation();

  const [showBanner, setShowBanner] = useState<any>(emp?.id === emp?.eos?.employee);

  const [displayFor, setDisplayFor] = useState("");
  const onClickLeaveOption = (props: any) => {
    navigate(props.navigate);
  };

  return (
    <div>
      <div>
        {showBanner && 
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '60%',
          padding: '1rem',
          borderRadius: '0.5rem',
          textTransform: 'uppercase',
          background: "#ff6665",
          color: "#ffffff",
          fontWeight: 800,
          border: "1px solid ff6665",
        }}>
           <p>{"This Employee has been terminated !"}</p>
           <IoMdClose size={24} onClick={()=> setShowBanner(false)} style={{
            cursor: 'pointer',
           }}/>
          </div>}
        <Heading className="payroll-header-h2">
          {`${displayFor} ${emp?.name}${Utils.getTitleWithCompanyName(" ")}`}
        </Heading>
      </div>
      <div className="payroll-header">
        {emp?.showPayroll && (
          <NavLink
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
            className="employee-sublink"
            to="emp"
            onClick={() => setDisplayFor("")}
          >
            Payroll
          </NavLink>
        )}
        <NavOptions
          label="Edit Info"
          val={location.pathname.slice(19)}
          name={setDisplayFor}
          values={[
            {
              displayName: "Edit Details - ",
              name: "Basic Info",
              navigate: "basic-info",
            },
            {
              displayName: "Edit Hours - ",
              name: "Edit Regular Hours",
              navigate: "regular-hour",
            },
          ]}
          onChange={onClickLeaveOption}
        />
        <NavOptions
          label="Leave"
          val={location.pathname.slice(19)}
          name={setDisplayFor}
          values={[
            { displayName: "Leave - ", name: "Activity", navigate: "activity" },
            {
              displayName: "Record Leave Taken - ",
              name: "Record Taken",
              navigate: "record-taken",
            },
            {
              displayName: "Entitlement Policy Setup for ",
              name: "Entitlement Policies",
              navigate: "entitlement-policies",
            },
            {
              displayName: "Leave Adjustments for ",
              name: "Adjusments",
              navigate: "adjusments",
            },
            {
              displayName: "Leave Take Ons for ",
              name: "Take Ons",
              navigate: "take-ons",
            },
          ]}
          onChange={onClickLeaveOption}
        />

        {emp?.id !== emp?.eos?.employee ? (
          <NavLink
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
            className="employee-sublink"
            to="end-service"
            onClick={() => setDisplayFor("")}
          >
            End Service
          </NavLink>
        ) : (
          <NavLink
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
            className="employee-sublink"
            to="manage-end-service"
            onClick={() => setDisplayFor("")}
          >
            Manage End Service
          </NavLink>
        )}

{/* {emp?.id !== emp?.eos?.employee ? ( */}
          <NavLink
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
            className="employee-sublink"
            to="probation"
            onClick={() => setDisplayFor("")}
          >
            Probation
          </NavLink>

        {/* ) : ( */}
          {/* <NavLink
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
            className="employee-sublink"
            to="manage-end-service"
            onClick={() => setDisplayFor("")}
          >
            
          </NavLink> */}
        {/* )} */}
      </div>
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default PayrollManage;
export { default as EditBasicSalary } from "./EditBasicSalary";
export { default as EditEmployeeInfo } from "./EditEmployeeInfo";
export { default as EditRegularHour } from "./EditRegularHour";
export { default as EmployeePayroll } from "./EmployeePayroll";
export { default as EndService } from "./EndService";
export { default as Notes } from "./Notes";
export { default as PayslipCalculation } from "./PayslipCalculation";
export { default as RegularHours } from "./RegularHours";
export { default as RegularInputItems } from "./RegularInputItems";

const Heading = styled.h2`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`;
