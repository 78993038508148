import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../_app";
import { openRoute, routeMap, routeMapWrite } from "../utils/routes";
import { Notify } from "../utils";

const ProtectedRoute = (props: any) => {
  const { children } = props;
  let { pathname } = useLocation();

  if (pathname.includes("RegularInputItemsAddField")) {
    pathname = "/dashboard/RegularInputItemsAddField";
  } else if (pathname.includes("EmployeePayslipAddField")) {
    pathname = "/dashboard/EmployeePayslipAddField";
  }
  const user = useAppSelector((state) => state.user);

  const routeValue = routeMap[pathname];
  const writeRouteValue = routeMapWrite[pathname];
  const isOpenRoute = openRoute.includes(pathname);

  let isAccessible = user?.permission !== "none" || pathname === "/dashboard/user-profile";

  console.log("isAccessible out beforeAllChecks", isAccessible, user?.permission, pathname );
  if (isAccessible) {
    console.log("isAccessible inside", isAccessible, user?.permission );
    if (user?.permission === "read") {
      isAccessible =
        isOpenRoute ||
        user?.menu?.includes(routeValue) ||
        pathname === "/dashboard/user-profile" ||
        pathname === "/";
    } else if (user?.permission === "full") {
      console.log("isAccessible inside FULL", isAccessible, user?.permission );
      console.log("BEFORE CHECK ROUTE", routeValue, pathname);
      console.log("BEFORE CHECK", user?.menu?.includes(routeValue), user?.menu?.includes(writeRouteValue), isOpenRoute);
      isAccessible =
        isOpenRoute ||
        user?.menu?.includes(routeValue) ||
        user?.menu?.includes(writeRouteValue) ||
        pathname === "/dashboard/user-profile" ||
        pathname === "/";

      console.log("isAccessible inside AFTER", isAccessible, user?.permission );
    }
  }

  if (!isAccessible && user?.email !== "") {
    console.log("calling toast",pathname)
    console.log("isAccessible toast", isAccessible, user?.permission, user?.email );
    Notify(
      "You do not have access to perform this operation. Contact your manager for access. You are redirected to User profile page for now",
      0
    );
  }

  console.log("isAccessible before Navigatiing to 403", {
    isAccessible: isAccessible,
    pathname: pathname,
    permission: user?.permission,
    notNone: user?.permission !== "none",
    routeValue: routeValue,
    writeRouteValue: writeRouteValue,
    isOpenRoute: isOpenRoute,
  } );



  return isAccessible ? children : <Navigate to="/dashboard/access-denied" />;
};

export default ProtectedRoute;
