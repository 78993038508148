const Constant = {
  common: {
    back_to_home_page: "Back to Home Page",
    continue: "Continue",
    all: "All",
    inactive: "Inactive",
    active: "Active",
    name: "Name",
    address: "Address",
    action: "Action",
    archive: "Archive",
    cancel: "Cancel",
    save: "Save",
    delete: "Delete",
    view: "View",
    something_went_wrong: "Something went wrong. Please try again",
  },
  login: {
    heading: "SalaryPay Login",
    subHeading: "Log in here to access Salary payroll CPF System.",
    email: "Email Address",
    password: "Password",
    login: "Log in to SalaryPay",
    forgot_password: "Forgot password?",
    dont_remember_password: "Don't remember password?",
  },
  createPassword: {
    token_is_not_valid_please_resend_the_email:
      "Token is not valid please resend the email",
    create_new_password: "Create New Password",
    enter_your_password: "Enter your password",
    back_to_reset_password: "Back to Reset Password",
    get_access_now: "Get access now",
    enter_new_password_again_to_confirm: "Enter new password again to confirm",
    conform_your_new_password: "Confirm your new password",
    enter_new_password: "Enter new password",
    new_password: "New password",
    update_new_password: "Update New Password",
  },
  resetPassword: {
    reset_password: "Reset Password",
    enter_the_mail_to_reset_your_password:
      "Enter the e-mail address you use to log in to SalaryPay to reset your password:",
    enter_your_email_address: "Enter your e-mail address",
  },
  company: {
    companies: "Manage Companies",
    sub_title:
      "This page allows you to manage the companies on your profile. From here you can add, switch to or even delete companies",
    add_company: "Add Company",
    current_status: "Current Status",
    default: "Default",
    make_default: "Make Default",
  },
  settings: {
    payrollCalculations: {
      workingDaysLabel:
        "Use working days for all payslip pro-ration calculations",
      enablePayLabel: "Enable leave pay on payslip for predefined leave types",
      hourlyPrompt:
        "You will be prompted on every payslip for the Normal hours",
      hourlyRateLabel: "Hourly Rate",
    },
    cpfPayrollSettings: {
      yearOne: "Year One",
      subsequentYears: "Subsequent Years",
      effectiveFrom: "Effective From",
    },
    giroSettings: {
      eftFormat: "EFT Format",
      eftFormatError: "Please Select an EFT Format",
      bank: "Bank",
      bankError: "Please Select a Bank",
      accountNumber: "Account Number",
      accountError: "Please Add a valid account number",
      serviceError:"",
      bankCodeError : "Please Add a valid bank code",
      branchCode: "Branch Code",
      branchError: "Please Add a valid branch code",
      noCompanyError: "Please add a company before filling out details",
      servicetype : "Service Type",
      bankcode: "Bank Code",
    },
    leaveType: {
      no_leave:
        "No Leave Type is added in this company. Add a Leave type instead",
      name: "Name",
      cycle_length: "Cycle Length (in months)",
      cycle_start_date: "Cycle start date",
      custom_date: "Custom Date",
      unpaid_leave: "Unpaid Leave",
      show_payslips: "Show on Payslips",
      min_bal: "Set minimum balance rule",
      hide_balances: "Hide balances in Self-Service?",
      empty_name_error: "Please add Leave Name",
      cycle_length_error: "Cycle should be of minimum 1 month",
    },
    entitlementPolicy: {
      leave_type: "Leave Type",
      custom_name: "Use custom name?",
      hours_worked: "Use hours worked for accrual (casual staff)",
      default_entitlement: "Default entitlement (in days)",
      entitlement_after: "Entitlement only available after (in months)",
      upfront_accrual: "Use upfront accrual?",
      leave_carried: "Allow leave to be carried forward to next cycle?",
      custom_name_detail: "Add Custom Name",
      carried_fwd_leave_expires:
        "Carried forward leave expires after (in months)",
      pro_rated: "Use Pro rated leave?",
      leave_for_hours: "One hour of leave for every time worked (in hours)",
      paid_leaves_contributes:
        "Paid leave contributes to hours worked for accrual",
      limit_type: "Limit Type",
      percentage_balance: "Balance (in %)",
      percentage_entitlement: "Entitlement (in %)",
      number_days: "Number of days",
    },
  },
  filing: {
    iras: {
      tax_borne: "Tax borne by employer",
      exempt_income_indicator: "Exempt or remission income indicator",
      exempt_income: "Exempt income",
      pension: "Pension",
      retirement_benefits: "Retirement benefits upto",
      retirement_benefits_from: "Retirement benefits from 1993",
      fund_name: "Retirement benefits fund name",
      benefits_kind: "Benefits in kind value",
      bonus_declaration: "Bonus declaration date",
      director_approval: "Director's fees approval date",
      additional_fee: "Additional director's fees (not via payroll)",
      exclude: "Exclude from submission",
    },
  },
  reports: {
    select_month: "Select Month",
    select_year: "Select Year",
    download_bulk: "Download Reports",
    select_all: "Select All",
    select_all_this_page: "Select All on this page",
  },
  templates: {
    hr: {
      add: {
        title: "Add HR Template",
        title_input: "Email Title",
        template_code: "Code",
        email_subject: "Email Subject",
        email_body: "Email Body",
        editTitle: "Edit HR Template",
      },
    },
  },

  invoice : {
    send: "Invoice Updated Sccessfully",

  }
};
export default Constant;
