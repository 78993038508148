import styled from "styled-components";

const Container = styled.div<any>`
  width: ${(props) => props.width || "100%"};
  display: flex;
  margin-top: ${(props) => props.margin || ".8rem"};
  align-items: ${(props) => (props.isColumn ? "flex-start" : "center")};
  flex-direction: ${(props) => (props.isColumn ? "column" : "row")};
`;
const Label = styled.label<any>`
  width: ${(props) => (props.isColumn ? "80%" : "50%")};
  max-width: ${(props) => (props.isColumn ? "400px" : "250px")};
  margin-right: 0.25rem;
`;
const Error = styled.p<any>`
  font-size: 14px;
  line-height: 14px;
  color: ${(props) => props.color || "red"};
  height: 14px;
`;
export { Container, Error, Label };
