import { useEffect, useState } from "react";
import { RoleService } from "../../../_service";
import { Notify, Utils } from "../../../utils";
import { LoaderContainer } from "../../../CommonStyles";
import { CustomButton, Loader } from "../../../components";
import { Heading } from "../../appSettings/AppSettingsStyles";
import { Constant } from "../../../config";
import { IRASTable } from "../../filing/CPFFilingStyles";
import { TableHeader } from "../../../components/table";
// import { TableRow } from "../../payslip/PayslipStyles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Card, Container, styled as styledMUI, CardHeader, IconButton, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { clone } from "lodash";
import { CardContainer } from "../../requestdashboard/ViewRequest/ViewRequestStyles";
import { useNavigate } from "react-router-dom";

const EditGroups = () => {
  const [allSections, setAllSections] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [groups, setGroups] = useState<any>([]);
  const [tableHeader, setTableHeader] = useState<any>([]);
  const [openMenus, setOpenMenus] = useState<{ [key: string]: boolean }>({22:true});

  const navigate = useNavigate();

  const getAllSections = async () => {
    setIsLoading(() => true);
    try {
      const res = await RoleService._getAllSections();
      if (res.status === 200) {
        const sectionData = res.data.data.map((section: any) => ({
          id: section.id,
          name: section.name,
          group: section.group.map((table: any) => table),
          tabs: section.tabs,
          children: section.children.map((item: any) => ({
            id: item.id,
            name: item.name,
            group: item.group.map((table: any) => table),
            tabs: item.tabs,
          })),
        }));

        setAllSections(() => sectionData);
        setIsLoading(() => false);
      }
    } catch (e: any) {
      Notify(Constant.common.something_went_wrong);
      setIsLoading(() => false);
    }
  };

  const groupOrder = [
    "CSO",
    "Data Entry",
    "Finalise",
    "Finalizer",
    "Approver",
    "Client",
    "Leave",
    "Leave Approval",
    "Claims",
    "HR",
    "TMS",
    "TellBoss",
    "Employees",
  ];

  console.log("openMenus", openMenus);

  const getUserGroups = async () => {
    try {
      const { data, status } = await RoleService._getRoles(0);

      if (status === 200) {
        const len = data.length;
        const percent = 85 / len;

        let tempArr = data.map((dataObj: any) => {
          return {
            id: dataObj.id,
            name: dataObj.name,
            width: percent,
          };
        });

        let newOrder = new Set([...groupOrder, ...tempArr?.map((y: any) => y.name)]);

        console.log('newOrder', [...newOrder], Array.isArray([...newOrder]));

        let orderedGroup = [...newOrder]
          ?.map((x: any) => tempArr?.filter((y: any) => y.name === x))
          .flat();
        console.log("flatArr", orderedGroup);

        setGroups(orderedGroup);
        // const len = data.length;
        // const percent = 85 / len;
        // const updatedTableHeader = data
        //   .sort((a: any, b: any) => a.id - b.id)
        //   .map((dataObj: any) => ({
        //     name: dataObj.name,
        //     width: `${percent}%`,
        //   }));
        // setTableHeader((prevTable: any) => [
        //   ...prevTable,
        //   ...updatedTableHeader,
        // ]);
        setTableHeader([{ id: "0", name: "" }, ...orderedGroup]);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  const toggleGroup = async (groupId: any, sectionId: any) => {
    console.log("inside", groupId, sectionId);
    const index = allSections.findIndex(
      (section: any) => section.id === sectionId
    );

    const sectionData = clone(allSections);
    // const sectionData = allSections;
    const groupIndex = sectionData[index].group.indexOf(groupId);
    let tableData = sectionData[index].group;

    if (groupIndex === -1) {
      tableData = [...tableData, groupId];
    } else {
      tableData.splice(groupIndex, 1);
    }
    sectionData[index].group = tableData;

    setAllSections(() => sectionData);
  };

  const toggleChildGroup = async (sectionId: any, childId: any, groupId: any) => {
    console.log("inside",  sectionId, childId, groupId,);
    const index = allSections.findIndex(
      (section: any) => section.id === sectionId
    );

    const sectionData = clone(allSections);
    const childIndex = sectionData[index].children.findIndex(
      (child: any) => child.id === childId
    );

    const groupIndex = sectionData[index].children[childIndex]?.group.indexOf(
      groupId
    );
    let tableData = sectionData[index].children[childIndex]?.group;

    if (groupIndex === -1) {
      tableData = [...tableData, groupId];
    } else {
      tableData?.splice(groupIndex, 1);
    }
    sectionData[index].children[childIndex].group = tableData;

    setAllSections(() => sectionData);
  }

  useEffect(() => {
    setIsLoading(() => true);
    getUserGroups();
    getAllSections();
  }, []);

  const submitRoleAccess = async (e: any) => {
    e.preventDefault();
    // const dataToSend = allSections.map((section: any) => ({
    //   id: section.id,
    //   group: section.group,
    // }));

    let payload: any = [];

    allSections.forEach((section:any) => {
      if(section?.children?.length > 0) {
        section.children.forEach((child:any) => {
          payload.push({
            id: child.id,
            group: child.group
          });
        });
      } else {
        payload.push({
          id: section.id,
          group: section.group
        });
      }
      
    });


    console.log("payload", payload);

    try {
      const res = await RoleService._editGroupPermissions(payload);
      if (res.status >= 200 && res.status < 300) {
        Notify("Permissions Updated Successfully", 1);
        Utils.autoScrollToTop();
        setIsLoading(() => true);
        getAllSections();
        setIsLoading(() => false);
      }
    } catch (error:any) {
      Utils.showErrorMessage(error);
    }
  };

  const StyledTableCell = styledMUI(TableCell)(({ theme }) => ({
    backgroundColor: "#61677A",
    color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: "semibold",

    paddingTop: "0.75rem",
    paddingBottom: "0.75rem",
  }));

  const StyledTableCellTwo = styledMUI(TableCell)(({ theme }) => ({
    backgroundColor: "#2E5077",
    color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: "semibold",
    paddingTop: "0.75rem",
    paddingBottom: "0.75rem",
  }));

  console.log("allSections", allSections);
  console.log("tableHeader", tableHeader);

  const [openMenu, setOpenMenu] = useState<{ [key: string]: boolean }>({});

  return (
    <div>
      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          <Heading>Edit Group Access</Heading>
          <div>
            <>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {tableHeader.map((header: any, index: number) => (
                        <StyledTableCell key={index} align="center">
                          {header.name}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allSections.map((section: any) => (
                      <>
                        {section?.children?.length > 0 ? (
                          // Parent table
                          <TableRow key={section.id}>
                            <TableCell
                              colSpan={tableHeader.length}
                              sx={{
                                padding: 0,
                              }}
                            >
                              <Card
                                sx={{
                                  boxShadow: openMenus[section.id]
                                  ? "0 4px 8px rgba(0,0,0,0.2)"
                                  : "none",
                                  border: openMenus[section.id]
                                    ? "2px solid #2E5077"
                                    : "none",
                                  margin: openMenus[section.id]
                                    ? "1rem 0"
                                    : 0,
                                    transition: "box-shadow 0.3s ease-in-out",
                                }}
                              >
                                <CardHeader
                                  sx={{ padding: "0.75rem" }}
                                  title={
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontSize: openMenus[section.id] ? "1.25rem": "0.95rem",
                                          fontWeight: openMenus[section.id]
                                            ? 600
                                            : 600,
                                        }}
                                      >
                                        {section.name}
                                      </p>
                                      <IconButton
                                        onClick={() =>
                                          setOpenMenus((prev) => ({
                                            ...prev,
                                            [section.id]: !prev[section.id],
                                          }))
                                        }
                                      >
                                        {openMenus[section.id] ? (
                                          <ExpandMoreIcon sx={{ fontSize: 28 }} />
                                        ) : (
                                          <ChevronRightIcon sx={{ fontSize: 28 }} />
                                        )}
                                      </IconButton>
                                    </div>
                                  }
                                />
                                <Collapse in={openMenus[section.id]}>
                                  {
                                    <Table>
                                      <TableBody>
                                        {/* Children table */}

                                        <Table>
                                          <TableHead>
                                            <TableRow>
                                              {tableHeader.map(
                                                (
                                                  header: any,
                                                  index: number
                                                ) => (
                                                  <StyledTableCellTwo
                                                    key={index}
                                                    align="center"
                                                  >
                                                    {header.name}
                                                  </StyledTableCellTwo>
                                                )
                                              )}
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {section.children.map(
                                              (child: any) => (
                                                <>
                                                  <TableRow key={child.id}>
                                                    <TableCell
                                                      sx={{
                                                      padding: "0.5rem 0.75rem",
                                                      backgroundColor: "#f5f5f5",
                                                      }}
                                                    >
                                                      <Typography
                                                        onClick={() => child.tabs?.length > 0 ? navigate(`/dashboard/manage-users/edit-groups/tabs`, 
                                                          { state: { data: {
                                                              menuName: child.name,
                                                              menuId: child.id,
                                                              tabs: child.tabs,
                                                              userGroups: tableHeader
                                                          } }}): null}
                                                        sx={{
                                                          color: child?.tabs?.length > 0 ? '#0000EE' : "#000",
                                                          textDecoration: 'none',
                                                          cursor: 'pointer',
                                                          fontWeight: 600,
                                                          width: 'fit-content',
                                                          fontSize: '0.875rem',
                                                          padding: '0.25rem',
                                                          borderBottom: child?.tabs?.length > 0 ?  '1px solid #0000EE' : 'none',
                                                          pointerEvents: child?.tabs?.length > 0 ? 'auto' : 'none',
                                                          '&:hover': {
                                                            borderBottom: '1px solid #06d6a0',
                                                            color: '#06d6a0',
                                                          }
                                                        }}>
                                                        {child.name}
                                                      </Typography>
                                                    </TableCell>
                                                    {groups.map(
                                                      (group: any) => (
                                                        <TableCell
                                                          sx={{
                                                            padding: "0.5rem",
                                                            backgroundColor:
                                                              "#f5f5f5",
                                                          }}
                                                          key={group.id}
                                                          align="center"
                                                        >
                                                          <Checkbox
                                                            checked={child?.group?.includes(
                                                                  group.id
                                                            )}
                                                            onChange={() =>
                                                              toggleChildGroup(section.id, child.id, group.id, )
                                                            }
                                                            size="medium"
                                                          />
                                                        </TableCell>
                                                      )
                                                    )}
                                                  </TableRow>
                                                </>
                                              )
                                            )}
                                          </TableBody>
                                        </Table>
                                        
                                      </TableBody>
                                    </Table>
                                  }
                                </Collapse>
                              </Card>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow key={section.id}>
                            <TableCell sx={{ padding: "0.5rem 0.75rem",  }}>
                            <Typography
                                 onClick={() => section.tabs?.length > 0 ? navigate(`/dashboard/manage-users/edit-groups/tabs`, { state: { data: {
                                  menuName: section.name,
                                  menuId: section.id,
                                  tabs: section.tabs,
                                  userGroups: tableHeader,
                                } }}): null}
                                sx={{
                                  color: section.tabs?.length > 0 ? '#0000EE' : "#000",
                                  textDecoration: 'none',
                                  cursor: 'pointer',
                                  fontWeight: 600,
                                  width: 'fit-content',
                                  fontSize: '0.875rem',
                                  padding: '0.25rem',
                                  borderBottom: section.tabs?.length > 0 ? '1px solid #0000EE' : "none",
                                  pointerEvents: section.tabs?.length > 0 ? 'auto' : 'none',
                                  '&:hover': {
                                    borderBottom: '1px solid #06d6a0',
                                    color: '#06d6a0',
                                  }
                                }}>
                                {section.name}
                              </Typography>
                            </TableCell>
                            {groups.map((group: any) => (
                              <TableCell
                                sx={{ padding: "0.5rem" }}
                                key={group.id}
                                align="center"
                              >
                                {
                                  <Checkbox
                                    checked={section?.group?.includes(
                                      group.id
                                    )}
                                    onChange={() =>
                                      toggleGroup(group.id, section.id)
                                    }
                                    size="medium"
                                  />
                                }
                              </TableCell>
                            ))}
                          </TableRow>
                        )}
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div style={{ marginTop: 40 }}>
                <CustomButton onClick={submitRoleAccess} title="Save" />
              </div>
            </>
          </div>
        </>
      )}
    </div>
  );
};

export default EditGroups;


// const hyperlinks = styled.p``