import React, { useEffect, useState } from 'react'
import { Loader, NavigateBack } from '../../components'
import { Heading } from '../appSettings/AppSettingsStyles'
import { Notify, Utils } from '../../utils'
import Button from '../../components/customButton/Button'
import { useNavigate } from 'react-router-dom'
import { IRASTable } from '../filing/CPFFilingStyles'
import { TableHeader } from '../../components/table'
import { TableRow } from '../payslip/PayslipStyles'
import moment from 'moment'
import { ServiceCodeService } from '../../_service'
import { Constant } from '../../config'
import { LoaderContainer } from '../../CommonStyles'

const RepeatedInvoice = () => {
    const navigate = useNavigate();
    const [repeatedInvoiceData, setRepeatedInvoiceData] = useState([])
    const [isLoading, setIsLoading] = useState<boolean>(false);

    console.log("repeatedInvoiceData", repeatedInvoiceData);

    const getRepeatInvoiceList = async () => {
        setIsLoading(() => true);
        try {
            const { data, status } = await ServiceCodeService._getRepeatInvoiceList("");
            if (status === 200) {
                setRepeatedInvoiceData(data?.data);
            }
        } catch (error) {
            Notify(Constant.common.something_went_wrong, 0);
        }
        setIsLoading(() => false);


    }

    useEffect(() => {
        getRepeatInvoiceList()
    }, [])

    // Table headers
    const repeateInvoiceHeaders = [
        { name: "Name", width: "20%" },
        { name: "SPC", width: "10%" },
        { name: "Reference", width: "15%" },
        { name: "Amount", width: "16%" , textAlign : "center" },
        { name: "Repeat", width: "15%" },
        { name: "Next Invoice Date", width: "15%" },
        { name: "End Date", },
    ];

    return (
        <div>
      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) :
        (<div>
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "0.5rem",
            }}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.5rem",
                    }}
                >
                    <NavigateBack />
                    <Heading>{("Repeated Invoice")}</Heading>
                </div>
                <div>
                    <Button onClick={() => navigate("/dashboard/manage_invoicing/repeatedinvoice/addrepeatedinvoice")} title='New Repeating Invoice' />
                </div>
            </div>

                    <IRASTable style={{ width: "100%" }}>
                        <TableHeader
                            headerColumns={repeateInvoiceHeaders}
                            variant="filled"
                            styles={{ padding: "5px", textAlign: "left" }}
                        />
                        <tbody>
                            {repeatedInvoiceData?.map((item: any) => (
                                <TableRow key={item.id} style={{ cursor: 'pointer' }} onClick={() => navigate('addrepeatedinvoice', { state: { ...item, isEdit: true } })} >
                                    {/* Customer Name */}
                                    <td
                                        style={{
                                            padding: "0.5rem",
                                            color: "#0000FF",
                                            cursor: "pointer",
                                            textDecoration: "none",
                                        }}
                                        onMouseEnter={(e:any) => (e.target.style.textDecoration = "underline")}
                                        onMouseLeave={(e:any) => (e.target.style.textDecoration = "none")}
                                    >
                                        {item?.company_data?.company_details?.name || ""}
                                    </td>


                             {/* SPC */}
                             <td style={{ padding: '0.5rem' }}>
                                <span className="spc-badge">
                                    {item?.company_data?.spc_details?.spc_code}
                                </span>
                            </td>

                            {/* Reference */}
                            <td style={{ padding: '0.5rem' }}>
                                {item.reference || "-"}
                            </td>

                            {/* Grand Amount */}
                            <td style={{ padding: '0.5rem' , textAlign : "right" , paddingRight : "10%" }}>
                                {item?.company_data?.billing_details?.currency_details?.symbol}{Utils.getCommanSepartedNumericString(item?.json_data?.sub_total) || ""}
                            </td>   
                            
                            {/* Repeat */}
                            <td style={{ padding: '0.5rem', }}>
                                {item.recurrence_text || "-"}
                            </td>

                            {/* Next Invoice Date */}
                            <td style={{ padding: '0.5rem' }}>
                                {item.next_invoice_date || "-"}
                            </td>

                                                    

                            {/* End Date */}
                            <td style={{ padding: '0.5rem' }}>
                                {item?.end_date || "-"}
                            </td>
                        </TableRow>
                    ))}
                </tbody>
            </IRASTable>



        </div>)
}   
 </div> 

    )
}

export default RepeatedInvoice