import { useLocation, useNavigate } from "react-router-dom";
import "./manageEmployee.css";
import { useEffect, useState } from "react";
import { EmployeeService } from "../../../_service";
import { EmployeeListProps } from "../../../_types";
import EmployeeHeader from "./employeeHeader";
import { useAppDispatch, useAppSelector } from "../../../_app";
import {
  updateCurrentEmployee,
  resetEmployee,
  updateEmployeeIntialState,
} from "../../../_features/employee";
import { CompanyInfoModal, Loader } from "../../../components";
import {
  FilterByChar,
  FilterBySelect,
  SearchField,
  TableFilterHeader,
} from "../component";
import styled from "styled-components";
import { Notify, Utils } from "../../../utils";

const EmployeeList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector((state) => state.user);
  const [text, setText] = useState("");
  const [char, setChar] = useState("");
  const [status, setStatus] = useState("1");
  const [employeeList, setEmployeeList] = useState<EmployeeListProps[]>([]);
  const [filterEmployeeList, setfilterEmployeeList] = useState<
    EmployeeListProps[]
  >([]);
  const [sending, setSending] = useState(true);
  const [pageInfo, setPageInfo] = useState<{
    pageNumber: number;
    totalLength: number;
    pagePerData: number;
  }>({
    pageNumber: 1,
    totalLength: 10,
    pagePerData: 10,
  });
  const [isFiltered, setIsFiltered] = useState(false);

  const changeStatus = (value: any) => {
    setIsFiltered(true);
    setStatus(value);
  };
  const getEmployeeList = async (type: string) => {
    const defaultCompanyId = user.defaultCompnayId;
    if (type !== "0") {
      setIsFiltered(() => true);
    } else {
      setIsFiltered(() => false);
    }
    if (defaultCompanyId) {
      try {
        setSending(() => true);
        const res = await EmployeeService._getEmployeeListByCompany(
          defaultCompanyId,
          type
        );
        if (res.status === 200) {
          setEmployeeList(() => res.data.data);
          setfilterEmployeeList(() => res.data.data);
          setPageInfo((prev) => ({
            ...prev,
            totalLength: res?.data?.data?.length,
          }));
        }
        setChar("All");
        setSending(() => false);
      } catch (e: any) {
        console.log(e);
        setSending(() => false);
        setEmployeeList(() => []);
        setfilterEmployeeList(() => []);
        setPageInfo((prev) => ({
          ...prev,
          totalLength: 0,
        }));
        if (e.response?.data?.payslip_date?.length > 0) {
          Notify(e.response?.data?.payslip_date[0], 0);
          // disaptch(resetUser());
        }
      }
    }
  };

  const getEmployeeListByChar = async (val: string, status: string) => {
    setIsFiltered(true);
    try {
      setSending(true);
      const defaultCompanyId = user.defaultCompnayId;
      const data = await EmployeeService._getEmployeeListByChar(
        defaultCompanyId,
        val,
        status
      );
      setEmployeeList(() => data.data.data);
      setfilterEmployeeList(() => data.data.data);
      setPageInfo((prev) => ({
        ...prev,
        totalLength: data?.data?.data?.length,
      }));

      setSending(() => false);
    } catch (e: any) {
      setSending(() => false);
      console.error(e);
    }
  };
  const handlePageValueChange = (
    type: "next" | "previous" | "first" | "last"
  ) => {
    if (type === "next") {
      const isLast =
        pageInfo.pageNumber * pageInfo.pagePerData < employeeList?.length;
      if (isLast) {
        setPageInfo((prev) => ({ ...prev, pageNumber: prev.pageNumber + 1 }));
      }
    }
    if (type === "previous") {
      if (pageInfo.pageNumber > 1) {
        setPageInfo((prev) => ({
          ...prev,
          pageNumber: prev.pageNumber - 1,
        }));
      }
    }
    if (type === "first") {
      setPageInfo((prev) => ({
        ...prev,
        pageNumber: 1,
      }));
    }
    if (type === "last") {
      setPageInfo((prev) => ({
        ...prev,
        pageNumber: Math.ceil(pageInfo.totalLength / pageInfo.pagePerData),
      }));
    }
  };
  const handleNavigate = (item: EmployeeListProps) => {
    console.log("Navigating to Atlantis", item)
    dispatch(resetEmployee());
    dispatch(
      updateCurrentEmployee({
        currentEmployee: {
          first_name: item?.first_name,
          last_name: item?.last_name,
          id: item?.id,
          name: item?.user?.name,
          showPayroll: false,
          eos: item?.eos || null,
        },
      })
    );
    dispatch(
      updateEmployeeIntialState({
        employee_info: {
          id: item?.id,
        },
      })
    );
    navigate(item?.employment_status.toLowerCase() === "pending regular hours*" ?"/dashboard/payroll/regular-hour" : "/dashboard/payroll/basic-info", {
      state: { reload: true },
    });
  };
  const handleDataUpdateByChar = async (props: string) => {
    setIsFiltered(true);
    setChar(props);
    if (props === "All") {
      getEmployeeList(status);
    } else {
      getEmployeeListByChar(props, status);
    }
  };

  useEffect(() => {
    getEmployeeList(status);
  }, [status]);

  const textColor = {
    new: "#155724",
    existing: "#059BBF",
    resigned: "#dc3545",
    "pending regular hours*": "#991c7e"
  } as any;

  useEffect(() => {
    if (text === "") {
      setfilterEmployeeList(employeeList);
    } else {
      setfilterEmployeeList(() => {
        return employeeList.filter((item) => {
          if (item?.user?.name?.toLowerCase().includes(text.toLowerCase())) {
            return true;
          }
          return false;
        });
      });
    }
  }, [text]);
  // page change
  useEffect(() => {
    const startIndex =
      pageInfo.pageNumber * pageInfo.pagePerData - pageInfo.pagePerData;
    const endIndex =
      pageInfo.pageNumber * pageInfo.pagePerData > employeeList?.length
        ? employeeList?.length
        : pageInfo.pageNumber * pageInfo.pagePerData;
    setfilterEmployeeList(() => employeeList.slice(startIndex, endIndex));
  }, [pageInfo.pageNumber, employeeList]);

  return (
    <div style={{ position: "relative" }}>
      {!sending &&
      employeeList &&
      employeeList?.length < 1 &&
      (char === "" || char === "All") &&
      !isFiltered ? (
        <CompanyInfoModal />
      ) : null}
      <div className="hero_header">
        <h2 className="employee-header-h2">{Utils.getTitleWithCompanyName("Employees")}</h2>
      </div>
      <EmployeeHeader />
      <div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "1rem",
            justifyContent: "space-between",
          }}
        >
          <FilterBySelect value={status} onChageOption={changeStatus} />
          <SearchField value={text} onChangeText={setText} />

          {/* {pathname === "/dashboard/employee-payroll" && showApprovedButton && (
            <CustomButton
              title="Send for approval"
              type="secondary"
              size="small"
              styles={{ marginLeft: 40, width: "15%", marginBottom: 15 }}
              onClick={() => setIsApprovalModalOpen(() => true)}
            />
          )} */}
          {/* {showPayrunMessage && (
            <p
              style={{
                fontSize: 16,
                color: "#059BBF",
                marginTop: 5,
                marginLeft: 20,
              }}
            >
              Ready for payrun
            </p>
          )} */}
          {/* {showCompletePayrunMessage && (
            <p
              style={{
                fontSize: 16,
                color: "#059BBF",
                marginTop: 5,
                marginLeft: 20,
              }}
            >
              Payrun Generated
            </p>
          )} */}
        </div>
        <FilterByChar value={char} onChange={handleDataUpdateByChar} />
        <div className="table">
          {sending && <Loader />}
          {filterEmployeeList?.length > 0 && (
            <TableFilterHeader
              name="Employee"
              pageInfo={pageInfo}
              setPageInfo={setPageInfo}
              handlePageValueChange={(val) => handlePageValueChange(val)}
            />
          )}
          <table className="company-table">
            <thead className="company-table-head">
              <tr>
                <th>Employee Name</th>
                <th>Employee Code</th>
                <th>Job Title</th>
                <th>Date of appt</th>
                <th>Resigned Date</th>
                <th>Employee Status</th>
              </tr>
            </thead>

            <tbody>
              {filterEmployeeList?.sort((a,b) => a.user.name.localeCompare(b.user.name)).map((item) => {
                return (
                  <tr
                    key={item.id}
                    onClick={() => handleNavigate(item)}
                    style={{ cursor: "pointer" }}
                  >
                    <td>
                      <Span className="td-link">{item?.user?.name || ""}</Span>
                    </td>
                    <td>
                      <Span className="td-link">{item?.emp_number || ""}</Span>
                    </td>
                    <td>
                      <Span>{item?.job_title || ""}</Span>
                    </td>
                    <td>
                      <Span>
                        {Utils._dateToShow(item?.date_of_appointment) || ""}
                      </Span>
                    </td>
                    <td>
                      <Span>
                        {item?.eos !== null
                          ? Utils._dateToShow(item?.eos?.last_date_of_service)
                          : ""}
                      </Span>
                    </td>
                    <td>
                      <Span
                        style={{
                          color:
                            textColor[item?.employment_status?.toLowerCase()],
                        }}
                      >
                        {item?.employment_status || ""}
                      </Span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div style={{ textAlign: "center", padding: ".5rem" }}>
            {filterEmployeeList?.length < 1 && <p>No records found</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeList;

const Span = styled.span`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
`;
