import React, { useState } from "react";

const FilterByCharacterBar = (props: any) => {
  const upperCaseAlphabets = [
    "All",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  // const [activeCharacter, setActiveCharacter] = useState<any>("");

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "1rem",
      }}
    >
      {props.showAccessControl && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            gap: "1rem",
            marginRight: '2rem'
          }}
        >
          {/* <p>Access: </p> */}
          <p
            onClick={() => props.handleAccess("full")}
            style={{
              fontSize: "1rem",
              fontWeight: 600,
              // background:
              //   props.selection === "full" ? "rgb(5, 155, 191)" : "#f9f9f9",
              // color: props.selection === "full" ? "#fff" : "#000",
              cursor: "pointer",
              border: "1px solid rgb(206, 212, 218)",
              borderRadius: "4px",
              padding: "0.25rem 0.5rem",
            }}
            onMouseEnter={(e) => {
              (e.target as HTMLElement).style.background = "rgb(5, 155, 191)";
              (e.target as HTMLElement).style.color = "#fff";
            }}
            onMouseLeave={(e) => {
              (e.target as HTMLElement).style.color = "#000";
              (e.target as HTMLElement).style.background =  "#fff";
            }}
          >
            {"Full"}
          </p>
          <p
            onClick={() => props.handleAccess("read")}
            style={{
              fontSize: "1rem",
              fontWeight: 600,
              // background:
              //   props.selection === "read" ? "rgb(5, 155, 191)" : "#f9f9f9",
              // color: props.selection === "read" ? "#fff" : "#000",
              cursor: "pointer",
              border: "1px solid rgb(206, 212, 218)",
              borderRadius: "4px",
              padding: "0.25rem 0.5rem",
            }}
            onMouseEnter={(e) => {
              (e.target as HTMLElement).style.background = "rgb(5, 155, 191)";
              (e.target as HTMLElement).style.color = "#fff";
            }}
            onMouseLeave={(e) => {
              (e.target as HTMLElement).style.color = "#000";
              (e.target as HTMLElement).style.background =  "#fff";
            }}
          >
            {"Read Only"}
          </p>
          <p
            onClick={() => props.handleAccess("none")}
            style={{
              fontSize: "1rem",
              fontWeight: 600,
              // background:
              //   props.selection === "none" ? "rgb(5, 155, 191)" : "#f9f9f9",
              // color: props.selection === "none" ? "#fff" : "#000",
              cursor: "pointer",
              border: "1px solid rgb(206, 212, 218)",
              borderRadius: "4px",
              padding: "0.25rem 0.5rem",
            }}
            onMouseEnter={(e) => {
              (e.target as HTMLElement).style.background = "rgb(5, 155, 191)";
              (e.target as HTMLElement).style.color = "#fff";
            }}
            onMouseLeave={(e) => {
              (e.target as HTMLElement).style.color = "#000";
              (e.target as HTMLElement).style.background =  "#fff";
            }}
          >
            {"None"}
          </p>
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        {upperCaseAlphabets?.map((item: any) => (
          <div
            key={item}
            style={{
              display: "inline-block",
              padding: "1px 3px",
              margin: "0.1rem 0.1rem",
              fontSize: "0.875rem",
              textAlign: "center",
              whiteSpace: "nowrap",
              verticalAlign: " middle",
              cursor: "pointer",
              userSelect: "none",
              border: "1px solid transparent",
              borderRadius: "4px",
              flexWrap: "wrap",
              background:
                props.activeCharacter === item ? "rgb(5, 155, 191)" : "#f9f9f9",
              color: props.activeCharacter === item ? "#fff" : "#000",
              width: "1.75rem",
              height: "1.75rem",
              fontWeight: 400,
            }}
            onClick={() => props.handleUpdate(item)}
          >
            <p
              style={{
                fontSize: "1rem",
                fontWeight: 600,
              }}
            >
              {item}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilterByCharacterBar;
