import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { PayrollService } from "../../../_service";
import { Notify, Utils } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../_app";
import {
  CustomNumberField,
  CustomSelectField,
  CustomeCkeckboxField,
} from "../../../components/customFormField";
import { CustomButton } from "../../../components/customButton";
import { RegularHoursFields } from "../component";
import Require from "../../../components/customFormField/Require";

const EditRegularHour = () => {
  const navigate = useNavigate();
  const id = useAppSelector((state) => state.employee.employee_info.id);
  const companyId = useAppSelector((state) => state.user.defaultCompnayId);

  const [editPrice, setEditPrice] = useState(false);
  const [price, setPrice] = useState(0.0);
  const [getPrice, setGetPrice] = useState(false);
  const [schedule, setSchedule] = useState<string | number>("1");
  const [hourlyPaid, setHourlyPaid] = useState(false);
  const [loading, setLoading] = useState(false);

  const [mondayChecked, setMondayChecked] = useState(true);
  const [tuesdayChecked, setTuesdayChecked] = useState(true);
  const [wednesdayChecked, setWednesdayChecked] = useState(true);
  const [thursdayChecked, setThursdayChecked] = useState(true);
  const [fridayChecked, setFridayChecked] = useState(true);
  const [saturdayChecked, setSaturdayChecked] = useState(false);
  const [sundayChecked, setSundayChecked] = useState(false);

  const [perDayHours, setPerDayHours] = useState("8");

  const [sundayPartialDay, setSundayPartialDay] = useState(false);
  const [sundayPartialHours, setSundayPartialHours] = useState("");

  const [mondayPartialDay, setMondayPartialDay] = useState(false);
  const [mondayPartialHours, setMondayPartialHours] = useState("");

  const [tuesdayPartialDay, setTuesdayPartialDay] = useState(false);
  const [tuesdayPartialHours, setTuesdayPartialHours] = useState("");

  const [wednesdayPartialDay, setWednesdayPartialDay] = useState(false);
  const [wednesdayPartialHours, setWednesdayPartialHours] = useState("");

  const [thursdayPartialDay, setThursdayPartialDay] = useState(false);
  const [thursdayPartialHours, setThursdayPartialHours] = useState("");

  const [fridayPartialDay, setFridayPartialDay] = useState(false);
  const [fridayPartialHours, setFridayPartialHours] = useState("");

  const [saturdayPartialDay, setSaturdayPartialDay] = useState(false);
  const [saturdayPartialHours, setSaturdayPartialHours] = useState("");

  const handleNavigateBack = () => {
    navigate("/dashboard/payroll/emp");
  };

  const handleAddingHoursData = () => {
    let flag = true;
    const working_days_info = [
      {
        day: "mon",
        partial: mondayPartialDay ? true : false,
        hours: mondayPartialDay ? mondayPartialHours : null,
        day_off: !mondayChecked,
      },
      {
        day: "tue",
        partial: tuesdayPartialDay ? true : false,
        hours: tuesdayPartialDay ? tuesdayPartialHours : null,
        day_off: !tuesdayChecked,
      },
      {
        day: "wed",
        partial: wednesdayPartialDay ? true : false,
        hours: wednesdayPartialDay ? wednesdayPartialHours : null,
        day_off: !wednesdayChecked,
      },
      {
        day: "thu",
        partial: thursdayPartialDay ? true : false,
        hours: thursdayPartialDay ? thursdayPartialHours : null,
        day_off: !thursdayChecked,
      },
      {
        day: "fri",
        partial: fridayPartialDay ? true : false,
        hours: fridayPartialDay ? fridayPartialHours : null,
        day_off: !fridayChecked,
      },
      {
        day: "sat",
        partial: saturdayPartialDay ? true : false,
        hours: saturdayPartialDay ? saturdayPartialHours : null,
        day_off: !saturdayChecked,
      },
      {
        day: "sun",
        partial: sundayPartialDay ? true : false,
        hours: sundayPartialDay ? sundayPartialHours : null,
        day_off: !sundayChecked,
      },
    ];

    for (
      let workingDaysIndex = 0;
      workingDaysIndex < working_days_info.length;
      workingDaysIndex++
    ) {
      let hours = working_days_info[workingDaysIndex]?.hours;
      if (working_days_info[workingDaysIndex]?.partial && !hours) {
        Notify(`Please check partial hours`, 0);
        flag = false;
      }
    }
    if (Utils.isEmpty(perDayHours)) {
      Notify(`Hours per day cannot be empty`, 0);
      flag = false;
    }
    if (Utils.isEmpty(price)) {
      Notify(`Full days per week cannot be empty`, 0);
      flag = false;
    }
    return flag;
  };

  const handleUpdateHoursData = async () => {
    setLoading(true);
    if (handleAddingHoursData()) {
      try {
        const res = await PayrollService._updateEmployeeWorkingHour(
          id,
          companyId,
          {
            employee: id,
            hourly_paid_bool: hourlyPaid,
            hours_per_day: perDayHours,
            full_days_per_week: price,
            schedules_id: schedule,
            working_days_info: [
              {
                day: "mon",
                partial: mondayPartialDay ? true : false,
                hours: mondayPartialDay ? mondayPartialHours : null,
                day_off: !mondayChecked,
              },
              {
                day: "tue",
                partial: tuesdayPartialDay ? true : false,
                hours: tuesdayPartialDay ? tuesdayPartialHours : null,
                day_off: !tuesdayChecked,
              },
              {
                day: "wed",
                partial: wednesdayPartialDay ? true : false,
                hours: wednesdayPartialDay ? wednesdayPartialHours : null,
                day_off: !wednesdayChecked,
              },
              {
                day: "thu",
                partial: thursdayPartialDay ? true : false,
                hours: thursdayPartialDay ? thursdayPartialHours : null,
                day_off: !thursdayChecked,
              },
              {
                day: "fri",
                partial: fridayPartialDay ? true : false,
                hours: fridayPartialDay ? fridayPartialHours : null,
                day_off: !fridayChecked,
              },
              {
                day: "sat",
                partial: saturdayPartialDay ? true : false,
                hours: saturdayPartialDay ? saturdayPartialHours : null,
                day_off: !saturdayChecked,
              },
              {
                day: "sun",
                partial: sundayPartialDay ? true : false,
                hours: sundayPartialDay ? sundayPartialHours : null,
                day_off: !sundayChecked,
              },
            ],
          }
        );
        
        if (res.status === 200 || res.status === 201) {
          Notify("Regular hours Updated Successfully", 1);
          navigate("/dashboard/payroll/emp", {
            state: { reload: true },
          });
          setLoading(false);
        } else {
          Notify(`some error`, 0);
          setLoading(false);
        }
      } catch (e: any) {
        setLoading(false);
        if (e.response.status === 401) {
          // disaptch(resetUser());
        }
      }
    }
  };
  const updateOverritePrice = () => {
    const partialDay = 1 / parseFloat(perDayHours || "8");
    const monday =
      mondayChecked && !mondayPartialDay
        ? 1
        : mondayPartialHours
        ? parseFloat(mondayPartialHours) * partialDay
        : 0;
    const tuesday =
      tuesdayChecked && !tuesdayPartialDay
        ? 1
        : tuesdayPartialHours
        ? parseFloat(tuesdayPartialHours) * partialDay
        : 0;
    const wednesday =
      wednesdayChecked && !wednesdayPartialDay
        ? 1
        : wednesdayPartialHours
        ? parseFloat(wednesdayPartialHours) * partialDay
        : 0;
    const thursday =
      thursdayChecked && !thursdayPartialDay
        ? 1
        : thursdayPartialHours
        ? parseFloat(thursdayPartialHours) * partialDay
        : 0;
    const friday =
      fridayChecked && !fridayPartialDay
        ? 1
        : fridayPartialHours
        ? parseFloat(fridayPartialHours) * partialDay
        : 0;
    const saturday =
      saturdayChecked && !saturdayPartialDay
        ? 1
        : saturdayPartialHours
        ? parseFloat(saturdayPartialHours) * partialDay
        : 0;
    const sunday =
      sundayChecked && !sundayPartialDay
        ? 1
        : sundayPartialHours
        ? parseFloat(sundayPartialHours) * partialDay
        : 0;
    if (getPrice) {
      setGetPrice(false);
    } else {
      setPrice(
        () =>
          monday + tuesday + thursday + wednesday + friday + saturday + sunday
      );
    }
  };
  useEffect(() => {
    updateOverritePrice();
  }, [
    mondayChecked,
    tuesdayChecked,
    wednesdayChecked,
    thursdayChecked,
    fridayChecked,
    saturdayChecked,
    sundayChecked,
    mondayPartialDay,
    tuesdayPartialDay,
    wednesdayPartialDay,
    thursdayPartialDay,
    saturdayPartialDay,
    sundayPartialDay,
    mondayPartialHours,
    tuesdayPartialHours,
    wednesdayPartialHours,
    thursdayPartialHours,
    fridayPartialDay,
    fridayPartialHours,
    saturdayPartialHours,
    sundayPartialHours,
    perDayHours,
  ]);
  const get = async () => {
    try {
      const res = await PayrollService._getWorkingHours(id, companyId);
      if (res.status === 200) {
        const days = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
        const workingDayInfo = res.data.working_days_info;

        setPerDayHours(() => res.data.hours_per_day || 8);
        setHourlyPaid(res.data.hourly_paid_bool);
        setSchedule(res.data.schedules || "1");

        const sunData = workingDayInfo.filter(
          (item: any) => item.day === days[6]
        )[0];
        setSundayChecked(!sunData.day_off);
        setSundayPartialDay(sunData.partial);
        setSundayPartialHours(() => sunData.hours);

        const satData = workingDayInfo.filter(
          (item: any) => item.day === days[5]
        )[0];
        setSaturdayChecked(!satData.day_off);
        setSaturdayPartialDay(satData.partial);
        setSaturdayPartialHours(() => satData.hours);

        const friData = workingDayInfo.filter(
          (item: any) => item.day === days[4]
        )[0];
        setFridayChecked(!friData.day_off);
        setFridayPartialDay(friData.partial);
        setFridayPartialHours(() => friData.hours);

        const thuData = workingDayInfo.filter(
          (item: any) => item.day === days[3]
        )[0];
        setThursdayChecked(!thuData.day_off);
        setThursdayPartialDay(thuData.partial);
        setThursdayPartialHours(() => thuData.hours);

        const wedData = workingDayInfo.filter(
          (item: any) => item.day === days[2]
        )[0];
        setWednesdayChecked(!wedData.day_off);
        setWednesdayPartialDay(wedData.partial);
        setWednesdayPartialHours(() => wedData.hours);

        const tueData = workingDayInfo.filter(
          (item: any) => item.day === days[1]
        )[0];
        setTuesdayChecked(!tueData.day_off);
        setTuesdayPartialDay(tueData.partial);
        setTuesdayPartialHours(() => tueData.hours);

        const mondayData = workingDayInfo.filter(
          (item: any) => item.day === days[0]
        )[0];
        setMondayChecked(!mondayData.day_off);
        setMondayPartialDay(mondayData.partial);
        setMondayPartialHours(() => mondayData.hours);
        setGetPrice(true);
        setPrice(() => res?.data?.full_days_per_week || "");
      }
    } catch (e: any) {
      if (e?.response?.status === 401) {
        // disaptch(resetUser());
      }
    }
  };

  useEffect(() => {
    get();
  }, []);
  return (
    <div>
      <CustomeCkeckboxField
        title="Hourly paid"
        value={hourlyPaid}
        onChange={() => setHourlyPaid((_) => !_)}
      />
      <CustomNumberField
        title="Hours per day"
        value={perDayHours}
        onChangeText={(val) => !val.startsWith("-") && setPerDayHours(val)}
        note={
          "Zero regular hours will result in a zero hourly rate for unpaid leave deductions."
        }
        require
      />
      <CustomSelectField
        title="Schedule"
        value={schedule}
        onChangeOption={setSchedule}
        option={[
          { key: "Fixed", value: "1" },
          { key: "Casual/ Temp", value: "2" },
        ]}
        require
      />

      <div className="regular-hours">
        <h4>Regular Working Days</h4>
      </div>

      {schedule == "1" && (
        <>
          <RegularHoursFields
            day="Mon"
            dayChecked={mondayChecked}
            partialDay={mondayPartialDay}
            partialHours={mondayPartialHours}
            setDayChecked={(val) => setMondayChecked(val)}
            setPartialDay={(val) => setMondayPartialDay(val)}
            setPartialHours={(val) => setMondayPartialHours(val)}
          />
          <RegularHoursFields
            day="Tue"
            dayChecked={tuesdayChecked}
            partialDay={tuesdayPartialDay}
            partialHours={tuesdayPartialHours}
            setDayChecked={(val) => setTuesdayChecked(val)}
            setPartialDay={(val) => setTuesdayPartialDay(val)}
            setPartialHours={(val) => setTuesdayPartialHours(val)}
          />
          <RegularHoursFields
            day="Wed"
            dayChecked={wednesdayChecked}
            partialDay={wednesdayPartialDay}
            partialHours={wednesdayPartialHours}
            setDayChecked={(val) => setWednesdayChecked(val)}
            setPartialDay={(val) => setWednesdayPartialDay(val)}
            setPartialHours={(val) => setWednesdayPartialHours(val)}
          />
          <RegularHoursFields
            day="Thu"
            dayChecked={thursdayChecked}
            partialDay={thursdayPartialDay}
            partialHours={thursdayPartialHours}
            setDayChecked={(val) => setThursdayChecked(val)}
            setPartialDay={(val) => setThursdayPartialDay(val)}
            setPartialHours={(val) => setThursdayPartialHours(val)}
          />
          <RegularHoursFields
            day="Fri"
            dayChecked={fridayChecked}
            partialDay={fridayPartialDay}
            partialHours={fridayPartialHours}
            setDayChecked={(val) => setFridayChecked(val)}
            setPartialDay={(val) => setFridayPartialDay(val)}
            setPartialHours={(val) => setFridayPartialHours(val)}
          />
          <RegularHoursFields
            day="Sat"
            dayChecked={saturdayChecked}
            partialDay={saturdayPartialDay}
            partialHours={saturdayPartialHours}
            setDayChecked={(val) => setSaturdayChecked(val)}
            setPartialDay={(val) => setSaturdayPartialDay(val)}
            setPartialHours={(val) => setSaturdayPartialHours(val)}
          />
          <RegularHoursFields
            day="Sun"
            dayChecked={sundayChecked}
            partialDay={sundayPartialDay}
            partialHours={sundayPartialHours}
            setDayChecked={(val) => setSundayChecked(val)}
            setPartialDay={(val) => setSundayPartialDay(val)}
            setPartialHours={(val) => setSundayPartialHours(val)}
          />
          <div
            className="new-employee full-days-per-week"
            style={{ alignItems: "center" }}
          >
            <label className="employee-label-extra">
              Full days per week
              <Require />
            </label>
            {editPrice ? (
              <>
                <input
                  className="edit-price"
                  value={price}
                  onChange={(e: any) => setPrice(e.target.value)}
                />
                <CustomButton
                  type="secondary"
                  size="small"
                  width="150px"
                  title="Cancel Override"
                  onClick={() => {
                    updateOverritePrice();
                    setEditPrice(false);
                  }}
                />
              </>
            ) : (
              <>
                <div className="price">{parseFloat(price.toFixed(1))}</div>
                <CustomButton
                  size="small"
                  width="80px"
                  title="Override"
                  onClick={() => setEditPrice(true)}
                />
              </>
            )}
          </div>
        </>
      )}
      <div style={{ marginTop: "1rem", marginBottom: "1rem", display: "flex" }}>
        <CustomButton
          type="secondary"
          title="Cancel"
          onClick={handleNavigateBack}
        />
        <CustomButton title="Save" onClick={handleUpdateHoursData} disable={loading} loading={loading} />
      </div>
    </div>
  );
};

export default EditRegularHour;
