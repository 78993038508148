import { Box, Modal, Paper } from "@mui/material";
import { PDFViewer } from "@react-pdf/renderer";
import { IconContext } from "react-icons";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ReportDocument from "../../pages/payslip/ReportDocument";
import { useAppSelector } from "../../_app";

interface ReportModalProps {
  isOpen?: boolean;
  handleClose?: () => void;
  documents: any;
  finalized?: boolean;
  job?: boolean;
  startDate:string;
  lastDate:string;
  type:string;
}

const ReportModal = (props: ReportModalProps) => {
  const {
    isOpen = false,
    handleClose,
    documents,
    finalized = false,
    job = false,
    startDate,
    lastDate,
    type
  } = props;


  const user = useAppSelector((state) => state.user);

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Paper
        sx={{
          width: "100%",
          height: "100%",
          background: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Box
          className="close-icon"
          sx={{
            position: "absolute",
            top: 20,
            right: 20,
          }}
          onClick={handleClose}
        >
          <IconContext.Provider value={{ color: "#f00", size: "30px" }}>
            <AiOutlineCloseCircle />
          </IconContext.Provider>
        </Box>
        <PDFViewer width="90%" height="90%">
          <ReportDocument
            documents={documents}
            finalized={finalized}
            job={job}
            startDate={startDate || ""}
            lastDate={lastDate || ""}
            type={type}
            payslipSettings={user?.payslip_settings}
          />
        </PDFViewer>
      </Paper>
    </Modal>
  );
};

export default ReportModal;
