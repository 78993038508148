import apiClient from "./BaseService";
import { URL } from "../config";

const _getEftList = async () => apiClient.get(URL.GET_EFT_LIST);

const _addGiroEft = async (data: any) => apiClient.post(URL.ADD_EFT, data);

const _addBasicPaySetting = async (data: any) =>
  apiClient.post(URL.ADD_EMPLOYEE_NUMBER, data);

const _getCpfOptions = async () => apiClient.get(URL.GET_CPF_OPTIONS);

const _getPayrollSettings = async (code: string, company: number) =>
  apiClient.get(`${URL.GET_COMPANY_SETTINGS}?code=${code}&company=${company}`);

const _getGiroSettings = async (company: number) =>
  apiClient.get(`${URL.GET_GIRO_DETAILS}?&company=${company}`);

const _getPayslipSettings = async (company: number) =>
  apiClient.get(`${URL.GET_PAYSLIP_SETTINGS}${company}/`);

const _savePayslipSettings = async (data: any) =>
  apiClient.post(URL.SAVE_PAYSLIP_SETTINGS, data);

const _getBillingData = async (companyId: number) =>
  apiClient.get(`${URL.GET_BILLING_DETAILS}${companyId}/get_billing_details/`);

const _saveBillingData = async (data: any) =>
  apiClient.post(URL.CREATE_BILLING, data);

const _updateBillingData = async (id:any, data: any) =>
  apiClient.patch(`${URL.UPDATE_BILLING}${id}/`, data);

export const CompanyPayrollService = {
  _getEftList,
  _addGiroEft,
  _addBasicPaySetting,
  _getCpfOptions,
  _getPayrollSettings,
  _getGiroSettings,
  _getPayslipSettings,
  _savePayslipSettings,
  _getBillingData,
  _saveBillingData,
  _updateBillingData
};
