import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { IoIosRibbon, IoIosSend } from "react-icons/io";
import { FaReceipt } from "react-icons/fa6";
import { MdOutlineFileDownload } from "react-icons/md";
import { ImBlocked } from "react-icons/im";
import {
  CommonModal,
  CustomButton,
  DeactivateModal,
  Loader,
  NavigateBack,
  Tooltip,
} from "../../../components";
import { TableHeader } from "../../../components/table";
import { Heading } from "../AppSettingsStyles";
import { IRASTable } from "../../filing/CPFFilingStyles";
import { TableRow } from "../../payslip/PayslipStyles";
import { Notify, Utils } from "../../../utils";
import { Constant } from "../../../config";
import { useEffect, useState } from "react";
import { LoaderContainer } from "../../../CommonStyles";
import { useLocation, useNavigate } from "react-router-dom";
import { ServiceCodeService } from "../../../_service";
import { useAppSelector } from "../../../_app";
import moment from "moment";
import ReconcileModal from "./ReconcileModal";
import { FilterByChar, SearchField } from "../../dashboard/component";
import { TableContainer } from "@mui/material";
import InvoiceSearchField from "../../invoice/InvoiceSearchField";
import { debounce } from "lodash";

const CustomerInvoice = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [serviceCodes, setServiceCodes] = useState<any>([]);
  const [isDeleteModalActive, setIsDeleteModalActive] = useState(false);
  const [serviceCodeToDelete, setServiceCodeToDelete] = useState<any>();
  const [invoiceData, setInvoiceData] = useState<any>([]);
  const [modalData, setModalData] = useState();
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<any>(null);
  const [selectedInvoiceAmount, setSelectedInvoiceAmount] = useState<string>("");
  const [showReconcileModal, setShowReconcileModal] = useState<boolean>(false)
  const [searchString, setSearchString] = useState("")
  const [open, setOpen] = useState(false)
  const [voidID, setVoidID] = useState('')
  const navigate = useNavigate();
  const location = useLocation();
  const [char, setChar] = useState("All")
  const user = useAppSelector((state) => state.user);
  const companyId = useAppSelector((state) => state.user.defaultCompnayId);

  //date
  const currentYear = new Date().getFullYear();
  const availableYears = Array.from({ length: 10 }, (_, i) => currentYear - 3 + i);
  const months = Array.from({ length: 12 }, (_, i) => ({ number: i + 1, name: new Date(0, i).toLocaleString('default', { month: 'long' }) }));

  const [Year, setYear] = useState(currentYear);
  const [fromMonth, setFromMonth] = useState(1);
  const [toMonth, setToMonth] = useState(12);

  const dateFrom = `${Year}-${String(fromMonth).padStart(2, "0")}-01`;
  const dateTo = `${Year}-${String(toMonth).padStart(2, "0")}-01`;
  console.log("datee", { dateFrom, dateTo });

  const debouncing = (search: any) => {
    let time = 1000;
    setTimeout(() => {
      setSearchString(search)
    }, time);
  }

  const serviceCodeTableHeaders = [
    {
      name: "Invoice No.",
      width: "7%",
    },
    {
      name: "Inovice Date",
      width: "8%",
    },
    {
      name: "Customer Name",
      width: "15%",
    },

    {
      name: "SPC",
      width: "15%",
    },

    {
      name: "Amount",
      width: "8%",
      textAlign: "right",
    },
    // {
    //   name: "Paid",
    //   width: "8%",
    //   textAlign: "right",
    // },
    // {
    //   name: "Unpaid",
    //   width: "8%",
    //   textAlign: "right",
    // },
    {
      name: "Status",
      width: "8%",
    },
    {
      name: "Sent",
      width: "6%",
    },

    {
      name: "Action",
      width: "10%",
    },
  ];

  const getInvoiceListing = async () => {
    setIsLoading(() => true);
    try {
      const { data, status } = await ServiceCodeService._getInvoiceList(dateFrom, dateTo, "");
      if (status >= 200 && status < 300) {
        setInvoiceData(data?.data?.results);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsLoading(() => false);
  };

  useEffect(() => {
    getInvoiceListing()
  }, [fromMonth, toMonth, Year])

  const handleResend = async (invoiceId: any) => {
    setIsLoading(() => true);
    try {
      const { status } = await ServiceCodeService._resendInvoice(invoiceId);

      if (status >= 200 && status < 300) {
        Notify("Send Successfully", 1);
        getInvoiceListing();
      }
    } catch (error: any) {
      if (error?.response?.status) {
        Notify(error.response.data?.message, 0);
      } else {
        Notify(Constant.common.something_went_wrong, 0);
      }
    }
    setIsLoading(() => false);
  };

  const handlePDFDownload = async (invoiceId: any) => {
    try {
      let response = await ServiceCodeService?._downloadInvoice(invoiceId);
      if (response.status >= 200 && response.status < 300) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `invoice.pdf`; // Set the desired filename for the downloaded file
        document.body.appendChild(a);
        a.click();
      }
    } catch (error) {
      // Notify(Constant.common.something_went_wrong, 0);
      Notify("No PDF Available", 0);
    }
  };

  // useEffect(() => {
  //   getInvoiceListing();
  // }, []);

  const handleReconcile = async (date: string, transactionId: string, action: any = "payment_received") => {
    let payload = {
      "payment_received_date": date,
      "transaction_id": transactionId
    }

    try {
      const { data, status } = await ServiceCodeService._voidinvoiceid(selectedInvoiceId, action, payload);

      if (status === 200) {
        Notify("Payment Received Successful!", 1);
        setShowReconcileModal(false);
        setSelectedInvoiceId(null);
        setSelectedInvoiceAmount("");
        getInvoiceListing();
      }
    } catch (error: any) {
      if (error.response.data?.message) {
        Notify(error.response.data?.message, 0);
      } else {
        Notify(Constant.common.something_went_wrong, 0);
      }
    }

  }

  const voidActionHandler = async (id: any, actions: any, data: any) => {

    try {
      const { status } = await ServiceCodeService._voidinvoiceid(id, actions, data)
      if (status === 200) {
        Notify("Void Successful!", 1);
        setSelectedInvoiceId(null)
        console.log('lol');

        getInvoiceListing();
      } else {
        setSelectedInvoiceId(null)

      }
    }
    catch (error: any) {
      if (error.response.data?.message) {
        Notify(error.response.data?.message, 0);
      } else {
        Notify(Constant.common.something_went_wrong, 0);
      }
    }
  }

  const tableHeaders = [
    { name: "Invoice Number", width: "150px" },
    { name: "Date", width: "120px" },
    { name: "Customer Name", width: "200px" },
    { name: "Service Code", width: "100px" },
    { name: "Description", width: "300px" },
    { name: "Total Amount", width: "150px" },
    { name: "Status", width: "120px" },
    { name: "Actions", width: "180px" },
  ];


  return (
    <div>
      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%"
            }}
          >

            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <NavigateBack />
              <Heading>{("Invoices")}</Heading>
            </div>

            {user.permission === "full" &&
              <div style={{
                display: 'flex',
                alignItems: 'center'
              }}>
                {/* <CustomButton
                title="Auto Reconcile"
                type="secondary"
                onClick={() => null}
              /> */}
                  <InvoiceSearchField onChangeText={setSearchString} value={searchString} />
                  <CustomButton
                    width="120px"
                    title="Invoice"
                    type="secondary"
                    onClick={() => navigate("addcreditnote", { state: { invoiceType: "INV" } })}
                  />
                  <CustomButton
                    width="120px"
                    title="Credit Note"
                    type="secondary"
                    onClick={() => navigate("addcreditnote", { state: { invoiceType: "CN" } })}
                  />
                </div>

            }


          </div>

          <div
            style={{
              display: "flex",
              gap: "16px",
              padding: "8px 0px",
              background: "#f9f9f9",

            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>

              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <label style={{ fontSize: "14px", fontWeight: "500", color: "#333", marginBottom: "4px" }}>
                  Year:
                </label>

                <select
                  value={Year}
                  onChange={(e: any) => setYear(e.target.value)}
                  style={{
                    padding: "6px",
                    border: "1px solid #ccc",
                    borderRadius: "6px",
                    background: "#fff",
                    cursor: "pointer"
                  }}
                >
                  {availableYears.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>

              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>

              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <label style={{ fontSize: "14px", fontWeight: "500", color: "#333", marginBottom: "4px" }}>
                  From:
                </label>

                <select
                  value={fromMonth}
                  onChange={(e: any) => setFromMonth(e.target.value)}
                  style={{
                    padding: "6px",
                    border: "1px solid #ccc",
                    borderRadius: "6px",
                    background: "#fff",
                    cursor: "pointer"
                  }}
                >
                  {months.map((month) => (
                    <option key={month.number} value={month.number}>
                      {month.name}
                    </option>
                  ))}
                </select>

              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>

              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <label style={{ fontSize: "14px", fontWeight: "500", color: "#333", marginBottom: "4px" }}>
                  To:
                </label>

                <select
                  value={toMonth}
                  onChange={(e: any) => setToMonth(e.target.value)}
                  style={{
                    padding: "6px",
                    border: "1px solid #ccc",
                    borderRadius: "6px",
                    background: "#fff",
                    cursor: "pointer"
                  }}
                >
                  {months.map((month) => (
                    <option key={month.number} value={month.number}>
                      {month.name}
                    </option>
                  ))}
                </select>

              </div>
            </div>
          </div>



          {/* <FilterByChar value={char} onChange={setChar} /> */}
          <IRASTable style={{
            width: "100%"
          }}>
            <TableHeader
              headerColumns={serviceCodeTableHeaders}
              variant="filled"
              styles={{ padding: "5px", textAlign: "left" }}
            />

            <tbody>
              {invoiceData?.length > 0 &&
                invoiceData.filter(((x: any) => x.invoice_number.toLowerCase().includes(searchString.toLowerCase().trim()) || x.company_data?.company_details?.name.toLowerCase().includes(searchString.toLowerCase().trim())))
                  .sort((a: any, b: any) => b.number - a.number)
                  .map((item: any, index: any) => (
                    <TableRow style={{ cursor: 'pointer' }} key={item.id}>
                      <td onClick={() => navigate("addcreditnote", {
                        state: { ...item, id: item.id, 'isViewOnly': item?.status != "saved", "isEdit": true , invoiceType : item.invoice_type   },
                      })} 
                      onMouseOver={(e:any) => e.target.style.textDecoration = "underline"}
                      onMouseOut={(e:any) => e.target.style.textDecoration = "none"}
                      style={{
                        textAlign: 'left',
                        paddingLeft: '.4rem',
                        color : "#0000FF"
                      }}>{item?.invoice_number || ""}</td>

                      <td onClick={() => navigate("addcreditnote", {
                        state: { ...item, 'isViewOnly': item?.status !== "saved", "isEdit": true , invoiceType : item.invoice_type },
                      })} style={{
                        padding: '0.3125rem'
                      }}>{item?.date && ` ${moment(item?.date, "YYYY-MM-DD").format("DD MM YYYY").replaceAll(' ', '-')}`}</td>

                      {/* service code */}
                      <td
                        onClick={() => navigate("addcreditnote", {
                          state: { ...item, 'isViewOnly': item?.status != "saved", "isEdit": true ,invoiceType : item.invoice_type },
                        })}
                      >                      {item?.company_data?.company_details?.name || "-"}
                      </td>
                      <td
                        onClick={() => navigate("addcreditnote", {
                          state: { ...item, 'isViewOnly': item?.status != "saved", "isEdit": true, invoiceType : item.invoice_type },
                        })}
                      >                      {item?.company_data?.spc_details?.company_name || "-"}
                      </td>

                      {/* <td
                        onClick={() =>
                          navigate("addcreditnote", {
                            state: { ...item, isViewOnly: item?.status !== "saved", isEdit: true },
                          })
                        }
                        style={{
                          padding: "6px",
                          cursor: "pointer",
                        }}
                      >
                        <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
                          {item?.json_data?.items?.map((x: any, index: number) => (
                            <li
                              key={index}
                              style={{
                                padding: "5px 2px",
                                backgroundColor: "#f0f9ff", // Slightly darker grey for contrast
                                borderRadius: "4px",
                                marginBottom: "3px",
                                width: "fit-content"

                              }}
                            >
                              {x?.name}
                            </li>
                          ))}
                        </ul>
                      </td> */}

                      {/* <td
                        onClick={() =>
                          navigate("addcreditnote", {
                            state: { ...item, isViewOnly: item?.status !== "saved", isEdit: true },
                          })
                        }
                        style={{
                          padding: "6px",
                          cursor: "pointer",
                        }}
                      >
                        <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
                          {item?.json_data?.items?.map((x: any, index: number) => (
                            <li
                              key={index}
                              style={{
                                padding: "5px 2px",
                                backgroundColor: "#f0f9ff",
                                borderRadius: "4px",
                                marginBottom: "3px",
                                width: "fit-content"
                              }}
                            >
                              {x?.description}
                            </li>
                          ))}
                        </ul>
                      </td> */}
                      <td onClick={() => navigate("addcreditnote", {
                        state: { ...item, 'isViewOnly': item?.status != "saved", "isEdit": true },
                      })} style={{
                        padding: '0.3125rem',
                        textAlign: "right",
                        paddingRight: "4.5rem",
                      }}>{`${item?.company_data?.billing_details?.currency_details?.symbol}${Utils.getCommanSepartedNumericString(item?.json_data?.grand_total || "")}`}</td>
                      {/* <td onClick={() => navigate("addcreditnote", {
                        state: { ...item, 'isViewOnly': item?.status != "saved", "isEdit": true },
                      })} style={{
                        padding: '0.3125rem'
                      }}>
                        <p style={{
                          textTransform: 'capitalize',
                          paddingLeft: "4px"
                        }}>{item?.paid || "-"}</p>
                      </td>
                      <td onClick={() => navigate("addcreditnote", {
                        state: { ...item, 'isViewOnly': item?.status != "saved", "isEdit": true },
                      })} style={{
                        padding: '0.3125rem'
                      }}>
                        <p style={{
                          textTransform: 'capitalize',
                          paddingLeft: "4px"
                        }}>{item?.unpaid || "-"}</p>
                      </td> */}




                      <td onClick={() => navigate("addcreditnote", {
                        state: { ...item, 'isViewOnly': item?.status != "saved", "isEdit": true },
                      })} style={{
                        padding: '0.3125rem'
                      }}>
                        <p style={{
                          textTransform: 'capitalize',
                          paddingLeft: "4px"
                        }}>{item?.status === "payment_received" ? "Received" : item.status}</p>
                      </td>
                      <td onClick={() => navigate("addcreditnote", {
                        state: { ...item, 'isViewOnly': item?.status != "saved", "isEdit": true },
                      })} style={{
                        padding: '0.3125rem'
                      }}>
                        <p style={{
                          textTransform: 'capitalize',
                          paddingLeft: "4px"
                        }}>{item?.is_sent && <span style={{
                          color: "#149614"
                        }}>Sent</span> || "-"}</p>
                      </td>
                      <td
                        style={{
                          display: 'flex',
                          gap: '1rem',
                          alignItems: 'center',
                          textAlign: "center",
                          padding: '0.3125rem'
                        }}
                      >
                        <Tooltip text="void">
                          <CustomButton
                            type={"secondary"}
                            size="small"
                            width="30px"
                            title={""}
                            onClick={() => {
                              setSelectedInvoiceId(() => item?.id);
                              setOpen(true)
                            }
                            }
                            icon={<ImBlocked color={(item?.status === "void" || item?.status === "payment_received") ? "gray" : ""} />}
                            disable={item?.status === "void" || item?.status === "payment_received"}

                          />
                        </Tooltip>
                        <Tooltip text="Payment Recieved">
                          <CustomButton
                            type={"secondary"}
                            size="small"
                            width="30px"
                            title={""}
                            onClick={(item?.status === "saved" || item?.status === "reconciled") ? () => null : () => {
                              setSelectedInvoiceId(() => item?.id);
                              setSelectedInvoiceAmount(() => Utils.getCommanSepartedNumericStringWithDollar(item?.json_data?.grand_total))
                              setShowReconcileModal(true)
                            }}
                            icon={<FaReceipt color={(item?.status === "saved" || item?.status === "payment_received" || item?.status === "void") ? "gray" : ""} />}
                            disable={item?.status === "saved" || item?.status === "payment_received" || item?.status === "void"}
                          />
                        </Tooltip>
                        <Tooltip text="Send">
                          <CustomButton
                            type={"secondary"}
                            size="small"
                            width="30px"
                            title={""}
                            onClick={
                              () => {
                                handleResend(item?.id)
                                setOpen(false)
                              }
                            }
                            icon={<IoIosSend color={(item?.status === "void" || item?.status === "payment_received" || item?.status === "sent" || item?.start === "reconciled") ? "gray" : ""} />}
                            disable={item?.status === "void" || item?.status === "payment_received" || item?.status === "sent" || item?.start === "reconciled"}
                          />
                        </Tooltip>
                        <Tooltip text="Download">
                          <CustomButton
                            type={"secondary"}
                            size="small"
                            width="30px"
                            title={""}
                            onClick={() => handlePDFDownload(item?.id)}
                            icon={<MdOutlineFileDownload />}
                          />
                        </Tooltip>
                      </td>
                    </TableRow>
                  ))}
            </tbody>
          </IRASTable>

          <ReconcileModal heading="Payment Received" amount={selectedInvoiceAmount} open={showReconcileModal} handleClose={() => {
            setShowReconcileModal(false);
            setSelectedInvoiceId(null);
            setSelectedInvoiceAmount("");
          }} onCancel={() => {
            setShowReconcileModal(false);
            setSelectedInvoiceId(null);
            setSelectedInvoiceAmount("");
          }} updateData={handleReconcile} />

          <CommonModal

            handleClose={() => {
              setSelectedInvoiceId(null)
              setOpen(false)
            }
            }
            open={open}
            updateData={() => {
              voidActionHandler(selectedInvoiceId, "void", null)
              setOpen(false)
            }

            }
            body={`Are you sure you want to void customer invoice?`}
            heading="Confirm Void"
            conformButtonText="Yes"
            cancelButtonText="No"
            onCancel={() => {
              setSelectedInvoiceId(null)
              setOpen(false)
            }

            }

          />
        </>
      )}
    </div>
  );
};

export default CustomerInvoice;

