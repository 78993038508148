import React, { useEffect, useState } from "react";
import {
  AllEmployeesHeading,
  MultiSelectButton,
  MultiSelectButtonSmall,
  ReportFilterBody,
  ReportFilterContainer,
  ReportHeading,
  ReportHeadingContainer,
  ReportHorizontalSeparator,
  ReportSectionHeading,
  TransactionGrid,
} from "../ReportFilterPageStyles";
import { H3Heading } from "../../appSettings/AppSettingsStyles";
import { useLocation, useNavigate } from "react-router-dom";
import { CommonModal, CustomButton, CustomDateField, Loader } from "../../../components";
import { useAppSelector } from "../../../_app";
import { Notify, Utils } from "../../../utils";
import { ReportService } from "../../../_service/ReportService";
import XLSX from 'xlsx';
import { Constant } from "../../../config";
import styled from "styled-components";
import EmployeeListModal from "../EmployeeListModal/EmployeeListModal";
import cloneDeep from "lodash.clonedeep";
import moment from "moment";
import { LoaderContainer } from "../../../CommonStyles";
import ExcelJS from 'exceljs';

const YTDPayrollReport = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const currentPayrunDate = useAppSelector(
    (state) => state.user.current_payrun_date
  );

  let currentDate = new Date(
    useAppSelector((state) => state?.user?.current_payrun_date)
  );

  if (!currentDate) {
    currentDate = new Date();
  }
  currentDate.setDate(1);
  // Move back one day to the last day of the last month
  currentDate.setDate(0);
  const lastDayOfLastMonth = Utils._date(currentDate);

  // Set the date to the first day of the last month
  currentDate.setDate(1);
  const firstDayOfLastMonth = Utils._date(currentDate);

  const [fromDate, setFromDate] = useState<string>(moment(currentPayrunDate, "YYYY-MM-DD").startOf('year').format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState<string>(moment(currentPayrunDate, "YYYY-MM-DD").endOf('month').format('YYYY-MM-DD'));
  const [isLoading, setIsLoading] = useState(true);

  const [reportData, setReportData] = useState<any>({});

  const companyId =
    useAppSelector((state) => state.user.defaultCompnayId) || "";
  let reportCode = location?.pathname.split("/")?.pop() || "";

  const [statusValue, setStatusValue] = useState<string>("1");
  const [selectedEmployeeList, setSelectedEmployeeList] = useState<any>([]);
  const [employeeList, setEmployeeList] = useState<any>([]);
  const [showEmployeeModal, setShowEmployeeModal] = useState<boolean>(false);

  const [selectedItems, setSelectedItems] = useState<any>(["Month","Employee"]);

  const [excelOrder, setExcelOrder] = useState<any[]>([])


  const getData = async (onlyReports:boolean) => {
    if (companyId) {
      setIsLoading(() => true);
      try {
        const res = await ReportService._getCustomReport(
          reportCode,
          companyId,
          fromDate,
          toDate,
          selectedEmployeeList?.length > 0
            ? selectedEmployeeList?.join(",")
            : ""
        );
        if (res.status === 200) {
          let data = res?.data?.data;

          // if (!res?.data?.data?.is_custom_report_settings) {
          //   navigate("/dashboard/manage-report");
          // }

          if(onlyReports){
            setReportData(res?.data?.data);
          } else {
            setReportData(res?.data?.data);
            setEmployeeList(res?.data?.data?.employee_list);

            // populate selectedItems - setting checkbox true by default
            setSelectedItems(["Month","Employee", ...Object.values(res?.data?.data?.parameters || {})?.flat()?.map((x:any)=>x.name)  ])
          }
        }
      } catch (e: any) {
        Notify("Someting went wrong", 0);
      }
      setIsLoading(() => false);
    }
  };

  
  useEffect(() => {
    setStatusValue("1")
    getData(false)
  }, [fromDate, toDate, companyId,  ]);


  useEffect(()=>{

    if(employeeList?.active?.length > 0 || employeeList?.inactive?.length > 0) {
      let data =  statusValue == "0" ?
      [...employeeList?.active, ...employeeList?.inactive]?.map((emp: any) => emp.id) 
      : statusValue == "1" ? [...employeeList?.active]?.map((emp: any) => emp.id) 
      : [...employeeList?.inactive]?.map((emp: any) => emp.id);
      setSelectedEmployeeList(data);
    }
  },[statusValue, employeeList])

  useEffect(() => {
    if(!showEmployeeModal && selectedEmployeeList?.length >= 1){
      getData(true);
    }
  }, [selectedEmployeeList, showEmployeeModal])



  const downloadExcel = async () => {
    selectedItems.sort((a:any, b:any) => {
      if (a === "Employee") return -1;
      if (b === "Employee") return 1;
      return excelOrder.indexOf(a) - excelOrder.indexOf(b)
    });

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('YTD Payroll Report');

    // Add header rows
    worksheet.addRow([reportData?.report_meta_data?.company_name]);
    worksheet.addRow(['YTD Payroll Report']);
    worksheet.addRow(['Period', `${Utils._dateToShow(fromDate)} to ${Utils._dateToShow(toDate)}`]);
    worksheet.addRow([]);

    // Add column headers
    worksheet.addRow(selectedItems);

    // Add data rows
    const valueArr = reportData?.report_data?.map((item:any) => {
      return selectedItems?.map((x:any) => 
        (x === "Month" || x === "Employee" || x === "Normal Hours") ? 
        item[x] : 
        Utils.getCommanSepartedNumericStringWithDollar(item[x])
      );
    });
    valueArr.forEach((row: any) => {
      worksheet.addRow(row);
    });

    // Add empty row and total row
    worksheet.addRow([]);
    const totalArr = selectedItems?.map((x:any) => 
      x === "Month" ? "Total" : 
      (x === "Employee" || x === "Normal Hours" || x === "Normal Rate") ? "" : 
      Utils.getCommanSepartedNumericStringWithDollar(reportData?.report_totals[x])
    );
    worksheet.addRow(totalArr);

    // Style the header rows
    worksheet.getRow(1).font = { bold: true, size: 14 };
    worksheet.getRow(2).font = { bold: true };
    worksheet.getRow(3).font = { bold: true };
    worksheet.getRow(5).font = { bold: true };

    // This to bold the Total Values row
    worksheet.getRow(worksheet.rowCount).font = { bold: true };

    // Calculate and set column widths based on content
    worksheet?.columns?.forEach((column, index) => {
      let maxLength = 0;
      if (column && typeof column.eachCell === 'function') {
        column.eachCell({ includeEmpty: true }, (cell) => {
          const length = cell.value ? cell.value.toString().length : 0;
          if (length > maxLength) {
            maxLength = length;
          }
        });
      }
      column.width = Math.max(maxLength + 4, 18); 
    });

    // Right align all cells except first column
    worksheet.eachRow((row, rowNumber) => {
      row.eachCell((cell, colNumber) => {
        // Skip first column
        if (colNumber > 1) {
          // Skip header rows (1-5)
          if (rowNumber > 1) {
            cell.alignment = { horizontal: 'right' };
          }
        }
      });
    });

    // Generate and download the file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${reportData?.report_meta_data?.company_name}_YTD_Payroll_Report.xlsx`;
    a.click();
    window.URL.revokeObjectURL(url);
  };



  const handleSelectAll = (status: any) => {
    const allEmpIds = status === "1" ? employeeList?.active?.map((emp: any) => emp.id) 
    : status === "2" ? employeeList?.inactive?.map((emp: any) => emp.id) 
    : [...employeeList?.active, ...employeeList?.inactive].map((emp: any) => emp.id)
    setSelectedEmployeeList([...allEmpIds]);
  };

  const handleSelectEmployee = (id: any) => {
    if (selectedEmployeeList.includes(id)) {
      let interArr = selectedEmployeeList;
      interArr = interArr.filter((em: any) => em !== id);
      setSelectedEmployeeList(() => interArr);
    } else {
      setSelectedEmployeeList(() => [...selectedEmployeeList, id]);
    }
  };

  const handleEmployeeSelectNone = () => {
    setSelectedEmployeeList(() => []);
  };

  const toggleCheckbox = (itemName:any) => {

    let data = [...selectedItems];
    let index = data?.indexOf(itemName);

    if(index >= 0) {
      data?.splice(index, 1)
    } else {
      data?.push(itemName);
    }
    console.log("selection", data)
    setSelectedItems(data);
  }

  const handleSelectAllItems = (list: []) => {
    let data = [...selectedItems];
    list.forEach((itemName:string) => {
        let index = data?.indexOf(itemName);
        if(index === -1) {
          data?.push(itemName);
        } 
    });
    setSelectedItems(data);
  }
  const handleSelectNoneItems = (list: []) => {
    let data = [...selectedItems];
    list.forEach((itemName:string) => {
        let index = data?.indexOf(itemName);
        if(index >= 0) {
          data?.splice(index, 1)
        } 
    });
    setSelectedItems(data);
  }

  const itemOrder = [
    "income",
    "allowance",
    "gross_pay",
    "deductions",
    "reimbursement",
    "netpay",
    "employer_contribution",
    "quantity",
  ]

  const sortedEntries = Object.entries(reportData?.parameters || {}).sort(([key1], [key2]) => {
    // const order = [...itemOrder];
    const order = [...new Set([...itemOrder, ...Object.entries(reportData?.parameters || {}).map((x:any) => x[0])])];
    console.log("finalOrder", order);
    return order.indexOf(key1) - order.indexOf(key2);
  });

  console.log("outside selected", selectedItems)
  console.log("excelOrder", excelOrder) 

   useEffect(()=> {
      let sortedArray = Object.entries(reportData?.parameters || {}).sort(([key1], [key2]) => {
        const order = [...new Set([...itemOrder, ...Object.entries(reportData?.parameters || {}).map((x:any) => x[0])])];
        console.log("finalOrder", order);
        return order.indexOf(key1) - order.indexOf(key2);
      });
  
      const dataObj:any = {};
  
      const createExcelStructure = () => {
          sortedArray.forEach(item => {
            const key = item[0]; 
            const value = item[1]; 
            dataObj[key] = value; 
        });
      }
      
      createExcelStructure();
  
      console.log("dataObj", dataObj)
      let order = Object.values(dataObj).flat().map((x:any) => x.name);
      setExcelOrder(order);
    }, [reportData?.parameters])


  if(isLoading) {
    return <LoaderContainer>
    <Loader />
  </LoaderContainer>
  }

  return (
    <>
      <H3Heading>{"YTD Payroll Report"}{Utils.getTitleWithCompanyName(" ")}</H3Heading>
      <ReportFilterContainer>
        <ReportHeadingContainer>
          {/* <ReportHeading>{pathToHeading[pathname]}</ReportHeading> */}
        </ReportHeadingContainer>

        <ReportFilterBody>

        <div>
            <ReportSectionHeading>Filters</ReportSectionHeading>
            <div style={{ display: "flex", gap: 50, marginTop: 20 }}>
              <div style={{ display: "flex" }}>
                <Container>
                  <Text>Current Status </Text>
                  <Select
                    value={statusValue}
                    onChange={(e:any) => {
                      setStatusValue(e.target.value);
                      handleSelectAll(e.target.value)
                    }}
                  >
                    <option value={"0"}>{Constant.common.all}</option>
                    <option value={"2"}>{Constant.common.inactive}</option>
                    <option value={"1"}>{Constant.common.active}</option>
                  </Select>
                </Container>
              </div>
              <div style={{ display: "flex" }}>
                <Container style={{ marginBottom: 20 }}>
                  <Text>Employees </Text>
                  <Information onClick={() => setShowEmployeeModal(() => true)}>
                    {selectedEmployeeList?.length === 0
                      ? "None"
                      : selectedEmployeeList?.length ===
                        employeeList?.active?.length +
                          employeeList?.inactive?.length
                      ? "All"
                      : `${selectedEmployeeList?.length} of ${ statusValue == "0" ?
                          (employeeList?.active?.length + employeeList?.inactive?.length) : statusValue == "1" ? employeeList?.active?.length : employeeList?.inactive?.length
                        }`}
                  </Information>
                </Container>
              </div>
            </div>
          </div>
          <ReportHorizontalSeparator style={{ marginTop: 6 }} />
          <div>
            <ReportSectionHeading>Date Range</ReportSectionHeading>
            <div style={{ display: "flex", width: "100%", alignItems: 'center', justifyContent: 'start' }}>
              <div style={{display: 'flex', gap: '1rem'}}>

              <CustomDateField
                label="From"
                date={fromDate}
                setDate={(date: any) => setFromDate(() => date)}
                labelWidth="40px"
                />
              <CustomDateField
                label="To"
                date={toDate}
                setDate={(date: any) => setToDate(() => date)}
                labelWidth="20px"
                />
              </div>

              <div style={{marginLeft: 'auto'}}>
                <CustomButton
                width="150px"
                title="Show Excel"
                onClick={selectedEmployeeList?.length >= 1 ? downloadExcel : () => Notify("Please select at least one employee", 0)}
                // disable={!selectedEmployeeList?.legth}
                />
              </div>
            </div>
          </div>
          <ReportHorizontalSeparator />

          <ReportSectionHeading>Employees</ReportSectionHeading>

          <AllEmployeesHeading>
            {selectedEmployeeList?.length === 0
              ? "No "
              : selectedEmployeeList?.length ===
                employeeList?.active?.length +
                  employeeList?.inactive?.length
              ? `All (${selectedEmployeeList?.length}) `
              : `${selectedEmployeeList?.length} of ${
                statusValue == "0" ?
                (employeeList?.active?.length + employeeList?.inactive?.length) : statusValue == "1" ? employeeList?.active?.length : employeeList?.inactive?.length
                } `}
            employee(s) selected
          </AllEmployeesHeading>

          <ReportHorizontalSeparator />

          <div style={{ display: "flex", gap: 20 }}>
            <ReportSectionHeading>Transactions</ReportSectionHeading>
            <MultiSelectButton onClick={() => setSelectedItems(["Month","Employee", ...Object.values(reportData?.parameters || {})?.flat()?.map((x:any)=>x.name)  ])}>
                  All
            </MultiSelectButton>
            <MultiSelectButton onClick={() => setSelectedItems(()=>["Month","Employee"]) }>
              None
            </MultiSelectButton>
          </div>

          <div>
            {sortedEntries?.length > 0 && sortedEntries?.map((item: any) => (
              <>
                {item[1]?.length >= 1 && <div style={{ display: "flex", gap: 10, margin: "20px 0" }}>
                  <h3
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '1rem',
                      fontWeight: 500,
                      fontSize: 20,
                      color: "#333333",
                      textTransform: 'capitalize',
                    }}
                  >
                    {Utils.formateStringWithUnderscore(item[0] === "netpay" ? "Net Pay" : item[0])}
                    <MultiSelectButtonSmall onClick={()=> {
                      let tempList = item[1]?.map((z:any)=>z.name);
                      console.log('tempList', tempList)
                      handleSelectAllItems(tempList)
                    }}
                    >
                          All
                    </MultiSelectButtonSmall>
                    <MultiSelectButtonSmall onClick={()=> {
                      let tempList = item[1]?.map((z:any)=>z.name);
                      console.log('tempList', tempList)
                      handleSelectNoneItems(tempList)
                    }}>
                          None
                    </MultiSelectButtonSmall>
                  </h3>
                </div>}
                <TransactionGrid>
                  {item[1]?.length >= 1 && item[1]?.map((x: any) => (
                    <div
                      style={{
                        display: "flex",
                        gap: 20,
                        color: "#333333",
                      }}
                    >
                      <input type="checkbox" onChange={(e:any)=>toggleCheckbox(x.name)} checked={selectedItems?.includes(x?.name)} />
                      <p>{x?.name}</p>
                    </div>
                  ))}
                </TransactionGrid>
              </>
            ))}
          </div>
          <ReportHorizontalSeparator />
          <CustomButton
              width="150px"
              title="Show Excel"
              onClick={selectedEmployeeList?.length >= 1 ? downloadExcel : () => Notify("Please select at least one employee", 0)}
              // disable={!selectedEmployeeList?.legth}
            />
        </ReportFilterBody>
      </ReportFilterContainer>

      <CommonModal
        handleClose={() => {
          setShowEmployeeModal(false);
          setSelectedEmployeeList([...selectedEmployeeList]);
        }}
        open={showEmployeeModal}
        updateData={() => setShowEmployeeModal(false)}
        body={
          <EmployeeListModal
            employeeList={statusValue === '1' ? employeeList?.active : statusValue === '2' ? employeeList?.inactive : [...employeeList?.active, ...employeeList?.inactive]}
            handleSelectAll={() => handleSelectAll(statusValue)}
            handleSelectNone={handleEmployeeSelectNone}
            selectedEmployeeList={selectedEmployeeList}
            handleSelectEmployee={handleSelectEmployee}
          />
        }
        heading="Filter Employees"
        conformButtonText="Close"
        isCancelButton={false}
      />
    </>
  );
};

export default YTDPayrollReport;



const Container = styled.div`
  margin-bottom: 1rem;
  display: flex;
`;
const Text = styled.p`
  width: 110px;
  height: 30px;
  color: #fff;
  border: 1px solid #afb5ba;
  font-size: 0.8rem;
  background-color: #afb5ba;
  text-transform: none;
  display: flex;
  align-items: center;
  padding: 0 5px;
  border-radius: 3px 0 0 3px;
`;
const Select = styled.select`
  max-width: 100px;
  height: 30px;
  background-color: #087ca7;
  color: #fff;
  border-radius: 0 3px 3px 0;
  cursor: pointer;
  padding: 0 5px;
  text-align: center;
`;

const Information = styled.div`
  max-width: 100px;
  height: 30px;
  background-color: #087ca7;
  color: #fff;
  border-radius: 0 3px 3px 0;
  cursor: pointer;
  padding: 0 10px;
  text-align: center;
  display: flex;
  align-items: center;
`;


// All - 0
// Active - 1
// Inactive - 2