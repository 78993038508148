import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_app";
import { CompanyPayrollService } from "../../_service";
import { Button, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { Notify, Utils } from "../../utils";
import { ca } from "date-fns/locale";
import { updateUserIntialState } from "../../_features";

// const data = {
//   include_employment_date: false,
//   include_job_title: false,
//   include_nric_fin: false,
// };

const PayslipSettings = () => {
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const [settings, setSettings] = useState<any>({
    include_employment_date: false,
    include_job_title: false,
    include_nric_fin: false,
  });

  const getPayslipSettings = async () => {
    const { status, data } = await CompanyPayrollService._getPayslipSettings(
      parseInt(user?.defaultCompnayId)
    );
    if (status >= 200 && status < 300) {
      console.log(data?.data);
      setSettings(data?.data);
    }
  };

  const handleSave = async () => {
    let payload = {
      company: parseInt(user?.defaultCompnayId),
      include_nric_fin: settings?.include_nric_fin,
      include_employment_date: settings?.include_employment_date,
      include_job_title: settings?.include_job_title,
    };

    try {
      const { status } = await CompanyPayrollService._savePayslipSettings(
        payload
      );
      if (status >= 200 && status < 300) {
        console.log("Settings saved successfully");
        Notify("Settings saved successfully", 1);
         dispatch(
          updateUserIntialState({
            ...user,
            payslip_settings: {
              ...user?.payslip_settings,
              include_nric_fin: settings?.include_nric_fin,
              include_employment_date: settings?.include_employment_date,
              include_job_title: settings?.include_job_title,
            },
          }));
      }
    } catch (error: any) {
      console.log("Error saving settings");
      Utils.showErrorMessage(error);
    }
  };

  useEffect(() => {
    getPayslipSettings();
  }, []);

  console.log(settings);

  return (
    <div style={{ maxWidth: "1000px", margin: "2rem auto", padding: "2rem" }}>
      <div
        style={{
          backgroundColor: "#ffffff",
          padding: "2.5rem",
          borderRadius: "0.75rem",
          boxShadow: "0 0.25rem 0.375rem rgba(0,0,0,0.05)",
          border: "0.0625rem solid #eaeaea",
        }}
      >
        <h2
          style={{
            color: "#2c3e50",
            marginBottom: "1.5rem",
            fontSize: "1.5rem",
            fontWeight: 600,
          }}
        >
          Payslip Settings
        </h2>

        <p
          style={{
            color: "#5f6368",
            marginBottom: "2.5rem",
            fontSize: "1rem",
            lineHeight: "1.6",
          }}
        >
          Please check the fields you would like to include in the payslip.
          These settings will be applied to all generated payslips for your
          company.
        </p>

        <FormGroup
          sx={{
            backgroundColor: "#f8fafc",
            padding: "2rem",
            borderRadius: "0.5rem",
            border: "0.0625rem solid #e2e8f0",
            "& .MuiFormControlLabel-root": {
              marginY: 1.5,
              "& .MuiCheckbox-root": {
                color: "#64748b",
              },
              "& .Mui-checked": {
                color: "#2563eb",
              },
            },
          }}
        >
          <FormControlLabel
            id="include_nric_fin"
            control={
              <Checkbox
                checked={settings?.include_nric_fin}
                onChange={(event) =>
                  setSettings({
                    ...settings,
                    include_nric_fin: event.target.checked,
                  })
                }
              />
            }
            label="Include NRIC/FIN"
          />

          <FormControlLabel
            id="include_job_title"
            control={
              <Checkbox
                checked={settings?.include_job_title}
                onChange={(event) =>
                  setSettings({
                    ...settings,
                    include_job_title: event.target.checked,
                  })
                }
              />
            }
            label="Include Job Title"
          />

          <FormControlLabel
            id="include_employment_date"
            control={
              <Checkbox
                checked={settings?.include_employment_date}
                onChange={(event) =>
                  setSettings({
                    ...settings,
                    include_employment_date: event.target.checked,
                  })
                }
              />
            }
            label="Include Employment Date"
          />
        </FormGroup>

        <Button
          variant="contained"
          sx={{
            mt: 4,
            px: 3,
            py: 1,
            textTransform: "none",
            fontSize: "1rem",
            fontWeight: 600,
            backgroundColor: "#59bbf",
            borderRadius: "0.5rem",
            "&:hover": {
              backgroundColor: "#057a97",
            },
          }}
          onClick={handleSave}
        >
          Save Settings
        </Button>
      </div>
    </div>
  );
};

export default PayslipSettings;
