import { useEffect, useState } from "react";
import XLSX from "xlsx";
import { ReportSectionHeading } from "../reports/ReportFilterPageStyles";

import { CustomDateField } from "../../components";
import {
  CustomButton,
  CustomSelectField,
  CustomeCkeckboxField,
  Loader,
  ReportModal,
  Tooltip,
} from "../../components";
import { TableHeader } from "../../components/table";
import { Constant } from "../../config";
import { H3Heading, Heading } from "../appSettings/AppSettingsStyles";
import { IRASTable, IRASTableCol } from "../filing/CPFFilingStyles";
import { OverviewCardItem } from "./components";
import { useLocation, useNavigate } from "react-router-dom";
import { DisplayFlex, RecordSeletedText, TableRow } from "./PayslipStyles";
import { PayslipReportService } from "../../_service";
import { useAppSelector } from "../../_app";
import { Notify, Utils } from "../../utils";
import {
  PayslipDetails,
  PayslipFrontendDetails,
  PayslipSummary,
} from "../../_types/ReportTypes";
import { LoaderContainer } from "../../CommonStyles";
import { MdFileDownload } from "react-icons/md";
import { SearchField, TablePaginationHeader } from "../dashboard/component";
import { v4 as uuidv4 } from 'uuid';
import moment from "moment";
import styled from "styled-components";
import ExcelJS from 'exceljs';


const PayslipReports = () => {
  const date = new Date();
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname?.split("/").pop();

  let isPreviousPath = location?.state?.isPreviousPath;

  let currentDate = new Date(
    useAppSelector((state) => state?.user?.current_payrun_date)
  );
  const currentPayrunDate = useAppSelector(
    (state) => state?.user?.current_payrun_date
  );
  const lastDayOfLastMonth = Utils._date(currentDate);
  const firstDayOfLastMonth = Utils._date(currentDate);
  const [toDate, setToDate] = useState<string>(lastDayOfLastMonth);
  const [fromDate, setFromDate] = useState<string>(firstDayOfLastMonth);
  const [month, setMonth] = useState<string>(`${date.getMonth() + 1}`);
  const [year, setYear] = useState<string>(isPreviousPath ? location?.state?.year : `${currentPayrunDate?.split('-')[0] - 1 }`);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [isSelectAllOnThisPage, setIsSelectAllOnThisPage] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedCount, setSelectedCount] = useState<number>(0);
  const [payslipSummary, setPayslipSummary] = useState<PayslipSummary>();
  const [ totalSummary, setTotalSummary] = useState<any>();
  const [payslipDetails, setPayslipDetails] = useState<
    any[]
  >([]);
  const [showPdf, setShowPdf] = useState<boolean>(false);
  const [pdfDataToShow, setPdfDataToShow] = useState<any>([]);
  const [searchText, setSearchText] = useState<string>("");

  const [pageInfo, setPageInfo] = useState<any>({
    currentPageNumber: 1,
    dataPerPage: 20,
    totalLength: 0,
  });
  const [filteredPayslips, setFilteredPayslips] = useState<any>();


  const companyId = useAppSelector((state) => state.user.defaultCompnayId);
  const companyName = useAppSelector((state) => state.user.defaultCompany);
 


  const payrunDateYear = currentPayrunDate?.split("-")[0] || date.getFullYear()
  console.log("isPreviousPath", isPreviousPath, location?.state?.fromMonth, location?.state?.toMonth)
  const [fromMonth, setFromMonth] = useState<string>(
    location?.state?.isPreviousPath ? location?.state?.fromMonth : `${1 || date.getMonth() + 1}`
  );
  const [toMonth, setToMonth] = useState<string>(
    path === "current" ? `${location?.state?.isPreviousPath ? location?.state?.toMonth : parseInt(currentPayrunDate?.split("-")[1] || 0) - 1 || date.getMonth() + 1}` : `${location?.state?.isPreviousPath ? location?.state?.toMonth : 12}`
  );

  const currentReportMonthsOptions = Utils.MONTH_OPTIONS?.slice(0, parseInt(currentPayrunDate?.split('-')[1]) - 2);

  const [totalIncome, setTotalIncome] = useState(0);
  const [totalAllowance, setTotalAllowance] = useState(0);
  const [totalReimbursement, setTotalReimbursement] = useState(0);
  const [totalDeduction, setTotalDeduction] = useState(0);
  const [cpfb, setTotalCpfb] = useState(0);
  const [totalNetPay, setTotalNetPay] = useState(0);

 

  /** Array for Header columns of Table */
  const tableHeader = [
    {
      width: "5%",
    },
    {
      name: "Names",
      width: "25%",
    },
    {
      name: "Emp No.",
      width: "7%",
    },
    {
      name: "Income",
      width: "11%",

    },
    {
      name: "Allowance",
      width: "11%",
    },
    {
      name: "Reimburse",
      width: "11%",
    },
    {
      name: "Deductions",
      width: "11%",
    },
    {
      name: "CPFB",
      width: "11%",
    },
    {
      name: "Net Pay",
      width: "11%",
    },
    {
      name: "Action",
    },
  ];

  /** Array for Options of month */
  const monthsOptions = Utils.MONTH_OPTIONS;

  /** Function to get details for paqyslip reports for all the users in the given month */
  const getPayslipReportDetails = async () => {
    setIsLoading(true);
    try {
      const { data, status } =
        await PayslipReportService._getPayslipReportDetails(
          companyId,
          month,
          path === "current" ? `${date.getFullYear()}` : year
        );
      if (status === 200) {
        setPayslipSummary(() => data?.data?.summary);
        setTotalSummary(() => data?.data?.summary);

        let newTotalIncome = 0;
        let newTotalAllowance = 0;
        let newTotalReimbursement = 0;
        let newTotalDeduction = 0;
        let newTotalCpfb = 0;
        let newTotalNetPay = 0;

        const payslipIndividualData = data.data.payslips.map(
          (payslip: any) => {
            newTotalIncome =
              newTotalIncome +
              parseFloat(
                payslip?.payslip_info?.income?.total?.replace(/,/g, "") || "0"
              );
            newTotalAllowance =
              newTotalAllowance +
              parseFloat(
                payslip?.payslip_info?.allowance?.total?.replace(/,/g, "") ||
                "0"
              );
            newTotalReimbursement =
              newTotalReimbursement +
              parseFloat(
                payslip?.payslip_info?.reimbursement?.total?.replace(
                  /,/g,
                  ""
                ) || "0"
              );
            newTotalDeduction =
              newTotalDeduction +
              parseFloat(
                payslip?.payslip_info?.deductions?.total?.replace(/,/g, "") ||
                "0"
              );
            newTotalCpfb =
              newTotalCpfb +
              parseFloat(
                payslip?.payslip_info?.cpf_details?.cpfb?.replace(/,/g, "") ||
                "0"
              );
            newTotalNetPay =
              newTotalNetPay +
              parseFloat(
                payslip?.payslip_info?.net_pay?.replace(/,/g, "") || "0"
              );

            return {
              income: payslip?.payslip_info?.income?.total || "0",
              deduction: payslip?.payslip_info?.deductions?.total || "0",
              cpfb: payslip?.payslip_info?.cpf_details?.cpfb || "0",
              net_pay: payslip?.payslip_info?.net_pay || "0",
              id: payslip?.employee?.emp_number || "NA",
              full_name: payslip?.employee?.user?.name || "",
              isFinalised: payslip?.is_finalized || false,
              allowance: payslip?.payslip_info?.allowance?.total || "0",
              reimbursement: payslip?.payslip_info?.reimbursement?.total || "0",
              employeeId: payslip?.employee?.id,
              payslipDocument: payslip,
              isSelected: false,
              payslipId: payslip.id,
            };
          }
        );

        setTotalIncome(() => newTotalIncome);
        setTotalAllowance(() => newTotalAllowance);
        setTotalDeduction(() => newTotalDeduction);
        setTotalCpfb(() => newTotalCpfb);
        setTotalReimbursement(() => newTotalReimbursement);
        setTotalNetPay(() => newTotalNetPay);
        setPayslipDetails(() => payslipIndividualData);
        setFilteredPayslips(() => payslipIndividualData);
        setPageInfo((info: any) => ({
          ...info,
          totalLength: data.data.payslips.length,
        }));
      }
    } catch (error) {
      Notify("Something went wrong", 1);
    }
    setIsLoading(false);
  };

  // -------- get Grouped Reports -----------

  const getCurrentPayslipReportDetails = async () => {
    setIsLoading(true);
    try {
      const { data, status } =
        await PayslipReportService._getCurrentPayslipReportDetails(
          companyId,
          path === "current" ? Utils.getStartDateOfMonth(currentPayrunDate?.split('-')[0],fromMonth) : Utils.getStartDateOfMonth(year, fromMonth) ,
          path === "current" ? Utils.getLastDateOfMonth(currentPayrunDate?.split('-')[0],toMonth): Utils.getLastDateOfMonth(year, toMonth),
        );
      if (status === 200) {
        setPayslipSummary(() => data?.data?.summary);
        setTotalSummary(() => data?.data?.summary);
        let newTotalIncome = 0;
        let newTotalAllowance = 0;
        let newTotalReimbursement = 0;
        let newTotalDeduction = 0;
        let newTotalCpfb = 0;
        let newTotalNetPay = 0;

        // const dataToMap = path === "current" ? data?.data?.grouped_payslip_data : data?.data?.payslips;

        const payslipIndividualData = data?.data?.grouped_payslip_data.map(
          (payslip: any) => {
            newTotalIncome =
              newTotalIncome +
              parseFloat(
                payslip?.payslip_info?.income?.total?.replace(/,/g, "") || "0"
              );
            newTotalAllowance =
              newTotalAllowance +
              parseFloat(
                payslip?.payslip_info?.allowance?.total?.replace(/,/g, "") ||
                "0"
              );
            newTotalReimbursement =
              newTotalReimbursement +
              parseFloat(
                payslip?.payslip_info?.reimbursement?.total?.replace(
                  /,/g,
                  ""
                ) || "0"
              );
            newTotalDeduction =
              newTotalDeduction +
              parseFloat(
                payslip?.payslip_info?.deductions?.total?.replace(/,/g, "") ||
                "0"
              );
            newTotalCpfb =
              newTotalCpfb +
              parseFloat(
                payslip?.payslip_info?.cpf_details?.cpfb?.replace(/,/g, "") ||
                "0"
              );
            newTotalNetPay =
              newTotalNetPay +
              parseFloat(
                payslip?.payslip_info?.net_pay?.replace(/,/g, "") || "0"
              );

            return {
              income: payslip?.payslip_info?.income?.total || "0",
              deduction: payslip?.payslip_info?.deductions?.total || "0",
              cpfb: payslip?.payslip_info?.cpf_details?.cpfb || "0",
              net_pay: payslip?.payslip_info?.net_pay || "0",
              id: payslip?.employee?.emp_number || "NA",
              full_name: payslip?.employee?.user?.name || "",
              isFinalised: payslip?.is_finalized || false,
              allowance: payslip?.payslip_info?.allowance?.total || "0",
              reimbursement: payslip?.payslip_info?.reimbursement?.total || "0",
              employeeId: payslip?.employee?.id,
              payslipDocument: payslip,
              isSelected: false,
              payslipId: payslip.id || uuidv4(),
              employeeData: payslip?.employee,
              individualPayslipData: payslip?.individual_payslips,
              shg: payslip?.summary?.shg,
              cpf_employee: payslip?.summary?.cpf_employee,
              other_deductions: payslip?.summary?.other_deductions,
            };
          }
        );

        setTotalIncome(() => newTotalIncome);
        setTotalAllowance(() => newTotalAllowance);
        setTotalDeduction(() => newTotalDeduction);
        setTotalCpfb(() => newTotalCpfb);
        setTotalReimbursement(() => newTotalReimbursement);
        setTotalNetPay(() => newTotalNetPay);
        setPayslipDetails(() => payslipIndividualData);
        setFilteredPayslips(() => payslipIndividualData);
        setPageInfo((info: any) => ({
          ...info,
          totalLength: data.data.payslips.length,
        }));
      }
    } catch (error) {
      Notify("Something went wrong", 1);
    }
    setIsLoading(false);
  };

  console.log("TheCurrent", {filteredPayslips})


  const downloadCsv = async () => {
    console.log("------- original download called -----", path)
    const monthName = monthsOptions.find((obj) => obj.value === month);

    // Create a new workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Combined Data');

    // Add title rows
    worksheet.addRow([`${companyName}`]);
    worksheet.addRow([path === "history" ? "Transaction History Report" : "YTD Payslips Report"]);
    worksheet.addRow([
      `${path === "history" ? "Month and Year:" : "Period:"}`,
      path === "history" ? `${monthName?.key} ${year}` : `${moment(fromDate).format("DD-MM-YYYY")} to ${moment(toDate).format("DD-MM-YYYY")}`
    ]);
    worksheet.addRow(["Number of Employees:", payslipDetails.length]);
    worksheet.addRow([]); // Empty row

    // Add headers
    const headers = [
      "Names",
      "Emp No.",
      "Incomes",
      "Allowances", 
      "Reimbursements",
      "CPF Employee",
      "SHG",
      "Other Deductions", 
      "Net Pay",
      "CPFB"
    ];
    worksheet.addRow(headers);

    // Add data rows
    payslipDetails.forEach(payslip => {
      worksheet.addRow([
        payslip.full_name,
        payslip.id,
        Utils.prependDollar(payslip?.income || "0"),
        Utils.prependDollar(payslip?.allowance || "0"),
        Utils.prependDollar(payslip?.reimbursement || "0"),
        Utils.prependDollar(payslip?.cpf_employee || "0"),
        Utils.prependDollar(payslip?.shg || "0"),
        Utils.prependDollar(payslip?.other_deductions || "0"),
        Utils.prependDollar(payslip?.net_pay || "0"),
        Utils.prependDollar(payslip?.cpfb || "0")
      ]);
    });

    worksheet.addRow([]); // Empty row

    // Add total row
    worksheet.addRow([
      "Total",
      "",
      Utils.getCommanSepartedNumericStringWithDollar(totalIncome.toFixed(2)),
      Utils.getCommanSepartedNumericStringWithDollar(totalAllowance.toFixed(2)),
      Utils.getCommanSepartedNumericStringWithDollar(totalReimbursement.toFixed(2)),
      Utils.getCommanSepartedNumericStringWithDollar(totalSummary?.cpf_employee || 0),
      Utils.getCommanSepartedNumericStringWithDollar(totalSummary?.shg || 0),
      Utils.getCommanSepartedNumericStringWithDollar(totalSummary?.other_deductions || 0),
      Utils.getCommanSepartedNumericStringWithDollar(totalSummary?.net_pay || 0),
      Utils.getCommanSepartedNumericStringWithDollar(totalSummary?.cpfb || 0)
    ]);

    // Calculate and set column widths based on content
    worksheet?.columns?.forEach((column, index) => {
      let maxLength = 0;
      if (column && typeof column.eachCell === 'function') {
        column.eachCell({ includeEmpty: true }, (cell) => {
          const length = cell.value ? cell.value.toString().length : 0;
          if (length > maxLength) {
            maxLength = length;
          }
        });
      }
      column.width = Math.max(maxLength + 4, 16); 
    });

    // Right align numeric columns (index 2-9)
    for(let i = 3; i <= 10; i++) {
      worksheet.getColumn(i).alignment = { horizontal: 'right' };
    }

    // Style the header row
    worksheet.getRow(6).font = { bold: true };

    // Style the total row
    const totalRowIndex = payslipDetails.length + 8;
    worksheet.getRow(totalRowIndex).font = { bold: true };

    // Generate buffer
    const buffer = await workbook.xlsx.writeBuffer();

    // Create blob and download
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${companyName?.split(" ")[0]}_report.xlsx`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  /** Function to send individual reports to download */
  const handleIndividualReportDownload = (payslip: any) => {
    setShowPdf(() => true);
    setPdfDataToShow(() => [payslip]);
  };

  const handleSelectChange = (
    payslipId: number,
    isCurrentItemSelected: boolean,
    payslipDocument: any
  ) => {
    if (isCurrentItemSelected) {
      setSelectedCount(() => selectedCount - 1);
      const updatedPdfDataToShow = pdfDataToShow.filter(
        (pdfData: any) => pdfData.id !== payslipId
      );
      setPdfDataToShow(() => updatedPdfDataToShow);
    } else {
      if (selectedCount <= 0) {
        setPdfDataToShow(() => [payslipDocument]);
      } else {
        setPdfDataToShow(() => [...pdfDataToShow, payslipDocument]);
      }
      setSelectedCount(() => selectedCount + 1);
    }
    setFilteredPayslips((prevPayslips: any) =>
      prevPayslips.map((payslip: any) =>
        payslip.payslipId === payslipId
          ? { ...payslip, isSelected: !payslip.isSelected }
          : payslip
      )
    );
  };

  /** Function to change of clicking select all button */
  // const handleSelectAllOnThisPage = () => {
  //   if (!isSelectAllOnThisPage) {
  //     setFilteredPayslips((prevPayslips: any) =>
  //       prevPayslips.map((payslip: any) => ({ ...payslip, isSelected: true }))
  //     );
  //     const updatedPdfDataToShow = filteredPayslips.map(
  //       (payslip: any) => payslip.payslipDocument
  //     );
  //     setSelectedCount(() => updatedPdfDataToShow.length);
  //     setPdfDataToShow(() => updatedPdfDataToShow);
  //   } else {
  //     setFilteredPayslips((prevPayslips: any) =>
  //       prevPayslips.map((payslip: any) => ({ ...payslip, isSelected: false }))
  //     );
  //     setSelectedCount(() => 0);
  //     setPdfDataToShow(() => []);
  //   }
  //   setIsSelectAllOnThisPage(() => !isSelectAllOnThisPage);
  // };

  const handleSelectAll = () => {
    if (!isSelectAll) {
      setPayslipDetails((prevPayslips: any) =>
        prevPayslips.map((payslip: any) => ({ ...payslip, isSelected: true }))
      );
      setFilteredPayslips((prevPayslips: any) =>
        prevPayslips.map((payslip: any) => ({ ...payslip, isSelected: true }))
      );

      const updatedPdfDataToShow = payslipDetails.map(
        (payslip) => payslip.payslipDocument
      );
      setSelectedCount(() => updatedPdfDataToShow.length);
      setPdfDataToShow(() => updatedPdfDataToShow);
    } else {
      setPayslipDetails((prevPayslips: any) =>
        prevPayslips.map((payslip: any) => ({ ...payslip, isSelected: false }))
      );
      setFilteredPayslips((prevPayslips: any) =>
        prevPayslips.map((payslip: any) => ({ ...payslip, isSelected: false }))
      );
      setSelectedCount(() => 0);
      setPdfDataToShow(() => []);
    }
    setIsSelectAll(() => !isSelectAll);
  };

  const changeSearchText = (value: string) => {
    setSearchText(() => value);
    const allPayslips = payslipDetails;
    const applyFilter = allPayslips.filter((payslip: any) =>
      payslip.full_name.toLowerCase().includes(value.toLowerCase().trim())
    );
    setPageInfo((info: any) => ({
      ...info,
      totalLength: applyFilter.length,
    }));
    setFilteredPayslips(() => applyFilter);
  };

  const handlePageChange = (pageNo: number) => {
    setPageInfo((info: any) => ({
      ...info,
      currentPageNumber: pageNo,
    }));
  };

  useEffect(() => {
    const startIndex =
      pageInfo.currentPageNumber * pageInfo.dataPerPage - pageInfo.dataPerPage;
    const endIndex =
      pageInfo.currentPageNumber * pageInfo.dataPerPage > payslipDetails.length
        ? payslipDetails.length
        : pageInfo.currentPageNumber * pageInfo.dataPerPage;
    const payslipDetailsObj = payslipDetails;
    const applyFilter = payslipDetailsObj.filter((payslipData) =>
      payslipData.full_name
        .toLowerCase()
        .includes(searchText.toLowerCase().trim())
    );
    setFilteredPayslips(() => applyFilter.slice(startIndex, endIndex));
  }, [pageInfo]);

  // const yearOptions = Utils.generateYearArray(2022);
  const yearOptions = Utils.getDefaultYearRange(parseInt(currentPayrunDate?.split('-')[0]));

  useEffect(() => {
    // if(path === "history") {
    //   getPayslipReportDetails();
    // } else {
    //   getCurrentPayslipReportDetails();
    // }
    getCurrentPayslipReportDetails();
  }, [month, year, path, fromMonth, toMonth]);

  useEffect(() => {
    const { previousMonth } = Utils.getPreviousMonthYear(currentPayrunDate);
    setMonth(`${previousMonth}`);
  }, []);


  // To reset the state if the path is changed from "current" to "history" or back
  useEffect(()=>{
    setSelectedCount(0);
    setIsSelectAll(false);
    setSearchText("");
    // setFromMonth("1")
    // setToMonth(path === "current" ? `${parseInt(currentPayrunDate?.split("-")[1] || 0) - 1 || date.getMonth() + 1}` : `${12}` )
  }, [path])

  console.log('theMonths', {fromMonth, toMonth})

  console.log("totalSummmary", totalSummary)

  console.log("report locationState", location?.state)

  return (
    <div>
      <section id="heading">
        <Heading>
          {path === "current"
            ? ` ${Utils.getTitleWithCompanyName(`YTD Reports (${currentPayrunDate?.split('-')[0]})`)} `
            :  `${Utils.getTitleWithCompanyName("YTD History Years Reports")}`}
        </Heading>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "70%",
              alignItems: "center",
            }}
          >

            {path === "current" && (
              <div
                style={{ display: "flex", width: "50%", alignItems: "center" }}
              >
                {/* <CustomDateField
                label="From"
                date={fromDate}
                setDate={(date: any) => setFromDate(() => date)}
                labelWidth="40px"
              />
              <CustomDateField
                label="To"
                date={toDate}
                setDate={(date: any) => setToDate(() => date)}
                labelWidth="40px"
              /> */}
                <p style={{marginRight: '1rem'}}>From: </p>
                <CustomSelectField
                  value={fromMonth}
                  onChangeOption={(value) => setFromMonth(value)}
                  option={currentReportMonthsOptions.map((month) => ({
                    key: month.key,
                    value: month.value,
                  }))}
                  disabled={isLoading}
                  isDate={true}
                />
                <p style={{marginRight: '1rem',marginLeft: '1rem'}}>To: </p>

                <CustomSelectField
                  value={toMonth}
                  onChangeOption={(value) => setToMonth(value)}
                  option={Utils.MONTH_OPTIONS?.slice(
                    0,
                    currentPayrunDate?.split("-")[1] === "01" ? 13 : parseInt(currentPayrunDate?.split("-")[1]) - 1
                  ).map((month) => ({
                    key: month.key,
                    value: month.value,
                  }))}
                  disabled={false}
                  isDate={true}
                />
              </div>
            )}

            {path === "history" && (
              <div>
                <div style={{
                  display:'flex',
                  alignItems: 'center',
                  marginRight: '1rem',
                }}>
                  <div style={{
                  display:'flex',
                    alignItems: 'center',
                    marginRight: '1rem'
                  }}>

                    <p style={{ color: "#333", marginRight: '1rem', whiteSpace: 'nowrap' }}>
                      {"Select Year: "}
                    </p>
                    <CustomSelectField
                      value={year}
                      onChangeOption={(value) => setYear(value)}
                      option={yearOptions.map((year) => ({
                        key: year,
                        value: year,
                      }))}
                      disabled={isLoading}
                      isDate={true}
                    />
                  </div>

                  <div style={{
                  display:'flex',
                    alignItems: 'center',
                    marginRight: '1rem'
                  }}>

                    <p style={{ color: "#333", marginRight: '1rem' }}>
                      {"From: "}
                    </p>
                    <CustomSelectField
                      value={fromMonth}
                      onChangeOption={(value) => setFromMonth(value)}
                      option={monthsOptions.map((month) => ({
                        key: month.key,
                        value: month.value,
                      }))}
                      disabled={isLoading}
                      isDate={true}
                    />
                  </div>
                  <div style={{
                  display:'flex',
                    alignItems: 'center'
                  }}>
                    <p style={{ color: "#333", marginRight: '1rem' }}>
                      {"To: "}
                    </p>
                    <CustomSelectField
                      value={toMonth}
                      onChangeOption={(value) => setToMonth(value)}
                      option={monthsOptions.map((month) => ({
                        key: month.key,
                        value: month.value,
                      }))}
                      disabled={isLoading}
                      isDate={true}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>{" "}
          {payslipDetails.length > 0 && (
            <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
              <div style={{ display: "flex", gap: 10, marginBottom: 10 }}>
                <input
                  type="checkbox"
                  name="selectall"
                  id="selectall"
                  onChange={() => handleSelectAll()}
                  checked={isSelectAll}
                />
                <label
                  htmlFor="selectAll"
                  style={{ paddingBottom: 0, width: 70 }}
                >
                  {Constant.reports.select_all}
                </label>
              </div>
              <SearchField value={searchText} onChangeText={changeSearchText} />
            </div>
          )}
          {payslipDetails.length > 0 && (
            <CustomButton
              title="Download CSV"
              onClick={() => downloadCsv()}
              styles={{ marginBottom: 20 }}
            />
          )}
        </div>
      </section>
      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          {/* <section id="overview" style={{ marginTop: 20 }}>
            <H3Heading>Overview</H3Heading>
            <DisplayFlex>
              <OverviewCardItem
                title="Gross Salary"
                description={Utils.prependDollar(payslipSummary?.gross || 0)}
                color="#059bbf"
              />
              <OverviewCardItem
                title="Net Pay"
                description={Utils.prependDollar(payslipSummary?.net_pay || 0)}
                color="#28a745"
              />
              <OverviewCardItem
                title="CPF Total"
                description={Utils.prependDollar(payslipSummary?.cpf || 0)}
                color="#dc3545"
              />
            </DisplayFlex>
          </section> */}
          <section id="report-table" style={{ marginTop: 40 }}>
            {payslipDetails.length === 0 ? (
              <p>{`${
                path === "history"
                  ? "No payslips occur for this month"
                  : "No payslips occur for the selected date range"
                }`}</p>
            ) : (
              <>
                {/* <IRASTable style={{ width: "100%" }}>
                  <TableRow
                    style={{ backgroundColor: "lightgreen", width: "100%" }}
                  >
                    <IRASTableCol
                      style={{
                        textAlign: "left",
                        width: "23%",
                        paddingLeft: "3%",
                      }}
                    >
                      <strong>Total</strong>
                    </IRASTableCol>
                    <IRASTableCol style={{ textAlign: "right", width: "10%" }}>
                      {Utils.prependDollar(
                        Utils.getNumberWithCommas(totalIncome.toFixed(2))
                      )}
                    </IRASTableCol>
                    <IRASTableCol style={{ textAlign: "right", width: "10%" }}>
                      {Utils.prependDollar(
                        Utils.getNumberWithCommas(totalAllowance.toFixed(2))
                      )}
                    </IRASTableCol>
                    <IRASTableCol style={{ textAlign: "right", width: "10%" }}>
                      {Utils.prependDollar(
                        Utils.getNumberWithCommas(totalReimbursement.toFixed(2))
                      )}
                    </IRASTableCol>
                    <IRASTableCol style={{ textAlign: "right", width: "10%" }}>
                      {Utils.prependDollar(
                        Utils.getNumberWithCommas(totalDeduction.toFixed(2))
                      )}
                    </IRASTableCol>
                    <IRASTableCol style={{ textAlign: "right", width: "10%" }}>
                      {Utils.prependDollar(
                        Utils.getNumberWithCommas(totalNetPay.toFixed(2))
                      )}
                    </IRASTableCol>

                    <IRASTableCol style={{ textAlign: "right", width: "10%" }}>
                      {Utils.prependDollar(
                        Utils.getNumberWithCommas(cpfb.toFixed(2))
                      )}
                    </IRASTableCol>
                    <IRASTableCol style={{ width: "7%" }}></IRASTableCol>
                  </TableRow>
                </IRASTable> */}

                {/* total table */}
        <table className="company-table" style={{ margin: "1rem 0" }}>
          <thead className="company-table-head">
            <tr>
              <th
                style={{
                  width: `${100/12}%`,
                  textAlign: "center",
                  backgroundColor: "#FF4500",
                }}
              >
              </th>
              <th
                style={{
                  width: `${100/12}%`,
                  textAlign: "center",
                  backgroundColor: "#FF4500",
                }}
              >
                Total Employees
              </th>
              <th
                style={{
                  width: `${100/12}%`,
                  textAlign: "center",
                  backgroundColor: "#FF4500",
                }}
              >
              </th>
              <th
                style={{
                  width: `${100/12}%`,
                  textAlign: "right",
                  backgroundColor: "#FF4500",
                }}
              >
                Income
              </th>
              <th
                style={{
                  width: `${100/12}%`,
                  textAlign: "right",
                  backgroundColor: "#FF4500",
                }}
              >
                Allowance
              </th>
              <th
                style={{
                  width: `${100/12}%`,
                  textAlign: "right",
                  backgroundColor: "#FF4500",
                }}
              >
                Reimburse
              </th>
              <th
                style={{
                  width: `${100/12}%`,
                  textAlign: "right",
                  backgroundColor: "#FF4500",
                }}
              >
                CPF Employee
              </th>
              <th
                style={{
                  width: `${100/12}%`,
                  textAlign: "right",
                  backgroundColor: "#FF4500",
                }}
              >
                SHG
              </th>
              <th
                style={{
                  width: `${100/12}%`,
                  textAlign: "right",
                  backgroundColor: "#FF4500",
                }}
              >
                Other Deduct
              </th>
              <th
                style={{
                  width: `${100/12}%`,
                  textAlign: "right",
                  backgroundColor: "#FF4500",
                }}
              >
                Net Pay
              </th>
              <th
                style={{
                  width: `${100/12}%`,
                  textAlign: "right",
                  backgroundColor: "#FF4500",
                }}
              >
                CPFB
              </th>
              <th
                  style={{
                    width: `${100/12}%`,
                    textAlign: "right",
                    backgroundColor: "#FF4500",
                  }}
                >
                  {" "}
                </th>
            </tr>
          </thead>
          <tbody>
            <tr>
            <td
                style={{
                  textAlign: "center",
                  fontSize: "1.25rem",
                  fontWeight: 400,
                  width: `${100/12}%`,
                }}
              >
                {("")}
              </td>
              <td
                style={{
                  width: `${100/12}%`,
                  textAlign: "center",
                  fontSize: "1.25rem",
                  fontWeight: 400,
                }}
              >
                {(totalSummary?.total_employee || 0)}
              </td>
              <td
                style={{
                  textAlign: "center",
                  fontSize: "1.25rem",
                  fontWeight: 400,
                  width: `${100/12}%`,
                }}
              >
                {("")}
              </td>
              <td
                style={{
                  width: `${100/12}%`,
                  textAlign: "right",
                  fontWeight: 400,
                  fontSize: "0.875rem",
                  color: "#333",
                }}
              >
                {Utils.getCommanSepartedNumericStringWithDollar(totalSummary?.total_income || 0)}
              </td>
              <td
                style={{
                  width: `${100/12}%`,
                  textAlign: "right",
                  fontWeight: 400,
                  fontSize: "0.875rem",
                  color: "#333",
                }}
              >
                {Utils.getCommanSepartedNumericStringWithDollar(totalSummary?.total_allowance || 0)}
              </td>
              <td
                style={{
                  width: `${100/12}%`,
                  textAlign: "right",
                  fontWeight: 400,
                  fontSize: "0.875rem",
                  color: "#333",
                }}
              >
                {Utils.getCommanSepartedNumericStringWithDollar(totalSummary?.total_reimbursement || 0)}
              </td>
              <td
                style={{
                  width: `${100/12}%`,
                  textAlign: "right",
                  fontWeight: 400,
                  fontSize: "0.875rem",
                  color: "#333",
                }}
              >
                {Utils.getCommanSepartedNumericStringWithDollar(totalSummary?.cpf_employee || 0)}
              </td>
              <td
                style={{
                  width: `${100/12}%`,
                  textAlign: "right",
                  fontWeight: 400,
                  fontSize: "0.875rem",
                  color: "#333",
                }}
              >
                {Utils.getCommanSepartedNumericStringWithDollar(totalSummary?.shg || 0)}
              </td>
              <td
                style={{
                  width: `${100/12}%`,
                  textAlign: "right",
                  fontWeight: 400,
                  fontSize: "0.875rem",
                  color: "#333",
                }}
              >
                {Utils.getCommanSepartedNumericStringWithDollar(totalSummary?.other_deductions || 0)}
              </td>
              <td
                style={{
                  width: `${100/12}%`,
                  textAlign: "right",
                  fontWeight: 400,
                  fontSize: "0.875rem",
                  color: "#333",
                }}
              >
                {Utils.getCommanSepartedNumericStringWithDollar(totalSummary?.net_pay || 0)}
              </td>
              <td
                style={{
                  width: `${100/12}%`,
                  textAlign: "right",
                  fontWeight: 400,
                  fontSize: "0.875rem",
                  color: "#333",
                }}
              >
                {Utils.getCommanSepartedNumericStringWithDollar(totalSummary?.cpfb || 0)}
              </td>
              <td
                style={{
                  width: `${100/12}%`,
                  textAlign: "right",
                  fontWeight: 400,
                  fontSize: "0.875rem",
                  color: "#333",
                }}
              >
                {""}
              </td>
              
              
            </tr>
          </tbody>
        </table>

                <div style={{ width: "100%" }}>
                  <TablePaginationHeader
                    pageInfo={pageInfo}
                    name="Reports"
                    handlePageValueChange={handlePageChange}
                  />
                </div>
                <IRASTable
                  style={{ borderCollapse: "collapse", width: "100%" }}
                >
                  {/* <TableHeader headerColumns={tableHeader} variant="filled" /> */}
                  <thead>
                    <tr>
                      <th
                        style={{
                          textAlign: "left",
                          backgroundColor: "#4a5e71",
                          color: "#f8f9fa",
                          border: "none",
                          fontWeight: "bold",
                          width: "3%",
                        }}
                      >
                        {""}{" "}
                      </th>
                      <th
                        style={{
                          textAlign: "left",
                          backgroundColor: "#4a5e71",
                          color: "#f8f9fa",
                          border: "none",
                          fontSize: "0.8125rem",
                          padding: "4.8px",
                          fontWeight: "bold",
                          width: "30%",
                        }}
                      >
                        Name
                      </th>
                      <th
                        style={{
                          textAlign: "right",
                          backgroundColor: "#4a5e71",
                          color: "#f8f9fa",
                          border: "none",
                          fontSize: "0.8125rem",
                          padding: "4.8px",
                          fontWeight: "bold",
                          width: "10%",
                        }}
                      >
                        Emp No.
                      </th>
                      <th
                        style={{
                          textAlign: "right",
                          backgroundColor: "#4a5e71",
                          color: "#f8f9fa",
                          border: "none",
                          fontSize: "0.8125rem",
                          padding: "4.8px",
                          fontWeight: "bold",
                          width: "10%",
                        }}
                      >
                        Incomes
                      </th>
                      <th
                        style={{
                          textAlign: "right",
                          backgroundColor: "#4a5e71",
                          color: "#f8f9fa",
                          border: "none",
                          fontSize: "0.8125rem",
                          padding: "4.8px",
                          fontWeight: "bold",
                          width: "10%",
                        }}
                      >
                        Allowances
                      </th>
                      <th
                        style={{
                          textAlign: "right",
                          backgroundColor: "#4a5e71",
                          color: "#f8f9fa",
                          border: "none",
                          fontSize: "0.8125rem",
                          padding: "4.8px",
                          fontWeight: "bold",
                          width: "10%",
                        }}
                      >
                        Reimburse
                      </th>
                      <th
                        style={{
                          textAlign: "right",
                          backgroundColor: "#4a5e71",
                          color: "#f8f9fa",
                          border: "none",
                          fontSize: "0.8125rem",
                          padding: "4.8px",
                          fontWeight: "bold",
                          width: "10%",
                        }}
                      >
                        CPF Employee
                      </th>
                      <th
                        style={{
                          textAlign: "right",
                          backgroundColor: "#4a5e71",
                          color: "#f8f9fa",
                          border: "none",
                          fontSize: "0.8125rem",
                          padding: "4.8px",
                          fontWeight: "bold",
                          width: "5%",
                        }}
                      >
                        SHG
                      </th>
                      <th
                        style={{
                          textAlign: "right",
                          backgroundColor: "#4a5e71",
                          color: "#f8f9fa",
                          border: "none",
                          fontSize: "0.8125rem",
                          padding: "4.8px",
                          fontWeight: "bold",
                          width: "10%",
                        }}
                      >
                        Other Deduct
                      </th>
                      <th
                        style={{
                          textAlign: "right",
                          backgroundColor: "#4a5e71",
                          color: "#f8f9fa",
                          border: "none",
                          fontSize: "0.8125rem",
                          padding: "4.8px",
                          fontWeight: "bold",
                          width: "10%",
                        }}
                      >
                        Net Pay
                      </th>
                      <th
                        style={{
                          textAlign: "right",
                          backgroundColor: "#4a5e71",
                          color: "#f8f9fa",
                          border: "none",
                          fontSize: "0.8125rem",
                          padding: "4.8px",
                          fontWeight: "bold",
                          width: "10%",
                        }}
                      >
                        CPFB
                      </th>

                      <th
                        style={{
                          textAlign: "center",
                          backgroundColor: "#4a5e71",
                          color: "#f8f9fa",
                          border: "none",
                          fontSize: "0.8125rem",
                          padding: "4.8px",
                          fontWeight: "bold",
                          width: "7%",
                        }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredPayslips
                      .sort((a: any, b: any) =>
                        a.full_name.localeCompare(b.full_name)
                      )
                      .map(
                        (payslip: any) =>
                          payslip.full_name
                            .toLowerCase()
                            .includes(searchText.toLowerCase().trim()) && (
                            <TableRow>
                              <IRASTableCol
                                style={{
                                  textAlign: "left",
                                  paddingLeft: "1rem",
                                  minWidth: "4%",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  style={{ width: 15 }}
                                  onChange={() =>
                                    handleSelectChange(
                                      payslip.payslipId,
                                      payslip.isSelected,
                                      payslip.payslipDocument
                                    )
                                  }
                                  checked={payslip.isSelected}
                                />
                              </IRASTableCol>
                              <IRASTableCol
                                style={{ textAlign: "left", width: "30%", cursor:'pointer', color: 'blue', fontWeight: 600, textDecoration: 'underline'  }}
                                onClick={()=>navigate("/dashboard/payslip/individual", {
                                  state: {
                                    payslipData: payslip.individualPayslipData,
                                    employeeData: payslip.employeeData,
                                    path: path,
                                    fromMonth: fromMonth,
                                    toMonth: toMonth,
                                    year: path === "current" ? currentPayrunDate?.split('-')[0] : year
                                  }
                                })}
                              >
                                {payslip.full_name}
                              </IRASTableCol>
                              <IRASTableCol style={{ textAlign: "right", cursor: 'pointer' }} onClick={()=>navigate("/dashboard/payslip/individual", {
                                state: {
                                  payslipData: payslip.individualPayslipData,
                                  employeeData: payslip.employeeData,
                                  path: path,
                                  fromMonth: fromMonth,
                                  toMonth: toMonth,
                                  year: path === "current" ? currentPayrunDate?.split('-')[0] : year
                                }
                              })}>
                                {payslip.id}
                              </IRASTableCol>
                              <IRASTableCol style={{ textAlign: "right", cursor: 'pointer' }} onClick={()=>navigate("/dashboard/payslip/individual", {
                                state: {
                                  payslipData: payslip.individualPayslipData,
                                  employeeData: payslip.employeeData,
                                  path: path,
                                  fromMonth: fromMonth,
                                  toMonth: toMonth,
                                  year: path === "current" ? currentPayrunDate?.split('-')[0] : year
                                }
                              })}>
                                {Utils.prependDollar(payslip.income)}
                              </IRASTableCol>
                              <IRASTableCol style={{ textAlign: "right", cursor: 'pointer' }} onClick={()=>navigate("/dashboard/payslip/individual", {
                                state: {
                                  payslipData: payslip.individualPayslipData,
                                  employeeData: payslip.employeeData,
                                  path: path,
                                  fromMonth: fromMonth,
                                  toMonth: toMonth,
                                  year: path === "current" ? currentPayrunDate?.split('-')[0] : year
                                }
                              })}>
                                {Utils.prependDollar(payslip.allowance)}
                              </IRASTableCol>
                              <IRASTableCol style={{ textAlign: "right", cursor: 'pointer' }} onClick={()=>navigate("/dashboard/payslip/individual", {
                                state: {
                                  payslipData: payslip.individualPayslipData,
                                  employeeData: payslip.employeeData,
                                  path: path,
                                  fromMonth: fromMonth,
                                  toMonth: toMonth,
                                  year: path === "current" ? currentPayrunDate?.split('-')[0] : year
                                }
                              })}>
                                {Utils.prependDollar(payslip.reimbursement)}
                              </IRASTableCol>
                              <IRASTableCol style={{ textAlign: "right", cursor: 'pointer' }} onClick={()=>navigate("/dashboard/payslip/individual", {
                                state: {
                                  payslipData: payslip.individualPayslipData,
                                  employeeData: payslip.employeeData,
                                  path: path,
                                  fromMonth: fromMonth,
                                  toMonth: toMonth,
                                  year: path === "current" ? currentPayrunDate?.split('-')[0] : year
                                }
                              })}>
                                {Utils.prependDollar(payslip.cpf_employee)}
                              </IRASTableCol>
                              <IRASTableCol style={{ textAlign: "right", cursor: 'pointer' }} onClick={()=>navigate("/dashboard/payslip/individual", {
                                state: {
                                  payslipData: payslip.individualPayslipData,
                                  employeeData: payslip.employeeData,
                                  path: path,
                                  fromMonth: fromMonth,
                                  toMonth: toMonth,
                                  year: path === "current" ? currentPayrunDate?.split('-')[0] : year
                                }
                              })}>
                                {Utils.prependDollar(payslip.shg)}
                              </IRASTableCol>
                              <IRASTableCol style={{ textAlign: "right", cursor: 'pointer' }} onClick={()=>navigate("/dashboard/payslip/individual", {
                                state: {
                                  payslipData: payslip.individualPayslipData,
                                  employeeData: payslip.employeeData,
                                  path: path,
                                  fromMonth: fromMonth,
                                  toMonth: toMonth,
                                  year: path === "current" ? currentPayrunDate?.split('-')[0] : year
                                }
                              })}>
                                {Utils.prependDollar(payslip.other_deductions)}
                              </IRASTableCol>
                              <IRASTableCol style={{ textAlign: "right", cursor: 'pointer' }} onClick={()=>navigate("/dashboard/payslip/individual", {
                                state: {
                                  payslipData: payslip.individualPayslipData,
                                  employeeData: payslip.employeeData,
                                  path: path,
                                  fromMonth: fromMonth,
                                  toMonth: toMonth,
                                  year: path === "current" ? currentPayrunDate?.split('-')[0] : year
                                }
                              })}>
                                {Utils.prependDollar(payslip.net_pay)}
                              </IRASTableCol>
                              <IRASTableCol style={{ textAlign: "right", cursor: 'pointer' }} onClick={()=>navigate("/dashboard/payslip/individual", {
                                state: {
                                  payslipData: payslip.individualPayslipData,
                                  employeeData: payslip.employeeData,
                                  path: path,
                                  fromMonth: fromMonth,
                                  toMonth: toMonth,
                                  year: path === "current" ? currentPayrunDate?.split('-')[0] : year
                                }
                              })}>
                                {Utils.prependDollar(payslip.cpfb)}
                              </IRASTableCol>
                              <IRASTableCol style={{ paddingLeft: "3%" }}>
                                <Tooltip text="Download">
                                  <CustomButton
                                    type={"secondary"}
                                    size="small"
                                    width="30px"
                                    title=""
                                    onClick={() =>
                                      handleIndividualReportDownload(
                                        payslip.payslipDocument
                                      )
                                    }
                                    icon={<MdFileDownload />}
                                  />
                                </Tooltip>
                              </IRASTableCol>
                            </TableRow>
                          )
                      )}

                    {/* <TableRow style={{ backgroundColor: "lightgreen" }}>
                      <IRASTableCol></IRASTableCol>
                      <IRASTableCol>
                        <strong>Total</strong>
                      </IRASTableCol>
                      <IRASTableCol></IRASTableCol>
                      <IRASTableCol style={{ textAlign: "right" }}>
                        {Utils.prependDollar(
                          Utils.getNumberWithCommas(totalIncome.toFixed(2))
                        )}
                      </IRASTableCol>
                      <IRASTableCol style={{ textAlign: "right" }}>
                        {Utils.prependDollar(
                          Utils.getNumberWithCommas(totalAllowance.toFixed(2))
                        )}
                      </IRASTableCol>
                      <IRASTableCol style={{ textAlign: "right" }}>
                        {Utils.prependDollar(
                          Utils.getNumberWithCommas(
                            totalReimbursement.toFixed(2)
                          )
                        )}
                      </IRASTableCol>
                      <IRASTableCol style={{ textAlign: "right" }}>
                        {Utils.prependDollar(
                          Utils.getNumberWithCommas(totalDeduction.toFixed(2))
                        )}
                      </IRASTableCol>
                      <IRASTableCol style={{ textAlign: "right" }}>
                        {Utils.prependDollar(
                          Utils.getNumberWithCommas(cpfb.toFixed(2))
                        )}
                      </IRASTableCol>
                      <IRASTableCol style={{ textAlign: "right" }}>
                        {Utils.prependDollar(
                          Utils.getNumberWithCommas(totalNetPay.toFixed(2))
                        )}
                      </IRASTableCol>
                      <IRASTableCol></IRASTableCol>
                    </TableRow>
                  </tbody> */}
                  </tbody>
                </IRASTable>
                <div style={{ marginTop: 20 }}>
                  {selectedCount > 0 && (
                    <DisplayFlex>
                      <RecordSeletedText>
                        {selectedCount} record(s) selected{" "}
                      </RecordSeletedText>
                      <CustomButton
                        title={Constant.reports.download_bulk}
                        onClick={() => setShowPdf(() => true)}
                      />
                    </DisplayFlex>
                  )}
                </div>
                {showPdf && (
                  <ReportModal
                    isOpen={showPdf}
                    handleClose={() => setShowPdf(false)}
                    documents={pdfDataToShow}
                    startDate={Utils.getStartDateOfMonth(currentPayrunDate?.split('-')[0],fromMonth)}
                    lastDate={Utils.getLastDateOfMonth(currentPayrunDate?.split('-')[0],toMonth)}
                    type={path || ""}
                  />
                )}
              </>
            )}
          </section>
        </>
      )}
    </div>
  );
};

export default PayslipReports;

