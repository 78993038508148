import React, { useEffect, useState } from "react";
// import Calender from "react-calendar";

import "./Calendar.css";
import EmployeeHeader from "./employeeHeader";
import { EmployeeService, LeaveService, S3Service } from "../../../_service";
import { useAppSelector } from "../../../_app";
import { Notify, Utils } from "../../../utils";
import { IRASTable } from "../../filing/CPFFilingStyles";
import { TableHeader } from "../../../components/table";
import { TableRow } from "../../payslip/PayslipStyles";
import { TableColumn } from "../../users/styles";
import { Calendar, momentLocalizer, Event } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { FilterBySelect } from "../component";

import styled from "styled-components";
import { CommonModal } from "../../../components";
import { Colors } from "../../../config/color";
import { Constant } from "../../../config";
import EmployeeListModal from "../../reports/EmployeeListModal/EmployeeListModal";

const localizer = momentLocalizer(moment);

const isWeekend = (date: Date) => [0, 6].includes(date.getDay());

const LeaveOverview = () => {
  const companyId = useAppSelector((state) => state.user.defaultCompnayId);
  const user = useAppSelector((state) => state.user);

  const [currentYear, setCurrentYear] = useState<any>(() =>
    new Date().getFullYear()
  );
  const [holidayList, setHolidayList] = useState<any[]>([]);
  const [leaveTaken, setLeaveTaken] = useState<string[]>([]);
  const [leaveTakenData, setLeaveTakenData] = useState<any>({});
  const [leaveData, setLeaveData] = useState<any[]>([]);
  const [filteredLeaveData, setFilteredLeaveData] = useState<any[]>([]);
  const [showLeaveDetails, setShowLeaveDetails] = useState<boolean>(false);
  const [dateToShow, setDateToShow] = useState<any>();
  const [status, setStatus] = useState("1");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isShowingHistory, setIsShowingHistory] = useState<boolean>(false);
  const [isShowingExpiry, setIsShowingExpiry] = useState<boolean>(false);

  const [showEmployeeModal, setShowEmployeeModal] = useState<boolean>(false);
  const [statusValue, setStatusValue] = useState<string>("1");
  const [selectedEmployeeList, setSelectedEmployeeList] = useState<any>([]);
  const [employeeList, setEmployeeList] = useState<any>([]);
  const [leaveDetails, setLeaveDetails] = useState<any>({});

  const detailsHeader = [
    {
      name: "Leave Type",
      width: "35%",
    },
    {
      name: "Employee Name",
      width: "35%",
    },
    {
      name: "Description",
      width: "30%",
    },
  ];

  const getLeaveList = async () => {
    try {
      const res = await S3Service._getCalenderLeaveList(currentYear);
      console.log("holiday", res?.data)

      let _holidayList = res.data?.map((item: any) => {
        return {
          ...item,
          allDay: true,
          isHoliday: true,
          employee_name: item?.Name ,
          start: moment(item.Date, "YYYY-MM-DD").toDate(),
          end: moment(item.Date, "YYYY-MM-DD").toDate(),
        };
      });

      console.log("updated holiday", _holidayList)

      setHolidayList(() => _holidayList);
    } catch (error) {
      console.error(error);
    }
  };

  const getOverview = async () => {
    try {
      const res = await LeaveService._getLeaveOverviewByCompany(
        companyId,
        `${currentYear}-01-01`,
        `${currentYear}-12-31`
      );
      // const keys = Object.keys(res.data.data);
      if (res.data.data?.length > 0) {
        let data = res.data.data?.map((item: any) => {
          return {
            ...item,
            allDay: true,
            start: moment(item.date, "YYYY-MM-DD").toDate(),
            end: moment(item.date, "YYYY-MM-DD").toDate(),
          };
        });
        
        // setLeaveTaken(() => keys);
        // setLeaveTakenData(() => data);
        setLeaveData(data);
      }
    } catch (error) {}
  };

  const showHistory = async () => {
    try {
      const res = await LeaveService._getLeaveOverviewByCompany(
        companyId,
        `${currentYear - 2}-01-01`,
        `${currentYear + 1}-12-31`
      );
      if (res.data.data?.length > 0) {
        let data = res.data.data?.map((item: any) => {
          return {
            ...item,
            allDay: true,
            start: moment(item.date, "YYYY-MM-DD").toDate(),
            end: moment(item.date, "YYYY-MM-DD").toDate(),
          };
        });
        
        // setLeaveTaken(() => keys);
        // setLeaveTakenData(() => data);
        setLeaveData(data);
        setIsShowingHistory(true);
      }
    } catch (error) {}
  };

  const getLeaveExpiryData = async () => {
    try {
      const res = await LeaveService._getLeaveOverviewByCompany(
        companyId,
        `${currentYear - 1}-01-01`,
        `${currentYear + 1}-12-31`
      );
      if (res.data.data?.length > 0) {
        let data = res.data.data?.map((item: any) => {
          return {
            ...item,
            allDay: true,
            start: moment(item.date, "YYYY-MM-DD").toDate(),
            end: moment(item.date, "YYYY-MM-DD").toDate(),
          };
        });
        
        // setLeaveTaken(() => keys);
        // setLeaveTakenData(() => data);
        setLeaveData(data);
        setIsShowingExpiry(true);
      }
      
    } catch (error) {}
  };

  useEffect(() => {
    getEmployeeList(statusValue)
    
  }, [statusValue]);
  
  useEffect(()=>{
    // getLeaveList();

    getOverview()
    .then(()=>getLeaveList());

  },[])

  const getFilteredLeaveData = (data : any) => {

    let updatedData = data.filter((item :any) => 
      selectedEmployeeList.includes(item?.employee)
      )

    setFilteredLeaveData(updatedData);

  }


  // disable date eg. holiday and weekend
  const isDisable = (date: Date) => isWeekend(date);
  // check date include in holiday list
  const isHolidayLeave = (date: Date): boolean =>
    holidayList.includes(Utils._date(date));

  const isLeaveTaken = (date: Date) => leaveTaken.includes(Utils._date(date));
  // const handleLeaveView = (date: any) => {
  //   if (date) {
  //     const formattedDate = Utils._date(date);
  //     setDateToShow(() => formattedDate);
  //     if (leaveTakenData[formattedDate]?.length > 0) {
  //       setShowLeaveDetails(() => true);
  //     } else {
  //       setShowLeaveDetails(() => false);
  //     }
  //   }
  // };
  // Customize tile content based on the date (view month) and Return null for other dates
  // const tileContent = ({ date, view }: any): React.ReactNode =>
  //   view === "month" && (isHolidayLeave(date) || isLeaveTaken(date)) ? (
  //     isLeaveTaken(date) ? (
  //       <div
  //         className="highlight-weekend"
  //         style={{ background: "pink" }}
  //         onClick={() => handleLeaveView(date)}
  //       >
  //         View Leave
  //       </div>
  //     ) : (
  //       <div className="highlight-weekend">Public Holiday</div>
  //     )
  //   ) : null;

  const dayPropGetter = (date: any) => {
    const momentDate = moment(date, "YYYY-MM-DD");
    const dayOfWeek = momentDate.day();
    
    const isHoliday = holidayList?.filter((holiday)=> {
      return holiday?.Date === momentDate.format("YYYY-MM-DD")
    })

    if(isHoliday.length > 0) {
      return {
        style: { backgroundColor: "#e6e6e6" },
      }
    } else if (dayOfWeek === 0 || dayOfWeek === 6) {
      return { style: { backgroundColor: "#e6e6e6" } };
    } else {
      return { style: {} };
    }
  };

  const eventPropGetter = (date: any) => {
    const momentDate = moment(date, "YYYY-MM-DD");
    const dayOfWeek = momentDate.day();
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      return { className: "custom-weekend-class" };
    } else {
      return { style: {} };
    }
  };



  const getEmployeeList = async (statusValue: string) => {
    try {
      const { data, status } = await EmployeeService._getEmployeeListByCompany(
        user.defaultCompnayId,
        statusValue
      );

      if (status === 200) {
        setEmployeeList(() => data.data);
        const empData = data.data.map((emp: any) => emp.id);
        setSelectedEmployeeList(() => empData);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  const handleSelectAll = () => {
    const allEmpIds = employeeList.map((emp: any) => emp.id);
    setSelectedEmployeeList(() => allEmpIds);
  };

  const handleSelectEmployee = (id: any) => {
    if (selectedEmployeeList.includes(id)) {
      let interArr = selectedEmployeeList;
      interArr = interArr.filter((em: any) => em !== id);
      setSelectedEmployeeList(() => interArr);
    } else {
      setSelectedEmployeeList(() => [...selectedEmployeeList, id]);
    }
  };

  const handleEmployeeSelectNone = () => {
    setSelectedEmployeeList(() => []);
  };

  useEffect(()=>{
    getFilteredLeaveData(leaveData)

  },[selectedEmployeeList, leaveData])



  console.log("here", holidayList, filteredLeaveData)
  return (
    <div>
      <div className="hero_header">
        <h2 className="employee-header-h2">{Utils.getTitleWithCompanyName("Calendar")}</h2>
      </div>
      <EmployeeHeader />

      <div style={{ marginBottom: "1.5rem" }}>
        {isShowingHistory ? (
          <p
            style={{
              color: "#333",
              fontSize: "1rem",
              fontWeight: 400,
              marginBottom: "0.5rem",
              lineHeight: 1.5,
            }}
          >
            Displaying all leave data.
          </p>
        ) : isShowingExpiry ? <p style={{
          color: "#333",
          fontSize: "1rem",
          fontWeight: 400,
          marginBottom: "0.5rem",
          lineHeight: 1.5,
        }} >{`Displaying data between ${currentYear - 1}-01-01 and ${currentYear + 1}-12-31.`}</p> : (
          <p
            style={{
              color: "#333",
              fontSize: "1rem",
              fontWeight: 400,
              marginBottom: "0.5rem",
              lineHeight: 1.5,
            }}
          >
            Displaying dates for 1 calendar year. Click{" "}
            <span style={linkStyle} onClick={showHistory}>
              show history
            </span>{" "}
            to view historic data.
          </p>
        )}
        <p>
          Click{" "}
          <span style={linkStyle} onClick={getLeaveExpiryData}>
            here
          </span>{" "}
          to view <span style={{fontWeight: 700}}>leave expiry</span> data.
        </p>
      </div>

      <div className="boder">
        {/* <Calender
          tileContent={tileContent}
          calendarType="US"
          tileDisabled={({ date }) => isDisable(date)}
          onChange={handleLeaveView}
        /> */}
        <Calendar
          localizer={localizer}
          events={[...filteredLeaveData, ...holidayList]}
          startAccessor="start"
          endAccessor="end"
          titleAccessor={"employee_name"}
          style={{ height: 570 }}
          views={["month", "week"]}
          tooltipAccessor={(event) => {
            return event.is_approved
              ? "Approved"
              : event.is_approved === false
              ? "Rejected"
              : "Pending";
          }}
          eventPropGetter={(event) => {
            const backgroundColor = 
            event.isHoliday
              ? "#e6e6e6"
              : event.is_approved === false
              ? "#FF6868"
              : event.is_approved
              ? "#74E291" : "#7BD3EA";
            
            const color = event.isHoliday ? "black" : "white";
            const fontWeight = event.isHoliday ? 'bold' : '600';
            return {
              style: {
                backgroundColor,
                color,
                fontWeight,
                padding: "0 0.3rem",
                fontSize: "14px",
              },
            };
          }}
          dayPropGetter={dayPropGetter}
          onSelectEvent={(event: any) => {
            setLeaveDetails(event);
            setShowLeaveDetails(true);
          }}

          // views={{
          //   agenda: false,
          // }}
        />
        {/* {showModal && (
          <CommonModal
            handleClose={() => setShowModal(false)}
            open={showModal}
            // loading={loading}
            updateData={() => console.log("no update")}
            isCancelButton={true}
            body={<EmployeeListComponent />}
            heading="Filter Employees"
            conformButtonText="Confirm"
            cancelButtonText="Close"
          />
        )} */}
      </div>

      {/* ----------- Filters ------------ */}

      {/* <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          marginTop: "0.75rem",
        }}
      >
        <FilterBySelect
          value={status}
          onChageOption={(text) => setStatus(text)}
          // marginBottom={0}
        />

        <Container>
          <Text>Employees</Text>
          <Text2 onClick={() => setShowModal(true)}>All</Text2>
        </Container>
      </div> */}

      <div style={{ display: "flex", gap: 50, marginTop: 20 }}>
        <div style={{ display: "flex" }}>
          <Container style={{ marginBottom: 20 }}>
            <Text>Current Status </Text>
            <Select
              value={statusValue}
              onChange={(e) => setStatusValue(e.target.value)}
            >
              <option value={"0"}>{Constant.common.all}</option>
              <option value={"2"}>{Constant.common.inactive}</option>
              <option value={"1"}>{Constant.common.active}</option>
            </Select>
          </Container>
        </div>
        <div style={{ display: "flex" }}>
          <Container style={{ marginBottom: 20 }}>
            <Text>Employees </Text>
            <Information onClick={() => setShowEmployeeModal(() => true)}>
              {selectedEmployeeList.length === 0
                ? "None"
                : selectedEmployeeList.length === employeeList.length
                ? "All"
                : `${selectedEmployeeList.length} of ${employeeList.length}`}
            </Information>
          </Container>
        </div>
      </div>

      {showLeaveDetails && (
        // <div>
        //   <IRASTable style={{ marginTop: 30 }}>
        //     <TableHeader headerColumns={detailsHeader} variant="filled" />
        //     <tbody>
        //       {leaveTakenData[Utils._date(dateToShow)]?.map((leave: any) => (
        //         <TableRow>
        //           <TableColumn>{leave.leave_name}</TableColumn>
        //           <TableColumn>{leave.employee_name}</TableColumn>
        //           <TableColumn>{leave.description}</TableColumn>
        //         </TableRow>
        //       ))}
        //     </tbody>
        //   </IRASTable>
        // </div>
        <div
          style={{
            padding: "1rem",
            margin: "1rem",
            background: "#f0f0f0",
          }}
        >
          <p
            style={{
              color: "#476575",
              fontSize: "1.75rem",
              fontWeight: 500,
              marginBottom: "0.5rem",
              lineHeight: 1.2,
            }}
          >
            {leaveDetails?.employee_name}
          </p>
          <p
            style={{
              color: "#333",
              fontSize: "1rem",
              fontWeight: 400,
              marginBottom: "1rem",
              lineHeight: 1.5,
            }}
          >
            {leaveDetails?.date}
          </p>
          <p
            style={{
              color: "#333",
              fontSize: "1rem",
              fontWeight: 400,
              marginBottom: "1rem",
              lineHeight: 1.5,
            }}
          >{`${leaveDetails?.leave_name}: 1 Day`}</p>
        </div>
      )}

      <CommonModal
        handleClose={() => {
          // getFilteredLeaveData(leaveData);
          setShowEmployeeModal(false);
        }}
        open={showEmployeeModal}
        // updateData={() => setShowEmployeeModal(false)}
        updateData={() => {
          // getFilteredLeaveData(leaveData);
          setShowEmployeeModal(false);
        }}
        body={
          <EmployeeListModal
            employeeList={employeeList}
            handleSelectAll={handleSelectAll}
            handleSelectNone={handleEmployeeSelectNone}
            selectedEmployeeList={selectedEmployeeList}
            handleSelectEmployee={handleSelectEmployee}
          />
        }
        heading="Filter Employees"
        conformButtonText="Close"
        isCancelButton={false}
      />
    </div>
  );
};

export default LeaveOverview;

const linkStyle = { fontWeight: "bold", color: "#059bbf", cursor: "pointer" };


const Container = styled.div`
  margin-bottom: 1rem;
  display: flex;
`;
const Text = styled.p`
  width: 110px;
  height: 30px;
  color: #fff;
  border: 1px solid #afb5ba;
  font-size: 0.8rem;
  background-color: #afb5ba;
  text-transform: none;
  display: flex;
  align-items: center;
  padding: 0 5px;
  border-radius: 3px 0 0 3px;
`;
const Select = styled.select`
  max-width: 100px;
  height: 30px;
  background-color: #087ca7;
  color: #fff;
  border-radius: 0 3px 3px 0;
  cursor: pointer;
  padding: 0 5px;
  text-align: center;
`;

const Information = styled.div`
  max-width: 100px;
  height: 30px;
  background-color: #087ca7;
  color: #fff;
  border-radius: 0 3px 3px 0;
  cursor: pointer;
  padding: 0 10px;
  text-align: center;
  display: flex;
  align-items: center;
`;



