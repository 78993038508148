import { URL } from "../config";
import apiClient from "./BaseService";

const _getPayslipReportDetails = async (
  companyId: string,
  month: string,
  year: string,
) =>
  apiClient.get(
    `${URL.GET_PAYSLIP_REPORTS}?month=${month}&company=${companyId}&year=${year}`,
  );

const _getCurrentPayslipReportDetails = async (
  companyId: string,
  fromDate: string,
  toDate: string,
) =>
  apiClient.get(
    `${URL.GET_PAYSLIP_REPORTS}?from_date=${fromDate}&to_date=${toDate}&company=${companyId}&code=ytd_report`,
  );

const _getCompanyPayslipReportDetails = async (
  companyId: string,
  fromDate: string,
  toDate: string,
) =>
  apiClient.get(
    `${URL.GET_PAYSLIP_REPORTS}?from_date=${fromDate}&to_date=${toDate}&company=${companyId}&code=ytd_company_report`,
  );

export const PayslipReportService = {
  _getPayslipReportDetails,
  _getCurrentPayslipReportDetails,
  _getCompanyPayslipReportDetails
};
