import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DullButton from "../../../components/buttons/DullButton";
import FormButton from "../../../components/buttons/FormButton";
import {
  CustomeCkeckboxField,
  CustomTextField,
} from "../../../components/customFormField";
import { Constant } from "../../../config";
import { Notify } from "../../../utils";
import { useAppSelector } from "../../../_app";
import { CompanyPayrollService } from "../../../_service";
import { ButtonContainer } from "../AppSettingsStyles";
import { CustomButton } from "../../../components/customButton";

const BasicPayrollSettings = () => {
  const [hourlyRate, setHourlyRate] = useState("");
  const [useWorkingDays, setUseWorkingDays] = useState(false);
  const [enableLeavePay, setEnableLeavePay] = useState(false);

  const [hourlyError, setHourlyError] = useState(false);

  const hourlyRatePattern = new RegExp("^[0-9]+$");

  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);

  const addBasicPayDetails = async () => {
    const formData = new FormData();

    if (!hourlyRatePattern.test(hourlyRate)) {
      Notify("Please Enter a Valid Hourly Rate", 0);
      setHourlyError(true);
      return;
    }

    const settingToSave = {
      hourly_rate: hourlyRate,
      use_working_days: useWorkingDays,
      enable_leave_pay: enableLeavePay,
    };

    formData.append("company", user.defaultCompnayId);
    formData.append("code", "payroll_calc_basic_pay");
    formData.append("name", "Payroll Calculation - Basic Pay");
    formData.append("setting", JSON.stringify(settingToSave));

    try {
      const { status } =
        await CompanyPayrollService._addBasicPaySetting(formData);

      if (status >= 200 && status < 300) {
        Notify("Setting added successfully", 1);
      }
    } catch (error) {
      Notify("Something went wrong, please try again", 1);
      setHourlyRate("");
      setEnableLeavePay(false);
      setUseWorkingDays(false);
    }
  };

  const getBasicPayDetails = async () => {
    try {
      if (user?.defaultCompnayId) {
        const { status, data } =
          await CompanyPayrollService._getPayrollSettings(
            "payroll_calc_basic_pay",
            parseInt(user?.defaultCompnayId),
          );
        if (status === 200) {
          if (data.data?.id) {
            setHourlyRate(data.data.setting?.hourly_rate || "");
            setUseWorkingDays(data.data.setting?.use_working_days || false);
            setEnableLeavePay(data.data.setting?.enable_leave_pay || false);
          }
        }
      }
    } catch (error) {
      Notify("Something went wrong, please try again", 1);
      setHourlyRate("");
      setEnableLeavePay(false);
      setUseWorkingDays(false);
    }
  };

  const changeHourlyRate = (value: string) => {
    setHourlyRate(value);
    if (value === "" || !hourlyRatePattern.test(value)) {
      setHourlyError(true);
    } else {
      setHourlyError(false);
    }
  };

  useEffect(() => {
    getBasicPayDetails();
  }, []);

  return (
    <div>
      {user?.defaultCompnayId ? (
        <>
          <CustomTextField
            title={Constant.settings.payrollCalculations.hourlyRateLabel}
            value={hourlyRate}
            onChangeText={changeHourlyRate}
            error={hourlyError ? "Please Enter a Valid Hourly Rate" : undefined}
          />
          <CustomeCkeckboxField
            title={Constant.settings.payrollCalculations.workingDaysLabel}
            value={useWorkingDays}
            onChange={() => setUseWorkingDays(() => !useWorkingDays)}
          />
          <CustomeCkeckboxField
            title={Constant.settings.payrollCalculations.enablePayLabel}
            value={enableLeavePay}
            onChange={() => setEnableLeavePay(() => !enableLeavePay)}
          />
          <p>{Constant.settings.payrollCalculations.hourlyPrompt}</p>
          <ButtonContainer>
            <CustomButton
              title={Constant.common.cancel}
              type="secondary"
              onClick={() => navigate("/dashboard/app-settings/employer-details")}
            />
            <CustomButton
              title={Constant.common.save}
              onClick={addBasicPayDetails}
            />
          </ButtonContainer>
        </>
      ) : (
        <p>Please add a company before you can add any details</p>
      )}
    </div>
  );
};

export default BasicPayrollSettings;
