import { useEffect, useRef, useState } from "react";
import {
  CustomButton,
  CustomSelectField,
  CustomTextField,
  NavigateBack,
} from "../../../components";
import { Constant } from "../../../config";
import { ButtonContainer, Heading } from "../../appSettings/AppSettingsStyles";
import TextEditor from "../../../components/editor";
import {
  AddTemplateContainer,
  EditorContainer,
  EditorLabel,
} from "./HRTemplateStyles";
import { useAppSelector } from "../../../_app";
import { Notify } from "../../../utils";
import { TemplateService } from "../../../_service";
import { useLocation, useNavigate } from "react-router-dom";

const AddHRTemplate = () => {
  const Typecategories = [
    { id: 9, name: 'Payroll' },
    { id: 1, name: 'HR matters' },
    { id: 2, name: 'Probation' },
    { id: 3, name: 'Self Service' },
    { id: 4, name: 'Instructions' },
    { id: 5, name: 'Leave' },
    { id: 6, name: 'Expense Claim' },
    { id: 7, name: 'TMS' },
    { id: 8, name: 'Others' }
  ]
  const [templateTitle, setTemplateTitle] = useState<string>("");
  const [templateCode, setTemplateCode] = useState<string>("");
  const [templateEmailSubject, setTemplateEmailSubject] = useState<string>("");
  const [emailContent, setEmailContent] = useState<any>("");
  const [hrCategory,setHrCategory] = useState<any>("");
  const companyId = useAppSelector((state) => state.user.defaultCompnayId)
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (content: any) => setEmailContent(() => content);

  const createTemplate = async (dataToSave: any) => {
    try {
      const { status } = await TemplateService._createTemplate(dataToSave);
      if (status === 201) {
        Notify("Template Added Successfully", 1);
        navigate("/dashboard/manage-template/hr")
      }
    }
    catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  }

  const updateTemplate = async (dataToSave: any) => {
    try {
      const { status } = await TemplateService._updateTemplate(location.state.hrTemplate.id, dataToSave);
      if (status === 200) {
        Notify("Template Updated Successfully", 1);
        navigate("/dashboard/manage-template/hr")
      }
    }
    catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  }

  const handleSubmit = async () => {
    if (!templateTitle) {
      Notify("Template Title is Required", 0);
      return;
    }
    if (!templateCode) {
      Notify("Template Code is Required", 0);
      return;
    }
    if (!templateEmailSubject) {
      Notify("Template Email Subject is Required", 0);
      return;
    }
    if (!emailContent) {
      Notify("Template Body is Required", 0);
      return;
    }
    let dataToSave = {};

    if (location.pathname.split("/").pop() === "edit") {
      dataToSave = {
        type: hrCategory,
        title: templateTitle,
        code: templateCode,
        email_subject: templateEmailSubject,
        email_body: emailContent,
      }
    } else {
      dataToSave = {
        type: hrCategory,
        title: templateTitle,
        code: templateCode,
        email_subject: templateEmailSubject,
        email_body: emailContent,
        companyId: companyId
      }
    }

    if (location.pathname.split("/").pop() === "edit") {
      updateTemplate(dataToSave)
    } else {
      createTemplate(dataToSave)
    }
  }

  useEffect(() => {
    if (location.pathname.split("/").pop() === "edit") {
      const data = location.state.hrTemplate;
      console.log(data.type, "___data" ,hrCategory)
      setTemplateTitle(() => data.title);
      setTemplateCode(() => data.code);
      setTemplateEmailSubject(() => data.email_subject);
      setEmailContent(() => data.email_body)
      setHrCategory(()=> data.type)
    }
  }, [])

  return (
    <>
      <AddTemplateContainer>
        <div style={{
          display: "flex",
          alignItems: "center",
          gap: "1rem"
        }}>
          <NavigateBack />
          <Heading>{location.pathname.split("/").pop() === "edit" ? Constant.templates.hr.add.editTitle : Constant.templates.hr.add.title}</Heading>
        </div>
        <CustomSelectField
          title="Type"
          value={hrCategory} 
          onChangeOption={setHrCategory}
          option={
            Typecategories.map((category, index) => ({
              key: category.name, // Display text
              value: category.name, 
            }))
          } 
          placeholder="Select a category"
        />
        <CustomTextField
          title={Constant.templates.hr.add.title_input}
          value={templateTitle}
          onChangeText={setTemplateTitle}
        />
        <CustomTextField
          title={Constant.templates.hr.add.template_code}
          value={templateCode}
          onChangeText={setTemplateCode}
          disabled={location.pathname.split("/").pop() === "edit" ? true : false}
        />
        <CustomTextField
          title={Constant.templates.hr.add.email_subject}
          value={templateEmailSubject}
          onChangeText={setTemplateEmailSubject}
        />
        <EditorContainer>
          <EditorLabel>{Constant.templates.hr.add.email_body}</EditorLabel>
          <TextEditor handleChange={handleChange} value={emailContent} />
        </EditorContainer>
      </AddTemplateContainer>
      <ButtonContainer>
        <CustomButton
          title="Cancel"
          type="secondary"
          onClick={() => navigate(-1)}
        />
        <CustomButton
          title={Constant.common.save}
          onClick={() => handleSubmit()}
        />
      </ButtonContainer>
    </>
  );
};

export default AddHRTemplate;
