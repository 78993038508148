import { useState } from "react";
import { CustomButton, CustomDateField, NavigateBack } from "../../components";
import { Notify, Utils } from "../../utils";
import { H3Heading } from "../appSettings/AppSettingsStyles";
import { ManageCPFCal } from "../../_service";
import { useNavigate } from "react-router-dom";
import { Constant } from "../../config";
import { microAgent } from "../../newRelicConfig";
import moment from "moment";
import { useAppSelector } from "../../_app";

const AddCPFPolicy = (props: any) => {
  const [effectiveFrom, setEffectiveFrom] = useState(Utils._date());
  const user = useAppSelector((state) => state.user);

  const navigate = useNavigate();

  const addCpfPolicy = async () => {
    try {
      const formData = new FormData();
      formData.append("effective_from", effectiveFrom);
      const { status } = await ManageCPFCal._addCpfPolicy(formData);
      if (status === 200) {
        console.log("CPF Policy");

        // firebase log here
        let key = "CPF Policy";
        let data = {
          user_id: user.id,
          name: user.name,
          // device_fingerprint: "using_client_js",
          event_date_time: moment().utc().format("DD/MM/YYYY hh:mm A"),
          platform: "web",
        };
        // logFirebaseEvent(key, data);
        microAgent.addPageAction(key, data)
        Notify("Policy Added Successfully", 1);
        navigate(-1);
      }
    } catch (e: any) {
      if (e?.response?.data?.detail) {
        Notify(e.response.data.detail, 0);
      } else {
        Notify(Constant.common.something_went_wrong, 0);
      }
    }
  };

  return (
    <div>
      <NavigateBack />
      <H3Heading>Add CPF Policy</H3Heading>
      <CustomDateField
        label="Effective From"
        date={effectiveFrom}
        setDate={setEffectiveFrom}
        startYear={new Date().getFullYear() - 1}
        endYear={new Date().getFullYear() + 10}
      />
      <CustomButton
        title="Add Policy"
        onClick={() => addCpfPolicy()}
        styles={{ marginTop: 50, marginLeft: 0 }}
      />
    </div>
  );
};

export default AddCPFPolicy;
