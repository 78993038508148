import React from "react";
import { useNavigate } from "react-router-dom";
import { FiAlertOctagon } from "react-icons/fi";
import { Typography, Box, Button } from "@mui/material";
import { useSelector } from "react-redux";

const AccessDenied: React.FC = () => {
  const navigate = useNavigate();

  const user = useSelector((state: any) => state.user);

  let isReadOnly = user.permission === "read";

  console.log('on403', user.permission === "read");

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
          bgcolor: "#ffffff",
          padding: 3,
          background: "linear-gradient(145deg, #ffffff 0%, #f5f5f5 100%)",
        }}
      >
        <Typography
          variant="h1"
          sx={{
        color: "#ff4444",
        fontSize: "8rem",
        fontWeight: "bold",
        textShadow: "2px 2px 4px rgba(0,0,0,0.1)",
          }}
        >
          403
        </Typography>
        {/* <FiAlertOctagon size={80} color="#ff4444" /> */}
        <Typography
          variant="h2"
          component="h1"
          gutterBottom
          sx={{
        mt: 2,
        color: "#333333",
        textShadow: "2px 2px 4px rgba(0,0,0,0.1)",
        fontWeight: "bold",
        letterSpacing: "2px",
          }}
        >
          Access Denied
        </Typography>
        {isReadOnly ? (
            <>
            <Typography
              component="h2"
              align="center"
              sx={{
              mb: 2,
              color: "#dc3545",
              maxWidth: "600px",
              fontSize: '1.75rem',
              textAlign: "center",
              fontWeight: "600",
              letterSpacing: "0.5px",
              // textTransform: "uppercase",
              // background: "linear-gradient(45deg, #A6AEBF 30%, #3f51b5 90%)",
              // WebkitBackgroundClip: "text",
              // WebkitTextFillColor: "transparent",
              textShadow: "1px 1px 2px rgba(0,0,0,0.05)"
              }}
            >
              You only have READ access for
            </Typography>
            <Typography
              component="div"
              align="center" 
              sx={{
              mb: 4,
              color: "#4C585B",
              fontSize: '1.5rem',
              fontWeight: "600",
              textTransform: "uppercase",
              letterSpacing: "1px",
              textShadow: "1px 1px 2px rgba(0,0,0,0.1)"
              }}
            >
              {user?.defaultCompany || 'No Company Selected'}
            </Typography>
            </>
        ) : (
          <Typography
            variant="h5"
            component="h2"
            align="center"
            sx={{
              mb: 4,
              color: "#dc3545",
              maxWidth: "600px",
              textAlign: "center",
              fontWeight: "600"
            }}
          >
            You don't have tab permission to access this page.
          </Typography>
        )}
        {/* <Button
          variant="contained"
          size="large"
          onClick={() => navigate("/")}
          sx={{
        background: "linear-gradient(45deg, #ff2222 30%, #ff6666 90%)",
        color: "white",
        padding: "12px 30px",
        
          }}
        >
          Return to Homepage
        </Button> */}
      </Box>
    </div>
  );
};

export default AccessDenied;

// 404 page
