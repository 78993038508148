import { useNavigate } from "react-router-dom";
import { IconContext } from "react-icons";
import { FaUserCircle } from "react-icons/fa";
import { BsQuestionCircle } from "react-icons/bs";

import "./style.css";
import { useAppSelector } from "../../_app";
import { useEffect, useState } from "react";
import SubmenuModal from "../modals/SubmenuModal";
import AuthHeader from "./AuthHeader";
import Logo from "../../assets/Logo.png";
import Tooltip from "../Tooltip";
import { RoleService } from "../../_service";
import { Notify } from "../../utils";
import { Constant } from "../../config";
import CustomTooltip from "../CustomTooltip";

const Header = () => {
  const navigate = useNavigate();

  const user = useAppSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [groups, setGroups] = useState([])
  const getUserGroups = async () => {
    try {
      const { data, status } = await RoleService._getRoles(0);

      if (status === 200) {
        setGroups(() =>
          data.map((dataObj: any) => ({
            name: dataObj.name,
          })),
        );
      }
    } catch (error) {
      console.log("error", error);
      // Notify(Constant.common.something_went_wrong, 0);
    }
  };

  useEffect(() => {
    getUserGroups();
  }, []);

  console.log("groups", groups);
  console.log("usergroup", user.groups)

  const handleSwitchCompany = () => {
    navigate("manage-company");
  };
  console.log("menu", user.groups);

  return (
    <div className="header-wrapper">
      <SubmenuModal open={open} close={() => setOpen(false)} />
      <div className="section">
        <p className="company-name">
          {user.defaultCompany ? user.defaultCompany : user.name}
        </p>
        <button className="company-btn" onClick={handleSwitchCompany}>
          switch
        </button>
      </div>
      <div className="section left">
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: ".5rem",
              width: 250,
            }}
          >
            <img
              style={{ width: "80%", margin: "auto" }}
              src={Logo}
              alt="salary pay logo"
            />
          </div>
          {/* <button className="company-btn" onClick={handleSwitchCompany} title="Switch Company">
            <p className="company-name">
              {user.defaultCompany ? user.defaultCompany : user.name}
            </p>
          </button> */}


        </div>
        <div
          style={{
            color: "#ebd369",
            textAlign: "center",
          }}
        >
          {/* User Email */}
          <p
            style={{
              fontSize: ".9rem",
              fontWeight: "600",
            }}
          >
            {user.email}
          </p>

          {/* User Groups */}
          <CustomTooltip title={user.groups.map((group: any) => group.name).join(", ")} placement={"bottom"} >

          <p style={{
            display: 'flex',
            textAlign : "center"

          }}>

            <span
              style={{
                fontSize: ".8rem",
                color: "#fff",
                alignItems : "center",
                paddingLeft : "15px"
              }}
            >
              {/* Display the first two groups */}
              {user.groups.slice(0, 3).map((group: any) => group.name).join(", ")}
            </span>
            {user.groups.length > 3 && (

                <span
                  style={{
                    fontSize: ".8rem",
                    color: "#fff",
                    width: 'max-content',
                    zIndex: 1,
                  }}
                >
                  
                  <p>....</p>
                </span>
                 )}
          </p>
              </CustomTooltip>


           
        </div>


        {/* <div className="navbar-icon-wrap">
          <IconContext.Provider value={{ color: "#BDBDBD", size: "1.5rem" }}>
            <BsQuestionCircle />
          </IconContext.Provider>
        </div> */}
        <div className="navbar-icon-wrap">
          <button className="no-btn" onClick={() => setOpen((_) => !_)}>
            <IconContext.Provider value={{ color: "#BDBDBD", size: "1.5rem" }}>
              <FaUserCircle />
            </IconContext.Provider>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
export { AuthHeader };
