import { NavLink, useNavigate } from "react-router-dom";
import { Heading } from "../../appSettings/AppSettingsStyles";
import { CustomButton } from "../../../components";
import { Utils } from "../../../utils";

const activeStyle = {
  color: "#495057 !important",
  borderBottom: "3px solid #25b481",
};

const DashboardHeader = () => {
  const navigate = useNavigate();
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Heading>{Utils.getTitleWithCompanyName("Request Dashboard")}</Heading>
        <CustomButton
          title="Add New Request"
          styles={{ height: 40 }}
          onClick={() => navigate("add")}
        />
      </div>
      <div className="employee-header">
        <NavLink
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
          className="employee-sublink"
          to="/dashboard/request/cso"
        >
          CSO
        </NavLink>
        <NavLink
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
          className="employee-sublink"
          to="/dashboard/request/data-entry"
        >
          Data Entry
        </NavLink>
        
        <NavLink
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
          className="employee-sublink"
          to="/dashboard/request/finalizer"
        >
          Finalizer
        </NavLink>
        <NavLink
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
          className="employee-sublink"
          to="/dashboard/request/reviewer"
        >
          Approver
        </NavLink>
        <NavLink
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
          className="employee-sublink"
          to="/dashboard/request/completed"
        >
          Completed
        </NavLink>
      </div>
    </>
  );
};

export default DashboardHeader;
