import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { Utils } from "../../../utils";
import { EmployeeService, PayrollService } from "../../../_service";
import { useAppSelector } from "../../../_app";
import { CustomSelectField, Loader, NavigateBack } from "../../../components";
import { Typography } from "@mui/material";
import { LoaderContainer } from "../../../CommonStyles";
import { Heading } from "../../appSettings/AppSettingsStyles";

const statusCode = [
  { "id": 7, "status_code": "", "status_name": "ALL" },
  { "id": 1, "status_code": "not_applicable", "status_name": "NOT APPLICABLE" },
  { "id": 2, "status_code": "early", "status_name": "EARLY" },
  { "id": 3, "status_code": "extended", "status_name": "EXTENDED" },
  { "id": 4, "status_code": "failed", "status_name": "FAILED" },
  { "id": 5, "status_code": "confirmed", "status_name": "CONFIRMED" },
  { "id": 6, "status_code": "ongoing", "status_name": "ONGOING" }
]


const ProbationReport = () => {
  const companyId = useAppSelector((state) => state.user.defaultCompnayId);
  const [reportData, setReportData] = useState<any[]>([]);
  const [selectedStatus, setSelectedStatus] = useState("");


  const [isLoading, setIsLoading] = useState(false);

  const date = new Date();

  const currentPayrunDate = useAppSelector(
    (state) => state?.user?.current_payrun_date
  );

  const handleChange = (event: any) => {
    setSelectedStatus(event.target.value);
  };
  const [month, setMonth] = useState<string>(`${date.getMonth() + 1}`);
  const [year, setYear] = useState<string>(
    `${currentPayrunDate?.split("-")[0]}`
  );

  const yearOptions = Utils.getDefaultYearRange(
    parseInt(currentPayrunDate?.split("-")[0])
  );

  const monthsOptions = Utils.MONTH_OPTIONS;
  const fromDate = moment([year, parseInt(month) - 1])
    .startOf("month")
    .format("YYYY-MM-DD");

  const toDate = moment([year, parseInt(month) - 1])
    .endOf("month")
    .format("YYYY-MM-DD");

  const getReportData = async () => {
    if (companyId) {
      try {
        setIsLoading(true);
        const response = await PayrollService._getProbationPendingReport(
          companyId,
          selectedStatus
        );
        console.log("theResponse", response);
        if (response.status >= 200 && response.status < 300) {
          setReportData(response?.data?.data);
          setIsLoading(false);
        }
      } catch (error: any) {
        Utils.showErrorMessage(error);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    getReportData();
  }, [companyId, selectedStatus]);

  console.log("thedata", reportData);

  console.log("dates", fromDate, toDate);

  return (
    <>
      <div style={{
        display: "flex",
        alignItems: "center",
        gap: ".5rem"
      }}>

        <NavigateBack />
        <Heading>{Utils.getTitleWithCompanyName("Records of All Probation Records Listing")}</Heading>

      </div>


      {/* Filter Section */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >

        <div>{""}</div>

        <div>
          <select
            style={{
              padding: "4px 6px",
              fontSize: "16px",
              border: "1px solid #ccc",
              cursor: "pointer",
              backgroundColor: "#fff",
              outline: "none",
            }}
            value={selectedStatus} onChange={handleChange}>
            <option value="" disabled>Filter by Status</option>
            {statusCode.map((status) => (
              <option key={status.id} value={status.status_code}>
                {status.status_name}
              </option>
            ))}
          </select>
        </div>
      </div>

      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <TableContainer component={Paper} sx={{ boxShadow: 3, marginTop: 2 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    backgroundColor: "#61677a",
                    color: "white",
                    padding: "0.75rem",
                  }}
                >
                  SPC
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    backgroundColor: "#61677a",
                    color: "white",
                    padding: "0.75rem",
                  }}
                >
                  Employee Name
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    backgroundColor: "#61677a",
                    color: "white",
                    padding: "0.75rem",
                  }}
                >
                  Appointment Date
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    backgroundColor: "#61677a",
                    color: "white",
                    padding: "0.75rem",
                    textAlign: "center",
                  }}
                >
                  Probation Months Count
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    backgroundColor: "#61677a",
                    color: "white",
                    padding: "0.75rem",
                  }}
                >
                  Last Date of Probation
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    backgroundColor: "#61677a",
                    color: "white",
                    padding: "0.75rem",
                  }}
                >
                  Live
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    backgroundColor: "#61677a",
                    color: "white",
                    padding: "0.75rem",
                  }}
                >
                  Probation Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reportData?.length > 0 ? (
                reportData?.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      "&:nth-of-type(odd)": { backgroundColor: "#f5f5f5" },
                      "&:hover": { backgroundColor: "#eeeeee" },
                    }}
                  >
                    <TableCell sx={{ padding: "0.75rem" }}>
                      {row?.spc_code || ""}
                    </TableCell>
                    <TableCell sx={{ padding: "0.75rem" }}>
                      {row?.employee_name || ""}
                    </TableCell>
                    <TableCell sx={{ padding: "0.75rem" }}>
                      {moment(row?.service_start_date, "YYYY-MM-DD").format(
                        "DD-MM-YYYY"
                      ) || ""}
                    </TableCell>
                    <TableCell sx={{ padding: "0.75rem", textAlign: "center" }}>
                      {row?.probation_months || "-"}
                    </TableCell>
                    <TableCell sx={{ padding: "0.75rem" }}>
                      {moment(row?.probation_last_date, "YYYY-MM-DD").format(
                        "DD-MM-YYYY"
                      ) || ""}
                    </TableCell>
                    <TableCell sx={{ padding: "0.75rem", textAlign: "left" }}>
                      {row?.service_end_date === null ? "✅" : "❌"}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.75rem",
                        textTransform: "capitalize",
                        fontWeight: 600,
                        textAlign: "center"
                      }}
                    >
                      <div
                        style={{
                          backgroundColor:
                            row?.probation_status === "early"
                              ? "#03B381"
                              : row?.probation_status === "extended"
                                ? "#ff6665"
                                : "#3498db",
                          borderRadius: "4px",
                          padding: "0.35rem 0.75rem",
                          maxWidth: "fit-content",
                          minWidth: "40%",
                        }}
                      >
                        <p
                          style={{
                            color: "white",
                            margin: 0,
                            textAlign: "center",
                          }}
                        >
                          {row?.probation_status.split("_").join(" ") || ""}
                        </p>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={7}
                    sx={{ textAlign: "center", padding: "2rem" }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: "#666",
                        fontStyle: "italic",
                        fontSize: "1rem",
                      }}
                    >
                      No pending probation records found
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default ProbationReport;
