import React, { useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
  CompanyService,
  EmployeeService,
  HelperService,
  PayrollService,
  UserService,
} from "../../_service";
import validator from "validator";
import { Notify, Utils, Validate } from "../../utils";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../_app";
import {
  ConfirmationModalInterface,
  PayFrequencyInterface,
} from "../../_types";
import {
  ConfirmationModal,
  FormErrorMessage,
  Loader,
  Tooltip,
} from "../../components";
import {
  CustomDateField,
  CustomGroupSelectField,
  CustomNumberField,
  CustomSelectField,
  CustomTextField,
  CustomeCkeckboxField,
} from "../../components/customFormField";
import { CustomButton } from "../../components/customButton";
import { updateCurrentEmployee } from "../../_features/employee";
import { ButtonContainer, H3Heading } from "../appSettings/AppSettingsStyles";
import { TableHeader } from "../../components/table";
import { IRASTable } from "../filing/CPFFilingStyles";
import { TableRow } from "../payslip/PayslipStyles";
import { TableColumn } from "../users/styles";
import {
  ChangeDetailContainer,
  ChangeDetailHeadingContainer,
  ChangeDetailInfoContainer,
} from "./EployeeReqestStyles";
import { DashboardService } from "../../_service/DashboardService";
import { FaFileUpload } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import moment from "moment";


const EditInfoUpdate = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const employee = useAppSelector((state) => state.employee);
  const companyId = useAppSelector((state) => state.user.defaultCompnayId);

  const id = useAppSelector((state) => state.employee.employee_info.id);

  const user = useAppSelector((state) => state.user);

  const [response, setResponse] = useState<any>();
  const [is_contain_finlaized_payslip, setIs_contain_finlaized_payslip] =
    useState(false);

  const [isRequestCheck, setIsRequestCheck] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [startDate1, setStartDate1] = useState(null);
  const [loading, setLoading] = useState(false);
  const [payFrequencyVal, setPayFrequencyVal] = useState<string>("");
  const [is_deduction_except, setIs_deduction_except] = useState(false);
  const [cpfDeduction, setCpfDeduction] = useState(false);
  const [legalName, setLegalName] = useState("");
  const [legalStatus, setLegalStatus] = useState<any>([]);
  const [isShgDedcution, setIsShgDeduction] = useState(false);

  const [employeeId, setEmployeeId] = useState(null);

  const [legalStatusSelected, setLegalStatusSelected] = useState("");
  const [addressTypeSelected, setAddressTypeSelected] = useState("");

  const [countryList, setCountryList] = useState<any>([]);
  const [raceList, setRaceList] = useState<any>([]);
  const [raceData, setRaceData] = useState([]);
  const [relisionList, setRelisionList] = useState<any>([]);
  const [payMethods, setPayMethods] = useState<any>([]);
  const [startPermanentResident, setStartPermanentResident] = useState<any>(
    new Date()
  );
  const [email, setEmail] = useState<string>("")

  const [nric, setNric] = useState("");
  const [nationality, setNationality] = useState("");
  const [fin, setFin] = useState("");
  const [malaysianIC, setMalaysianIC] = useState("");
  const [passport, setPassport] = useState("");

  const [blockNo, setBlockNo] = useState("");
  const [street, setStreet] = useState("");
  const [level, setLevel] = useState("");
  const [unit, setUnit] = useState("");

  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const [sdl, setSdl] = useState(false);
  const [cpf, setCpf] = useState(false);
  const [dual_shg_deduction, set_Dual_shg_deduction] = useState(false);

  const [bankList, setBankList] = useState([]);
  const [addressType, setAddressType] = useState<any>([]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nameFormat, setNameFormat] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [gender, setGender] = useState("");
  const [race, setRace] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [religion, setReligion] = useState("");
  const [phone, setphone] = useState("");

  const [bankValue, setBankValue] = useState("");

  const [company1,setCompany1]=useState("");
  const [employee1,setEmployee1]=useState("");
  const [isProbationConfirmed,setIsProbationConfirmed]=useState();
  // const [probationConfirmedBy,setProbationConfirmedBy]=useState();
  const [probationConfirmedDate,setProbationConfirmedDate]=useState();
  const [probationLastDate,setProbationLastDate]=useState();
  const[probationMonths,setProbationMonths]=useState("");
  // const[serviceEndDate,setServiceEndDate]=useState();
  // const[serviceStartDate,setServiceStartDate]=useState();
  

  // old state
  const [oldPayFrequencyVal, setOldPayFrequencyVal] = useState<string>("");
  const [oldPostalCode, setOldPostalCode] = useState("");
  const [oldLegalName, setOldLegalName] = useState("");
  const [oldIsShgDedcution, setOldIsShgDeduction] = useState(false);

  const [oldLegalStatusSelected, setOldLegalStatusSelected] = useState("");
  const [oldAddressTypeSelected, setOldAddressTypeSelected] = useState("");

  const [oldStartDate, setOldStartDate] = useState(null);
  const [oldStartDate1, setOldStartDate1] = useState(null);
  const [oldStartPermanentResident, setOldStartPermanentResident] =
    useState<any>(new Date());

  const [oldNric, setOldNric] = useState("");
  const [oldNationality, setOldNationality] = useState("");
  const [oldFin, setOldFin] = useState("");
  const [oldMalaysianIC, setOldMalaysianIC] = useState("");
  const [oldPassport, setOldPassport] = useState("");

  const [oldBlockNo, setOldBlockNo] = useState("");
  const [oldStreet, setOldStreet] = useState("");
  const [oldLevel, setOldLevel] = useState("");
  const [oldUnit, setOldUnit] = useState("");

  const [oldAddress1, setOldAddress1] = useState("");
  const [oldAddress2, setOldAddress2] = useState("");
  const [oldAddress3, setOldAddress3] = useState("");
  const [oldCountryCode, setOldCountryCode] = useState("");

  const [oldSdl, setOldSdl] = useState(false);
  const [oldCpf, setOldCpf] = useState(false);
  const [old_Dual_shg_deduction, setOld_Dual_shg_deduction] = useState(false);

  const [oldFirstName, setOldFirstName] = useState("");
  const [oldLastName, setOldLastName] = useState("");
  const [oldNameFormat, setOldNameFormat] = useState("");
  const [oldJobTitle, setOldJobTitle] = useState("");
  const [oldGender, setOldGender] = useState("");
  const [oldRace, setOldRace] = useState("");
  const [oldPaymentMethod, setOldPaymentMethod] = useState("");
  const [oldReligion, setOldReligion] = useState("");
  const [oldPhone, setOldPhone] = useState("");
  const [oldEmail, setOldEmail] = useState("");

  const [oldBankValue, setOldBankValue] = useState("");
  const [oldBranchCode, setOldBranchCode] = useState("");
  const [oldAlphaNumericInput, setOldAlphaNumericInput] = useState("");
  const [changeObject, setChangeObject] = useState<any>([]);

  const [message, setMessage] = useState("");
  const [fileUrl, setFileUrl] = useState<any>(null);
  const [isFileUpload, setIsFileUpload] = useState(false);
  const fileInputRef = useRef(null);

  const [oldCompany1,setOldCompany1]=useState("");
  const [oldEmployee1,setOldEmployee1]=useState("");
  const [oldisProbationConfirmed,setOldIsProbationConfirmed]=useState();
  const [oldprobationConfirmedBy,setOldProbationConfirmedBy]=useState();
  const [oldprobationConfirmedDate,setOldProbationConfirmedDate]=useState();
  const [oldprobationLastDate,setOldProbationLastDate]=useState();
  const[oldprobationMonths,setOldProbationMonths]=useState("");
  const[oldserviceEndDate,setOldServiceEndDate]=useState();
  const[oldserviceStartDate,setOldServiceStartDate]=useState();
  

  const headerColumn = [
    {
      name: "Field",
      width: "50%",
    },
    {
      name: "Old Value",
      width: "25%",
    },
    {
      name: "New Value",
      width: "25%",
    },
  ];

  const [error, setError] = useState(false);
  const [errorCollection, setErrorCollection] = useState<{
    [key: string]: any;
  }>({});
  const [payFrequency, setPayFrequency] = useState<
    PayFrequencyInterface[] | null
  >(null);

  const handleNavigateBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const option = legalStatusSelected;
    if (option == "1" || option == "2") {
      setIs_deduction_except(false);
      setCpf(false);
      setCpfDeduction(false);
      setRaceList(() => raceData);
    } else if (option !== "") {
      setRaceList(() => raceList?.filter((i: any) => i.id == 5));
      setRace(() => "5");
      setIs_deduction_except(true);
      setCpf(true);
      setCpfDeduction(true);
    }
  }, [legalStatusSelected]);

  const updatedErrorByKey = (key: string, value: string = "") => {
    if (value === "") {
      let err = errorCollection;
      delete err[`${key}`];
      setErrorCollection(err);
    } else {
      setErrorCollection((prev) => ({ ...prev, [key]: value }));
    }
  };
  const handleLegalNameFormat = (e: any) => {
    setLegalName(e);
  };

  const handleAddressType = (e: any) => {
    setAddressTypeSelected(e);
  };

  const get = async () => {
    getEmployee();

    const LegalStatus = await HelperService._getLegalStatus();
    const countryList = await HelperService._getCountry();
    const raceList = await HelperService._getRace();
    const relisionList = await HelperService._getReligion();
    const payMethods = await HelperService._getPaymentMethods();
    const bankList = await HelperService._getBankList();
    const addressType = await HelperService._getAddressTypes();

    CompanyService._getPayFrequency(companyId).then((data) => {
      if (data.length > 0) {
        setPayFrequency(data);
      }
    });

    // legal status
    setLegalStatus(() => LegalStatus.data);

    // get country name
    setCountryList(() => countryList.data);

    // get race
    setRaceList(() => raceList.data);
    setRaceData(() => raceList.data);

    //get relision
    setRelisionList(() => relisionList.data);

    //get pay Methods
    setPayMethods(() => payMethods.data);

    //get bank list
    setBankList(() => bankList.data);

    // get address type
    setAddressType(() => addressType.data);

    setLoading(false);
  };

  const getEmployee = async () => {
    try {
      setLoading(true);
      const res = await PayrollService._getEmployeeDataById(`${user.employee_id}`);
      if (res.status === 200) {
        setResponse(() => res.data);
        setIs_contain_finlaized_payslip(
          () => res.data?.is_contain_finlaized_payslip || false
        );
        setAddress1(() => res.data?.address_data?.[0]?.address_line_1 || "");
        setAddress2(() => res?.data?.address_data?.[0]?.address_line_2 || "");
        setAddress3(() => res?.data?.address_data?.[0]?.address_line_3 || "");
        setBlockNo(() => res?.data?.address_data?.[0]?.block_no || "");
        setCountryCode(() => res?.data?.address_data?.[0]?.country || "");
        setLevel(() => res?.data?.address_data?.[0]?.level_no || "");
        setPostalCode(() => res?.data?.address_data?.[0]?.postal_code || "");
        setStreet(() => res?.data?.address_data?.[0]?.street_name);
        setUnit(() => res?.data?.address_data?.[0]?.unit_no || "");

        setFin(() => res?.data?.fin || "");
        setMalaysianIC(() => res?.data?.malaysin_ic || "");
        setNationality(() => res?.data?.nationality || "");
        setNric(() => `${res?.data?.nric}` || "");
        setPassport(() => res?.data?.passport || "");
        setStartPermanentResident(() =>
          res.data?.start_permanent_date ? res.data.start_permanent_date : null
        );

        setProbationMonths(()=>res?.data?.service_period_info[res?.data?.service_period_info?.length - 1]?.probation_months );
        setProbationLastDate(()=>res?.data?.service_period_info[res?.data?.service_period_info?.length - 1]?.probation_last_date || "");
        // setIsProbationConfirmed(()=>res?.data?.service_period_info[res?.data?.service_period_info?.length - 1]?.is_probation_confirmed || "");
        // setServiceStartDate(()=>res?.data?.service_period_info[res?.data?.service_period_info?.length - 1]?.service_start_date || "");
        // setServiceEndDate(()=>res?.data?.service_period_info[res?.data?.service_period_info?.length - 1]?.service_end_date || "");
        setProbationConfirmedDate(()=>res?.data?.service_period_info[res?.data?.service_period_info?.length - 1]?.probation_confirmed_date || "");
        // setCompany1(()=>res?.data?.service_period_info[res?.data?.service_period_info?.length - 1]?.company || "");
        // setEmployee1(()=>res?.data?.service_period_info[res?.data?.service_period_info?.length - 1]?.employee || "");


        setPayFrequencyVal(() => res?.data?.pay_frequency || "");
        setNameFormat(() => res?.data?.name_format || "");
        setLegalName(() => res?.data?.name_format || "");
        setFirstName(() => res?.data?.first_name || "");
        setLastName(() => res?.data?.last_name || "");
        setStartDate(() => res?.data?.date_of_birth);
        // setStartDate1(() => res?.data?.date_of_appointment);
        setStartDate1(() => res?.data?.service_period_info[res?.data?.service_period_info?.length - 1]?.service_start_date);

        setLegalStatusSelected(() => `${res.data?.legal_status}` || "");
        setIsShgDeduction(() => res?.data?.is_shg_deduction);
        setGender(() => res?.data?.gender || "");
        setSdl(() => res?.data?.is_sdl);
        setCpf(() => res?.data?.is_cpf);
        set_Dual_shg_deduction(() => res?.data?.is_dual_shg_deduction);
        setJobTitle(() => res?.data?.job_title || "");
        setRace(() => `${res?.data?.race}` || "");
        setReligion(() => res?.data?.religion || "");
        setAddressTypeSelected(
          () => `${res.data?.address_data?.[0]?.address_type || ""}`
        );
        setphone(() => res?.data?.phone || "");
        setEmail(() => res?.data?.email || "");

        setBankValue(() => res?.data?.bank_name || "");
        setAlphaNumericInput(() => res?.data?.bank_account_number || "");
        setBranchCode(() => res?.data?.bank_branch_code || "");
        setPaymentMethod(() => res?.data?.payment_method || "");

        setOldAddress1(() => res.data?.address_data?.[0]?.address_line_1 || "");
        setOldAddress2(() => res?.data?.address_data?.[0]?.address_line_2 || "");
        setOldAddress3(() => res?.data?.address_data?.[0]?.address_line_3 || "");
        setOldBlockNo(() => res?.data?.address_data?.[0]?.block_no || "");
        setOldCountryCode(() => res?.data?.address_data?.[0]?.country || "");
        setOldLevel(() => res?.data?.address_data?.[0]?.level_no || "");
        setOldPostalCode(() => res?.data?.address_data?.[0]?.postal_code || "");
        setOldStreet(() => res?.data?.address_data?.[0]?.street_name);
        setOldUnit(() => res?.data?.address_data?.[0]?.unit_no || "");

        setOldFin(() => res?.data?.fin || "");
        setOldMalaysianIC(() => res?.data?.malaysin_ic || "");
        setOldNationality(() => res?.data?.nationality || "");
        setOldNric(() => `${res?.data?.nric}` || "");
        setOldPassport(() => res?.data?.passport || "");
        setOldStartPermanentResident(() =>
          res.data?.start_permanent_date ? res.data.start_permanent_date : null
        );

        setOldPayFrequencyVal(() => res?.data?.pay_frequency || "");
        setOldNameFormat(() => res?.data?.name_format || "");
        setOldLegalName(() => res?.data?.name_format || "");
        setOldFirstName(() => res?.data?.first_name || "");
        setOldLastName(() => res?.data?.last_name || "");
        setOldStartDate(() => res?.data?.date_of_birth);
        setOldStartDate1(() => res?.data?.date_of_appointment);

        setOldLegalStatusSelected(() => `${res.data?.legal_status}` || "");
        setOldIsShgDeduction(() => res?.data?.is_shg_deduction);
        setOldGender(() => res?.data?.gender || "");
        setOldSdl(() => res?.data?.is_sdl);
        setOld_Dual_shg_deduction(() => res?.data?.is_dual_shg_deduction);
        setOldCpf(() => res?.data?.is_cpf);
        setOldJobTitle(() => res?.data?.job_title || "");
        setOldRace(() => `${res?.data?.race}` || "");
        setOldReligion(() => res?.data?.religion || "");
        setOldAddressTypeSelected(
          () => `${res.data?.address_data?.[0]?.address_type || ""}`
        );
        setOldPhone(() => res?.data?.phone || "");
        setOldEmail(() => res?.data?.email || "")

        setOldBankValue(() => res?.data?.bank_name || "");
        setOldAlphaNumericInput(() => res?.data?.bank_account_number || "");
        setOldBranchCode(() => res?.data?.bank_branch_code || "");
        setOldPaymentMethod(() => res?.data?.payment_method || "");


        setOldProbationMonths(()=>res?.data?.service_period_info?.probation_months || "");
        setOldProbationLastDate(()=>res?.data?.service_period_info?.probation_last_date || "");
        setOldIsProbationConfirmed(()=>res?.data?.service_period_info?.is_probation_confirmed || "");
        setOldServiceStartDate(()=>res?.data?.service_period_info?.service_start_date || "");
        setOldServiceEndDate(()=>res?.data?.service_period_info?.service_end_date || "");
        setOldProbationConfirmedDate(()=>res?.data?.service_period_info?.probation_confirmed_date || "");
        setOldCompany1(()=>res?.data?.service_period_info?.company || "");
        setOldEmployee1(()=>res?.data?.service_period_info?.employee || "");

  
      }
    } catch (e: any) {
      setLoading(false);
      // if (e.response.status == 401) {
      //   disaptch(resetUser());
      // }
      Notify("Someting Went Wrong", 0)
    }
  };
  useEffect(() => {
    get();
    getEmployeeIdFromCompany();
  }, []);

  const validatePhone = (e: string) => {
    if (e.length <= 8) {
      setphone(e);
    }
  };
  const handlePhoneValidation = () => {
    let phoneValid = Validate.phoneValidate(phone);
    if (phoneValid) {
      updatedErrorByKey("phone", phoneValid);
    } else {
      updatedErrorByKey("phone");
    }
  };
  const handleFileChange = () => {
    const file = (fileInputRef.current as any).files?.[0];
    if (file) {
      setFileUrl(() => file);
    }
  };
  const [alphaNumericInput, setAlphaNumericInput] = useState("");
  const [accountNoError, setAccountNoError] = useState(false);
  const checkAccountNoAlphanumeric = (value: any) => {
    var regEx = /^[0-9a-zA-Z]+$/;
    if (value.match(regEx)) {
      setAccountNoError(true);
    } else {
      setAccountNoError(false);
    }
  };

  const [branchCode, setBranchCode] = useState("");
  const [branchCodeError, setBranchCodeError] = useState(false);
  const checBranchCodeAlphanumeric = (value: any) => {
    var regEx = /^[0-9a-zA-Z]+$/;
    if (value.match(regEx)) {
      setBranchCodeError(true);
    } else {
      setBranchCodeError(false);
    }
  };

  const [postalCode, setPostalCode] = useState("");
  const [postalCodeError, setPostalCodeError] = useState(false);

  const checkPostalCodeNoAlphanumeric = (value: any) => {
    var regEx = /^[0-9a-zA-Z]+$/;
    if (value.match(regEx)) {
      setPostalCodeError(true);
    } else {
      setPostalCodeError(false);
    }
  };
  const [confirmationModalContent, setConfirmationModalContent] =
    useState<null | ConfirmationModalInterface>(null);
  const handleNRICValidate = async () => {
    try {
      if (nric !== response?.nric) {
        const res = await EmployeeService._validateEmployeeData(`nric=${nric}`);
        if (res.status == 200) {
          if (res?.data?.id) {
            setConfirmationModalContent({
              content: `This NRIC already exists with an Employee.`,
              cancelText: "Cancel",
              resetContent: () => {
                setConfirmationModalContent(null);
                setNric(() => response?.nric || "");
              },
            });
          }
        }
      }
    } catch (e: any) {
      console.error(e.response.data.is_valid, "error");
      let nricValid = Validate.validateNRIC(nric);
      if (nricValid) {
        updatedErrorByKey("nric", nricValid);
      } else {
        updatedErrorByKey("nric", "NRIC is not valid");
      }
      if (e.response.status == 401) {
        // disaptch(resetUser());
      }
    }
  };
  const handleFINValidate = async () => {
    try {
      if (fin !== response?.fin) {
        const res = await EmployeeService._validateEmployeeData(`fin=${fin}`);
        if (res.status == 200) {
          if (res.data.fin.exists == true) {
            if (res?.data?.id) {
              setConfirmationModalContent({
                content: `This FIN number already exists with an Employee.`,
                cancelText: "Cancel",
                resetContent: () => {
                  setConfirmationModalContent(null);
                  setFin(() => response?.fin || "");
                },
              });
            }
          }
        }
      }
    } catch (e: any) {
      let finValid = Validate.validateFIN(fin);
      if (finValid) {
        updatedErrorByKey("fin", finValid);
      } else {
        updatedErrorByKey("fin", "Fin is not valid");
      }
      if (e.response.status == 401) {
        // disaptch(resetUser());
      }
    }
  };

  const sendChangeRequest = async () => {
    const requestJsonData = [
      {
        name: "Company Name",
        value: user.defaultCompany,
      },
      {
        name: "Employee Name",
        value: user.name,
      },
    ];
    requestJsonData.push(...changeObject);
    const formData:any = new FormData();

    formData.append("company", user.defaultCompnayId);
    formData.append("request_type", "IU");
    formData.append("name", user.name);
    formData.append("email", user.email);
    formData.append("employee", employeeId);
    formData.append("json_data", JSON.stringify(requestJsonData));
    formData.append("message", message);
    if(fileUrl) {
      formData.append("file_upload", fileUrl);
    }

    const { status } = await DashboardService._submitRequest(formData);

    if (status === 201) {
      Notify("Request Added Successfully", 1);
      navigate(-1);
    }
  };

  const getEmployeeIdFromCompany = async () => {
    try {
      const { status, data } = await UserService._getEmployeeIdFromCompany(
        companyId
      );
      if (status >= 200 && status < 300) {
        // dispatch(
        //   updateUserIntialState({
        //     employee_id: data.data.employee_id,
        //   })
        // );
        setEmployeeId(data?.data?.employee_id);
      }
    } catch {
      // Notify("Someting Went Wrong", 0);
    }
  };

  const checkChangedValue = () => {
    const changeArr = changeObject;
    if (firstName !== oldFirstName) {
      const changeObj: any = {
        name: "First Name",
        old: oldFirstName,
        new: firstName,
      };
      changeArr.push(changeObj);
    }
    if (lastName !== oldLastName) {
      const changeObj: any = {
        name: "Last Name",
        old: oldLastName,
        new: lastName,
      };
      changeArr.push(changeObj);
    }
    if (startDate !== oldStartDate) {
      const changeObj: any = {
        name: "Date of Birth",
        old: oldStartDate,
        new: startDate,
      };
      changeArr.push(changeObj);
    }    
    if (startDate1 !== oldStartDate1) {
      const changeObj: any = {
        name: "Date of Appointment",
        old: oldStartDate1,
        new: startDate1,
      };
      changeArr.push(changeObj); 
    }
 
    if (branchCode !== oldBranchCode) {
      const changeObj: any = {
        name: "Branch Code",
        old: oldBranchCode,
        new: branchCode,
      };
      changeArr.push(changeObj);
    }
    if (alphaNumericInput !== oldAlphaNumericInput) {
      const changeObj: any = {
        name: "Account Number",
        old: oldAlphaNumericInput,
        new: alphaNumericInput,
      };
      changeArr.push(changeObj);
    }
    if (jobTitle !== oldJobTitle) {
      const changeObj: any = {
        name: "Job Title",
        old: oldJobTitle,
        new: jobTitle,
      };
      changeArr.push(changeObj);
    }
    if (phone !== oldPhone) {
      const changeObj: any = {
        name: "Phone No.",
        old: oldPhone,
        new: phone,
      };
      changeArr.push(changeObj);
    }
    if (cpf !== oldCpf) {
      const changeObj: any = {
        name: "CPF exempt.",
        old: oldCpf,
        new: cpf,
      };
      changeArr.push(changeObj);
    }
    if (sdl !== oldSdl) {
      const changeObj: any = {
        name: "SDL exempt.",
        old: oldSdl,
        new: sdl,
      };
      changeArr.push(changeObj);
    }
    if (address1 !== oldAddress1) {
      const changeObj: any = {
        name: "Address Line 1",
        old: oldAddress1,
        new: address1,
      };
      changeArr.push(changeObj);
    }
    if (address2 !== oldAddress2) {
      const changeObj: any = {
        name: "Address Line 2",
        old: oldAddress2,
        new: address2,
      };
      changeArr.push(changeObj);
    }
    if (address3 !== oldAddress3) {
      const changeObj: any = {
        name: "Address Line 3",
        old: oldAddress3,
        new: address3,
      };
      changeArr.push(changeObj);
    }
    if (blockNo !== oldBlockNo) {
      const changeObj: any = {
        name: "Block No.",
        old: oldBlockNo,
        new: blockNo,
      };
      changeArr.push(changeObj);
    }
    if (level !== oldLevel) {
      const changeObj: any = {
        name: "Level No.",
        old: oldLevel,
        new: level,
      };
      changeArr.push(changeObj);
    }
    if (street !== oldStreet) {
      const changeObj: any = {
        name: "Street No.",
        old: oldStreet,
        new: street,
      };
      changeArr.push(changeObj);
    }
    if (unit !== oldUnit) {
      const changeObj: any = {
        name: "Unit No.",
        old: oldUnit,
        new: unit,
      };
      changeArr.push(changeObj);
    }
    if (nric !== oldNric) {
      const changeObj: any = {
        name: "NRIC",
        old: oldNric,
        new: nric,
      };
      changeArr.push(changeObj);
    }
    if (fin !== oldFin) {
      const changeObj: any = {
        name: "FIN",
        old: oldFin,
        new: fin,
      };
      changeArr.push(changeObj);
    }
    if (malaysianIC !== oldMalaysianIC) {
      const changeObj: any = {
        name: "Malaysian I/C",
        old: oldMalaysianIC,
        new: malaysianIC,
      };
      changeArr.push(changeObj);
    }
    if (passport !== oldPassport) {
      const changeObj: any = {
        name: "Passport",
        old: oldPassport,
        new: passport,
      };
      changeArr.push(changeObj);
    }
    if (startPermanentResident !== oldStartPermanentResident) {
      const changeObj: any = {
        name: "Start of Permanent Status",
        old: oldStartPermanentResident,
        new: startPermanentResident,
      };
      changeArr.push(changeObj);
    }
    if (isShgDedcution !== oldIsShgDedcution) {
      const changeObj: any = {
        name: "SHG deduction applicable?",
        old: oldIsShgDedcution,
        new: isShgDedcution,
      };
      changeArr.push(changeObj);
    }
    if (postalCode !== oldPostalCode) {
      const changeObj: any = {
        name: "Postal Code",
        old: oldPostalCode,
        new: postalCode,
      };
      changeArr.push(changeObj);
    }
    if (legalName !== oldLegalName) {
      const changeObj: any = {
        name: "Legal Name",
        old: oldLegalName,
        new: legalName,
      };
      changeArr.push(changeObj);
    }
    if (gender !== oldGender) {
      const changeObj: any = {
        name: "Gender",
        old: oldGender,
        new: gender,
      };
      changeArr.push(changeObj);
    }
    if (bankValue !== oldBankValue) {
      const changeObj: any = {
        name: "Bank Name",
        old: oldBankValue,
        new: bankValue,
      };
      changeArr.push(changeObj);
    }
    if (oldReligion !== religion) {
      const index = relisionList.findIndex(
        (religionItem: any) => religionItem.id == religion
      );
      const oldIndex = relisionList.findIndex(
        (religionItem: any) => religionItem.id == oldReligion
      );
      const changeObj: any = {
        name: "Religion",
        old: relisionList[oldIndex].religion_name,
        new: relisionList[index].religion_name,
      };
      changeArr.push(changeObj);
    }
    if (oldRace !== race) {
      const index = raceList.findIndex((raceItem: any) => raceItem.id == race);
      const oldIndex = raceList.findIndex(
        (raceItem: any) => raceItem.id == oldRace
      );
      const changeObj: any = {
        name: "Race",
        old: raceList[oldIndex].race_name,
        new: raceList[index].race_name,
      };
      changeArr.push(changeObj);
    }
    if (oldLegalStatusSelected !== legalStatusSelected) {
      const index = legalStatus.findIndex(
        (legalStatusItem: any) => legalStatusItem.id == legalStatusSelected
      );
      const oldIndex = legalStatus.findIndex(
        (legalStatusItem: any) => legalStatusItem.id == oldLegalStatusSelected
      );

      const changeObj: any = {
        name: "Legal Status",
        old: legalStatus[oldIndex].name,
        new: legalStatus[index].name,
      };
      changeArr.push(changeObj);
    }
    if (oldAddressTypeSelected !== addressTypeSelected) {
      const index = addressType.findIndex(
        (addressTypeItem: any) => addressTypeItem.id == addressTypeSelected
      );
      const oldIndex = addressType.findIndex(
        (addressTypeItem: any) => addressTypeItem.id == oldAddressTypeSelected
      );

      const changeObj: any = {
        name: "Address Type",
        old: addressType[oldIndex].name,
        new: addressType[index].name,
      };
      changeArr.push(changeObj);
    }
    if (oldNationality !== nationality) {
      const index = countryList.findIndex(
        (countryItem: any) => countryItem.id == payFrequencyVal
      );
      const oldIndex = countryList.findIndex(
        (countryItem: any) => countryItem.id == oldPayFrequencyVal
      );

      const changeObj: any = {
        name: "Nationality",
        old: countryList[oldIndex].nationality,
        new: countryList[index].nationality,
      };
      changeArr.push(changeObj);
    }
    if (oldPayFrequencyVal !== payFrequencyVal) {
      const index = payFrequency
        ? payFrequency.findIndex(
            (payFrequencyItem: any) => payFrequencyItem.id == payFrequencyVal
          )
        : -1;
      const oldIndex = payFrequency
        ? payFrequency.findIndex(
            (payFrequencyItem: any) =>
              payFrequencyItem.id == oldPayFrequencyVal
          )
        : -1;

      const changeObj: any = {
        name: "Pay Frequency",
        old: payFrequency ? payFrequency[oldIndex].name : "",
        new: payFrequency ? payFrequency[index].name : "",
      };
      changeArr.push(changeObj);
    }
    if (oldEmail !== email) {
      const changeObj: any = {
        name: "Email",
        old: oldEmail,
        new: email,
      };
      changeArr.push(changeObj);
    }
    if (changeArr.length === 0) {
      setErrorCollection(() => ({ no_change: "No Changes Done" }));
    } else {
      setChangeObject(() => changeArr);
      setIsRequestCheck(() => true);
    }
  };


  const months= [
    {id:`0`  ,value: "0"},
    {id:`3`  ,value: "3"},
    {id:`6`  ,value: "6"},
    {id:`12`,value:"12"}
  ];
  const deduction= [
    {id:"1", value: 'CDAC'},
   { id:"3", value:'SINDA'},
   {id: "4", value:'ECF'}
  ];

  return isRequestCheck ? (
    <>
      <H3Heading style={{ fontWeight: 500, fontSize: 28 }}>
        New Info Update Request
      </H3Heading>
      <ChangeDetailContainer>
        <ChangeDetailHeadingContainer>
          <p>Request Details</p>
        </ChangeDetailHeadingContainer>
        <ChangeDetailInfoContainer>
          <p style={{ fontSize: 14 }}>
            <strong>
              There is no info update approver assigned to you, or the info
              update approver assigned to you is not yet enabled on
              self-service. Please contact your payroll administrator. The
              request made will be saved for whenever an approver is assigned to
              you.
            </strong>
          </p>
          <H3Heading style={{ fontWeight: 500, fontSize: 25, marginTop: 20 }}>
            Changes
          </H3Heading>
          <IRASTable>
            <TableHeader headerColumns={headerColumn} variant="filled" />
            <tbody>
              {changeObject.map((change: any) => (
                <TableRow>
                  <TableColumn style={{ fontWeight: 600, fontSize: 14 }}>
                    {change.name}
                  </TableColumn>
                  <TableColumn style={{ fontSize: 14 }}>
                    {change.old}
                  </TableColumn>
                  <TableColumn style={{ fontSize: 14 }}>
                    {change.new}
                  </TableColumn>
                </TableRow>
              ))}
            </tbody>
          </IRASTable>
          <div
            style={{
              marginTop: 20,
              display: "flex",
              gap: 10,
              alignItems: "center",
            }}
          >
            <p>
              <strong>Supporting Documents (if applicable)</strong>
            </p>
            <CustomButton
              title="Upload New Files"
              onClick={() => setIsFileUpload(() => true)}
              width="150px"
              size="small"
              styles={{ fontSize: 14 }}
            />
          </div>
          {isFileUpload && (
            <div
              style={{
                display: "flex",
                marginTop: "0.8rem",
                gap: "0.25rem",
                alignItems: "center",
              }}
            >
              <p style={{ color: "#495057", width: "30%", maxWidth: 250 }}>
                Choose file
              </p>
              <div style={{ display: "flex", gap: 20, alignItems: "center" }}>
                <input
                  type="file"
                  name="manual-file"
                  onChange={() => handleFileChange()}
                  ref={fileInputRef}
                  style={{ display: "none" }}
                />
                <Tooltip text={fileUrl ? "Change" : "Upload"}>
                  <CustomButton
                    size="small"
                    width="30px"
                    onClick={() => (fileInputRef.current as any).click()}
                    title=""
                    icon={<FaFileUpload />}
                  />
                </Tooltip>
                <span>{fileUrl?.name || ""}</span>
                {fileUrl && (
                  <Tooltip text="Delete" del>
                    <CustomButton
                      type={"secondary"}
                      size="small"
                      del
                      width="30px"
                      title={""}
                      onClick={() => {
                        setFileUrl(() => null);
                        (fileInputRef.current as any).value = "";
                      }}
                      icon={<AiFillDelete />}
                    />
                  </Tooltip>
                )}
              </div>
            </div>
          )}
          <div style={{ marginTop: 20 }}>
            <p>Optional message to info update approver</p>
            <textarea
              name=""
              id=""
              cols={"40" as any}
              rows={"5" as any}
              value={message}
              onChange={(e: any) => setMessage(e.target.value)}
            />
          </div>
          <ButtonContainer style={{ gap: 0 }}>
            <CustomButton
              title="Edit"
              type={"secondary"}
              onClick={() => setIsRequestCheck(() => false)}
              width="100px"
            />
            <CustomButton
              title="Save"
              onClick={() => sendChangeRequest()}
              width="100px"
            />
          </ButtonContainer>
        </ChangeDetailInfoContainer>
      </ChangeDetailContainer>
    </>
  ) : (
    <div style={{ position: "relative" }}>
      {loading && <Loader />}
      <div>
        {confirmationModalContent && (
          <ConfirmationModal
            confirmationModalContent={confirmationModalContent}
          />
        )}
        <H3Heading>Update Info</H3Heading>
        <div className="new-employee">
          <h4>Basic Details</h4>
        </div>
        <FormErrorMessage name="employee" errorCollection={errorCollection} />

        <CustomTextField
          title="Job Title"
          disabled={true}
          value={jobTitle}
          onChangeText={(text) => {
            setJobTitle(text);
          }}
          error={
            Object.keys(errorCollection)?.includes("job_title") &&
            errorCollection.job_title
          }
          onFocus={() => updatedErrorByKey("job_title")}
          require={true}
        />
        
        <CustomSelectField
          title="Pay Frequency"
          disabled={true}
          value={payFrequencyVal}
          onChangeOption={(option) => setPayFrequencyVal(option)}
          option={
            (payFrequency &&
              payFrequency.length > 0 &&
              payFrequency?.map((item) => ({
                key: item.name,
                value: item.id,
              }))) ||
            []
          }
          require={true}
        />
        <CustomSelectField
          title="Legal Name format"
          disabled={true}
          value={nameFormat}
          onChangeOption={(option) => {
            setNameFormat(option);
            handleLegalNameFormat(option);
            if (legalName == "Single Name") {
              setTimeout(() => {
                setLastName("");
              }, 200);
            }
          }}
          option={[
            { key: "Last Name First Name", value: "Last Name First Name" },
            { key: "First Name Last Name", value: "First Name Last Name" },
            { key: "Single Name", value: "Single Name" },
          ]}
          require={true}
        />
        <CustomTextField
          title="First Name (Given Name)"
          disabled={true}
          value={firstName}
          onChangeText={setFirstName}
          error={
            Object.keys(errorCollection)?.includes("first_name") &&
            errorCollection.first_name
          }
          onFocus={() => updatedErrorByKey("first_name")}
          require={true}
        />
        {legalName !== "Single Name" && (
          <CustomTextField
            title="Last Name (Family Name)"
            disabled={true}
            value={lastName}
            onChangeText={setLastName}
            error={
              Object.keys(errorCollection)?.includes("last_name") &&
              errorCollection.last_name
            }
            onFocus={() => updatedErrorByKey("last_name")}
            require={true}
          />
        )}
        <CustomDateField
          label="Date of Birth"
          startYear={1900}
          disabled={true}
          date={startDate || null}
          setDate={(date: any) => setStartDate(date)}
          error={
            Object.keys(errorCollection)?.includes("date_of_birth") &&
            errorCollection.date_of_birth
          }
          onFocus={() => updatedErrorByKey("date_of_birth")}
          require={true}
        />
        <CustomDateField
          label="Date of Appointment"
          disabled={true}
          date={startDate1 || null}
          setDate={(date: any) => setStartDate1(date)}
          error={
            Object.keys(errorCollection)?.includes("date_of_appointment") &&
            errorCollection.date_of_appointment
          }
          onFocus={() => updatedErrorByKey("date_of_appointment")}
          require={true}
        />
        <div style={{}}>
          <div  style={{width:"50%"}}>
          <CustomSelectField
          title="Select"
          disabled={true}
          placeholder={"Probation Months"}
          value={probationMonths}
          defaultValue={probationMonths}
          onChangeOption={(option) => {
            setProbationMonths(option);
          }}
          option={
            months?.map((item: any) => ({
              key: item.id,
              value: item.value,
            })) || []
          }
          
          onFocus={() => updatedErrorByKey("probation_months")}
          require={true}
        />
        </div>
        <div style={{width:"100%", display: 'flex'}}>
<CustomDateField
          label="Last Date of Probation"
          disabled={true}
          date={probationLastDate || null}
          setDate={(date: any) => setProbationLastDate(date)}
          error={
            Object.keys(errorCollection)?.includes("probation_last_date") &&
            errorCollection.probation_last_date
          }
          onFocus={() => updatedErrorByKey("probation_last_date")}
          require={true}
        />
        <div style={{width:"50%" , alignContent:"center"}}>
 {/* {(isProbationConfirmed)
        ? <span style={{color: '#fff', backgroundColor:"#03b381",fontWeight:500, padding: '4px 8px', borderRadius: '4px'}}>Confirmed on {moment(probationConfirmedDate).format("DD/MM/YYYY")}</span>
        :<span style={{color: '#fff', backgroundColor:"#ff6665",fontWeight:500, padding: '4px 8px', borderRadius: '4px'}}>Probation not Confirmed</span>
        } */}
        </div>
        </div>
        </div>
        <CustomSelectField
          title="Legal Status"
          disabled={true}
          placeholder={"Select Legal Status"}
          value={legalStatusSelected || ""}
          onChangeOption={(option) => {
            setLegalStatusSelected(option);
          }}
          option={
            legalStatus?.map((item: any) => ({
              key: item.name,
              value: item.id,
            })) || []
          }
          error={
            Object.keys(errorCollection)?.includes("legal_status") &&
            errorCollection.legal_status
          }
          onFocus={() => updatedErrorByKey("legal_status")}
          require={true}
        />
        {(legalStatusSelected == "3" ||
          legalStatusSelected == "4" ||
          legalStatusSelected == "5" ||
          legalStatusSelected == "6") && (
          <CustomeCkeckboxField
            title="SHG deduction applicable?"
            value={isShgDedcution}
            onChange={() => setIsShgDeduction((prev) => !prev)}
          />
        )}
        {(legalStatusSelected == "1" || legalStatusSelected == "2") && (
          <CustomTextField
            title="NRIC"
            value={nric}
            onBlur={handleNRICValidate}
            onChangeText={(text) => setNric(text)}
            // disabled={is_contain_finlaized_payslip}
            disabled={true}
            error={
              (Object.keys(errorCollection)?.includes("non_field_errors") ||
                Object.keys(errorCollection)?.includes("nric")) &&
              (errorCollection.nric || errorCollection.non_field_errors)
            }
            onFocus={() => updatedErrorByKey("non_field_errors")}
            require={true}
            
          />
        )}
        {(legalStatusSelected == "2" ||
          legalStatusSelected == "3" ||
          legalStatusSelected == "4" ||
          legalStatusSelected == "6") && (
          <CustomSelectField
            title="Nationality"
            placeholder={"Select Nationality"}
            value={nationality || ""}
            onChangeOption={(option) => {
              setNationality(option);
            }}
            option={
              countryList?.map((item: any) => ({
                key: item.nationality,
                value: item.id,
              })) || []
            }
            error={
              Object.keys(errorCollection)?.includes("nationality") &&
              errorCollection.nationality
            }
            onFocus={() => updatedErrorByKey("nationality")}
            require={true}
          />
        )}
        {legalStatusSelected == "2" && (
          <>
            <CustomDateField
              label="Start of Permanent Status"
              date={startPermanentResident || null}
              setDate={(date: any) => setStartPermanentResident(date)}
              require
              error={
                Object.keys(errorCollection)?.includes(
                  "start_permanent_date"
                ) && errorCollection.start_permanent_date
              }
              onFocus={() => updatedErrorByKey("start_permanent_date")}
              startYear={1900}
            />
          </>
        )}
        {(legalStatusSelected == "3" || legalStatusSelected == "4") && (
          <CustomTextField
            title="FIN"
            disabled={is_contain_finlaized_payslip}
            value={fin}
            require
            onBlur={handleFINValidate}
            onChangeText={setFin}
            error={
              Object.keys(errorCollection)?.includes("fin") &&
              errorCollection.fin
            }
            onFocus={() => updatedErrorByKey("fin")}
          />
        )}

        {legalStatusSelected == "5" && (
          <CustomTextField
            title="Malaysian I/C"
            value={malaysianIC}
            onChangeText={setMalaysianIC}
            require
            error={
              Object.keys(errorCollection)?.includes("malaysian_ic") &&
              errorCollection.malaysian_ic
            }
            onFocus={() => updatedErrorByKey("malaysian_ic")}
          />
        )}

        {legalStatusSelected == "6" && (
          <CustomTextField
            title="Passport"
            value={passport}
            onChangeText={setPassport}
            require
            error={
              Object.keys(errorCollection)?.includes("passport") &&
              errorCollection.passport
            }
            onFocus={() => updatedErrorByKey("passport")}
          />
        )}
        <CustomSelectField
          title="Gender"
          disabled={true}
          value={gender}
          onChangeOption={(option) => {
            setGender(option);
          }}
          placeholder="Select Gender"
          option={[
            { key: "Male", value: "Male" },
            { key: "Female", value: "Female" },
          ]}
          error={
            Object.keys(errorCollection)?.includes("gender") &&
            errorCollection.gender
          }
          onFocus={() => updatedErrorByKey("gender")}
          require={true}
        />
        <CustomSelectField
          title="Religion"
          disabled={true}
          value={religion}
          onChangeOption={(option) => {
            setReligion(option);
          }}
          placeholder="Select Religion"
          option={
            relisionList?.map((item: any) => ({
              key: item.religion_name,
              value: item.id,
            })) || []
          }
          error={
            Object.keys(errorCollection)?.includes("religion") &&
            errorCollection.religion
          }
          onFocus={() => updatedErrorByKey("religion")}
          require={true}
        />
        <CustomSelectField
          title="Race"
          disabled={true}
          value={race}
          onChangeOption={(option) => {
            setRace(option);
          }}
          placeholder="Select Race"
          option={
            raceList?.map((item: any) => ({
              key: item.race_name,
              value: item.id,
            })) || []
          }
          error={
            Object.keys(errorCollection)?.includes("race") &&
            errorCollection.race
          }
          onFocus={() => updatedErrorByKey("race")}
          require={true}
        />
         {( religion == "4" && ["1","3","4"]?.includes(race || "" ) &&
        <CustomeCkeckboxField
        title={`Deduct ${deduction?.find((item)=>item?.id==race)?.value} for Muslim-${raceList?.find((item: { [x: string]: string; })=>item?.['id']==race)?.['race_name']}`}
        disabled={true}
        value={dual_shg_deduction}
        onChange={() => set_Dual_shg_deduction((p) => !p)}
        error={
          Object.keys(errorCollection)?.includes("is_dual_shg_deduction") &&
          errorCollection.is_dual_shg_deduction
        }
          
        />)}
        <CustomeCkeckboxField
          title="SDL Exempt"
          disabled={true}
          value={sdl}
          onChange={() => setSdl((p) => !p)}
          error={
            Object.keys(errorCollection)?.includes("is_sdl") &&
            errorCollection.is_sdl
          }
          // onFocus={() => updatedErrorByKey("is_sdl")}
        />
        <CustomeCkeckboxField
          title="CPF Exempt"
          disabled={true}
          value={cpf}
          onChange={() => setCpf((p) => !p)}
          error={
            Object.keys(errorCollection)?.includes("is_cpf") &&
            errorCollection.religion
          }
          // disabled={cpfDeduction}
          // onFocus={() => updatedErrorByKey("religion")}
        />
        <CustomTextField
          title="Email"
          value={email}
          onChangeText={(text) => {
            setEmail(text);
          }}
        />
        <CustomNumberField
          title="Hand Phone"
          value={phone}
          onBlur={handlePhoneValidation}
          placehoder=""
          removeNumIcon={true}
          onChangeText={(text) => {
            validatePhone(text);
          }}
          note="enter 8 digit handphone number without +65 area code"
          error={
            Object.keys(errorCollection)?.includes("phone") &&
            errorCollection.phone
          }
          onFocus={handlePhoneValidation}
        />
        <CustomSelectField
          title="Payment Method"
          disabled={true}
          value={paymentMethod}
          placeholder="Select Payment Method"
          onChangeOption={(option) => {
            setPaymentMethod(option);
          }}
          option={payMethods?.map((item: any) => ({
            key: item.payment_method_name,
            value: item.id,
          }))}
          error={
            Object.keys(errorCollection)?.includes("payment_method") &&
            errorCollection.payment_method
          }
          onFocus={() => updatedErrorByKey("payment_method")}
          require={true}
        />
        {paymentMethod == "5" && (
          <div className="new-employee">
            <h4>Bank Details</h4>
          </div>
        )}

        {paymentMethod == "5" && (
          <CustomGroupSelectField
            title="Bank Name"
            value={bankValue}
            placeholder="Select Bank"
            onChangeOption={(option) => {
              setBankValue(option);
            }}
            option={bankList}
            error={
              Object.keys(errorCollection)?.includes("bank_name") &&
              errorCollection.bank_name
            }
            onFocus={() => updatedErrorByKey("bank_name")}
            require={true}
          />
        )}
        {paymentMethod == "5" && (
          <CustomTextField
            title="Account Number"
            value={alphaNumericInput}
            onChangeText={(text) => {
              setAlphaNumericInput(text);
              checkAccountNoAlphanumeric(text);
            }}
            error={
              Object.keys(errorCollection)?.includes("bank_account_number") &&
              errorCollection.bank_account_number
            }
            onFocus={() => updatedErrorByKey("bank_account_number")}
            require={true}
          />
        )}
        {paymentMethod == "5" && (
          <CustomTextField
            title="Branch Code"
            value={branchCode}
            require
            onChangeText={(text) => {
              setBranchCode(text);
              checBranchCodeAlphanumeric(text);
            }}
            error={
              Object.keys(errorCollection)?.includes("bank_branch_code") &&
              errorCollection.bank_branch_code
            }
            onFocus={() => updatedErrorByKey("bank_branch_code")}
          />
        )}
        <div className="new-employee">
          <h4>Address</h4>
        </div>
        <CustomSelectField
          title="Type"
          value={addressTypeSelected || ""}
          placeholder="Select address"
          onChangeOption={(option) => {
            handleAddressType(option);
          }}
          option={addressType?.map((item: any) => ({
            key: item.name,
            value: item.id,
          }))}
          error={
            Object.keys(errorCollection)?.includes("address_type") &&
            errorCollection.address_type
          }
          onFocus={() => updatedErrorByKey("address_type")}
          require={true}
        />
        {addressTypeSelected == "1" && (
          <>
            <div className="new-employee">
              <CustomTextField
                title="Block No"
                value={blockNo}
                onChangeText={(text) => {
                  setBlockNo(text);
                }}
                error={
                  Object.keys(errorCollection)?.includes("block_no") &&
                  errorCollection.block_no
                }
                onFocus={() => updatedErrorByKey("block_no")}
                require={true}
              />
              <CustomTextField
                title="Street Name"
                value={street}
                onChangeText={(text) => {
                  setStreet(text);
                }}
                error={
                  Object.keys(errorCollection)?.includes("street_name") &&
                  errorCollection.street_name
                }
                onFocus={() => updatedErrorByKey("street_name")}
              />
            </div>
            <div className="new-employee">
              <CustomNumberField
                title="Level No"
                value={level}
                placehoder=""
                onChangeText={(text) => {
                  setLevel(text);
                }}
                error={
                  Object.keys(errorCollection)?.includes("level_no") &&
                  errorCollection.level_no
                }
                onFocus={() => updatedErrorByKey("level_no")}
              />
              <CustomNumberField
                title="Unit No"
                placehoder=""
                value={unit}
                onChangeText={(text) => {
                  setUnit(text);
                }}
                error={
                  Object.keys(errorCollection)?.includes("unit_no") &&
                  errorCollection.unit_no
                }
                onFocus={() => updatedErrorByKey("unit_no")}
              />
            </div>
          </>
        )}
        {(addressTypeSelected == "2" || addressTypeSelected == "3") && (
          <>
            <CustomTextField
              title="Address Line1"
              value={address1}
              onChangeText={(text) => {
                setAddress1(text);
              }}
              error={
                Object.keys(errorCollection)?.includes("address_line_1") &&
                errorCollection.address_line_1
              }
              onFocus={() => updatedErrorByKey("address_line_1")}
            />
            <CustomTextField
              title="Address Line2"
              value={address2}
              onChangeText={(text) => {
                setAddress2(text);
              }}
              error={
                Object.keys(errorCollection)?.includes("address_line_2") &&
                errorCollection.address_line_2
              }
              onFocus={() => updatedErrorByKey("address_line_2")}
            />
            <CustomTextField
              title="Address Line3"
              value={address3}
              onChangeText={(text) => {
                setAddress3(text);
              }}
              error={
                Object.keys(errorCollection)?.includes("address_line_3") &&
                errorCollection.address_line_3
              }
              onFocus={() => updatedErrorByKey("address_line_3")}
            />
          </>
        )}
        {(addressTypeSelected == "1" ||
          addressTypeSelected == "2" ||
          addressTypeSelected == "3") && (
          <div
            style={{
              width: addressTypeSelected == "1" ? "50%" : "100%",
            }}
          >
            <CustomNumberField
              title="Postal Code"
              value={`${postalCode}`}
              placehoder=""
              removeNumIcon={true}
              onChangeText={(text) => {
                setPostalCode(text);
                checkPostalCodeNoAlphanumeric(text);
              }}
              error={
                Object.keys(errorCollection)?.includes("postal_code") &&
                errorCollection.postal_code
              }
              onFocus={() => updatedErrorByKey("postal_code")}
              require={true}
            />
          </div>
        )}
        {addressTypeSelected == "2" && (
          <CustomSelectField
            title="Country Code"
            value={countryCode}
            onChangeOption={(option) => {
              setCountryCode(option);
            }}
            placeholder="Select Country Code"
            option={
              countryList?.map((item: any) => ({
                key: item.nationality,
                value: item.id,
              })) || []
            }
            error={
              Object.keys(errorCollection)?.includes("country") &&
              errorCollection.country
            }
            onFocus={() => updatedErrorByKey("country")}
          />
        )}
        <div className="new-employee">
          <h4>Other Statutory Info</h4>
        </div>
        
        <div
          style={{
            marginTop: "1rem",
            marginBottom: "1rem",
            display: "flex",
          }}
        >
          <CustomButton
            title="Cancel"
            type={"secondary"}
            onClick={handleNavigateBack}
          />
          <CustomButton
            title="Check Request"
            onClick={() => checkChangedValue()}
          />
        </div>
      </div>
    </div>
  );
};
export default EditInfoUpdate;
