import apiClient from "./BaseService";
import { URL } from "../config";

const _getAllUsers = async () => apiClient.get(URL.GET_ALL_USERS, {});

// const _addRole = async (name: string) => {
//
// 	const data = { name };
// 	return apiClient.post(URL.ADD_USER_ROLE, data, {
// 		headers: { Authorization: `Bearer ${access}` },
// 	});
// };

// const _getAllPermissions = async () => {
//
// 	return apiClient.get(URL.GET_ALL_PERMISSIONS, {
// 		headers: { Authorization: `Bearer ${access}` },
// 	});
// };

// const _getAllSections = async () => {
//
// 	return apiClient.get(URL.GET_ALL_SECTIONS, {
// 		headers: { Authorization: `Bearer ${access}` },
// 	});
// };

const _updateUserPermissions = async (data: any) =>
  apiClient.post(URL.GET_USER_PERMISSIONS, data);

const _getUserPermissions = async (userId: number) =>
  apiClient.get(`${URL.GET_USER_PERMISSIONS}?user_id=${userId}`);

const _deactivateUser = async (data: any) =>
  apiClient.post(URL.DEACTIVATE_USER, data);

const _reactivateUser = async (data: any) =>
  apiClient.post(URL.REACTIVATE_USER, data);

const _deleteUser = async (userId: number) =>
  apiClient.delete(`${URL.GET_ALL_USERS}${userId}`);

const _createUser = async (data: any) =>
  apiClient.post(URL.GET_ALL_USERS, data);

const _getUserInformation = async (userId: number) =>
  apiClient.get(`${URL.GET_ALL_USERS}${userId}`);

const _checkUserExist = async (email: string) =>
  apiClient.get(`${URL.CHECK_USER_EXIST}${encodeURIComponent(email)}`);

const _updateUser = async (userId: number, data: any) =>
  apiClient.patch(`${URL.GET_ALL_USERS}${userId}/`, data);

const _getEmployeeIdFromCompany = async (companyId: string) => 
  apiClient.get(`${URL.GET_EMPLOYEE_ID_FROM_COMPANY}${companyId}`)

export const UserService = {
  _getAllUsers,
  _updateUserPermissions,
  _getUserPermissions,
  _deactivateUser,
  _createUser,
  _getUserInformation,
  _deleteUser,
  _reactivateUser,
  _updateUser,
  _getEmployeeIdFromCompany,
  _checkUserExist,
  // _addRole,
  // _getAllPermissions,
  // _getAllSections,
};
